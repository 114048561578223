/* 条件查询 */


/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Form, Select, Input, Button, Row, Col } from 'antd'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { CONSULTATION_STATUS } from '../../../utils/constants'

/* 调用模块: 对象结构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  query: PropTypes.bool.isRequired,
  setCondition: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const { query, setCondition, setPage } = props

  // 提交表单
  const [form] = Form.useForm()

    /**
   * 查询
   * 按钮：选择状态
   * @param {*} value
   */
     const onChangeStatus = (value) => {
      setPage()
      setCondition({ status: value })
    }

  /**
   * 查询
   * 按钮：点击查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPage()
    setCondition(value)
  }

  /**
   * 按钮
   * 点击重置
   */
  const onReset = () => {
    form.resetFields()
    setPage()
    setCondition({
      hospitalizationCards: undefined,
      searchData: undefined,
      status: undefined
    })
  }

  /**
   * 渲染
   */
  if (!query) return null
  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='hospitalizationCards' label='住院号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='姓名'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
            <Select style={{ width: 174 }} onChange={onChangeStatus}>
              {CONSULTATION_STATUS &&
                CONSULTATION_STATUS.map((v) => (
                  <Option key={v.enumId} value={v.enumId}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <div>
            <Button className='g_mr_20' type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 失控历史 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Tooltip, Button } from 'antd'
import { SelectOutlined, DropboxOutlined, PlusOutlined, RollbackOutlined, EditOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import RunawayReport from './RunawayReport'
import RunawayForm from './RunawayForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqRunaways } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
Runaway.propTypes = {
  qc: PropTypes.object,
  goBack: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function Runaway(props) {
  const { qc, goBack } = props
  // console.log(qc);

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 失控历史记录
  const [runaways, setRunaways] = useState([])

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 操作相关参数
  const [operation, setOperation] = useState({
    action: undefined, // add：新增失控 | up：编辑失控 | see：查看失控报告
    isShow: false,
    data: {}
  })

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '失控时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center'
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      width: 100,
      align: 'center',
      render: (_, { createBy }) => createBy.name
    },
    {
      title: '触发规则',
      dataIndex: 'determine',
      width: '',
      align: 'center',
      render: (_, { determine }) => <span className='g_color_red'>{determine}</span>
    },
    {
      title: '失控原因',
      dataIndex: 'outOfControlReason',
      ellipsis: true,
      align: 'center'
    },
    {
      title: '失控处理',
      dataIndex: 'outOfControlHandle',
      ellipsis: true,
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <div>
          <Tooltip title='编辑失控' mouseEnterDelay={0.5}>
            <Button type='link' icon={<EditOutlined />} onClick={() => onShowOperation('up', record)} />
          </Tooltip>
          <Tooltip title='查看失控报告' mouseEnterDelay={0.5}>
            <Button type='link' icon={<SelectOutlined />} onClick={() => onShowOperation('see', record)} />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取失控历史列表
   * @param {*} params
   */
  const getRunaways = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows },
        status
      } = await reqRunaways(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setRunaways(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 操作
   * 新增 / 编辑失控 / 查看报告
   * @param {*} action
   * @param {*} data
   */
  const onShowOperation = (action, data) => {
    switch (action) {
      case 'add':
      case 'up':
        setOperation({ action, data })
        break
      default:
        setOperation({ action, isShow: true, data })
        break
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getRunaways({ qualityControlItemId: qc.data.id, pageNo: page.pageNo, pageSize: page.pageSize })
  }, [qc.data.id, page.pageNo, page.pageSize])

  /**
   * 渲染
   */
  if (!operation.isShow) {
    return (
      <>
        <Card
          className='g_h_percent'
          size='small'
          title={
            <>
              <DropboxOutlined className='g_mr_10' />
              <span>失控历史</span>
            </>
          }
          extra={
            <>
              <Button
                className='g_ml_10'
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => onShowOperation('add', {})}
              >
                新增
              </Button>
              <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={() => goBack()}>
                返回
              </Button>
            </>
          }
        >
          <Table
            bordered
            size='small'
            rowKey='rid'
            loading={isLoading}
            columns={initColumns}
            dataSource={runaways}
            pagination={{
              current: page.pageNo,
              pageSize: page.pageSize,
              total: total,
              showTotal: (total) => `共 ${total} 条记录`,
              showQuickJumper: true,
              onChange: onChangePage
            }}
          />
        </Card>

        <IModal
          visible={operation.action === 'add' || operation.action === 'up'}
          titleText={operation.action === 'add' ? '添加失控' : '编辑失控'}
        >
          <RunawayForm
            qc={qc}
            operation={operation}
            setOperation={() => setOperation({ ...operation, action: undefined })}
            refreshRunaways={() =>
              getRunaways({ qualityControlItemId: qc.data.id, pageNo: page.pageNo, pageSize: page.pageSize })
            }
          />
        </IModal>
      </>
    )
  }

  return <RunawayReport qc={qc} operation={operation} setOperation={() => setOperation({ ...operation, isShow: false })} />
}

export default Runaway

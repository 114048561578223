/* 质控报表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Tooltip } from 'antd'
import { FileExcelOutlined, FilterOutlined, ExportOutlined } from '@ant-design/icons'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import QcReportExportForm from './QCReportExportForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqProjectBindings } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { MONITOR, PAGE_SIZE, QC_REPORT_STATUS } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Report() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询 / 隐藏显示控制
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined,
    officeId: undefined,
    type: undefined,
    instrumentId: undefined,
    controlProjectId: undefined
  })

  // 质控报表记录
  const [qcReports, setQcReports] = useState([])

  // 质控报表一行的记录
  const [row, setRow] = useState({})

  // 弹框相关参数
  const [modal, setModal] = useState({
    isShow: false,
    data: {}
  })

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '医院',
      dataIndex: 'company',
      ellipsis: true,
      align: 'center',
      render: (_, { company }) => company.name
    },
    {
      title: '科室',
      dataIndex: 'office',
      ellipsis: true,
      align: 'center',
      render: (_, { office }) => office.name
    },
    {
      title: '监测类型',
      dataIndex: 'type',
      align: 'center',
      render: (_, { type }) => MONITOR[type - 1].name
    },
    {
      title: '型号',
      dataIndex: 'instrumentModel',
      ellipsis: true,
      align: 'center',
      render: (_, { instrument }) => instrument.modelVO.name
    },
    {
      title: 'SN号',
      dataIndex: 'instrumentSnnum',
      ellipsis: true,
      align: 'center',
      render: (_, { instrument }) => instrument.snnum
    },
    {
      title: '项目名称',
      dataIndex: 'controlProject',
      align: 'center',
      render: (_, { controlProject }) => controlProject.name
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'controlProjectStatus',
      width: 60,
      align: 'center',
      render: (_, { controlProjectStatus }) =>
        controlProjectStatus ? (
          <span style={{ color: 'green' }}>{QC_REPORT_STATUS[controlProjectStatus].name}</span>
        ) : (
          <span style={{ color: 'red' }}>
            {controlProjectStatus === 0 && QC_REPORT_STATUS[controlProjectStatus].name}
          </span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 60,
      align: 'center',
      render: (_, record) => (
        <div className='g_action'>
          <Tooltip title='导出' mouseEnterDelay={0.5}>
            <Button type='link' icon={<ExportOutlined />} onClick={() => onShowModal(record)} />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取质控报表记录
   */
  const getQcReports = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqProjectBindings(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setQcReports(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 查询
   * 按钮：条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   *
   * @param {*} record
   */
  const onShowModal = (data) => {
    setModal({ isShow: true, data })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, _) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getQcReports({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })

    return () => {
      setQcReports((_) => {
        return
      })
    }
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <FileExcelOutlined className='g_mr_10' />
            <span>质控报表</span>
          </>
        }
        extra={
          <Button className='g_ml_10' icon={<FilterOutlined />} onClick={onShowQuery}>
            {query ? '隐藏' : '查询'}
          </Button>
        }
      >
        {query && (
          <QueryForm
            monitor={MONITOR}
            setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          scroll={{ x: 1400, y: 450 }}
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={qcReports}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
         rowClassName={showRowClassName}
          onRow={(record) => {
            return {
              onClick: () => {
                setRow(record)
              }
            }
          }}
        />
      </Card>

      <IModal visible={modal.isShow} titleText='质控报表导出'>
        <QcReportExportForm modal={modal.data} setModal={() => setModal({ ...modal, isShow: false })} />
      </IModal>
    </>
  )
}

export default Report

/* App入口 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import 'moment/locale/zh-cn'

/* 功能模块
---------------------------------------------------------------- */
import storageUtils from './utils/storageUtils'
import memoryUtils from './utils/memoryUtils'

/* 全局样式
---------------------------------------------------------------- */
import './assets/styles/global.less'

/**
 * 如果 local 中保存了 user, 将 user 保存到内存中
 */
const user = storageUtils.getUser()
if (Object.keys(user).length > 0) {
  memoryUtils.user = user
}

/* 唯一入口
---------------------------------------------------------------- */
const Root = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  )
}
ReactDOM.render(<Root />, document.getElementById('root'))

/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Select, Button, Row, Col } from 'antd'

/* 功能模块
---------------------------------------------------------------- */
import { reqProjects, reqInstruments } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  setPage: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const [form] = Form.useForm()

  const { monitor, setPage, setCondition } = props

  // 监测类型 id
  const [typeId, setTypeId] = useState(undefined)

  // 监测类型对应的项目、仪器列表
  const [typeToList, setTypeToList] = useState({
    projects: [], // 项目
    instruments: [] // 仪器
  })

  /**
   * 查询
   * 选择框：选择监测类型
   */
  const onChangeType = (value) => {
    form.setFieldsValue({ instrumentId: undefined, controlProjectId: undefined })
    setTypeId(value)
  }

  const getTypeToList = async (typeId) => {
    if (typeId) {
      let params = {}
      const resOne = await reqProjects({ type: typeId, status: 1 })
      if (resOne.status) {
        params.projects = resOne.data
      }
      const resTwo = await reqInstruments({ type: typeId, status: 1, pageNo: 1, pageSize: PAGE_SIZE_MAX })
      if (resTwo.status) {
        params.instruments = resTwo.data.resultList
      }
      setTypeToList(params)
    }
  }

  /**
   * 查询
   * 按钮：点击查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPage()
    setCondition(value)
  }

  /**
   * 查询
   * 按钮：点击重置
   */
  const onReset = () => {
    form.resetFields()
    setTypeId(undefined)
    setTypeToList({ projects: [], instruments: [] })
    setPage()
    setCondition({
      type: undefined,
      instrumentId: undefined,
      controlProjectId: undefined
    })
  }

  useEffect(() => {
    getTypeToList(typeId)
  }, [typeId])

  /**
   * 渲染
   */
  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='type' label='监测类型'>
            <Select className='g_w_174' onChange={onChangeType}>
              {monitor.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='instrumentId' label='仪器'>
            <Select
              className='g_w_174'
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {typeId &&
                typeToList.instruments.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.brand.name + '  ' + v.modelVO.name + '  ' + v.snnum}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='controlProjectId' label='项目'>
            <Select className='g_w_174'>
              {typeToList.projects.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Input, Select } from 'antd'

/* 调用模块: 对象结构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  statusType: PropTypes.array.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块调用
---------------------------------------------------------------- */
function QueryForm(props) {
  const [form] = Form.useForm()

  const { statusType, setCondition } = props

  /**
   * 查询
   * 按钮：选择状态
   * @param {*} value
   */
  const onChangeStatus = (value) => {
    setCondition({ status: value })
  }

  /**
   * 查询
   * 按钮：点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setCondition(value)
  }

  /**
   * 查询
   * 按钮：点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setCondition({
      searchData: undefined,
      status: undefined
    })
  }

  /**
   * 渲染
   */
  return (
    <Form className='g_mb_10' layout='inline' form={form} onFinish={onOkQuery}>
      <Item name='searchData' label='医院'>
        <Input />
      </Item>
      <Item name='status' label='状态'>
        <Select style={{ width: 70 }} allowClear onChange={onChangeStatus}>
          {statusType.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>

      <div>
        <Button className='g_mr_10' type='primary' htmlType='submit'>
          查询
        </Button>
        <Button onClick={onReset}>重置</Button>
      </div>
    </Form>
  )
}

export default QueryForm

/* 次批号列表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Tooltip, Button, Modal, message } from 'antd'
import {
  DropboxOutlined,
  EditOutlined,
  StopOutlined,
  PauseCircleOutlined,
  PlusOutlined,
  RollbackOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import SubBNForm from './SubBNForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqLiquidSubBNs, reqAddOrEditLiquidSubBN } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { QC_LEVEL, LIQUID_SUBBN_STATUS } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 使用类型检查
---------------------------------------------------------------- */
SubBatchNumber.propTypes = {
  operation: PropTypes.object.isRequired,
  setOperation: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function SubBatchNumber(props) {
  const { operation, setOperation } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 切换: 录入 / 编辑
  const [modal, setModal] = useState({
    action: undefined, // 操作行为
    isShow: false, // 显示状态
    data: {} // 数据
  })

  // 质控液主批号对应的子批号列表
  const [subBNs, setSubBNs] = useState([])

  // 录入 / 编辑次批号表单信息 ref
  const [form, setForm] = useState(undefined)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '水平 - 质控液次批号',
      dataIndex: 'name',
      align: 'left'
    },
    {
      title: '效期',
      dataIndex: 'expireDate',
      width: 150,
      align: 'center',
      render: (text, { expireDate }) => expireDate && moment(expireDate).format('YYYY-MM-DD')
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      align: 'center',
      render: (text, { status }) => {
        if (status !== undefined) {
          return status ? (
            <span style={{ color: 'green' }}>{LIQUID_SUBBN_STATUS[status].name}</span>
          ) : (
            <span style={{ color: 'red' }}>{LIQUID_SUBBN_STATUS[status].name}</span>
          )
        }
      }
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (record.status !== undefined) {
          return (
            <div className='g_action'>
              <Tooltip title='编辑次批号' mouseEnterDelay={0.5}>
                <Button
                  type='link'
                  icon={<EditOutlined />}
                  onClick={() => onShowModal('up', record)}
                  disabled={record.status ? false : true}
                />
              </Tooltip>
              <Tooltip title={record.status ? '停用次批号' : '启用次批号'} mouseEnterDelay={0.5}>
                <Button
                  type='link'
                  icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
                  onClick={() => onShowStatus(record)}
                />
              </Tooltip>
            </div>
          )
        }
      }
    }
  ]

  /**
   *  加工 单条质控液记录 获取质控液主批号对应的子批号列表
   * @param {*} params
   */
  const getLiquidSubNBs = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqLiquidSubBNs(params)
      if (status) {
        const { levelNum, level1, level2, level3 } = data
        // console.log(level1, level2, level3, levelNum)
        let result
        switch (levelNum) {
          case 1:
            result = [
              {
                key: '1',
                name: '水平1',
                children: !level1
                  ? []
                  : level1.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `1${v.id}`
                      }
                    })
              }
            ]
            break

          case 2:
            result = [
              {
                key: '1',
                name: '水平1',
                children: !level1
                  ? []
                  : level1.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `1${v.id}`
                      }
                    })
              },
              {
                key: '2',
                name: '水平2',
                children: !level2
                  ? []
                  : level2.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `2${v.id}`
                      }
                    })
              }
            ]
            break

          default:
            result = [
              {
                key: '1',
                name: '水平1',
                children: !level1
                  ? []
                  : level1.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `1${v.id}`
                      }
                    })
              },
              {
                key: '2',
                name: '水平2',
                children: !level2
                  ? []
                  : level2.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `2${v.id}`
                      }
                    })
              },
              {
                key: '3',
                name: '水平3',
                children: !level3
                  ? []
                  : level3.map((v) => {
                      return {
                        ...v,
                        name: v.batchNum,
                        key: `2${v.id}`
                      }
                    })
              }
            ]
            break
        }
        setSubBNs(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表操作
   * 按钮：点击显示对应的弹框
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
    if(action === 'add') {
      if (form) form.resetFields()
    }
  }

  /**
   * 列表操作
   * 按钮：点击启用 / 停用试剂
   * @param {*} value
   */
  const onShowStatus = (value) => {
    const { id, status } = value
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该次批号吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该次批号吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const res = await reqAddOrEditLiquidSubBN({ id, status: status ? 0 : 1, controlThingsId: operation.data.id })
        let msg // 提示信息
        if (res.status) {
          if (status) {
            msg = '停用试剂成功！'
          } else {
            msg = '启用试剂成功！'
          }
          message.success(msg)
          getLiquidSubNBs({ id: operation.data.id })
        }
      }
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getLiquidSubNBs({ id: operation.data.id })
  }, [operation.data.id])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <DropboxOutlined className='g_mr_10' />
            <span className='g_mr_10'>质控液主批号</span>
            <span>( {operation.data.thingsBatchNumber} )</span>
          </>
        }
        extra={
          <>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => onShowModal('add', {})}>
              录入次批号
            </Button>
            <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={() => setOperation()}>
              返回
            </Button>
          </>
        }
      >
        <Table bordered size='small' rowKey='key' loading={isLoading} columns={initColumns} dataSource={subBNs} />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '录入次批号' : '编辑次批号'}>
        <SubBNForm
          level={QC_LEVEL}
          levelNum={operation.data.levelNum}
          liquidId={operation.data.id}
          modal={modal}
          setModal={() => setModal({ ...modal, isShow: false })}
          refreshLiquidSubBNs={() => getLiquidSubNBs({ id: operation.data.id })}
          setForm={(form) => setForm(form)}
        />
      </IModal>
    </>
  )
}

export default SubBatchNumber

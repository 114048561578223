/* 主界面 */

/* 调用模块
---------------------------------------------------------------- */
import React, { Component } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'

/* 路由组件
---------------------------------------------------------------- */
// 患者管理
import PatientList from '../pages/Patient/List'
import PatientCreate from '../pages/Patient/Create'
import PatientQuery from '../pages/Patient/Query'
import Consultation from '../pages/Patient/Consultation'

// 床旁监测
import BloodSugar from '../pages/Monitor/BloodSugar'
import BloodGas from '../pages/Monitor/BloodGas'
import Heart from '../pages/Monitor/Heart'
import BloodClotting from '../pages/Monitor/BloodClotting'

// 科室报表
import Atlas from '../pages/DeptReport/Atlas'
import BSReport from '../pages/DeptReport/BloodSugar'
import BGReport from '../pages/DeptReport/BloodGas'
import HeartReport from '../pages/DeptReport/Heart'
import BCReport from '../pages/DeptReport/BloodClotting'
import Workload from '../pages/DeptReport/Workload'

// 项目维护
import Instrument from '../pages/PM/Instrument'
import Reagent from '../pages/PM/Reagent'
import Liquid from '../pages/PM/Liquid'
import Binding from '../pages/PM/Binding'


// 质控管理
import Record from '../pages/QC/Record'
import Report from '../pages/QC/Report'
import Setting from '../pages/QC/Setting'

// 组织管理
import Institution from '../pages/Organization/Institution'
import User from '../pages/Organization/User'

// 系统设置
import Log from '../pages/Sys/Log'
import Dictionary from '../pages/Sys/Dictionary'
import Version from '../pages/Sys/Version'

/* 唯一的模块导出
---------------------------------------------------------------- */
export class RouterComp extends Component {
  render() {
    return (
      <Switch>
        <Route path='/patient/list' component={PatientList} />
        <Route path='/patient/create' component={PatientCreate} />
        <Route path='/patient/query' component={PatientQuery} />
        <Route path='/patient/consultation' component={Consultation} />

        <Route path='/monitor/blood_sugar' component={BloodSugar} />
        <Route path='/monitor/blood_gas' component={BloodGas} />
        <Route path='/monitor/heart' component={Heart} />
        <Route path='/monitor/blood_clotting' component={BloodClotting} />

        <Route path='/dept_report/atlas' component={Atlas} />
        <Route path='/dept_report/bs_report' component={BSReport} />
        <Route path='/dept_report/bg_report' component={BGReport} />
        <Route path='/dept_report/heart_report' component={HeartReport} />
        <Route path='/dept_report/bc_report' component={BCReport} />
        <Route path='/dept_report/workload' component={Workload} />

        <Route path='/pm/instrument' component={Instrument} />
        <Route path='/pm/reagent' component={Reagent} />
        <Route path='/pm/liquid' component={Liquid} />
        <Route path='/pm/binding' component={Binding} />

        <Route path='/qc/record' component={Record} />
        <Route path='/qc/report' component={Report} />
        <Route path='/qc/setting' component={Setting} />

        <Route path='/organization/institution' component={Institution} />
        <Route path='/organization/user' component={User} />

        <Route path='/sys/log' component={Log} />
        <Route path='/sys/dictionary' component={Dictionary} />
        <Route path='/sys/version' component={Version} />

        <Redirect to='/patient/list' />

      </Switch>
    )
  }
}

export default RouterComp

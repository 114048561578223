/* 一些常用的功能函数 */

/**
 * 列表合并rid字段
 * @param {*} arr
 * @param {*} pageNo
 * @param {*} pageSize
 */
export const mergeRid = (arr, pageNo, pageSize) => {
  return arr.map((v, i) => {
    return {
      ...v,
      rid: pageNo > 1 ? [(pageNo - 1) * pageSize + (i + 1)] : i + 1
    }
  })
}

/**
 * 2 层 tree 型数组添加 key
 * 注意：in 这个方法返回的是 boolean 值
 * @param {*} arr
 */
export const mergeTreeByKey = (arr) => {
  return arr.map((v) => {
    return {
      ...v,
      level: 1,
      key: '' + v.id,
      children:
        'children' in v &&
        v.children.map((u) => {
          return { ...u, level: 2, key: '' + v.id + u.id }
        })
    }
  })
}

/**
 * 3 层 tree 型数组添加 key
 * 注意：in 这个方法返回的是 boolean 值
 * @param {*} arr
 */
export const addKeyToThree = (arr) => {
  return arr.map((v) => {
    return {
      ...v,
      key: '' + v.id,
      children:
        'children' in v &&
        v.children.map((u) => {
          return {
            ...u,
            key: '' + v.id + u.id,
            children:
              'children' in u &&
              u.children.map((t) => {
                return {
                  ...t,
                  key: '' + v.id + u.id + t.id
                }
              })
          }
        })
    }
  })
}

/**
 * 2 层 tree 型数组添加 value
 * 注意：in 这个方法返回的是 boolean 值
 * @param {*} arr
 */
export const addValueToThree = (arr) => {
  return arr.map((v) => {
    return {
      value: v.id,
      title: v.name,
      children:
        'children' in v &&
        v.children &&
        v.children.map((u) => {
          return {
            value: u.id,
            title: u.name
          }
        })
    }
  })
}

export const addValueToThrees = (arr) => {
  return arr.map((v) => {
    return {
      value: v.id,
      title: v.name,
      children:
        'children' in v &&
        v.children &&
        v.children.map((u) => {
          return {
            value: u.id,
            title: u.name,
            children:
              'children' in u &&
              u.children &&
              u.children.map((s) => {
                return {
                  value: s.id,
                  title: s.name,
              //     children:
              // 'children' in s &&
              // s.children &&
              // s.children.map((i) => {
              //   return {
              //     value: i.id,
              //     title: i.name
              //   }
              // })
                }
              })
          }
        })
    }
  })
}

/**
 * 关联数组合并
 * @param {*} parentArr
 * @param {*} childArr
 */
export const filterArr = (parentArr, childArr) => {
  return parentArr.map((v) => {
    return {
      ...v,
      children: childArr.filter((u) => u.type === v.id)
    }
  })
}

/**
 * echarts字体大小响应式
 * @param {*} res
 * @returns
 */
export const fontSize = (res) => {
  let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  if (!clientWidth) return
  let fontSize = 100 * (clientWidth / 1920)

  return res * fontSize
}

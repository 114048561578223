/* 新增字典数据 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect } from 'react'
import { Form, Input, Button, Select } from 'antd'
import PropTypes from 'prop-types'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
DicForm.propTypes = {
  formRef: PropTypes.object,
  list: PropTypes.array,
  dictionary: PropTypes.object,
  modal: PropTypes.object,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function DicForm(props) {
  const { formRef, list, dictionary, modal, onOk, onCancel } = props
  // console.log(modal)

  /**
   * 判断级联菜单显示上级字典
   * @param {*} id
   * @param {*} list
   */
  const showUpDicType = (id, list) => {
    // console.log(id)
    switch (id) {
      case 4:
        return (
          <>
            <Item name='dicType' label={<span className='g_w_67'>上级字典</span>}>
              <Select>
                {list.length &&
                  list.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item name='name' label='字典标签' rules={[{ required: true, message: '请输入字典标签' }]}>
              <Input />
            </Item>
          </>
        )
      case 5:
        if (modal.action === 'up' && 'children' in modal.data) {
          return (
            <Item name='dicType' label={<span>上级字典</span>} rules={[{ required: true,message: '请输入上级字典' }]}>
              <Select disabled>
                {list.length &&
                  list.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          )
        } else {
          return (
            <>
              <Item
                name='dicType'
                label={<span>上级字典</span>}
                rules={[{ required: true, message: '请输入上级字典' }]}
              >
                <Select>
                  {list.length &&
                    list.map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.name}
                      </Option>
                    ))}
                </Select>
              </Item>

              <Item name='name' label='字典标签' rules={[{ required: true, message: '请输入字典标签' }]}>
                <Input />
              </Item>
            </>
          )
        }
      default:
        return (
          <Item name='name' label='字典标签' rules={[{ required: true,message: '请输入字典标签' }]}>
            <Input />
          </Item>
        )
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    switch (dictionary.id) {
      case 4:
        if ('brand' in modal.data && modal.data.brand) {
          formRef.current.setFieldsValue({ dicType: modal.data.brand.id, name: modal.data.name })
        } else {
          formRef.current.setFieldsValue({ dicType: undefined, name: modal.data.name })
        }
        break
      case 5:
        if (modal.action === 'up' && 'children' in modal.data) {
          formRef.current.setFieldsValue({ dicType: modal.data.id })
        } else {
          formRef.current.setFieldsValue({ dicType: modal.data.dicType, name: modal.data.name })
        }
        break
      default:
        formRef.current.setFieldsValue({ name: modal.data.name })
        break
    }
  }, [formRef, dictionary.id, modal])

  /**
   * 渲染
   */
  return (
    <Form ref={formRef} onFinish={onOk}>
      {showUpDicType(dictionary.id, list)}
      <div className='g_txt_c'>
        <Button className='g_mr_20' onClick={onCancel}>
          取消
        </Button>
        <Button type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default DicForm

/* 质控记录 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Tabs, Empty } from 'antd'
import { ProjectOutlined, FilterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'
import QueryForm from './QueryForm'

/* 子模块
---------------------------------------------------------------- */
import QcGraph from './QCGraph'
import BindingForm from './BindingForm'
import BasicPane from './BasicPane'
import QcPane from './QCPane'
import Runaway from './Runaway'
import Maintain from './Maintain'
import QcForm from './QCForm'
import ViewQc from './ViewQC'

/* 功能模块
---------------------------------------------------------------- */
import { reqProjectBindings } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { BINDING_TYPE, MONITOR, PAGE_SIZE } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { TabPane } = Tabs

/* 唯一的模块导出
---------------------------------------------------------------- */
function Record() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 左侧列表展开/ 折叠
  const [isFolding, setIsFolding] = useState(false)

  // 查询 / 隐藏显示
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 分页相关参数
  const [qcPage, setQcPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined,
      officeId: undefined,
    type: undefined, // 监测类型
    instrumentId: undefined, // 仪器
    controlProjectId: undefined // 项目
  })

  // 操作相关参数
  const [operation, setOperation] = useState({
    action: undefined, // addQc：录入质控 | upQc：编辑质控 | runaway：失控历史 | maintain：维护历史 | viewQc：查看质控信息 | upProject：编辑当前项目
    isShow: false, // 切换状态
    data: {} // 数据
  })

  // 品牌列表
  const [projectBindings, setProjectBindings] = useState([])

  // 项目列表一行的记录
  const [projectRow, setProjectRow] = useState({})

  // 质控数据一行的记录
  const [qcRow, setQcRow] = useState({})

  // 标记
  const [flag, setFlag] = useState(false)

  // 质控数据第一行的记录
  const [newestQc, setNewestQc] = useState({})

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '监测类型',
      dataIndex: 'type',
      align: 'center',
      render: (_, { type }) => MONITOR[type - 1].name
    },
    {
      title: 'SN号',
      dataIndex: 'snnum',
      align: 'center',
      ellipsis: true,
      render: (_, { instrument }) => instrument.snnum
    },
    {
      title: '项目',
      dataIndex: 'controlProject',
      align: 'center',
      ellipsis: true,
      render: (_, { controlProject }) => controlProject.name
    }
  ]

  /**
   * api
   * 获取已项目绑定的列表
   */
  const getProjectBindings = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqProjectBindings(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setProjectBindings(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 查询
   * 按钮：条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  const onShowOperation = (action, data) => {
    let params
    switch (action) {
      case 'addQc':
      case 'upQc':
      case 'upProject':
        params = { action, data }
        break
      default:
        params = { action, isShow: true, data }
        break
    }
    setOperation(params)
  }

  const onFoldingProjects = () => {
    if (isFolding) {
      setIsFolding(false)
    } else {
      setIsFolding(true)
    }
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, _) => {
    if (projectRow) return record.id === projectRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getProjectBindings({ controlProjectStatus: 1, pageNo: page.pageNo, pageSize: page.pageSize, ...condition })

    return () => {
      setProjectBindings((_) => {
        return
      })
      setTotal((_) => {
        return
      })
    }
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  if (!operation.isShow) {
    return (
      <>
        <div className='g_fx g_h_percent'>
          {!isFolding && (
            <Card
              className='g_w_385'
              size='small'
              title={
                <>
                  <ProjectOutlined className='g_mr_10' />
                  <span>项目列表</span>
                </>
              }
            >
              <Table
                bordered
                size='small'
                scroll={{ x: 'max-content' }}
                rowKey='rid'
                loading={isLoading}
                columns={initColumns}
                dataSource={projectBindings}
                pagination={{
                  current: page.pageNo,
                  pageSize: page.pageSize,
                  total: total,
                  showTotal: (total) => `共 ${total} 条记录`,
                  showQuickJumper: true,
                  onChange: onChangePage
                }}
                rowClassName={showRowClassName}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setProjectRow(record)
                      setQcRow({})
                      setQcPage({ pageNo: 1, pageSize: PAGE_SIZE })
                    }
                  }
                }}
              />
            </Card>
          )}

          <div className='g_switch' onClick={onFoldingProjects}>
            {!isFolding ? <CaretLeftOutlined /> : <CaretRightOutlined />}
          </div>

          <Card
            className='g_fx_a'
            // style={{ overflow: 'hidden' }}
            size='small'
            title={
              <>
                <ProjectOutlined className='g_mr_10' />
                <span>项目信息</span>
              </>
            }
            extra={
              <>
                <Button className='g_ml_10' icon={<FilterOutlined />} onClick={onShowQuery}>
                  {query ? '隐藏' : '查询'}
                </Button>
                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => onShowOperation('qcGraph', projectRow)}
                  disabled={projectRow.id ? false : true}
                >
                  质控图形
                </Button>
                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => onShowOperation('upProject', projectRow)}
                  disabled={projectRow.id ? false : true}
                >
                  编辑当前项目
                </Button>
                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => onShowOperation('addQc', {})}
                  disabled={projectRow.id ? false : true}
                >
                  录入质控
                </Button>
              </>
            }
          >
            {query && (
              <QueryForm
                monitor={MONITOR}
                setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
                setCondition={(params) => setCondition({ ...condition, ...params })}
              />
            )}

            <Tabs size='small' defaultActiveKey='1' type='card' tabBarGutter={-1}>
              <TabPane tab='质控数据' key='1'>
                {projectRow.id ? (
                  <QcPane
                    projectRow={projectRow}
                    qcRow={qcRow}
                    flag={flag}
                    qcPage={qcPage}
                    setOperation={(params) => setOperation({ ...operation, ...params })}
                    onShowOperation={onShowOperation}
                    setQcPage={(params) => setQcPage(params)}
                    setQcRow={(params) => setQcRow(params)}
                    setNewestQc={(params) => setNewestQc(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
              <TabPane tab='基本信息' key='2'>
                {projectRow.id && Object.keys(newestQc).length ? (
                  <BasicPane newestQc={newestQc} operation={{}} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </div>

        <IModal visible={operation.action === 'upProject'} titleText='编辑当前项目' width={660}>
          <BindingForm
            binding={BINDING_TYPE}
            monitor={MONITOR}
            operation={operation}
            setOperation={() => setOperation({ ...operation, action: undefined })}
            refreshProjectBindings={() =>
              getProjectBindings({
                controlProjectStatus: 1,
                pageNo: page.pageNo,
                pageSize: page.pageSize,
                ...condition
              })
            }
          />
        </IModal>

        <IModal
          visible={operation.action === 'addQc' || operation.action === 'upQc'}
          titleText={operation.action === 'addQc' ? '录入质控' : '编辑质控'}
          width={800}
        >
          <QcForm
            projectRow={projectRow}
            operation={operation}
            flag={flag}
            setOperation={() => setOperation({ ...operation, action: undefined })}
            refreshQcs={(param) => setFlag(param)}
          />
        </IModal>
      </>
    )
  }

  if (operation.action === 'qcGraph') {
    return <QcGraph operation={operation} goBack={() => setOperation({ ...operation, isShow: false })} />
  }

  if (operation.action === 'runaway') {
    return <Runaway qc={operation} goBack={() => setOperation({ ...operation, isShow: false })} />
  }

  if (operation.action === 'maintain') {
    return <Maintain qc={operation} goBack={() => setOperation({ ...operation, isShow: false })} />
  }

  return <ViewQc operation={operation} goBack={() => setOperation({ ...operation, isShow: false })} />
}

export default Record

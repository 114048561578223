/* 失控报告 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useRef, useEffect, useState } from 'react'
import { Button, Card, Row, Col, Spin } from 'antd'
import { FileWordOutlined, RollbackOutlined, PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'
import PropTypes from 'prop-types'

/* 子模块
---------------------------------------------------------------- */
import RunawayReportChart from './RunawayReportChart'

/* 功能模块
---------------------------------------------------------------- */
import { reqRunawayReport } from '../../../api'
import { MONITOR } from '../../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
RunawayReport.propTypes = {
  qc: PropTypes.object,
  operation: PropTypes.object,
  setOperation: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function RunawayReport(props) {
  const {
    qc: {
      data: { level1Value, level2Value, level3Value }
    },
    operation: {
      data: { id: runawayId, determine, outOfControlReason, outOfControlHandle }
    },
    setOperation
  } = props

  const printRef = useRef()

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 失控报告信息
  const [runawayReport, setRunawayReport] = useState({})

  const onPrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print',
    documentTitle: '失控报告（葡萄糖）'
  })

  /**
   * api
   * 获取失控报告信息
   * @param {*} params
   */
  const getRunawayReport = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqRunawayReport(params)
      if (status) {
        setRunawayReport(data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  // console.log('runawayReport', runawayReport)

  // 失控报告解构
  const {
    qualityControlInfoVO: {
      company: { name: company } = {},
      office: { name: office } = {},
      type,
      createDate,
      instrument: { brand: { name: instrumentBrand } = {}, modelVO: { name: instrumentModel } = {}, snnum } = {},
      controlProject: { name: controlProject } = {},
      metering: { name: metering } = {},
      reagent: {
        brand: { name: reagentBrand } = {},
        modelVO: { name: reagentModel } = {},
        batchNumber,
        methodology: { name: methodology } = {},
        expireDate
      } = {},
      controlThings: {
        levelNum,
        brand: { name: controlThingsBrand } = {},
        model: { name: controlThingsModel } = {},
        thingsBatchNumber,
        name: { name: controlThings } = {},
        stroma: { name: stroma } = {}
      } = {},
      controlStaticMeanValueSetting: {
        level1MV: fixedLevel1MV,
        level1SDCV: fixedLevel1SDCV,
        level2MV: fixedLevel2MV,
        level2SDCV: fixedLevel2SDCV,
        level3MV: fixedLevel3MV,
        level3SDCV: fixedLevel3SDCV
      } = {},
      controlFloatMeanValueSetting: {
        level1MV: floatLevel1MV,
        level1SDCV: floatLevel1SDCV,
        level2MV: floatLevel2MV,
        level2SDCV: floatLevel2SDCV,
        level3MV: floatLevel3MV,
        level3SDCV: floatLevel3SDCV
      } = {},
      level1,
      level2,
      level3
    } = {},
  } = runawayReport

  /**
   * 生命周期
   */
  useEffect(() => {
    // console.log(operation)
    getRunawayReport({ outOfControlRecordId: runawayId })
  }, [runawayId])

  /**
   * 渲染
   */
  return (
    <Card
      size='small'
      title={
        <>
          <FileWordOutlined className='g_mr_10' />
          <span>失控报告</span>
        </>
      }
      extra={
        <>
          <Button className='g_ml_10' type='primary' icon={<PrinterOutlined />} onClick={onPrint}>
            打印
          </Button>
          <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={setOperation}>
            返回
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <div style={{ fontSize: 8, margin: '0 auto', width: 1000 }} ref={printRef}>
          <div
            className='g_txt_c g_pb_10 g_mb_10'
            style={{ fontSize: 20, fontWeight: 500, borderBottom: '2px solid #000' }}
          >
            失控报告（{controlProject}）
          </div>

          <Row className='g_mb_10' style={{ borderBottom: '1px solid #000' }}>
            <Col span={6}>医院：{company}</Col>
            <Col span={6}>科室：{office}</Col>
            <Col span={6}>监测类型：{type && MONITOR[type - 1].name}</Col>
            <Col span={6}>质控时间：{createDate}</Col>
          </Row>

          <Row>
            <Col>
              仪器：{instrumentBrand}&emsp;{instrumentModel}&emsp;{snnum}
            </Col>
          </Row>
          <Row>
            <Col span={8}>项目：{controlProject}</Col>
            <Col span={8}>单位：{metering}</Col>
          </Row>
          <Row>
            <Col span={8}>
              试剂：{reagentBrand}&emsp;{reagentModel}&emsp;{batchNumber}
            </Col>
            <Col span={8}>方法学：{methodology}</Col>
            <Col span={8}>效期：{expireDate}</Col>
          </Row>
          <Row>
            <Col span={8}>
              质控液：{controlThingsBrand}&emsp;{controlThingsModel}&emsp;{thingsBatchNumber}
            </Col>
            <Col span={8}>质控液名称：{controlThings}</Col>
            <Col span={8}>基质：{stroma}</Col>
          </Row>
          {levelNum >= 1 && (
            <Row>
              <Col className='g_pr_10'>质控水平1</Col>
              <Col className='g_pr_10'>质控值：{level1Value}</Col>
              <Col className='g_pr_10'>
                固定：[均值：{fixedLevel1MV}，SD：{fixedLevel1SDCV}，CV：
                {fixedLevel1MV && fixedLevel1SDCV && parseFloat(fixedLevel1SDCV / fixedLevel1SDCV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>
                浮动：[均值：{floatLevel1MV}，SD：{floatLevel1SDCV}，CV：
                {floatLevel1MV && floatLevel1SDCV && parseFloat(floatLevel1SDCV / floatLevel1MV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>次批号：{level1 && level1.batchNum}</Col>
              <Col>效期：{level1 && level1.expireDate}</Col>
            </Row>
          )}
          {levelNum >= 2 && (
            <Row>
              <Col className='g_pr_10'>质控水平2</Col>
              <Col className='g_pr_10'>质控值：{level2Value}</Col>
              <Col className='g_pr_10'>
                固定：[均值：{fixedLevel2MV}，SD：{fixedLevel2SDCV}，CV：
                {fixedLevel2MV && fixedLevel2SDCV && parseFloat(fixedLevel2SDCV / fixedLevel2SDCV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>
                浮动：[均值：{floatLevel2MV}，SD：{floatLevel2SDCV}，CV：
                {floatLevel2MV && floatLevel2SDCV && parseFloat(floatLevel2SDCV / floatLevel2MV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>次批号：{level2 && level2.batchNum}</Col>
              <Col>效期：{level2 && level2.expireDate}</Col>
            </Row>
          )}
          {levelNum >= 3 && (
            <Row>
              <Col className='g_pr_10'>质控水平3</Col>
              <Col className='g_pr_10'>质控值：{level3Value}</Col>
              <Col className='g_pr_10'>
                固定：[均值：{fixedLevel3MV}，SD：{fixedLevel3SDCV}，CV：
                {fixedLevel3MV && fixedLevel3SDCV && parseFloat(fixedLevel3SDCV / fixedLevel3SDCV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>
                浮动：[均值：{floatLevel3MV}，SD：{floatLevel3SDCV}，CV：
                {floatLevel3MV && floatLevel3SDCV && parseFloat(floatLevel3SDCV / floatLevel3MV).toFixed(2)}]
              </Col>
              <Col className='g_pr_10'>次批号：{level3 && level3.batchNum}</Col>
              <Col>效期：{level3 && level3.expireDate}</Col>
            </Row>
          )}

          <div>触发规则：{determine}</div>

          <Card style={{ width: 1000 }} size='small' bordered={false}>
            <RunawayReportChart chartData={runawayReport}/>
          </Card>

          <Row className='g_mb_10'>
            <Col>失控原因：</Col>
            <Col flex={1}>{outOfControlReason}</Col>
          </Row>
          <Row className='g_mb_30'>
            <Col>失控处理：</Col>
            <Col flex={1}>{outOfControlHandle}</Col>
          </Row>

          <div className='g_mb_30' style={{ textAlign: 'right' }}>
            <span style={{ marginRight: 200 }}>操作人：</span>
            <span style={{ marginRight: 150 }}>时间：</span>
          </div>
          <div style={{ textAlign: 'right' }}>
            <span style={{ marginRight: 200 }}>审核人：</span>
            <span style={{ marginRight: 150 }}>时间：</span>
          </div>
        </div>
      </Spin>
    </Card>
  )
}

export default RunawayReport

/* 封装模态框 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Modal } from 'antd'

/* 静态资源
---------------------------------------------------------------- */
import './index.less'

/* 唯一的模块导出
---------------------------------------------------------------- */
export default function IModal(props) {
  return (
    <Modal className='modal-form' {...props} footer={null} centered closable={false}>
      <div style={{ borderRadius: '2px 2px 0 0' }} className='modal-header'>
        {props.titleText}
      </div>
      <div className='modal-body'>{props.children}</div>
    </Modal>
  )
}

/* 患者-基本信息页签 */

/* 调用模块
---------------------------------------------------------------- */
import React,{useState,useEffect} from 'react'
import { Card, Row, Col, Empty } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { GENDER, MARRIAGE, ANTION, EDUCATION } from '../../utils/constants'
import {reqPatientById} from '../../api'

/* 静态类型检查
---------------------------------------------------------------- */
InfoPane.propTypes = {
  patientRow: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function InfoPane(props) {
  const { patientRow,flag } = props

  // 患者列表
  const [patient, setPatient] = useState({})

  /**
   * api
   * 通过id获取患者信息
   * @param {*} id
   */
  const getPatientById = async (params) => {
    const { data, status } = await reqPatientById(params)
    if (status) {
      setPatient(data)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (patientRow.id) {
      getPatientById({ id: patientRow.id })
    }
  }, [patientRow.id,flag])

  /**
   * 渲染
   */
  if (Object.keys(patient).length) {
    return (
      <>
        <Card className='g_mb_10'>
          <Row>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>姓名：</span>
                  <span>{patient.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>出生年月：</span>
                  <span>{patient.birth && moment(patient.birth).format('YYYY-MM-DD')}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>性别：</span>
                  <span>{patient.gender > -1 && GENDER[patient.gender].name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>文化程度：</span>
                  <span>{patient.education && EDUCATION[patient.education - 1].name}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>年龄：</span>
                  <span>{patient.age}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>身高：</span>
                  <span>{patient.stature}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>民族：</span>
                  <span>{patient.nation && ANTION[patient.nation].name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>体重：</span>
                  <span>{patient.weight}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>籍贯：</span>
                  <span>{patient.nationPlace}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span> BMI：</span>
                  <span>{patient.bmi}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>婚姻状况：</span>
                  <span>{patient.maritalStatus && MARRIAGE[patient.maritalStatus - 1].name}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>联系电话：</span>
                  <span>{patient.phone}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>身份证号：</span>
                  <span>{patient.identityCard}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>电子邮箱：</span>
                  <span>{patient.email}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>家庭住址：</span>
                  <span>{patient.homeAddress}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card className='g_mb_10'>
          <Row>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>所在医院：</span>
                  <span>{patient.company && patient.company.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>主治医师：</span>
                  <span>{patient.doctor && patient.doctor.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>门诊号：</span>
                  <span>{patient.patientNo}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>入院时间：</span>
                  <span>{patient.createDate && moment(patient.createDate).format('YYYY-MM-DD')}</span>
                </Col>
              </Row>
            </Col>

            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>所在科室：</span>
                  <span>{patient.office && patient.office.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>责任护士：</span>
                  <span>{patient.primaryNurse && patient.primaryNurse.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>住院号：</span>
                  <span>{patient.hospitalizationCards}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>出院时间：</span>
                  <span>{patient.dischargeTime && moment(patient.dischargeTime).format('YYYY-MM-DD')}</span>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row className='padding-5-0'>
                <Col>
                  <span>所在病区：</span>
                  <span>{patient.inpatientWard && patient.inpatientWard.name}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>医保卡号：</span>
                  <span>{patient.medicalCards}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>床位号：</span>
                  <span>{patient.bedCards}</span>
                </Col>
              </Row>
              <Row className='padding-5-0'>
                <Col>
                  <span>诊断：</span>
                  <span>{patient.diagnose}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </>
    )
  }
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}

export default InfoPane

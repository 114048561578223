/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select, Button, Row, Col } from 'antd'

/* 调用模块: 对象结构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  query: PropTypes.bool.isRequired,
  statusType: PropTypes.array.isRequired,
   institutions: PropTypes.array,
  roleLists: PropTypes.array,
  setPage: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const [form] = Form.useForm()

  const { query, statusType,  institutions, setPage, setCondition, roleLists } = props

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  /**
   * 查询
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    form.setFieldsValue({ officeId: undefined })
    setCompanyId(value)
  }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    if (companyId) {
      const result =  institutions.filter((v) => v.id === companyId)
      if ('children' in result[0]) {
        return result[0].children.map((v) => (
          <Option key={v.id} value={v.id}>
            {v.name}
          </Option>
        ))
      }
    }
  }

  /**
   * 查询
   * 按钮：选择状态
   * @param {*} value
   */
  const onChangeStatus = (value) => {
    setPage()
    setCondition({ status: value })
  }

  /**
   * 查询
   * 按钮：点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPage()
    setCondition(value)
  }

  /**
   * 查询
   * 按钮：点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setCompanyId(undefined)
    setPage()
    setCondition({
      companyId: undefined,
      officeId: undefined,
      no: undefined,
      searchData: undefined,
      status: undefined,
      role: undefined
    })
  }

  /**
   * 渲染
   */
  return (
    <Form className={query ? 'g_display' : 'g_hide'} layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='companyId' label='医院'>
            <Select className='g_w_174' onChange={onChangeCompany}>
              { institutions.length > 0
                ?  institutions.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
            <Select className='g_w_174'>{companyId && showOffices(companyId)}</Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='姓名'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='no' label='工号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='role' label='角色'>
            <Select
              className='g_w_174'
              allowClear
              // onChange={onChangeStatus}
            >
              {roleLists.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
            <Select style={{ width: 70 }} allowClear onChange={onChangeStatus}>
              {statusType.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 血凝记录表 */

/*  调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqReagents, reqBloodClotById } from '../../api/index'
import { mergeRid } from '../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
BCRecordPane.prototype = {
  patientRow: PropTypes.object,
  commonRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
  onShowOperation: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BCRecordPane(props) {
  const { patientRow, commonRow, commonPage, setCommonPage, onShowOperation, setCommonRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 血凝记录表
  const [bloodClot, setBloodClot] = useState([])

  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '检测时间',
      dataIndex: 'detectionTime',
      width: 150,
      align: 'center',
      render: (text, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '血浆凝血酶原时间',
      dataIndex: 'item1',
      width: '',
      align: 'center',
      render: (_, { bloodClotItems }) => {
        const result = bloodClotItems.filter((v) => v.item === 1)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { bloodClotItems }) => <>{bloodClotItems[0] ? bloodClotItems[0].value : ''}</>
    },
    {
      title: '活化部分凝血活酶时间',
      dataIndex: 'item2',
      width: '',
      align: 'center',
      render: (_, { bloodClotItems }) => {
        const result = bloodClotItems.filter((v) => v.item === 2)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { bloodClotItems }) => <>{bloodClotItems[1] ? bloodClotItems[1].value : ''}</>
    },
    {
      title: '凝血酶时间',
      dataIndex: 'item3',
      width: '',
      align: 'center',
      render: (_, { bloodClotItems }) => {
        const result = bloodClotItems.filter((v) => v.item === 3)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { bloodClotItems }) => <>{bloodClotItems[2] ? bloodClotItems[2].value : ''}</>
    },
    {
      title: '纤维蛋白原',
      dataIndex: 'item4',
      width: '',
      align: 'center',
      render: (_, { bloodClotItems }) => {
        const result = bloodClotItems.filter((v) => v.item === 4)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { bloodClotItems }) => <>{bloodClotItems[3] ? bloodClotItems[3].value : ''}</>
    },
    {
      title: '试剂批号',
      dataIndex: 'batchNumber',
      width: '',
      align: 'center',
      // render: (text, { batchNumber }) => <>{reagents.length && reagents[batchNumber - 1].batchNumber}</>
      render: (_, { batchNumber }) => {
        const result = reagents.filter((v) => v.id === batchNumber)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.batchNumber}</span>
          })
        }
      }
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作者',
      dataIndex: 'user',
      width: '',
      align: 'center',
      render: (text, { user }) => <>{user.name}</>
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '',
      align: 'center',
      render: (text, record) => (
        <Button type='link' onClick={() => onShowOperation('upClotting', record)}>
          修改
        </Button>
      )
    }
  ]

  /**
   * 根据患者id查询血凝记录表
   */
  const getBloodClotById = async (params) => {
    setIsLoading(true)
    if (params) {
      try {
        const {
          data: { resultList, totalRows } = {},
          status
        } = await reqBloodClotById(params)
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        if (status) {
          setBloodClot(result)
          setTotal(totalRows)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    const result = data && mergeRid(data.resultList)
    if (status) {
      setReagents(result)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
  }, [])

  useEffect(() => {
    getBloodClotById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  /**
   * 渲染
   */
  return (
    <Table
      bordered
      size='small'
      rowKey='rid'
      loading={isLoading}
      columns={initColumns}
      dataSource={bloodClot}
      scroll={{ x: 1600, y: 520 }}
      pagination={{
        current: commonPage.pageNo,
        pageSize: commonPage.pageSize,
        showQuickJumper: true,
        showSizeChanger: false,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        onChange: onChangePage
      }}
      rowClassName={showRowClassName}
      onRow={(record) => {
        return {
          onClick: () => {
            setCommonRow(record)
          }
        }
      }}
    />
  )
}

export default BCRecordPane

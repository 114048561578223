/* 胰岛素修改 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect } from 'react'
import { Form, Select, Input, Button, Row, Col, DatePicker, message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 静态资源
---------------------------------------------------------------- */
import { DRUG } from '../../../utils/constants'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddInsulin } from '../../../api'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 静态类型检查
---------------------------------------------------------------- */
UpdateInsulinForm.propTypes = {
  ncd: PropTypes.object.isRequired,
  findInsulinList: PropTypes.array.isRequired,
  medicationItemList: PropTypes.array.isRequired,
  patientRow: PropTypes.object,
  commonRow: PropTypes.object.isRequired,
  onShowModal: PropTypes.func.isRequired,
  refresh:PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function UpdateInsulinForm(props) {
  // 提交表单
  const [form] = Form.useForm()

  const { findInsulinList, medicationItemList, ncd, patientRow, commonRow, onShowModal,refresh } = props

  /**
   * 按钮
   * 保存胰岛素编辑弹框
   */
  const onOkInsulin = async (values) => {
    const { drug, item, insulinType, value, medicationTime } = values
    const params = {
      drug,
      value,
      insulinType,
      item,
      druginsulinType: findInsulinList.insulin,
      patientId: patientRow.id,
      id: commonRow.id,
      medicationTime: moment(medicationTime[0]).format('YYYY-MM-DD HH:mm:ss')
    }
    const { status } = await reqAddInsulin(params)
    if (status) {
      message.success('修改成功！')
      form.resetFields()
      refresh()
      onShowModal()
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const { medicationTime } = ncd
    form.setFieldsValue({ ...ncd, medicationTime: moment(medicationTime) })
  }, [form, ncd])

  return (
    <Form form={form} onFinish={onOkInsulin}>
      <Row>
        <Col className='g_mr_10'>
          <Item
            name='drug'
            label={<span className='g_txt_r g_w_60'>药品</span>}
            rules={[{ required: true, message: '请输入仪器' }]}
          >
            <Select className='g_w_174'>
              {DRUG.length &&
                DRUG.map((v) => (
                  <Option key={v.id} value={v.name}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <Item
            name='insulinType'
            label={<span className='g_txt_r g_w_71'>类型</span>}
            rules={[{ required: true, message: '请选择型号' }]}
          >
            <Select className='g_w_174'>
              {findInsulinList.length &&
                findInsulinList.map((v) => (
                  <Option key={v.insulin} value={v.insulin}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col className='g_mr_10'>
          <Item
            name='item'
            label={<span className='g_txt_r g_w_60'>时间段</span>}
            rules={[{ required: true, message: '请选择试纸批号' }]}
          >
            <Select className='g_w_174'>
              {medicationItemList.length &&
                medicationItemList.map((v) => (
                  <Option key={v.insulinMedicationItem} value={v.insulinMedicationItem}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <Item
            name='value'
            label={<span className='g_txt_r g_w_71'>请输入数值</span>}
            rules={[{ required: true, message: '请输入数值' }]}
          >
            <Input className='g_w_174 g_mr_10' /> 
          </Item>
        </Col>
        <Col>U</Col>
      </Row>

      <Row>
        <Col className='g_mr_10'>
          <Item
            name='medicationTime'
            label={<span className='g_txt_r g_w_60'>用药时间</span>}
            rules={[{ required: true, message: '请选择试纸批号' }]}
          >
            <DatePicker className='g_w_174' />
          </Item>
        </Col>
      </Row>

      <div className='g_txt_c'>
        <Button className='g_mr_10' key='back' onClick={onShowModal}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default UpdateInsulinForm

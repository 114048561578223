/* 血气修改 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Input, Divider, Button, Select, DatePicker, message, Spin } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstruments, reqReagents, reqUpdateFleshReport, reqfindNurseListByLoginUser } from '../../../api/index'

/* 静态资源模块
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 唯一的模块导出
---------------------------------------------------------------- */
function UpdateBGForm(props) {
  const { setOperation, operation, patientRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 仪器列表
  const [instrumentList, setInstrumentList] = useState([])

  //护士列表
  const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 提交表单
  const [form] = Form.useForm()

  /**
   * api
   * 仪器接口
   */
  const getInstrumentList = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqInstruments(params)
      if (status) {
        setInstrumentList(data.resultList)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
* api
* 获取护士列表
* @param {*} value 
*/
  const getNurseListByLoginUser = async () => {
    const { data, status } = await reqfindNurseListByLoginUser()
    if (status) {
      setNurseListByLoginUser(data)
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqReagents(params)
      if (status) {
        setReagents(data.resultList)
      }
    } finally {
      setIsLoading(false)
    }
  }

  let operations = operation.data

  const { fleshReportItems } = operations
  let item1
  let item2
  let item3
  let item4
  let item5
  let item6
  let item7
  let item8
  let item9
  let item10
  let item11
  let item12
  let item13
  let item14
  let item15
  let item16
  for (let i = 0; i < fleshReportItems.length; i++) {
    if (fleshReportItems[i].item === 1) {
      item1 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 2) {
      item2 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 3) {
      item3 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 4) {
      item4 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 5) {
      item5 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 6) {
      item6 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 7) {
      item7 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 8) {
      item8 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 8) {
      item8 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 9) {
      item9 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 10) {
      item10 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 11) {
      item11 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 12) {
      item12 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 13) {
      item13 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 14) {
      item14 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 15) {
      item15 = fleshReportItems[i].value
    } if (fleshReportItems[i].item === 16) {
      item16 = fleshReportItems[i].value
    }
  }


  /**
   * 按钮
   * 点击保存血气修改
   */
  const onOKFleshReport = async (values) => {
    const {
      detectionTime,
      snnum,
      batchNumber,
      userId,
      item1,
      item2,
      item3,
      item4,
      item5,
      item6,
      item7,
      item8,
      item9,
      item10,
      item11,
      item12,
      item13,
      item14,
      item15,
      item16
    } = values
    const params = {
      id: operations.id,
      patientId: patientRow.id,
      snnum,
      userId,
      batchNumber,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss'),
      fleshResult: {
        1: parseFloat(item1),
        2: parseFloat(item2),
        3: parseFloat(item3),
        4: parseFloat(item4),
        5: parseFloat(item5),
        6: parseFloat(item6),
        7: parseFloat(item7),
        8: parseFloat(item8),
        9: parseFloat(item9),
        10: parseFloat(item10),
        11: parseFloat(item11),
        12: parseFloat(item12),
        13: parseFloat(item13),
        14: parseFloat(item14),
        15: parseFloat(item15),
        16: parseFloat(item16)
      }
    }
    const { status } = await reqUpdateFleshReport(params)
    if (status) {
      message.success('血气修改成功！')
      setOperation()
      form.resetFields()
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstrumentList({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
  }, [])

  useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])



  useEffect(() => {
    const { detectionTime, snnum, batchNumber, user } = operations
    form.setFieldsValue({
      ...operations,
      snnum,
      userId: user.id,
      batchNumber,
      detectionTime: moment(detectionTime),
      item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16,
      // item1: fleshReportItems.length > 0 && fleshReportItems[0].item === 1 ? fleshReportItems[0].value : '',
      // item2: fleshReportItems.length > 1 ? fleshReportItems[1].value : '',
      // item3: fleshReportItems.length > 2 ? fleshReportItems[2].value : '',
      // item4: fleshReportItems.length > 3 ? fleshReportItems[3].value : '',
      // item5: fleshReportItems.length > 4 ? fleshReportItems[4].value : '',
      // item6: fleshReportItems.length > 5 ? fleshReportItems[5].value : '',
      // item7: fleshReportItems.length > 6 ? fleshReportItems[6].value : '',
      // item8: fleshReportItems.length > 7 ? fleshReportItems[7].value : '',
      // item9: fleshReportItems.length > 8 ? fleshReportItems[8].value : '',
      // item10: fleshReportItems.length > 9 ? fleshReportItems[9].value : '',
      // item11: fleshReportItems.length > 10 ? fleshReportItems[10].value : '',
      // item12: fleshReportItems.length > 11 ? fleshReportItems[11].value : '',
      // item13: fleshReportItems.length > 12 ? fleshReportItems[12].value : '',
      // item14: fleshReportItems.length > 13 ? fleshReportItems[13].value : '',
      // item15: fleshReportItems.length > 14 ? fleshReportItems[14].value : '',
      // item16: fleshReportItems.length > 15 ? fleshReportItems[15].value : ''
    })
  }, [form, operations,
    item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16
  ])
 
  /**
   * 渲染
   */
  return (
    <Spin spinning={isLoading}>
      <Card
        size='small'
        title={
          <h3 className='margin-clear'>
            <UserOutlined className='g_mr_10' />
            血气修改
          </h3>
        }
      >
        <Form form={form} onFinish={onOKFleshReport}>
          <Divider orientation='left' plain>
            基本信息
          </Divider>
          <Row>
            <Col span={8} offset={2}>
              <Item
                name='snnum'
                label={
                  <span className='g_w_98' rules={[{ required: true, message: '请输入仪器' }]}>
                    仪器
                  </span>
                }
              >
                <Select style={{ width: '100%' }}>
                  {instrumentList.length &&
                    instrumentList.map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.brand.name + '     ' + v.modelVO.name + '     ' + v.snnum}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={8} offset={2}>
              <Item
                name='userId'
                label={<span className='g_w_56'>操作人</span>}
                rules={[{ required: true, message: '请选择操作人' }]}
              >
                <Select >
                  {nurseListByLoginUser.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item
                name='batchNumber'
                label={
                  <span className='g_w_98' rules={[{ required: true, message: '请选择试纸批号' }]}>
                    试纸批号
                  </span>
                }
              >
                <Select style={{ width: '100%' }}>
                  {reagents.length &&
                    reagents.map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.brand.name + '      ' + v.methodology.name + '      ' + v.batchNumber}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={8} offset={2}>
              <Item
                name='detectionTime'
                label={
                  <span className='g_w_98' rules={[{ required: true, message: '请选择监测时间' }]}>
                    监测时间
                  </span>
                }
              >
                <DatePicker className='width-percent' />
              </Item>
            </Col>
          </Row>
          <Divider orientation='left' plain>
            详细信息
          </Divider>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item1' label={<span className='g_w_98'>二氧化碳分压</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmHg
            </Col>
            <Col span={8} offset={2}>
              <Item name='item2' label={<span className='g_w_98'>标准重碳酸</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item3' label={<span className='g_w_98'>酸碱度</span>}>
                <Input />
              </Item>
            </Col>
            <Col span={8} offset={4}>
              <Item name='item4' label={<span className='g_w_98'>碳酸氢根浓度</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item5' label={<span className='g_w_98'>氧分压</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmHg
            </Col>
            <Col span={8} offset={2}>
              <Item name='item6' label={<span className='g_w_98'>二氧化碳总量</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item7' label={<span className='g_w_98'>细胞比积</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              g/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item8' label={<span className='g_w_98'>氧饱和度</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              %
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item9' label={<span className='g_w_98'>钠</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item10' label={<span className='g_w_98'>血红蛋白</span>}>
                <Input />
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item11' label={<span className='g_w_98'>钾</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item12' label={<span className='g_w_98'>细胞外液剩余碱</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item13' label={<span className='g_w_98'>氯</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item14' label={<span className='g_w_98'>肺泡内氧分压</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmHg
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item15' label={<span className='g_w_98'>离子钙</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              g/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item16' label={<span className='g_w_98'>全血碱剩余</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              mmol/L
            </Col>
          </Row>
          <Divider plain />
          <div className='g_txt_c'>
            <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={setOperation}>
              关闭
            </Button>
            <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
              保存
            </Button>
          </div>
        </Form>
      </Card>
    </Spin>
  )
}

export default UpdateBGForm

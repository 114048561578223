/* 条件查询 */

/*  调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Form, Row, Col, Input, Button, Select,DatePicker } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 静态资源
---------------------------------------------------------------- */
import { PATIENT_OUT_STATUS } from '../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker

/* 使用类型检查
---------------------------------------------------------------- */
OfficeQueryForm.propTypes = {
  institutions: PropTypes.array,
  setCondition: PropTypes.func.isRequired,
  setPatientPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function OfficeQueryForm(props) {
  const { institutions, setCondition, setPatientPage } = props

  const [form] = Form.useForm()

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  // 科室 id
  const [officeId, setOfficeId] = useState(undefined)

  /**
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    setCompanyId(value)
    setOfficeId(undefined)
    form.setFieldsValue({ officeId: undefined })
  }

    /**
   * 选择状态
   * @param {*} value
   */
     const onChangeStatus = (value) => {
      setPatientPage()
      setCondition({ status: value })
    }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId)
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 选择科室
   * @param {*} value
   */
  const onChangeOffice = (value) => {
    setOfficeId(value)
  }

  /**
   * 通过科室 id 显示对应的病区列表
   * @param {*} officeId
   */
  const showtWards = (companyId, officeId) => {
    const { children } = institutions.find((v) => v.id === companyId) || []
    const ward = children.find((v) => v.id === officeId) || {}
    if (ward.children) {
      return ward.children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    const { date } = value
    let params
    if (date) {
      params = {
        ...value,
        startTime: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      params = {
        ...value,
        startTime: undefined,
        endTime: undefined
      }
    }
    setPatientPage()
    setCondition(params)
  }

  /**
   * 点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setOfficeId(undefined)
    setCompanyId(undefined)
    setPatientPage()
    setCondition({
      companyId: undefined,
      officeId: undefined,
      inpatientWardId: undefined,
      bedCards: undefined,
      hospitalizationCards: undefined,
      searchData: undefined,
      patientNo:undefined,
      startTime: undefined,
      endTime: undefined,
      status: undefined
    })
  }

  /**
   * 渲染
   */

  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='companyId' label='医院'>
            <Select style={{ width: 174 }} onChange={onChangeCompany}>
              {institutions.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
            <Select style={{ width: 174 }} onChange={onChangeOffice}>
              {companyId && showOffices(companyId)}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='inpatientWardId' label='病区'>
            <Select style={{ width: 174 }}>{companyId && officeId && showtWards(companyId, officeId)}</Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='hospitalizationCards' label='住院号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='bedCards' label='床位号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='patientNo' label='门诊号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='姓名'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
            <Item label='时间' name='date'>
              <RangePicker />
            </Item>
          </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
            <Select style={{ width: 174 }} onChange={onChangeStatus} allowClear>
              {PATIENT_OUT_STATUS.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
       
      </Row>
    </Form>
  )
}

export default OfficeQueryForm

/* 血糖录入 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Select, Input, Button, Row, Col, DatePicker, Divider, message } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import memoryUtils from '../../../utils/memoryUtils'


/* 功能模块
---------------------------------------------------------------- */
import { reqInstruments, reqReagents, reqAddPatientBlood,reqfindNurseListByLoginUser } from '../../../api/index'

/* 静态资源
---------------------------------------------------------------- */
import { BLOOD_SUGAR, PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
AddBSForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddBSForm(props) {
  const { patientRow, onCancel, setShow } = props

  // 提交表单
  const [form] = Form.useForm()

  // 仪器列表
  const [instrumentList, setInstrumentList] = useState([])

  //试纸批号列表
  const [reagents, setReagents] = useState([])

    //护士列表
    const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  // 下拉框列表(早餐前--- 随机九个下拉框的状态值)
  const [earlyMorning, setEarlyMorning] = useState(0)
  const [beforeBreakfast, setBeforeBreakfast] = useState(0)
  const [afterBreakfast, setAfterBreakfast] = useState(0)
  const [beforeLunch, setBeforeLunch] = useState(0)
  const [afterLunch, setAfterLunch] = useState(0)
  const [beforeDinner, setBeforeDinner] = useState(0)
  const [afterDinner, setAfterDinner] = useState(0)
  const [beforeSleep, setBeforeSleep] = useState(0)
  const [random, setRandom] = useState(0)

  /**
   * 当天以后的日期不可选择
   * @param {*} current
   * @returns
   */
  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }
  /**
   * 获取外出、拒测等下拉的值并清空凌晨输入框的值
   * @param {*} value
   */
  const onChangeEarlyMorning = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setEarlyMorning(result[0].id)
    if (form) form.setFieldsValue({ earlyMorning: undefined })
  }

    /**
 * api
 * 获取护士列表
 * @param {*} value 
 */
     const getNurseListByLoginUser = async () => {
      const { data, status } = await reqfindNurseListByLoginUser()
      if (status) {
        setNurseListByLoginUser(data)
      }
    }

  /**
   * 获取凌晨输入框的值并清空下拉框的值
   */
  const onChangeMorning = () => {
    if (earlyMorning) {
      form.setFieldsValue({ item1: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空早餐前输入框的值
   * @param {*} value
   */
  const onChangeBeforeBreakfast = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setBeforeBreakfast(result[0].id)
    if (form) form.setFieldsValue({ beforeBreakfast: undefined })
  }

  /**
   * 获取早餐前输入框的值并清空下拉框的值
   */
  const onChangeBefore = () => {
    if (beforeBreakfast) {
      form.setFieldsValue({ item2: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空早餐后输入框的值
   * @param {*} value
   */
  const onChangeAfterBreakfast = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setAfterBreakfast(result[0].id)
    if (form) form.setFieldsValue({ afterBreakfast: undefined })
  }

  /**
   * 获取早餐后输入框的值并清空下拉框的值
   */
  const onChangeAfter = () => {
    if (afterBreakfast) {
      form.setFieldsValue({ item3: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空午餐前输入框的值
   * @param {*} value
   */
  const onChangeBeforeLunch = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setBeforeLunch(result[0].id)
    if (form) form.setFieldsValue({ beforeLunch: undefined })
  }

  /**
   * 获取午餐前输入框的值并清空下拉框的值
   */
  const onChangeBeforeLunchs = () => {
    if (beforeLunch) {
      form.setFieldsValue({ item4: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空午餐后输入框的值
   * @param {*} value
   */
  const onChangeAfterLunch = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setAfterLunch(result[0].id)
    if (form) form.setFieldsValue({ afterLunch: undefined })
  }

  /**
   * 获取午餐后输入框的值并清空下拉框的值
   */
  const onChangeAfterLunchs = () => {
    if (afterLunch) {
      form.setFieldsValue({ item5: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空晚餐前输入框的值
   * @param {*} value
   */
  const onChangeBeforeDinner = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setBeforeDinner(result[0].id)
    if (form) form.setFieldsValue({ beforeDinner: undefined })
  }

  /**
   * 获取晚餐前输入框的值并清空下拉框的值
   */
  const onChangeBeforeDinners = () => {
    if (beforeDinner) {
      form.setFieldsValue({ item6: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空晚餐后输入框的值
   * @param {*} value
   */
  const onChangeAfterDinner = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setAfterDinner(result[0].id)
    if (form) form.setFieldsValue({ afterDinner: undefined })
  }

  /**
   * 获取晚餐后输入框的值并清空下拉框的值
   */
  const onChangeAfterDinners = () => {
    if (afterDinner) {
      form.setFieldsValue({ item7: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空睡前输入框的值
   * @param {*} value
   */
  const onChangeBeforeSleep = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setBeforeSleep(result[0].id)
    if (form) form.setFieldsValue({ beforeSleep: undefined })
  }

  /**
   * 获取睡前输入框的值并清空下拉框的值
   */
  const onChangeBeforeSleeps = () => {
    if (beforeSleep) {
      form.setFieldsValue({ item8: undefined })
    }
  }

  /**
   * 获取外出、拒测等下拉的值并清空随机输入框的值
   * @param {*} value
   */
  const onChangeRandom = (value) => {
    const result = BLOOD_SUGAR.filter((v) => v.id === value)
    if (result && result.length) setRandom(result[0].id)
    if (form) form.setFieldsValue({ random: undefined })
  }

  /**
   * 获取随机输入框的值并清空下拉框的值
   */
  const onChangeRandoms = () => {
    if (random) {
      form.setFieldsValue({ item9: undefined })
    }
  }

  /**
   * api
   * 仪器接口
   */
  const getInstrumentList = async (params) => {
    const { data, status } = await reqInstruments(params)
    if (status) {
      setInstrumentList(data.resultList)
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    if (status) {
      setReagents(data.resultList)
    }
  }

  /**
   * 按钮
   * 点击确定保存血糖录入
   * @param {*} value
   */
  const onOkAddBS = async (value) => {
    const {
      detectionTime,
      earlyMorning,
      beforeBreakfast,
      afterBreakfast,
      beforeLunch,
      afterLunch,
      beforeDinner,
      afterDinner,
      beforeSleep,
      random,
      item1,
      item2,
      item3,
      item4,
      item5,
      item6,
      item7,
      item8,
      item9
    } = value
    const { status } = await reqAddPatientBlood({
      ...value,
      patientId: patientRow.id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss') || moment(today).format('YYYY-MM-DD HH:mm:ss'),
      bloodResult: {
        1: parseFloat(earlyMorning) || item1 || -3,
        2: parseFloat(beforeBreakfast) || item2 || -3,
        3: parseFloat(afterBreakfast) || item3 || -3,
        4: parseFloat(beforeLunch) || item4 || -3,
        5: parseFloat(afterLunch) || item5 || -3,
        6: parseFloat(beforeDinner) || item6 || -3,
        7: parseFloat(afterDinner) || item7 || -3,
        8: parseFloat(beforeSleep) || item8 || -3,
        9: parseFloat(random) || item9 || -3
      }
    })
    if (status) {
      message.success('血糖录入成功！')
      form.resetFields()
      setShow()
    }
  }



  /**
   * 生命周期
   */
  useEffect(() => {
    getInstrumentList({ pageNo: 1, pageSize: PAGE_SIZE_MAX ,type:1})
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX ,type:1})
    getNurseListByLoginUser()
  }, [])

  /**
   * 渲染
   */
   const today = moment() // 当天日期
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          血糖录入
        </h3>
      }
    >
      <Form form={form} onFinish={onOkAddBS}>
        <Divider orientation='left' plain>
          基本信息
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='snnum'
              label={<span className='text-right g_w_56'>仪器</span>}
              rules={[{ required: true, message: '请输入仪器' }]}
            >
              <Select style={{ width: '100%' }}>
                {instrumentList.length &&
                  instrumentList.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '     ' + v.modelVO.name + '     ' + v.snnum}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='userId'
              label={<span className='text-right g_w_56'>操作人</span>}
              rules={[{ required: true, message: '请输入仪器' }]}
            >
              {memoryUtils.user.role.id >= 3 ? (
              <Select style={{ width: '100%' }}>
                {nurseListByLoginUser.length &&
                  nurseListByLoginUser.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            ) : null}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='batchNumber'
              label={<span className='text-right g_w_98'>试纸（剂）批号</span>}
              rules={[{ required: true, message: '请选择试纸（剂）批号' }]}
            >
              <Select style={{ width: '100%' }}>
                {reagents.length &&
                  reagents.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '      ' + v.methodology.name + '      ' + v.batchNumber}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='detectionTime'
              label={<span className='text-right g_w_56'>监测时间</span>}
              initialValue={moment(today)}
            >
              <DatePicker
                className='width-percent'
                disabledDate={disabledDate}
              />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          详细信息
        </Divider>
        <Row>
          <Col offset={1} className='g_mb_10 color-red'>
            说明：以下内容至少填写一项
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Item
              name='earlyMorning'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>凌晨</span>}
            >
              <Input onChange={onChangeMorning} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item1'>
              <Select style={{ width: '100%' }} onChange={onChangeEarlyMorning} allowClear>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>

          <Col span={6} offset={2}>
            <Item
              name='beforeBreakfast'
              label={<span className='text-right g_w_67'>早餐前</span>}
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
            >
              <Input onChange={onChangeBefore} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item2'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeBeforeBreakfast}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Item
              name='afterBreakfast'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>早餐后</span>}
            >
              <Input onChange={onChangeAfter} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item3'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeAfterBreakfast}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                id
              </Select>
            </Item>
          </Col>
          <Col span={6} offset={2}>
            <Item
              name='beforeLunch'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>午餐前</span>}
            >
              <Input onChange={onChangeBeforeLunchs} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item4'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeBeforeLunch}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                id
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Item
              name='afterLunch'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>午餐后</span>}
            >
              <Input onChange={onChangeAfterLunchs} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item5'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeAfterLunch}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={6} offset={2}>
            <Item
              name='beforeDinner'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>晚餐前</span>}
            >
              <Input onChange={onChangeBeforeDinners} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item6'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeBeforeDinner}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Item
              name='afterDinner'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>晚餐后</span>}
            >
              <Input onChange={onChangeAfterDinners} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item7'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeAfterDinner}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={6} offset={2}>
            <Item
              name='beforeSleep'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>睡前</span>}
            >
              <Input onChange={onChangeBeforeSleeps} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item8'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeBeforeSleep}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                id
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={2}>
            <Item
              name='random'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='text-right g_w_67'>随机</span>}
            >
              <Input onChange={onChangeRandoms} />
            </Item>
          </Col>
          <Col className='unit-1'>mmol/L</Col>
          <Col span={2}>
            <Item name='item9'>
              <Select style={{ width: '100%' }} allowClear onChange={onChangeRandom}>
                {BLOOD_SUGAR.length &&
                  BLOOD_SUGAR.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Divider plain />
        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default AddBSForm

// 血糖控制图

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucoseScopeCount } from '../../../api/index'

function BSTIME(props) {
  const { condition } = props

  const [chartDom, setChartDom] = useState('')

  //获取每日检测次数图
  const [bloodGlucoseScopeCount, setBloodGlucoseScopeCount] = useState({})
  /**
   * api
   * 获取每日检测次数图
   * @param {*} params
   */
  const getBloodGlucoseScopeCount = async (params) => {
    const { data, status } = await reqBloodGlucoseScopeCount(params)
    if (status) {
      setBloodGlucoseScopeCount(data)
    }
  }


  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, bloodGlucoseScopeCount) => {

    if (chartDom !== '') {
      const option = {
        title: {
          text: '血糖控制图',
          left: 'center',
          top: 20
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: bloodGlucoseScopeCount.length>0 && bloodGlucoseScopeCount,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      option && chartDom.setOption(option)
      
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodGlucoseScopeCount({ ...condition })
  }, [condition])

  useEffect(() => {
    const node = document.getElementById('dept-atlas')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, bloodGlucoseScopeCount)
    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, bloodGlucoseScopeCount])

  return <div id='dept-atlas' style={{ height: 500 }}></div>
}

export default BSTIME

/* 条件查询：患者列表 | 血糖监测 | 血气监测 | 心标监测 | 血凝监测 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Select, Button } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstitutionTrees } from '../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PATIENT_IN_STATUS } from '../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.prototype = {
  setCondition: PropTypes.func.isRequired,
  setPatientPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const { setCondition, setPatientPage } = props

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  // 科室 id
  const [officeId, setOfficeId] = useState(undefined)

  const [form] = Form.useForm()

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutionTrees()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    setCompanyId(value)
    setOfficeId(undefined)
    form.setFieldsValue({ officeId: undefined, inpatientWardId: undefined })
  }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId)
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 选择科室
   * @param {*} value
   */
  const onChangeOffice = (value) => {
    setOfficeId(value)
    form.setFieldsValue({ inpatientWardId: undefined })
  }

  /**
   * 通过科室 id 显示对应的病区列表
   * @param {*} officeId
   */
  const showtWards = (companyId, officeId) => {
    const { children } = institutions.find((v) => v.id === companyId) || []
    const ward = children.find((v) => v.id === officeId) || {}
    if (ward.children) {
      return ward.children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 选择状态
   * @param {*} value
   */
  const onChangeStatus = (value) => {
    setPatientPage()
    setCondition({ status: value })
  }

  /**
   * 点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPatientPage()
    setCondition(value)
  }

  /**
   * 点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setOfficeId(undefined)
    setCompanyId(undefined)
    setPatientPage()
    setCondition({
      companyId: undefined,
      officeId: undefined,
      inpatientWardId: undefined,
      bedCards: undefined,
      patientNo: undefined,
      hospitalizationCards: undefined,
      searchData: undefined,
      status: undefined
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()
    
    return () => {
      setInstitutions((_) => {
        return
      })
    }
  }, [])

  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='companyId' label='医院'>
            <Select style={{ width: 174 }} onChange={onChangeCompany}>
              {institutions.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
            <Select style={{ width: 174 }} onChange={onChangeOffice}>
              {companyId && showOffices(companyId)}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Item name='inpatientWardId' label='病区'>
            <Select style={{ width: 174 }}>{companyId && officeId && showtWards(companyId, officeId)}</Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Item name='bedCards' label='床位号'>
            <Input />
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Item name='patientNo' label='门诊号'>
            <Input />
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Item name='hospitalizationCards' label='住院号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='姓名'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
            <Select style={{ width: 174 }} onChange={onChangeStatus}>
              {PATIENT_IN_STATUS.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <div>
            <Button className='g_mr_10' type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 科室患者分布 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'

/* 使用类型检查
---------------------------------------------------------------- */
DeptPatientBar.prototype = {
  data: PropTypes.object
  // flag: PropTypes.bool
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function DeptPatientBar(props) {
  const { data } = props

  const [myChart, setMyChart] = useState('')

  const getChart = (myChart, data) => {
    if (myChart !== '') {
      let result = []
      for (let i = 0; i < data.length; i++) {
        result.push({ name: data[i].office.name, value: data[i].num })
      }

      let option
      option = {
        dataset: {
          source: result
        },
        grid: {
          top: 10,
          right: '20%',
          bottom: 10,
          left: '30%'
        },
        xAxis: {
          show: false,
          max: 'dataMax',
          axisLabel: {
            color: '#0E8AA4'
          }
        },
        dataZoom: [
          //滑动条
          {
            yAxisIndex: 0, //这里是从Y轴的0刻度开始
            show: true, //是否显示滑动条，不影响使用
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始
            endValue: 3, // 一次性展示4个
            left: '93%', //滑动条位置
            width: '6',

            fillerColor: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                offset: 0,
                color: 'rgba(14,50,111,0.5)'
              },
              {
                offset: 1,
                color: 'rgba(14,50,111,0.5)'
              }
            ]),
            zoomLock: true, //不可滚动条两头进行缩放
            borderColor: 'transparent',
            backgroundColor: '#0e8aa4', //两边未选中的滑动条区域的颜色
            showDataShadow: false, //是否显示数据阴影 默认auto
            showDetail: false //即拖拽时候是否显示详细数值信息 默认true
          },
          {
            type: 'inside',
            yAxisIndex: 0,
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true
          }
        ],
        yAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisLabel: {
            fontSize: 16,
            fontFamily: 'Source Han Sans CN',
            fontWeight: 400,
            color: '#fff'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              valueAnimation: false,
              fontSize: 16,
              fontFamily: 'Source Han Sans CN',
              fontWeight: 'bold',
              color: '#DDAD61'
            },
            itemStyle: {
              color: '#0E8AA4'
            },
            encode: { x: 'value', y: 'name' }
          }
        ],
        legend: {
          show: false
        }
      }
      //通过定时器的方式刷新，改变statrValue，endValue
      // setInterval(() => {
      //   // 每次向后滚动一个，最后一个从头开始。
      //   if (option.dataZoom[0].endValue === result.length) {
      //     option.dataZoom[0].endValue = 3
      //     option.dataZoom[0].startValue = 0
      //   } else {
      //     option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1
      //     option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1
      //   }
      option && myChart.setOption(option)
      // }, 2000)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const node = document.getElementById('deptPatient')
    const myChart = echarts.init(node)
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, data)
  }, [myChart, data])

  /**
   * 渲染
   */
  return <div id='deptPatient' className='bi_chart1'></div>
}

export default DeptPatientBar

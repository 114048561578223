/* 录入质控 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table, Tooltip, Button } from 'antd'
import { EditOutlined, MenuOutlined, SelectOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqQcsById } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { IS_ACCEPT } from '../../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
QCPane.propTypes = {
  qcPage: PropTypes.object.isRequired,
  projectRow: PropTypes.object,
  qcRow: PropTypes.object,
  flag: PropTypes.bool.isRequired,
  setOperation: PropTypes.func.isRequired,
  onShowOperation: PropTypes.func.isRequired,
  setQcPage: PropTypes.func.isRequired,
  setQcRow: PropTypes.func.isRequired,
  setNewestQc: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCPane(props) {
  const { qcPage, projectRow, qcRow, flag, onShowOperation, setQcPage, setQcRow, setNewestQc } = props
  const {
    controlId: qualityControlId,
    controlThings: { levelNum }
  } = projectRow

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  // 质控数据
  const [qcs, setQcs] = useState([])

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = (levelNum) => {
    let columns = [
      {
        title: '',
        dataIndex: 'rid',
        width: 60,
        align: 'center'
      },
      {
        title: '水平-1',
        children: [
          {
            title: '质控值',
            dataIndex: 'level1Value',
            align: 'center'
          },
          {
            title: '是否接受',
            dataIndex: 'level1Status',
            align: 'center',
            render: (_, { level1Status }) => {
              const [result] = IS_ACCEPT.filter((v) => v.type === level1Status)
              return <span className={!level1Status ? 'g_color_red' : null}>{result.name}</span>
            }
          },
          {
            title: 'Z值',
            dataIndex: 'level1ZValue',
            align: 'center'
          },
          {
            title: '规则判定',
            dataIndex: 'level1Determine',
            align: 'center',
            render: (_, { level1Determine }) => <span className='g_color_red'>{level1Determine}</span>
          }
        ]
      },
      {
        title: '质控时间',
        dataIndex: 'createDate',
        width: 150,
        align: 'center',
        render: (_, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: '操作人',
        dataIndex: 'createUser',
        width: 150,
        align: 'center',
        render: (_, { createUser }) => <span>{createUser.name}</span>

      },
      {
        title: '操作',
        dataIndex: 'action',
        width: 150,
        fixed: 'right',
        align: 'center',
        render: (_, record) => (
          <div className='g_action'>
            <Tooltip title='编辑质控' mouseEnterDelay={0.5}>
              <Button type='link' icon={<EditOutlined />} onClick={() => onShowOperation('upQc', record)} />
            </Tooltip>
            <Tooltip title='失控历史' mouseEnterDelay={0.5}>
              <Button
                type='link'
                icon={<MenuOutlined className={record.hasOutOfControlRecord ? 'g_color_red' : ''} />}
                onClick={() => onShowOperation('runaway', record)}
              />
            </Tooltip>
            <Tooltip title='维护历史' mouseEnterDelay={0.5}>
              <Button
                type='link'
                icon={<MenuOutlined className={record.hasQualityControlMaintain ? 'g_color_red' : ''} />}
                onClick={() => onShowOperation('maintain', record)}
              />
            </Tooltip>
            <Tooltip title='查看质控信息' mouseEnterDelay={0.5}>
              <Button type='link' icon={<SelectOutlined />} onClick={() => onShowOperation('viewQc', record)} />
            </Tooltip>
            {/* <Tooltip title='打印质控信息' mouseEnterDelay={0.5}>
            <Button type='link' icon={<PrinterOutlined />} onClick={() => onShowOperation('printQc', record)} />
          </Tooltip> */}
          </div>
        )
      }
    ]

    if (levelNum >= 2) {
      columns.splice(2, 0, {
        title: '水平-2',
        children: [
          {
            title: '质控值',
            dataIndex: 'level2Value',
            width: '',
            align: 'center'
          },
          {
            title: '是否接受',
            dataIndex: 'level2Status',
            align: 'center',
            render: (_, { level2Status }) => {
              const [result] = IS_ACCEPT.filter((v) => v.type === level2Status)
              return <span className={!level2Status ? 'g_color_red' : null}>{result.name}</span>
            }
          },
          {
            title: 'Z值',
            dataIndex: 'level2ZValue',
            align: 'center'
          },
          {
            title: '规则判定',
            dataIndex: 'level2Determine',
            align: 'center',
            render: (_, { level2Determine }) => <span className='g_color_red'>{level2Determine}</span>
          }
        ]
      })
    }
    if (levelNum >= 3) {
      columns.splice(3, 0, {
        title: '水平-3',
        children: [
          {
            title: '质控值',
            dataIndex: 'level3Value',
            width: '',
            align: 'center'
          },
          {
            title: '是否接受',
            dataIndex: 'level2Status',
            align: 'center',
            render: (_, { level3Status }) => {
              const [result] = IS_ACCEPT.filter((v) => v.type === level3Status)
              return <span className={!level3Status ? 'g_color_red' : null}>{result.name}</span>
            }
          },
          {
            title: 'Z值',
            dataIndex: 'level3ZValue',
            align: 'center'
          },
          {
            title: '规则判定',
            dataIndex: 'level3Determine',
            align: 'center',
            render: (_, { level3Determine }) => <span className='g_color_red'>{level3Determine}</span>
          }
        ]
      })
    }
    return columns
  }

  /**
   * api
   * 通过项目 id 获取质控数据
   */
  const getQcs = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows },
        status
      } = await reqQcsById(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setQcs(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setQcPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, _) => {
    if (qcRow) return record.id === qcRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getQcs({ qualityControlId, pageNo: qcPage.pageNo, pageSize: qcPage.pageSize })
  }, [qualityControlId, qcPage.pageNo, qcPage.pageSize, flag])

  useEffect(() => {
    if (qcs.length > 0) setNewestQc(qcs[0])
  }, [qcs, setNewestQc])

  /**
   * 渲染
   */

  return (
    <>
      <Table
        bordered
        size='small'
        scroll={{ x: 1400, y: 450 }}
        rowKey='rid'
        loading={isLoading}
        columns={initColumns(levelNum)}
        dataSource={qcs}
        pagination={{
          current: qcPage.pageNo,
          pageSize: qcPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setQcRow(record)
            }
          }
        }}
      />
    </>
  )
}

export default QCPane

/* 新增 / 编辑用户 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Button, Select, Divider, Row, Col, message } from 'antd'
import { RollbackOutlined, CheckOutlined, SolutionOutlined, UserAddOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddUser, reqEditUser } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select
const { TextArea } = Input

/* 使用类型检查
---------------------------------------------------------------- */
UserForm.propTypes = {
  institutions: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  action: PropTypes.object,
  setAction: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function UserForm(props) {
  const [form] = Form.useForm()

  const {
    institutions,
    roles,
    action: { type, data },
    setAction,
    refreshUsers
  } = props

  // 医院id
  const [companyId, setCompanyId] = useState(undefined)

  /**
   * 新增 / 编辑用户
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    form.setFieldsValue({ officeId: undefined })
    setCompanyId(value)
  }

  /**
   * 新增 / 编辑用户
   * 选择框：选择医院
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    if (companyId) {
      const result = institutions.length > 0 && institutions.filter((v) => v.id === companyId)
      if ('children' in result[0]) {
        return result[0].children.map((v) => (
          <Option key={v.id} value={v.id}>
            {v.name}
          </Option>
        ))
      }
    }
  }

  /**
   * 新增 / 编辑用户
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkUser = async (value) => {
    let res // 响应信息
    let msg // 提示信息
    // 判断是否为编辑用户操作
    if (type === 'up') {
      res = await reqEditUser({ ...value, id: data.id })
      msg = '编辑用户成功！'
    } else {
      res = await reqAddUser(value)
      msg = '新增用户成功！'
    }
    if (res.status) {
      setAction()
      message.success(msg)
      refreshUsers()
      form.resetFields()
      setCompanyId(undefined)
    }
  }

  /**
   * 新增 / 编辑用户
   * 按钮：点击取消
   */
  const onClose = () => {
    setAction()
    form.resetFields()
    setCompanyId(undefined)
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (type === 'up') {
      const { company, office, role } = data
      form.setFieldsValue({
        ...data,
        companyId: company.id,
        officeId: office.id,
        roleId: role.id
      })
    }
  }, [data, form, type])

  /**
   * 渲染DOM
   */
  return (
    <Card
      size='small'
      bordered={false}
      title={
        Object.keys(data).length ? (
          <>
            <SolutionOutlined className='g_mr_10' />
            <span>编辑用户</span>
          </>
        ) : (
          <>
            <UserAddOutlined className='g_mr_10' />
            <span>新增用户</span>
          </>
        )
      }
    >
      <Form form={form} onFinish={onOkUser}>
        <Divider orientation='left' plain>
          基本信息
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='companyId'
              label={<span className='g_txt_r g_w_56'>归属医院</span>}
              rules={[{ required: true, message: '请选择归属医院' }]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                onChange={onChangeCompany}
                disabled={type === 'up' ? true : false}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {type === 'add' ? (
                  institutions.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))
                ) : (
                  <Option key={data.company.id} value={data.company.id}>
                    {data.company.name}
                  </Option>
                )}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={4}>
            <Item name='officeId' label={<span className='g_txt_r g_w_67'>归属科室</span>}>
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={type === 'up' ? true : false}
              >
                {type === 'up' ? (
                  <Option key={data.office.id} value={data.office.id}>
                    {data.office.name}
                  </Option>
                ) : (
                  companyId && showOffices(companyId)
                )}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='loginName'
              label={<span className='g_txt_r g_w_56'>登录账号</span>}
              rules={[
                {
                  required: true,
                  pattern: /^[a-zA-Z\d_]{3,15}$/,
                  message: '请输入（长度在3-15之间，包含大小写英文，数字）登录账号'
                }
              ]}
            >
              {/* 验证账号：字母、数字、下划线组成，字母开头，4-16位 */}
              <Input disabled={data.id ? true : false} />
            </Item>
          </Col>
          <Col span={8} offset={4}>
            {type === 'add' ? (
              <Item
                name='password'
                label={<span className='g_txt_r g_w_56'>用户密码</span>}
                rules={[
                  { required: true, whitespace: true, message: '密码必须输入' },
                  { min: 6, message: '密码至少6位' },
                  { max: 16, message: '密码最多16位' },
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: '密码必须是英文、数字'
                  }
                ]}
              >
                <Input />
              </Item>
            ) : (
              <Item name='password' label={<span className='g_txt_r g_w_67'>用户密码</span>}>
                <Input disabled={data.id ? true : false} />
              </Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='mobile'
              label={<span className='g_txt_r g_w_56'>手机号码</span>}
              rules={[{ required: true, pattern: /^1\d{10}$/, message: '请输入以1开头的11位数字的手机号码' }]}
            >
              {/* 手机号：11位数字，以1开头 */}
              <Input />
            </Item>
          </Col>
          <Col span={8} offset={4}>
            <Item
              name='phone'
              rules={[{ pattern: /[0-9]{4,23}/, message: '请输入正确的的办公电话' }]}
              label={<span className='g_txt_r g_w_67'>办公电话</span>}
            >
              {/* 以数字0开始，并跟随2-3个数字 */}
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='email'
              rules={[{ pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: '请输入正确格式的电子邮箱' }]}
              label={<span className='g_txt_r g_w_67'>电子邮箱</span>}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          详细信息
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='no'
              label={<span className='g_txt_r g_w_56'>员工工号</span>}
              rules={[{ required: true, pattern: /^[0-9]{1,20}$/, message: '请输入1-20位的员工工号' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={8} offset={4}>
            <Item
              name='name'
              label={<span className='g_txt_r g_w_56'>员工姓名</span>}
              rules={[
                {
                  required: true,
                  pattern: /^[a-zA-Z\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/,
                  message: '请输入正确格式的员工姓名'
                }
              ]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={20} offset={2}>
            <Item name='remarks' label={<span className='g_txt_r g_w_67'>备注信息</span>}>
              <TextArea rows={3} maxLength={100} />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          分配角色
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='roleId'
              label={<span className='g_txt_r g_w_56'>用户角色</span>}
              rules={[{ required: true, message: '请选择角色' }]}
            >
              <Select
                style={{ width: '100%' }}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={data.id ? true : false}
              >
                {roles.length > 0 &&
                  roles.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Divider plain />
        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onClose}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default UserForm

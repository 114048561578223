/* 血糖图谱 */

// ==================
// 调用模块
// ==================
import React, { useState } from 'react'
import { DatePicker, Form, Select, Row, Col, Button } from 'antd'
import moment from 'moment'

// ==================
// 子模块
// ==================
import BSTrend from './Atlas/BSTrend'
import BSAverage from './Atlas/BSAverage'
import BSDrift from './Atlas/BSDrift'
import BSPie from './Atlas/BSPie'

// ==================
// 静态资源
// ==================
import { ATLAS } from '../../utils/constants'

// ==================
// 调用模块: 对象解构
// ==================
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker

// ==================
// 唯一的模块导出
// ==================
function BSAtlasPane(props) {
  const [form] = Form.useForm()

  const { patientRow } = props

  // 查询条件
  const [condition, setCondition] = useState({
    startTime: undefined,
    endTime: undefined
  })

  // 图谱切换显示状态
  const [chartId, setchartId] = useState(0)

  /**
   * 选择器
   * 选择血糖图谱获取id
   * @param {*} value
   */
  const onChangeAtlas = (value) => {
    setchartId(value)
  }

  /**
   * 点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    const { date } = value
    let params
    if (date) {
      params = {
        ...value,
        startTime: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      params = {
        ...value,
        startTime: moment().startOf('day').subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
    }
    setCondition(params)
  }

  /**
   * 点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setCondition({
      startTime: undefined,
      endTime: undefined
    })
  }

  /**
   * 显示图表
   * @param {*} chartId
   */
  const showChart = (chartId) => {
    switch (chartId) {
      case 1:
        return <BSTrend patientRow={patientRow} condition={condition} />
      case 2:
        return <BSAverage patientRow={patientRow} condition={condition} />
      case 3:
        return <BSDrift patientRow={patientRow} condition={condition} />
        case 4:
          return <BSPie patientRow={patientRow} condition={condition} />
      default:
        return <BSTrend patientRow={patientRow} condition={condition} />
    }
  }

  /**
   * 渲染
   */
  return (
    <>
      <Form className='g_mb_10' layout='inline' form={form} onFinish={onOkQuery}>
        <Row>
          <Col className='g_mb_10'>
            <Item label='图谱'>
              <Select style={{ width: 174 }} onChange={onChangeAtlas} defaultValue={1}>
                {ATLAS.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col className='g_mb_10'>
            <Item label='时间' name='date'>
              <RangePicker defaultValue={[moment(),moment().startOf('day').subtract(14, 'days')]}/>
            </Item>
          </Col>
          <Col className='g_mb_10'>
            <Button className='g_mr_10' type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </Col>
        </Row>
      </Form>

      {showChart(chartId)}
    </>
  )
}

export default BSAtlasPane

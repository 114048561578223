/* 添加失控 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect } from 'react'
import { Form, Button, Input, message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditRunaway } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { TextArea } = Input

/* 使用类型检查
---------------------------------------------------------------- */
RunawayForm.prototype = {
  qc: PropTypes.object.isRequired,
  operation: PropTypes.object.isRequired,
  setOperation: PropTypes.func.isRequired,
  refreshRunaways: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function RunawayForm(props) {
  const { qc, operation, setOperation, refreshRunaways } = props

  const [form] = Form.useForm()

  /**
   * 新增 / 编辑失控
   * 确定
   * @param {*} value
   */
  const onOkRunaway = async (value) => {
    // console.log(value)
    let msg = '' // 提示信息
    let params = {} // 表单信息
    if (operation.action === 'up') {
      msg = '编辑失控成功！'
      params = {
        ...value,
        qualityControlItemId: qc.data.id,
        id: operation.data.id
      }
    } else {
      msg = '新增失控成功！'
      params = {
        ...value,
        qualityControlItemId: qc.data.id
      }
    }
    const { status } = await reqAddOrEditRunaway(params)
    if (status) {
      message.success(msg)
      setOperation()
      refreshRunaways()
    }
  }

  /**
   * 新增 / 编辑失控
   * 取消
   */
  const onCancel = () => {
    setOperation()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (operation.action === 'up') {
      form.setFieldsValue(operation.data)
    } else {
      form.resetFields()
    }
  }, [operation.action, form, operation.data])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkRunaway}>
      <Item name='outOfControlReason' label='失控原因'>
        <TextArea rows={4} maxLength={300}/>
      </Item>
      <Item name='outOfControlHandle' label='失控处理'>
        <TextArea rows={4} maxLength={300}/>
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default RunawayForm

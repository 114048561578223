/* 编辑当前绑定项目 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Select, Button, message, DatePicker, Spin } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import {
  reqAddOrEditProjectBinding,
  reqInstruments,
  reqLiquids,
  reqMeters,
  reqProjects,
  reqReagents
} from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
BindingForm.propTypes = {
  binding: PropTypes.array.isRequired,
  monitor: PropTypes.array.isRequired,
  operation: PropTypes.object,
  setOperation: PropTypes.func.isRequired,
  refreshProjectBindings: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BindingForm(props) {
  const [form] = Form.useForm()

  const { binding, monitor, operation, setOperation, refreshProjectBindings } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 单位列表
  const [meters, setMeters] = useState([])

  // 监测类型 id
  const [typeId, setTypeId] = useState(undefined)

  // 监测类型对应的列表信息
  const [typeToList, setTypeToList] = useState({
    projects: [], // 项目
    instruments: [], // 仪器
    reagents: [], // 试剂
    liquids: [] // 质控液
  })

  // 质控液 id
  const [liquidId, setLiquidId] = useState(undefined)

  // 水平信息
  const [levels, setLevels] = useState({
    level1: [],
    level2: [],
    level3: [],
    levelNum: undefined
  })

  /**
   * api
   * 获取单位列表
   */
  const getMeters = async () => {
    const { data, status } = await reqMeters()
    if (status) {
      setMeters(data)
    }
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择监测类型
   * @param {*} value
   */
  const onChangeType = (value) => {
    form.setFieldsValue({
      controlProjectId: undefined,
      instrumentId: undefined,
      reagentId: undefined,
      reagentExpireDate: undefined,
      controlThingsId: undefined,
      level1Id: undefined,
      level1ExpireDate: undefined,
      level2Id: undefined,
      level2ExpireDate: undefined,
      level3Id: undefined,
      level3ExpireDate: undefined
    })
    setTypeId(value)
    setLevels({ level1: [], level2: [], level3: [], levelNum: undefined })
  }

  /**
   * 通过监测类型显示对应的列表信息
   * @param {*} typeId
   */
  const getTypeToList = async (typeId) => {
    setIsLoading(true)
    try {
      let params = {}
      const resOne = await reqProjects({ type: typeId, status: 1 })
      if (resOne.status) {
        params.projects = resOne.data
      }
      const resTwo = await reqInstruments({ type: typeId, status: 1, pageNo: 1, pageSize: PAGE_SIZE_MAX })
      if (resTwo.status) {
        params.instruments = resTwo.data.resultList
      }
      const resThree = await reqReagents({ type: typeId, status: 1, pageNo: 1, pageSize: PAGE_SIZE_MAX })
      if (resThree.status) {
        params.reagents = resThree.data.resultList
      }
      const resFour = await reqLiquids({ type: typeId, status: 1, pageNo: 1, pageSize: PAGE_SIZE_MAX })
      if (resFour.status) {
        params.liquids = resFour.data.resultList
      }
      setTypeToList(params)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择试剂
   * @param {*} value
   */
  const onChangeReagent = (value) => {
    const result = typeToList.reagents.filter((v) => v.id === value)
    form.setFieldsValue({ reagentExpireDate: moment(result[0].expireDate) })
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择质控液
   * @param {*} value
   */
  const onChangeLiquid = (value) => {
    form.setFieldsValue({
      level1Id: undefined,
      level1ExpireDate: undefined,
      level2Id: undefined,
      level2ExpireDate: undefined,
      level3Id: undefined,
      level3ExpireDate: undefined
    })
    setLiquidId(value)
  }

  /**
   * 通过质控液 id 获取对应的水平相关信息
   * @param {*} liquidId
   */
  const getLevels = (id, data) => {
    if (data.length) {
      const result = data.filter((v) => v.id === id)
      let params = {
        level1: [],
        level2: [],
        level3: []
      }
      const { levelNum } = result[0] || {}
      if (levelNum >= 1) {
        params.level1 = result[0].level1
      }
      if (levelNum >= 2) {
        params.level2 = result[0].level2
      }
      if (levelNum >= 3) {
        params.level3 = result[0].level3
      }
      setLevels({ ...params, levelNum })
    }
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择水平1次批号
   * @param {*} value
   */
  const onChangeLevel1 = (value, option) => {
    form.setFieldsValue({ level1ExpireDate: moment(option.expire) })
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择水平2次批号
   * @param {*} value
   */
  const onChangeLevel2 = (value, option) => {
    form.setFieldsValue({ level2ExpireDate: moment(option.expire) })
  }

  /**
   * 绑定 / 编辑项目
   * 选择框：选择水平3次批号
   * @param {*} value
   */
  const onChangeLevel3 = (value, option) => {
    form.setFieldsValue({ level3ExpireDate: moment(option.expire) })
  }

  /**
   * 绑定 / 编辑项目
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkBinding = async (value) => {
    const { bindType, type, controlProjectId, meteringId, instrumentId, reagentId, controlThingsId } = value
    let params // 表单相关参数
    switch (levels.levelNum) {
      case 1:
        params = { level1Id: value.level1Id }
        break
      case 2:
        params = { level1Id: value.level1Id, level2Id: value.level2Id }
        break
      default:
        params = { level1Id: value.level1Id, level2Id: value.level2Id, level3Id: value.level3Id }
        break
    }
    params = {
      bindType,
      type,
      controlProjectId,
      meteringId,
      instrumentId,
      reagentId,
      controlThingsId,
      ...params,
      id: operation.data.id
    }
    const { status } = await reqAddOrEditProjectBinding(params)
    if (status) {
      setOperation()
      message.success('绑定项目成功！')
      refreshProjectBindings()
      form.resetFields()
      setTypeId(undefined)
      setTypeToList({ projects: [], instruments: [], reagents: [], liquids: [] })
      setLiquidId(undefined)
      setLevels({ level1: [], level2: [], level3: [], levelNum: undefined })
    }
  }

  /**
   * 绑定 / 编辑项目
   * 按钮：点击取消
   */
  const onCancel = () => {
    setOperation()
    setTypeId(undefined)
    setTypeToList({ projects: [], instruments: [], reagents: [], liquids: [] })
    setLiquidId(undefined)
    setLevels({ level1: [], level2: [], level3: [], levelNum: undefined })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMeters()
  }, [])

  useEffect(() => {
    if (typeId) {
      getTypeToList(typeId)
    }
  }, [typeId])

  useEffect(() => {
    if (liquidId) {
      getLevels(liquidId, typeToList.liquids)
    }
  }, [liquidId, typeToList.liquids])

  useEffect(() => {
    if (operation.action === 'upProject') {
      setTypeId(operation.data.type)
      setLiquidId(operation.data.controlThings.id)
    }
  }, [operation.action, operation.data])

  useEffect(() => {
    if (operation.action === 'upProject') {
      const {
        bindType,
        type,
        controlProject,
        metering,
        instrument,
        reagent,
        controlThings,
        level1,
        level2,
        level3
      } = operation.data

      let params = {
        bindType,
        type,
        controlProjectId: controlProject.id,
        meteringId: metering.id,
        instrumentId: instrument.id,
        reagentId: reagent.id,
        reagentExpireDate: moment(reagent.expireDate),
        controlThingsId: controlThings.id
      }
      if (controlThings.levelNum >= 1) {
        params.level1Id = level1.id
        params.level1ExpireDate = moment(level1.expireDate)
      }
      if (controlThings.levelNum >= 2) {
        params.level2Id = level2.id
        params.level2ExpireDate = moment(level2.expireDate)
      }
      if (controlThings.levelNum >= 3) {
        params.level3Id = level3.id
        params.level3ExpireDate = moment(level3.expireDate)
      }
      form.setFieldsValue(params)
    }
  }, [operation.action, operation.data, form])

  /**
   * 渲染
   */
  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onOkBinding}>
        <Row justify='space-between'>
          <Col>
            <Item
              name='bindType'
              label={<span className='g_w_78'>绑定类型</span>}
              rules={[{ required: true, message: '请选择绑定类型' }]}
            >
              <Select className='g_w_174'>
                {binding.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col>
            <Item
              name='type'
              label={<span className='g_w_78'>监测类型</span>}
              rules={[{ required: true, message: '请选择监测类型' }]}
            >
              <Select className='g_w_174' onChange={onChangeType}>
                {monitor.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Item
              name='controlProjectId'
              label={<span className='g_w_78'>项目</span>}
              rules={[{ required: true, message: '请选择项目' }]}
            >
              <Select className='g_w_174'>
                {typeId &&
                  typeToList.projects.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col>
            <Item
              name='meteringId'
              label={<span className='g_w_78'>单位</span>}
              rules={[{ required: true, message: '请选择单位' }]}
            >
              <Select className='g_w_174'>
                {meters.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Item
              name='instrumentId'
              label={<span className='g_w_78'>仪器</span>}
              rules={[{ required: true, message: '请选择仪器' }]}
            >
              <Select
                className='g_w_174'
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {typeId &&
                  typeToList.instruments.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '  ' + v.modelVO.name + '  ' + v.snnum}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Item
              name='reagentId'
              label={<span className='g_w_78'>试剂</span>}
              rules={[{ required: true, message: '请选择试剂' }]}
            >
              <Select
                className='g_w_174'
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeReagent}
              >
                {typeId &&
                  typeToList.reagents.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '  ' + v.modelVO.name + '  ' + v.batchNumber + '  ' + v.methodology.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col>
            <Item name='reagentExpireDate' label={<span className='g_w_89'>效期</span>}>
              <DatePicker className='g_w_174' disabled placeholder='' />
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Item
              name='controlThingsId'
              label={<span className='g_w_78'>质控液</span>}
              rules={[{ required: true, message: '请选择质控液' }]}
            >
              <Select
                className='g_w_174'
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={onChangeLiquid}
              >
                {typeId &&
                  typeToList.liquids.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '  ' + v.model.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <div style={{ height: 168 }}>
          {levels.levelNum && levels.levelNum >= 1 && (
            <Row justify='space-between'>
              <Col>
                <Item
                  name='level1Id'
                  label={<span className='g_w_78'>水平1次批号</span>}
                  rules={[{ required: true, message: '请选择水平1次批号' }]}
                >
                  <Select className='g_w_174' onChange={onChangeLevel1}>
                    {levels.level1.map((v) => (
                      <Option key={v.id} value={v.id} expire={v.expireDate}>
                        {v.batchNum}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col>
                <Item name='level1ExpireDate' label={<span className='g_w_89'>效期</span>}>
                  <DatePicker className='g_w_174' disabled placeholder='' />
                </Item>
              </Col>
            </Row>
          )}
          {levels.levelNum && levels.levelNum >= 2 && (
            <Row justify='space-between'>
              <Col>
                <Item
                  name='level2Id'
                  label={<span className='g_w_78'>水平2次批号</span>}
                  rules={[{ required: true, message: '请选择水平2次批号' }]}
                >
                  <Select className='g_w_174' onChange={onChangeLevel2}>
                    {levels.level2.map((v) => (
                      <Option key={v.id} value={v.id} expire={v.expireDate}>
                        {v.batchNum}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col>
                <Item name='level2ExpireDate' label={<span className='g_w_80'>效期</span>}>
                  <DatePicker className='g_w_174' disabled placeholder=''/>
                </Item>
              </Col>
            </Row>
          )}
          {levels.levelNum && levels.levelNum >= 3 && (
            <Row justify='space-between'>
              <Col>
                <Item
                  name='level3Id'
                  label={<span className='g_w_78'>水平3次批号</span>}
                  rules={[{ required: true, message: '请选择水平3次批号' }]}
                >
                  <Select className='g_w_174' onChange={onChangeLevel3}>
                    {levels.level3.map((v) => (
                      <Option key={v.id} value={v.id} expire={v.expireDate}>
                        {v.batchNum}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col>
                <Item name='level3ExpireDate' label={<span className='g_w_89'>效期</span>}>
                  <DatePicker className='g_w_174' disabled placeholder='' />
                </Item>
              </Col>
            </Row>
          )}
        </div>

        <div className='g_txt_c'>
          <Button className='g_mr_20' key='back' onClick={onCancel}>
            取消
          </Button>
          <Button key='submit' type='primary' htmlType='submit'>
            确定
          </Button>
        </div>
      </Form>
    </Spin>
  )
}

export default BindingForm

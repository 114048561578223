/* 质控液维护 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, Table, Tooltip } from 'antd'
import {
  ExperimentOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  // StopOutlined,
  // PauseCircleOutlined,
  MenuOutlined
} from '@ant-design/icons'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import LiquidForm from './LiquidForm'
import SubBatchNumber from './SubBatchNumber'

/* 功能模块
---------------------------------------------------------------- */
import { reqLiquids, reqBrands, reqQualityControls } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { LIQUID_STATUS, MONITOR, PAGE_SIZE, QC_LEVEL } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Liquid() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    type: undefined, // 监测类型
    brandId: undefined, // 品牌号
    modelId: undefined, //型号
    thingsBatchNumber: undefined, // 质控液主批号
    startTime: undefined, // 开始时间
    endTime: undefined, // 结束时间
    status: undefined // 状态
  })

  // 操作相关参数
  const [operation, setOperation] = useState({
    action: undefined, // add：录入质控液 | up：编辑质控液 | viewSubBatchNumber：显示次批号列表
    isShow: false, // 切换状态
    data: {} // 数据
  })

  // 新增 / 编辑质控液表单信息 ref
  const [form, setForm] = useState(undefined)

  // 质控液列表
  const [liquids, setLiquids] = useState([])

  // 品牌列表
  const [brands, setBrands] = useState([])

  // 质控液名称列表
  const [qcLiquids, setQcLiquids] = useState([])

  // 一行记录
  const [row, setRow] = useState(undefined)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '监测类型',
      dataIndex: 'type',
      align: 'center',
      render: (text, { type }) => <>{type && MONITOR[type - 1].name}</>
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      align: 'center',
      render: (text, { brand }) => <>{brand.name}</>
    },
    {
      title: '型号',
      dataIndex: 'model',
      align: 'center',
      render: (text, { model }) => <>{model.name}</>
    },
    {
      title: '质控液名称',
      dataIndex: 'controlThingsNameId',
      align: 'center',
      ellipsis: true,
      render: (text, { controlThingsNameId }) => {
        const result = qcLiquids.filter((v) => v.id === controlThingsNameId)
        return result.length && result[0].name
      }
    },
    {
      title: '基质',
      dataIndex: 'stroma',
      align: 'center',
      render: (text, { stroma }) => <>{stroma.name}</>
    },
    {
      title: '质控液主批号',
      dataIndex: 'thingsBatchNumber',
      align: 'center'
    },
    {
      title: '录入者',
      dataIndex: 'createUser',
      width: 100,
      align: 'center',
      render: (text, { createUser }) => <>{createUser.name}</>
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      align: 'center',
      render: (text, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{LIQUID_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{LIQUID_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='编辑质控液' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowOperation('up', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          <Tooltip title='质控液次批号' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<MenuOutlined />}
              onClick={() => onShowOperation('viewSubBatchNumber', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          {/* <Tooltip title={record.status ? '停用质控液' : '启用质控液'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip> */}
        </div>
      )
    }
  ]

  /**
   * api
   * 获取质控液列表
   * @param {*} params
   */
  const getLiquids = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqLiquids(params)
      if (status) {
        const result = mergeRid(data.resultList, params.pageNo, params.pageSize)
        setLiquids(result)
        setTotal(data.totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 获取品牌列表
   * @param {*} value
   */
  const getBrands = async () => {
    const { data, status } = await reqBrands()
    if (status) {
      setBrands(data)
    }
  }

  /**
   * api
   * 获取质控液名称列表
   */
  const getQcLiquids = async () => {
    const { data, status } = await reqQualityControls()
    if (status) {
      setQcLiquids(data)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 录入 / 编辑质控液
   * 按钮：点击显示弹框
   * @param {*} value
   */
  const onShowOperation = (action, data) => {
    // console.log(data)
    let params
    switch (action) {
      case 'add':
      case 'up':
        params = { action, data }
        break
      default:
        params = { action, isShow: true, data }
        break
    }
    setOperation(params)
    if (action === 'add') {
      if (form) form.resetFields()
    }
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getLiquids({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
  }, [page.pageNo, page.pageSize, condition])

  useEffect(() => {
    getBrands()
    getQcLiquids()

    return () => {
      setBrands((_) => {
        return
      })
      setQcLiquids((_) => {
        return
      })
    }
  }, [])

  /**
   * 渲染
   */
  if (!operation.isShow) {
    return (
      <>
        <Card
          className='height-percent'
          size='small'
          title={
            <>
              <ExperimentOutlined className='g_mr_10' />
              <span>质控液维护</span>
            </>
          }
          extra={
            <>
              <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
                {query ? '隐藏' : '查询'}
              </Button>
              <Button type='primary' icon={<PlusOutlined />} onClick={() => onShowOperation('add', {})}>
                录入质控液
              </Button>
            </>
          }
        >
          {query && (
            <QueryForm
              monitor={MONITOR}
              brands={brands}
              liquidStatus={LIQUID_STATUS}
              setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              setCondition={(params) => setCondition({ ...condition, ...params })}
            />
          )}

          <Table
            bordered
            size='small'
            scroll={{ x: 1400, y: 450 }}
            rowKey='rid'
            loading={isLoading}
            columns={initColumns}
            dataSource={liquids}
            pagination={{
              current: page.pageNo,
              pageSize: page.pageSize,
              total: total,
              showTotal: (total) => `共 ${total} 条记录`,
              showQuickJumper: true,
              onChange: onChangePage
            }}
            rowClassName={showRowClassName}
            onRow={(record) => {
              return { onClick: () => setRow(record) }
            }}
          />
        </Card>

        <IModal
          visible={operation.action === 'add' || operation.action === 'up'}
          titleText={operation.action === 'add' ? '录入质控液' : '编辑质控液'}
        >
          <LiquidForm
            monitor={MONITOR}
            brands={brands}
            qcLiquids={qcLiquids}
            level={QC_LEVEL}
            operation={operation}
            setForm={(form) => setForm(form)}
            setOperation={() => setOperation({ ...operation, action: undefined })}
            refreshLiquids={() => getLiquids({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })}
          />
        </IModal>
      </>
    )
  }
  return <SubBatchNumber operation={operation} setOperation={() => setOperation({ ...operation, isShow: false })} />
}

export default Liquid

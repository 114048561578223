/* 新建医嘱 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Form, Row, Col, Button, Checkbox, DatePicker, message } from 'antd'
import { BS_ADVICE } from '../../../utils/constants'
import PropTypes from 'prop-types'
import moment from 'moment'

import { reqAddBloodPrescription } from '../../../api/index'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Group } = Checkbox
const { RangePicker } = DatePicker

/* 使用类型检查
---------------------------------------------------------------- */
AddAdviceForm.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddAdviceForm(props) {
  const { flag, patientRow, setModal, onCancel, setFlag } = props

  // 提交表单
  const [form] = Form.useForm()

  /**
   * 按钮
   * 点击保存新建医嘱
   * @param {*} value
   */
  const onOkAddAdvice = async (value) => {
    const { prescriptionStatusId, time } = value
    const params = {
      prescriptionStatusId,
      startTime: moment(time[0]).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(time[1]).format('YYYY-MM-DD HH:mm:ss'),
      patientId: patientRow.id
    }
    const { status } = await reqAddBloodPrescription(params)
    if (status) {
      message.success('新建医嘱成功！')
      form.resetFields()
      setModal()
      setFlag(!flag)
    }
  }

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkAddAdvice}>
      <Row>
        <Col span={22} offset={2}>
          <Item name='prescriptionStatusId' label='检测时段' rules={[{ required: true, message: '请选择检测时段' }]}>
            <Group options={BS_ADVICE} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={2}>
          <Item name='time' label='执行时间' rules={[{ required: true, message: '请选择检测时间' }]}>
            <RangePicker showTime={{ format: 'HH:mm' }} format='YYYY-MM-DD HH:mm' style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
      <div className='g_txt_c'>
        <Button className='g_mr_20' onClick={onCancel}>
          取消
        </Button>
        <Button type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default AddAdviceForm

/* 编辑血糖医嘱 */

// ==================
// 调用模块
// ==================
import React, { useEffect } from 'react'
import { Form, Row, Col, Button, Checkbox, DatePicker,message } from 'antd'
import { BS_ADVICE } from '../../../utils/constants'
import PropTypes from 'prop-types'
import moment from 'moment'


/* 功能模块
---------------------------------------------------------------- */
import { reqBloodPrescription } from '../../../api'

// ==================
// 调用模块: 对象解构
// ==================
const { Item } = Form
const { Group } = Checkbox
const { RangePicker } = DatePicker

// ==================
// 使用类型检查
// ==================
UpdateAdviceForm.propTypes = {
  ncd: PropTypes.object.isRequired,
  patientRow: PropTypes.object,
  onShowModal:PropTypes.func.isRequired,
  refresh:PropTypes.func.isRequired,
  refreshS:PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function UpdateAdviceForm(props) {
  const { ncd,patientRow,onShowModal,refresh,refreshS} = props
  // 提交表单
  const [form] = Form.useForm()

    /**
   * api
   * 按钮
   * 点击保存修改血糖医嘱
   */
     const onOk = async (value) => {
      const { prescriptionStatusId, time } = value
      const params = {
        prescriptionStatusId,
        startTime: moment(time[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(time[1]).format('YYYY-MM-DD HH:mm:ss'),
        patientId: patientRow.id,
        id: ncd.id
      }
      const { status } = await reqBloodPrescription(params)
      if (status) {
        message.success('血糖医嘱修改成功！')
        form.resetFields()
        refresh()
        refreshS()
        onShowModal()
      }
    }

  /**
   * 生命周期
   */
  useEffect(() => {
    const {
      startTime,
      endTime,
      afterBreakfast,
      afterDinner,
      afterLunch,
      beforeBreakfast,
      beforeDinner,
      beforeLunch,
      beforeSleep,
      random,
      weeHours
    } = ncd
    form.setFieldsValue({
      prescriptionStatusId: [
        afterBreakfast,
        afterDinner,
        afterLunch,
        beforeBreakfast,
        beforeDinner,
        beforeLunch,
        beforeSleep,
        random,
        weeHours
      ],
      time: [moment(startTime), moment(endTime)]
    })
  }, [ form, ncd])
  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOk}>
      <Row>
        <Col span={22} offset={2}>
          <Item name='prescriptionStatusId' label='检测时段' rules={[{ required: true, message: '请选择检测时段' }]}>
            <Group options={BS_ADVICE} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={2}>
          <Item name='time' label='执行时间'>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
      <div className='g_txt_c'>
        <Button className='g_mr_20' onClick={onShowModal}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default UpdateAdviceForm

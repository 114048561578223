//应诊列表 | 患者查询 里面的心标记录表

/* 心标录入表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/*  功能模块
---------------------------------------------------------------- */
import { reqReagents, reqHeart } from '../../api/index'
import { mergeRid } from '../../utils/func'

/*  静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
PatientHeart.prototype = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientHeart(props) {
  const { patientRow, commonPage, setCommonPage } = props

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  // 心标记录表
  const [heart, setHeart] = useState([])

  /**
   * 初始化生成 table 所以列
   */
   const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '检测时间',
      dataIndex: 'detectionTime',
      width: 150,
      align: 'center',
      render: (text, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'CKMB',
      dataIndex: 'item1',
      width: '',
      align: 'center',
      render: (_, { theHeartItems }) => {
        const result = theHeartItems.filter((v) => v.item === 1)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { theHeartItems }) => <>{theHeartItems[0] ? theHeartItems[0].value : ''}</>
    },
    {
      title: '肌红蛋白',
      dataIndex: 'item2',
      width: '',
      align: 'center',
      render: (_, { theHeartItems }) => {
        const result = theHeartItems.filter((v) => v.item === 2)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { theHeartItems }) => <>{theHeartItems[1] ? theHeartItems[1].value : ''}</>
    },
    {
      title: '肌钙蛋白I',
      dataIndex: 'item3',
      width: '',
      align: 'center',
      render: (_, { theHeartItems }) => {
        const result = theHeartItems.filter((v) => v.item === 3)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { theHeartItems }) => <>{theHeartItems[2] ? theHeartItems[2].value : ''}</>
    },
    {
      title: 'B型钠尿肽',
      dataIndex: 'item4',
      width: '',
      align: 'center',
      render: (_, { theHeartItems }) => {
        const result = theHeartItems.filter((v) => v.item === 4)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { theHeartItems }) => <>{theHeartItems[3] ? theHeartItems[3].value : ''}</>
    },

    {
      title: 'DDIM',
      dataIndex: 'item5',
      width: '',
      align: 'center',
      render: (_, { theHeartItems }) => {
        const result = theHeartItems.filter((v) => v.item === 5)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { theHeartItems }) => <>{theHeartItems[4] ? theHeartItems[4].value : ''}</>
    },
    {
      title: '试纸批号',
      dataIndex: 'batchNumber',
      width: '',
      align: 'center',
      // render: (text, { batchNumber }) => reagents.filter((v)=>v.id===batchNumber)
      render: (_, { batchNumber }) => {
        const result = reagents.filter((v) => v.id === batchNumber)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.batchNumber}</span>
          })
        }
      }
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作者',
      dataIndex: 'user',
      width: '',
      align: 'center',
      render: (text, { user }) => <>{user && user.name}</>
    }
  ]

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    if (status) {
      setReagents(data.resultList)
    }
  }

  /**
   * 根据患者id查询心标记录表
   */
  const getHeart = async (params) => {
    setIsLoading(true)
    if (params) {
      try {
        const {
          data: { resultList, totalRows } = {},
          status
        } = await reqHeart(params)
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        if (status) {
          setHeart(result)
          setTotal(totalRows)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 挂载
   */
  useEffect(() => {
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
  }, [])
  useEffect(() => {
    getHeart({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  /**
   * 渲染
   */
  return (
    <Table
      bordered
      size='small'
      rowKey='rid'
      loading={isLoading}
      scroll={{ x: 1600 }}
      columns={initColumns}
      dataSource={heart}
      pagination={{
        current: commonPage.pageNo,
        pageSize: commonPage.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        showQuickJumper: true,
        showSizeChanger: false,
        onChange: onChangePage
      }}
    />
  )
}

export default PatientHeart

/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Form, Button, Row, Col, DatePicker } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { RangePicker } = DatePicker

/* 使用类型检查
---------------------------------------------------------------- */
QCGraphQueryForm.prototype = {
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCGraphQueryForm(props) {
  const { setCondition } = props

  const [form] = Form.useForm()

  /**
   * 查询
   * @param {*} value
   */
  const onQuery = (value) => {
    const { date } = value
    if (date) {
      let params = {
        startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      setCondition(params)
    }
  }

  /**
   * 重置
   */
  const onReset = () => {
    form.resetFields()
    setCondition({
      startDate: undefined,
      endDate: undefined
    })
  }

  /**
   * 渲染
   */
  return (
    <Form layout='inline' form={form} onFinish={onQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='date' label='时间'>
            <RangePicker />
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QCGraphQueryForm

/* 新建会诊 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Form, Input, Select, Button, Checkbox, message, Spin } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddConsultation, findConsultationOfficeList } from '../../../api/index'

/* 静态资源模块
---------------------------------------------------------------- */
import { CONSULTATION_TYPE } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select
const { TextArea } = Input

// ==================
// 使用类型检查
// ==================
AddConsultationForm.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function AddConsultationForm(props) {
  const { flag, patientRow, setModal, setFlag} = props

  // 提交表单
  const [form] = Form.useForm()

  //加载中
  const [isLoading, setIsLoading] = useState(false)

  // 机构列表(医院-科室-病区)
  const [institutions, setInstitutions] = useState([])

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  // 三级分诊多选框状态值
  const [isTriage, setIsTriage] = useState(false)

  // 通过props获取应诊科室列表、会诊类别
  const children = []
  for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>)
  }

  /**
   * 获取多选框的布尔值
   * @param {*} e
   */
  const onChangeTriage = (e) => {
    setIsTriage(e.target.checked)
    form.setFieldsValue({ companyId: undefined, officeId: undefined })
  }

  /**
   * 下拉框选择
   * @param {*} value
   */
  const onClick = async () => {
    setIsLoading(true)
    try {
      const params = {
        triage: isTriage === true ? 2 : 1
      }
      const { data, status } = await findConsultationOfficeList(params)
      if (status) {
        setInstitutions(data)
        form.setFieldsValue({ officeId: undefined })
      }
    } finally {
      setIsLoading(false)
    }
  }
  

  /**
   * 选择框
   * 点击选择医院
   * @param {*} value
   */
  const onChangeHospital = (value) => {
    setCompanyId(value)
    form.setFieldsValue({ officeId: undefined })
  }

  /**
   * 选择科室
   * @param {*} companyId
   * @returns
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId) || {}
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    } else {
      return null
    }
  }

  /**
   * 按钮
   * 点击保存新建会诊
   */
  const onOkAddAdvice = async (value) => {
    const { triage, consultationType, companyId, officeId, pursueReason } = value
    const params = {
      triage: triage === true ? 2 : 1,
      patientId: patientRow.id,
      consultationType: consultationType === 2 ? 2 : 1,
      companyId,
      officeId,
      pursueReason
    }
    const { status } = await reqAddConsultation(params)
    if (status) {
      message.success('新建会诊成功！')
      form.resetFields()
      setModal()
      setFlag(!flag)
    }
  }

  /**
   * 取消按钮
   */
  const onCancel = () => {
    setIsTriage(false)
    setModal()
    form.resetFields()
  }

  /**
   * 渲染
   */
  return (
    <>
      <Form form={form} onFinish={onOkAddAdvice}>
        <Spin spinning={isLoading}>
          <Item name='triage' label={<span className='g_txt_r g_w_67'>三级分诊</span>} valuePropName='checked'>
            <Checkbox onChange={onChangeTriage} />
          </Item>
          <Item label={<span className='g_txt_r g_w_67'>患者姓名</span>} className='g_w_174'>
            <Input value={patientRow.name} bordered={false} />
          </Item>
          <Item
            name='consultationType'
            label={<span className='g_txt_r g_w_67'>会诊类别</span>}
          >
            <Select placeholder='普通会诊'>
              {CONSULTATION_TYPE.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name='companyId' label='应诊医院' rules={[{ required: true, message: '请选择应诊医院' }]}>
            <Select onChange={onChangeHospital} onClick={onClick}>
              {Object.keys(institutions).length &&
                institutions.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
          <Item name='officeId' label='应诊科室' rules={[{ required: true, message: '请选择应诊科室' }]}>
            <Select style={{ width: '100%' }} mode='multiple'>
              {companyId && showOffices(companyId)}
            </Select>
          </Item>
          <Item name='pursueReason' label={<span className='g_txt_r g_w_67'>申请原因</span>}>
            <TextArea rows={4} maxLength={200}/>
          </Item>

          <div className='g_txt_c'>
            <Button className='g_mr_20' key='back' onClick={onCancel}>
              取消
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              确定
            </Button>
          </div>
        </Spin>
      </Form>
    </>
  )
}

export default AddConsultationForm

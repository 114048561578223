/* 质控报表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Tooltip } from 'antd'
import { SettingOutlined, FilterOutlined, ToolOutlined } from '@ant-design/icons'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import QcRulesForm from './QCRulesForm'
import FixedMeanForm from './FixedMeanForm'
import FloatMeanForm from './FloatMeanForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqProjectBindings } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE, MONITOR, BINDING_TYPE } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Setting() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询 / 隐藏显示控制
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    type: undefined, // 监测类型
    instrumentId: undefined, // 仪器
    controlProjectId: undefined // 项目
  })

  // 切换: 录入 / 编辑 | 用户列表
  const [modal, setModal] = useState({
    action: undefined, // 操作行为 | 显示状态控制
    data: {} // 数据
  })

  // 项目绑定列表
  const [projectBindings, setProjectBindings] = useState([])

  // 一行记录
  const [row, setRow] = useState(undefined)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '绑定类型',
      dataIndex: 'bindType',
      align: 'center',
      render: (text, { bindType }) => BINDING_TYPE[bindType - 1].name
    },
    {
      title: '监测类型',
      dataIndex: '',
      width: '',
      align: 'center',
      render: (text, { type }) => MONITOR[type - 1].name
    },
    {
      title: '仪器',
      dataIndex: 'instrument',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { instrument }) => instrument.brand.name + '  ' + instrument.modelVO.name + '  ' + instrument.snnum
    },
    {
      title: '试剂',
      dataIndex: 'reagent',
      align: 'center',
      ellipsis: true,
      render: (text, { reagent }) => reagent.brand.name + '  ' + reagent.modelVO.name + '  ' + reagent.batchNumber
    },
    {
      title: '质控液',
      dataIndex: 'controlThings',
      align: 'center',
      ellipsis: true,
      render: (text, { controlThings }) =>
        controlThings.brand.name + '  ' + controlThings.model.name + '  ' + controlThings.thingsBatchNumber
    },
    {
      title: '质控液名称',
      dataIndex: 'controlThingsName',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { controlThings }) => controlThings.name.name
    },
    {
      title: '方法学',
      dataIndex: 'methodology',
      align: 'center',
      ellipsis: true,
      render: (text, { reagent }) => reagent.methodology.name
    },
    {
      title: '基质',
      dataIndex: 'stroma',
      align: 'center',
      render: (text, { controlThings }) => controlThings.stroma.name
    },
    {
      title: '项目',
      dataIndex: 'controlProject',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { controlProject }) => controlProject.name
    },
    {
      title: '单位',
      dataIndex: 'metering',
      width: 100,
      align: 'center',
      render: (text, { metering }) => metering.name
    },
    {
      title: '质控规则',
      dataIndex: 'qcRules',
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='设置质控规则' mouseEnterDelay={0.5}>
            <Button type='link' icon={<ToolOutlined />} onClick={() => onShowModal('qcRules', record)} />
          </Tooltip>
        </div>
      )
    },
    {
      title: '固定均值',
      dataIndex: 'fixedMean',
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='设置固定均值' mouseEnterDelay={0.5}>
            <Button type='link' icon={<ToolOutlined />} onClick={() => onShowModal('fixedMean', record)} />
          </Tooltip>
        </div>
      )
    },
    {
      title: '浮动均值',
      dataIndex: 'floatMean',
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='设置浮动均值' mouseEnterDelay={0.5}>
            <Button type='link' icon={<ToolOutlined />} onClick={() => onShowModal('floatMean', record)} />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取已项目绑定的列表
   */
  const getProjectBindings = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqProjectBindings(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setProjectBindings(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 查询
   * 按钮：条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   *  列表操作
   * 按钮：
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, data })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getProjectBindings({ controlProjectStatus: 1, pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <SettingOutlined className='g_mr_10' />
            <span>质控设置</span>
          </>
        }
        extra={
          <Button className='g_ml_10' icon={<FilterOutlined />} onClick={onShowQuery}>
            {query ? '隐藏' : '查询'}
          </Button>
        }
      >
        {query && (
          <QueryForm
            monitor={MONITOR}
            setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          scroll={{ x: 1400, y: 450 }}
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={projectBindings}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
          rowClassName={showRowClassName}
          onRow={(record) => {
            return { onClick: () => setRow(record) }
          }}
        />
      </Card>

      <IModal visible={modal.action === 'qcRules'} titleText='设置质控规则' width={800}>
        <QcRulesForm monitor={MONITOR} modal={modal} setModal={() => setModal({ ...modal, action: undefined })} />
      </IModal>
      <IModal visible={modal.action === 'fixedMean'} titleText='设置固定均值' width={800}>
        <FixedMeanForm monitor={MONITOR} modal={modal} setModal={() => setModal({ ...modal, action: undefined })} />
      </IModal>
      <IModal visible={modal.action === 'floatMean'} titleText='设置浮动均值' width={800}>
        <FloatMeanForm monitor={MONITOR} modal={modal} setModal={() => setModal({ ...modal, action: undefined })} />
      </IModal>
    </>
  )
}

export default Setting

/* 常量库 */

const commonStatus = [
  { id: 0, name: '停用' },
  { id: 1, name: '正常' }
]

export const INSTITUTION_STATUS = commonStatus // 机构状态

export const USER_STATUS = commonStatus // 用户状态

export const DICTIONARY_STATUS = commonStatus // 字典状态

export const PAGE_SIZE = 10 // 每页10条记录

export const PAGE_SIZE_MAX = 10000 // 选择框最大记录

// 患者在院状态
export const PATIENT_IN_STATUS = [
  { id: 1, name: '住院中' },
  { id: 2, name: '会诊中' },
  // { id: 3, name: '出院' },
  { id: 4, name: '门诊' }
]

// 会诊状态
export const STATUS = [
  { id: 1, name: '会诊中' },
  { id: 2, name: '已完成会诊' }
]


// 患者查询状态
export const PATIENT_OUT_STATUS = [
  { id: 1, name: '住院中' },
  { id: 2, name: '会诊中' },
  { id: 3, name: '出院' },
  { id: 4, name: '门诊' }
]

// 性别
export const GENDER = [
  { id: 0, name: '女' },
  { id: 1, name: '男' }
]

// 会诊状态
export const CONSULTATION_STATUS = [
  { id: 1, name: '会诊中', enumId: 1 },
  { id: 2, name: '完成会诊', enumId: 2 }
]


// 应诊管理-应诊类型
export const COURTYARD_STATUS = [
  { id: 1, name: '院内应诊' },
  { id: 2, name: '院外应诊' }
]

// 会诊进度-会诊状态
export const CONSULTATION = [
  { id: 1, name: '等待会诊' },
  { id: 2, name: '已完成会诊' },
  { id: 3, name: '已取消会诊' }
]

// 民族
export const ANTION = [
  { id: 1, name: '汉' },
  { id: 2, name: '阿昌族' },
  { id: 3, name: '白族' },
  { id: 4, name: '保安族' },
  { id: 5, name: '布朗族' },
  { id: 6, name: '布依族' },
  { id: 7, name: '朝鲜族' },
  { id: 8, name: '达斡尔族' },
  { id: 9, name: '傣族' },
  { id: 10, name: '德昂族' },
  { id: 11, name: '东乡族' },
  { id: 12, name: '侗族' },
  { id: 13, name: '独龙族' },
  { id: 14, name: '鄂伦春族' },
  { id: 15, name: '俄罗斯族' },
  { id: 16, name: '鄂温克族' },
  { id: 17, name: '高山族' },
  { id: 18, name: '仡佬族' },
  { id: 19, name: '哈尼族' },
  { id: 20, name: '哈萨克族' },
  { id: 21, name: '赫哲族' },
  { id: 22, name: '回族' },
  { id: 23, name: '基诺族' },
  { id: 24, name: '景颇族' },
  { id: 25, name: '京族' },
  { id: 26, name: '柯尔克孜族' },
  { id: 27, name: '拉祜族' },
  { id: 28, name: '珞巴族' },
  { id: 29, name: '僳僳族' },
  { id: 30, name: '黎族' },
  { id: 31, name: '满族' },
  { id: 32, name: '毛南族' },
  { id: 33, name: '门巴族' },
  { id: 34, name: '蒙古族' },
  { id: 35, name: '苗族' },
  { id: 36, name: '仫佬族' },
  { id: 37, name: '纳西族' },
  { id: 38, name: '怒族' },
  { id: 39, name: '普米族' },
  { id: 40, name: '羌族' },
  { id: 41, name: '撒拉族' },
  { id: 42, name: '畲族' },
  { id: 43, name: '水族' },
  { id: 44, name: '塔吉克族' },
  { id: 45, name: '塔塔尔族' },
  { id: 46, name: '土家族' },
  { id: 47, name: '土族' },
  { id: 48, name: '佤族' },
  { id: 49, name: '维吾尔族' },
  { id: 50, name: '乌孜别克族' },
  { id: 51, name: '锡伯族' },
  { id: 52, name: '瑶族' },
  { id: 53, name: '彝族' },
  { id: 54, name: '裕固族' },
  { id: 55, name: '藏族' },
  { id: 56, name: '壮族' },
  { id: 57, name: '其他' }
]

// 文化程度
export const EDUCATION = [
  { id: 1, name: '小学及以下' },
  { id: 2, name: '初中' },
  { id: 3, name: '高中' },
  { id: 4, name: '中专' },
  { id: 5, name: '专科' },
  { id: 6, name: '本科' },
  { id: 7, name: '研究生硕士' },
  { id: 8, name: '研究生博士及以上' }
]

// 婚姻状况
export const MARRIAGE = [
  { id: 1, name: '未婚' },
  { id: 2, name: '已婚' },
  { id: 3, name: '离异' },
  { id: 4, name: '丧偶' }
]

// 图谱
export const ATLAS = [
  { id: 1, name: '血糖趋势图' },
  { id: 2, name: '血糖均值图' },
  { id: 3, name: '血糖波动图' },
  { id: 4, name: '血糖控制图' }
]

// 图谱
export const ATLAS_1 = [
  { id: 1, name: '测量血糖次数图' },
  { id: 2, name: '血糖监测分布图' },
  { id: 3, name: '血糖监测人数图' },
  { id: 4, name: '血糖控制图' }
]

// 时段
export const TIME_PERIOD = [
  { id: 1, name: '近7天' },
  { id: 2, name: '近15天' }
]

// 字典
export const DICTIONARY = [
  {
    id: 1,
    name: '单位',
    type: 'unit',
    dicType: null,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  },
  {
    id: 2,
    name: '基质',
    type: 'matrix',
    dicType: null,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  },
  {
    id: 3,
    name: '方法学',
    type: 'methodology',
    dicType: null,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  },
  {
    id: 4,
    name: '品牌型号',
    type: 'brand',
    dicType: 7,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  },
  {
    id: 5,
    name: '监测项目',
    type: 'monitor',
    dicType: null,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  },
  {
    id: 6,
    name: '质控液名称',
    type: 'qualityControl',
    dicType: null,
    createTime: null,
    updateTime: null,
    remark: null,
    status: 1
  }
  // {
  //   id: 7,
  //   name: '操作人',
  //   type: 'action',
  //   dicType: null,
  //   createTime: null,
  //   updateTime: null,
  //   remark: null,
  //   status: 1
  // }
]

// 血糖医嘱
export const BS_ADVICE = [
  { value: 1, label: '凌晨' },
  { value: 2, label: '早餐前' },
  { value: 3, label: '早餐后' },
  { value: 4, label: '午餐前' },
  { value: 5, label: '午餐后' },
  { value: 6, label: '晚餐前' },
  { value: 7, label: '晚餐后' },
  { value: 8, label: '睡前' },
  { value: 9, label: '随机' }
]

// 血糖医嘱
export const WARNING = [
  { id: 1, name: '正常' },
  { id: 2, name: '高于预警值' },
  { id: 3, name: '低于预警值' }
]

// 患者状态
export const PATIENT_STATUS = [
  { id: 0, name: '会诊中' },
  { id: 1, name: '住院中' },
  { id: 2, name: '出院' },
  { id: 3, name: '门诊' }
]

// 患者类型
export const PATIENT_TYPE = [
  { id: 1, name: '门诊' },
  { id: 2, name: '住院' }
]

// 会诊类别
export const CONSULTATION_TYPE = [
  { id: 1, name: '普通会诊', enumId: 1 },
  { id: 2, name: '紧急会诊', enumId: 2 }
]

// 血糖录入：外出/拒测下拉框
export const BLOOD_SUGAR = [
  { id: -1, name: '外出' },
  { id: -2, name: '拒测' },
  { id: -3, name: '未测' }
  // { id: -4, name: '已测' }
]

// 用药时间
export const INSULINMEDICATIONITEM = [
  { insulinMedicationItem: 1, name: '早餐前' },
  { insulinMedicationItem: 2, name: '餐后' },
  { insulinMedicationItem: 3, name: '餐时' },
  { insulinMedicationItem: 4, name: '餐前15分钟' },
  { insulinMedicationItem: 5, name: '餐前25分钟' },
  { insulinMedicationItem: 6, name: '餐前45分钟' }
]

//药品类型
export const INSULIN = [
  { name: '短效胰岛素', insulin: 1 },
  { name: '中效胰岛素', insulin: 2 },
  { name: '长效胰岛素', insulin: 3 },
  { name: '混合胰岛素', insulin: 4 },
  { name: '速效胰岛素', insulin: 5 }
]

// 药品下拉选择框
export const DRUG = [
  { id: 1, name: '赖脯胰岛素（优泌乐' },
  { id: 2, name: '门冬胰岛素（诺和锐' },
  { id: 3, name: '甘舒霖' },
  { id: 4, name: '诺和灵' },
  { id: 5, name: '优泌林' }
]

export const INSTRUMENT_STATUS = commonStatus // 仪器状态

export const REAGENT_STATUS = commonStatus // 试剂状态

export const LIQUID_STATUS = commonStatus // 质控液状态

export const BINDING_STATUS = commonStatus // 绑定状态

// 监测类型
export const MONITOR = [
  { id: 1, name: '血糖监测', status: 1, enumId: 1 },
  { id: 2, name: '血气监测', status: 1, enumId: 2 },
  { id: 3, name: '心标监测', status: 1, enumId: 3 },
  { id: 4, name: '血凝监测', status: 1, enumId: 4 }
]

// 绑定类型
export const BINDING_TYPE = [
  { id: 1, name: '绑定监测' },
  { id: 2, name: '绑定质控' }
]

// 质控水平
export const QC_LEVEL = [
  { id: 1, name: '水平1' },
  { id: 2, name: '水平2' },
  { id: 3, name: '水平3' }
]

// 质控规则
export const QC_RULES = [
  { id: 0, name: '拒绝', color: 'g_color_red' },
  { id: 1, name: '警告', color: 'g_color_alert' },
  { id: 2, name: '接受', color: 'g_color_green' }
]

// 输入框错误提示信息
export const errorMsg = [
  { id: 1, name: '请输入数字' },
  { id: 2, name: '请输入正整数' }
]

export const LIQUID_SUBBN_STATUS = commonStatus // 水平 - 质控液次批号状态

// 是否接受
export const IS_ACCEPT = [
  { id: 1, name: 'Y', type: true },
  { id: 2, name: 'N', type: false }
]

// 维护内容
export const MAINTAIN_CONTENT = [
  { id: 1, name: '仪器电池状态', enumId: 0 },
  { id: 2, name: '操作温湿度适宜', enumId: 1 },
  { id: 3, name: '仪器数字显示完好', enumId: 2 },
  { id: 4, name: '仪器清洁与消毒', enumId: 3 }
]

// 质控报表状态
export const QC_REPORT_STATUS = commonStatus

/* 主界面 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from 'antd'

/* 路由组件
---------------------------------------------------------------- */
import RouterComp from '../router'

/* 公共组件
---------------------------------------------------------------- */
import { Header, Footer } from '../components'

/* 子模块
---------------------------------------------------------------- */
import BigScreen from '../pages/BigScreen'

/* 功能模块
---------------------------------------------------------------- */
import memoryUtils from '../utils/memoryUtils'

/* 静态资源
---------------------------------------------------------------- */
import './index.less'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Content } = Layout

/* 唯一的模块导出
---------------------------------------------------------------- */
function AdminLayout() {
  // 获取内存中的用户信息
  const user = memoryUtils.user

  // 大屏模式
  const [isBigScreen, setIsBigScreen] = useState(false)

  if (!Object.keys(user).length > 0) {
    return <Redirect to='/login' />
  }
  if (isBigScreen) {
    return <BigScreen setIsBigScreen={(params) => setIsBigScreen(params)} />
  }
  return (
    <Layout className='layout'>
      <div className='layout_header'>
        <Header setIsBigScreen={(params) => setIsBigScreen(params)}/>
      </div>
      <Content className='layout_content'>
        <RouterComp />
      </Content>
      <div className='layout_footer'>
        <Footer />
      </div>
    </Layout>
  )
}

export default AdminLayout

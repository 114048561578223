//应诊列表 | 患者查询 里面的血糖医嘱

/* 血糖医嘱 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucoseById, reqQrescriptionInProgress } from '../../api/index'
import { mergeRid } from '../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
PatientBGAdvice.propTypes = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientBGAdvice(props) {
  const { commonPage, patientRow ,setCommonPage} = props

  // 总记录数
  const [total, setTotal] = useState(0)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 根据患者id查询血糖医嘱页面数据
  const [bloodGlucoseById, setBloodGlucoseById] = useState([])

  // 根据患者id查询血糖医嘱页面数据（正在执行）
  const [qrescriptionInProgress, setQrescriptionInProgress] = useState({})

  /**
   * 初始化 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      width: 160,
      align: 'center',
      render: (text, { startTime }) => moment(startTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 160,
      align: 'center',
      render: (text, { endTime }) => moment(endTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '凌晨',
      dataIndex: 'weeHours',
      width: '',
      align: 'center',
      render: (text, { weeHours }) => (weeHours ? '√' : '')
    },
    {
      title: '早餐前',
      dataIndex: 'beforeBreakfast ',
      width: '',
      align: 'center',
      render: (text, { beforeBreakfast }) => (beforeBreakfast ? '√' : '')
    },
    {
      title: '早餐后',
      dataIndex: 'afterBreakfast ',
      width: '',
      align: 'center',
      render: (text, { afterBreakfast }) => (afterBreakfast ? '√' : '')
    },
    {
      title: '午餐前',
      dataIndex: 'beforeLunch',
      width: '',
      align: 'center',
      render: (text, { beforeLunch }) => (beforeLunch ? '√' : '')
    },
    {
      title: '午餐后',
      dataIndex: 'afterLunch ',
      width: '',
      align: 'center',
      render: (text, { afterLunch }) => (afterLunch ? '√' : '')
    },
    {
      title: '晚餐前',
      dataIndex: 'beforeDinner',
      width: '',
      align: 'center',
      render: (text, { beforeDinner }) => (beforeDinner ? '√' : '')
    },
    {
      title: '晚餐后',
      dataIndex: 'afterDinner ',
      width: '',
      align: 'center',
      render: (text, { afterDinner }) => (afterDinner ? '√' : '')
    },
    {
      title: '睡前',
      dataIndex: 'beforeSleep',
      width: '',
      align: 'center',
      render: (text, { beforeSleep }) => (beforeSleep ? '√' : '')
    },
    {
      title: '随机',
      dataIndex: 'random',
      width: '',
      align: 'center',
      render: (text, { random }) => (random ? '√' : '')
    },
  ]

  /**
   * api
   * 通过患者id获取血糖医嘱页面信息
   * @param {*} id
   */
  const getBloodGlucoseById = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqBloodGlucoseById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setBloodGlucoseById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 通过患者id获取血糖医嘱页面信息(正在执行)
   * @param {*} id
   */
  const getQrescriptionInProgress = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqQrescriptionInProgress(params)
      const result = data
      if (status) {
        setQrescriptionInProgress(result)
        setTotal(data.totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodGlucoseById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
    getQrescriptionInProgress({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  return (
    <>
      <Row className='g_mb_10'>
        <Col className='g_mr_10'>正在执行:</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.weeHours ? '凌晨' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.beforeBreakfast ? '早餐前' : ''} </Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.afterBreakfast ? '早餐后' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.beforeLunch ? '午餐前' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.afterLunch ? '午餐后' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.beforeDinner ? '晚餐前' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.afterDinner ? '晚餐后' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.beforeSleep ? '睡前 ' : ''}</Col>
        <Col className='g_mr_10'>{qrescriptionInProgress.random ? '随机' : ''}</Col>
      </Row>
      <Table
        bordered
        size='small'
        rowKey='rid'
        loading={isLoading}
        scroll={{ x: 1400, y: 400 }}
        columns={initColumns}
        dataSource={bloodGlucoseById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
      />
    </>
  )
}

export default PatientBGAdvice

/* 慢病管理 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Table, Tooltip} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { mergeRid } from '../../utils/func'
import { reqChronicDiseaseById } from '../../api'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../ui'

/* 调用子组件
---------------------------------------------------------------- */
import UpdateNCDForm from '../../pages/Monitor/BloodSugar/UpdateNCDForm'

/* 使用类型检查
---------------------------------------------------------------- */
BSNCDPane.propTypes = {
  flag:PropTypes.bool,
  patientRow: PropTypes.object,
  commonRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func.isRequired
}

/* 唯一的模块调用
---------------------------------------------------------------- */
function BSNCDPane(props) {
  const { flag,patientRow, commonRow, commonPage, setCommonPage, setCommonRow } = props

  //根据患者id查询患者慢病页面列表
  const [chronicDiseaseById, setChronicDiseaseById] = useState([])

  // 模态框相关参数
  const [modal, setModal] = useState({
    type: undefined,
    data: {},
    isShow: false
  })

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总条数
  const [total, setTotal] = useState(0)

  /**
   * 初始化生成 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '身高',
      dataIndex: 'stature',
      width: '',
      align: 'center'
    },
    {
      title: '体重',
      dataIndex: 'weight',
      width: '',
      align: 'center'
    },
    {
      title: 'BMI',
      dataIndex: 'bmi',
      width: '',
      align: 'center'
    },
    {
      title: '腰围',
      dataIndex: 'waistline',
      width: '',
      align: 'center'
    },
    {
      title: '臂围',
      dataIndex: 'hipLine',
      width: '',
      align: 'center'
    },
    {
      title: '收缩压',
      dataIndex: 'systolicPressure',
      width: '',
      align: 'center'
    },
    {
      title: '舒张压',
      dataIndex: 'dbp',
      width: '',
      align: 'center'
    },
    {
      title: '心率',
      dataIndex: 'heartRate',
      width: '',
      align: 'center'
    },
    {
      title: '体脂',
      dataIndex: 'bodyFat',
      width: '',
      align: 'center'
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (_, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '糖尿病并发症',
      dataIndex: 'complications',
      width: 300,
      align: 'center',
      render: (_, { complications }) => complications.map((v) => <span key={v.id}>{`${v.name}; `}</span>)
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '',
      align: 'center',
      render: (_, record) => (
        <Tooltip title='编辑' mouseEnterDelay={0.5}>
        <Button type='link' onClick={() => onShowModal('up', record)} icon={<EditOutlined />} />
      </Tooltip>
      )
    }
  ]

  /**
   * api
   * 通过患者id获取页面慢病信息
   * @param {*} id
   */
  const getChronicDisease = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqChronicDiseaseById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setChronicDiseaseById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 按钮
   * 点击显示慢病更新弹框
   */
  const onShowModal = (type, data) => {
    setModal({ type, data, isShow: true })
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getChronicDisease({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize,flag])

  /**
   * 渲染
   */
  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        loading={isLoading}
        scroll={{ x: 1400, y: 500 }}
        columns={initColumns}
        dataSource={chronicDiseaseById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setCommonRow(record)
            }
          }
        }}
      />
      <IModal visible={modal.isShow} titleText='慢病编辑' width={800}>
        <UpdateNCDForm
          patientRow={patientRow}
          commonRow={commonRow}
          ncd={modal.data}
          onShowModal={() => setModal({ ...modal, isShow: false })}
          refresh={() =>
            getChronicDisease({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
          }
        />
      </IModal>
    </>
  )
}

export default BSNCDPane

/* 血气录入 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Input, Divider, Button, Select, DatePicker, message } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 调用模块
---------------------------------------------------------------- */
import { reqInstruments, reqReagents, reqFleshReport,reqfindNurseListByLoginUser } from '../../../api/index'
import { mergeRid } from '../../../utils/func'
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
AddBGForm.prototype = {
  patientRow: PropTypes.object,
  setShow: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddBGForm(props) {
  const { patientRow, setShow, onCancel } = props

  // 仪器列表
  const [instrumentList, setInstrumentList] = useState([])

  //护士列表
 const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 提交表单
  const [form] = Form.useForm()

  /**
   * api
   * 仪器接口
   */
  const getInstrumentList = async (params) => {
    const { data, status } = await reqInstruments(params)
    const result = mergeRid(data.resultList)
    if (status) {
      setInstrumentList(result)
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    const result = mergeRid(data.resultList)
    if (status) {
      setReagents(result)
    }
  }

  /**
   * 当天以后的日期不可选择
   * @param {*} current
   * @returns
   */
  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }

   /**
 * api
 * 获取护士列表
 * @param {*} value 
 */
    const getNurseListByLoginUser = async () => {
      const { data, status } = await reqfindNurseListByLoginUser()
      if (status) {
        setNurseListByLoginUser(data)
      }
    }

  /**
   * 按钮
   * 点击保存血气录入
   * @param {*} value
   */
  const onOkAddBG = async (value) => {
    const {
      detectionTime,
      userId,
      item1,
      item2,
      item3,
      item4,
      item5,
      item6,
      item7,
      item8,
      item9,
      item10,
      item11,
      item12,
      item13,
      item14,
      item15,
      item16
    } = value
    const { status } = await reqFleshReport({
      ...value,
      userId,
      patientId: patientRow.id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss') || moment(today).format('YYYY-MM-DD HH:mm:ss'),
      fleshResult: {
        1: parseFloat(item1),
        2: parseFloat(item2),
        3: parseFloat(item3),
        4: parseFloat(item4),
        5: parseFloat(item5),
        6: parseFloat(item6),
        7: parseFloat(item7),
        8: parseFloat(item8),
        9: parseFloat(item9),
        10: parseFloat(item10),
        11: parseFloat(item11),
        12: parseFloat(item12),
        13: parseFloat(item13),
        14: parseFloat(item14),
        15: parseFloat(item15),
        16: parseFloat(item16)
      }
    })
    if (status) {
      message.success('血气录入成功！')
      form.resetFields()
      setShow()
    }
  }

  /**
   * 生命周期
   */

   useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])
  useEffect(() => {
    getInstrumentList({ pageNo: 1, pageSize: PAGE_SIZE_MAX, type: 2 })
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX, type: 2 })
  }, [])

  /**
   * 渲染
   */
  const today = moment() // 当天日期
  return (
    <Card
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          血气录入
        </h3>
      }
    >
      <Form form={form} onFinish={onOkAddBG}>
        <Divider orientation='left' plain>
          基本信息
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='snnum'
              rules={[{ required: true, message: '请输入仪器' }]}
              label={
                <span className='g_w_98' rules={[{ required: true, message: '请输入仪器' }]}>
                  仪器
                </span>
              }
            >
              <Select style={{ width: '100%' }}>
                {instrumentList.length &&
                  instrumentList.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '     ' + v.modelVO.name + '     ' + v.snnum}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='userId'
              label={<span className='g_w_56'>操作人</span>}
              rules={[{ required: true, message: '请选择操作人' }]}
            >
              <Select >
                {nurseListByLoginUser.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='batchNumber'
              rules={[{ required: true, message: '请选择试纸（剂）批号' }]}
              label={<span className='g_w_98'>试纸（剂）批号</span>}
            >
              <Select style={{ width: '100%' }}>
                {reagents.length &&
                  reagents.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '      ' + v.methodology.name + '      ' + v.batchNumber}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='detectionTime'
              // rules={[{ required: true, message: '请选择监测时间' }]}
              label={<span className='g_w_98'>监测时间</span>}
              initialValue={moment(today)}
            >
              <DatePicker className='width-percent' disabledDate={disabledDate} />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          详细信息
        </Divider>
        <Row>
          <Col offset={1} className='g_mb_10 color-red'>
            说明：以下内容至少填写一项
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item1'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>二氧化碳分压</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmHg
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item2'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>标准重碳酸</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item3'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>酸碱度</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col span={8} offset={4}>
            <Item
              name='item4'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>碳酸氢根浓度</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item5'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>氧分压</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmHg
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item6'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>二氧化碳总量</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item7'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>细胞比积</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            g/L
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item8'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>氧饱和度</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            %
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item9'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>钠</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item10'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>血红蛋白</span>}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item11'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>钾</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item12'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>细胞外液剩余碱</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item13'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>氯</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item14'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>肺泡内氧分压</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmHg
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item15'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>离子钙</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            g/L
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item16'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_98'>全血碱剩余</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Divider plain />
        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default AddBGForm

/* 血糖透视表 */

// ==================
// 调用模块
// ==================
import React, { useState, useEffect } from 'react'
import { Table, Select, Button, Form,message } from 'antd'
import { mergeRid } from '../../utils/func'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../ui'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../utils/constants'

/* 功能模块
---------------------------------------------------------------- */
import { reqSugarPerspectiveById, reqfindNurseListByLoginUser, reqUpdateBloodGlucoseOperator } from '../../api'
import memoryUtils from '../../utils/memoryUtils'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Option } = Select
const { Item } = Form

// ==================
// 使用类型检查
// ==================
BSPerspectivePane.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function BSPerspectivePane(props) {
  const { patientRow, commonPage, setCommonPage,flag } = props

  const [form] = Form.useForm()

  // 总条数
  const [total, setTotal] = useState(0)

  const [row, setRow] = useState({})

  const [option,setOption] = useState(0)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 血糖透视表
  const [sugarPerspective, setSugarPerspective] = useState([])

  //护士列表
  const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  // 模态框相关参数
  const [modal, setModal] = useState({
    type: undefined,
    data: {},
    isShow: false
  })

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '时间',
      dataIndex: 'detectionTime',
      width: 150,
      align: 'center',
      render: (text, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '凌晨',
      dataIndex: 'weeHours',
      align: 'center',
      render: (text, { weeHours }) =>
        weeHours && weeHours.warning === 1 ? (
          <span className={weeHours.status === -1 ? 'g_color_green' : weeHours.status === -2 ? 'g_color_alert' : ''}>
            {weeHours.status === -1 ? '外出' : weeHours.status === -2 ? '拒测' : weeHours && weeHours.value}
          </span>
        ) : (
          <span
            className={weeHours.status === -1 ? 'g_color_green' : weeHours.status === -2 ? 'g_color_alert' : 'g_color_red'}
          >
            {weeHours.status === -1 ? '外出' : weeHours.status === -2 ? '拒测' : weeHours && weeHours.value}
          </span>
        )
    },
    {
      title: '早餐前',
      dataIndex: 'beforeBreakfast',
      align: 'center',
      render: (text, { beforeBreakfast }) =>
        beforeBreakfast && beforeBreakfast.warning === 1 ? (
          <span
            className={
              beforeBreakfast.status === -1 ? 'g_color_green' : beforeBreakfast.status === -2 ? 'g_color_alert' : ''
            }
          >
            {beforeBreakfast.status === -1
              ? '外出'
              : beforeBreakfast.status === -2
                ? '拒测'
                : beforeBreakfast && beforeBreakfast.value}
          </span>
        ) : (
          <span
            className={
              beforeBreakfast.status === -1
                ? 'g_color_green'
                : beforeBreakfast.status === -2
                  ? 'g_color_alert'
                  : 'g_color_red'
            }
          >
            {beforeBreakfast.status === -1
              ? '外出'
              : beforeBreakfast.status === -2
                ? '拒测'
                : beforeBreakfast && beforeBreakfast.value}
          </span>
        )
    },
    {
      title: '早餐后',
      dataIndex: 'afterBreakfast',
      align: 'center',
      render: (text, { afterBreakfast }) =>
        afterBreakfast && afterBreakfast.warning === 1 ? (
          <span
            className={afterBreakfast.status === -1 ? 'g_color_green' : afterBreakfast.status === -2 ? 'g_color_alert' : ''}
          >
            {afterBreakfast.status === -1
              ? '外出'
              : afterBreakfast.status === -2
                ? '拒测'
                : afterBreakfast && afterBreakfast.value}
          </span>
        ) : (
          <span
            className={
              afterBreakfast.status === -1 ? 'g_color_green' : afterBreakfast.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {afterBreakfast.status === -1
              ? '外出'
              : afterBreakfast.status === -2
                ? '拒测'
                : afterBreakfast && afterBreakfast.value}
          </span>
        )
    },
    {
      title: '午餐前',
      dataIndex: 'beforeLunch',
      align: 'center',
      render: (text, { beforeLunch }) =>
        beforeLunch && beforeLunch.warning === 1 ? (
          <span className={beforeLunch.status === -1 ? 'g_color_green' : beforeLunch.status === -2 ? 'g_color_alert' : ''}>
            {beforeLunch.status === -1 ? '外出' : beforeLunch.status === -2 ? '拒测' : beforeLunch && beforeLunch.value}
          </span>
        ) : (
          <span
            className={
              beforeLunch.status === -1 ? 'g_color_green' : beforeLunch.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {beforeLunch.status === -1 ? '外出' : beforeLunch.status === -2 ? '拒测' : beforeLunch && beforeLunch.value}
          </span>
        )
    },
    {
      title: '午餐后',
      dataIndex: 'afterLunch ',
      align: 'center',
      render: (text, { afterLunch }) =>
        afterLunch && afterLunch.warning === 1 ? (
          <span className={afterLunch.status === -1 ? 'g_color_green' : afterLunch.status === -2 ? 'g_color_alert' : ''}>
            {afterLunch.status === -1 ? '外出' : afterLunch.status === -2 ? '拒测' : afterLunch && afterLunch.value}
          </span>
        ) : (
          <span
            className={
              afterLunch.status === -1 ? 'g_color_green' : afterLunch.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {afterLunch.status === -1 ? '外出' : afterLunch.status === -2 ? '拒测' : afterLunch && afterLunch.value}
          </span>
        )
    },
    {
      title: '晚餐前',
      dataIndex: 'beforeDinner',
      align: 'center',
      render: (text, { beforeDinner }) =>
        beforeDinner && beforeDinner.warning === 1 ? (
          <span
            className={beforeDinner.status === -1 ? 'g_color_green' : beforeDinner.status === -2 ? 'g_color_alert' : ''}
          >
            {beforeDinner.status === -1
              ? '外出'
              : beforeDinner.status === -2
                ? '拒测'
                : beforeDinner && beforeDinner.value}
          </span>
        ) : (
          <span
            className={
              beforeDinner.status === -1 ? 'g_color_green' : beforeDinner.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {beforeDinner.status === -1
              ? '外出'
              : beforeDinner.status === -2
                ? '拒测'
                : beforeDinner && beforeDinner.value}
          </span>
        )
    },
    {
      title: '晚餐后',
      dataIndex: 'afterDinner',
      align: 'center',
      render: (text, { afterDinner }) =>
        afterDinner && afterDinner.warning === 1 ? (
          <span className={afterDinner.status === -1 ? 'g_color_green' : afterDinner.status === -2 ? 'g_color_alert' : ''}>
            {afterDinner.status === -1 ? '外出' : afterDinner.status === -2 ? '拒测' : afterDinner && afterDinner.value}
          </span>
        ) : (
          <span
            className={
              afterDinner.status === -1 ? 'g_color_green' : afterDinner.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {afterDinner.status === -1 ? '外出' : afterDinner.status === -2 ? '拒测' : afterDinner && afterDinner.value}
          </span>
        )
    },
    {
      title: '睡前',
      dataIndex: 'beforeSleep',
      align: 'center',
      render: (text, { beforeSleep }) =>
        beforeSleep && beforeSleep.warning === 1 ? (
          <span className={beforeSleep.status === -1 ? 'g_color_green' : beforeSleep.status === -2 ? 'g_color_alert' : ''}>
            {beforeSleep.status === -1 ? '外出' : beforeSleep.status === -2 ? '拒测' : beforeSleep && beforeSleep.value}
          </span>
        ) : (
          <span
            className={
              beforeSleep.status === -1 ? 'g_color_green' : beforeSleep.status === -2 ? 'g_color_alert' : 'g_color_red'
            }
          >
            {beforeSleep.status === -1 ? '外出' : beforeSleep.status === -2 ? '拒测' : beforeSleep && beforeSleep.value}
          </span>
        )
    },
    {
      title: '随机',
      dataIndex: 'random',
      align: 'center',
      render: (text, { random }) =>
        random && random.warning === 1 ? (
          <span className={random.status === -1 ? 'g_color_green' : random.status === -2 ? 'g_color_alert' : ''}>
            {random.status === -1 ? '外出' : random.status === -2 ? '拒测' : random && random.value}
          </span>
        ) : (
          <span className={random.status === -1 ? 'g_color_green' : random.status === -2 ? 'g_color_alert' : 'g_color_red'}>
            {random.status === -1 ? '外出' : random.status === -2 ? '拒测' : random && random.value}
          </span>
        )
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      width: 150,
      align: 'center',
      render: (_, { operator }) =>
        <span>{operator && operator.name}</span>
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
      align: 'center',
      render: (_, record) => (     
        memoryUtils.user.role.id >= 3 ? (
        <Button type='link' onClick={() => onShowUpdateAdvice('up', record)}>
          修改
        </Button>) : '无操作权限'
      )
    }
  ]

  /**
  * 点击显示血糖医嘱编辑弹框
  * @param {} value
  */
  const onShowUpdateAdvice = (type, data) => {
    setModal({ type, data, isShow: true })
  }

  /**
   * 根据患者id分页查询血糖透视表
   * @param {*} patientId
   */
  const getSugarPerspectiveById = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqSugarPerspectiveById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setSugarPerspective(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
 * api
 * 获取护士列表
 * @param {*} value 
 */
  const getNurseListByLoginUser = async () => {
    const { data, status } = await reqfindNurseListByLoginUser()
    if (status) {
      setNurseListByLoginUser(data)
    }
  }

    /**
   * 查询
   * 选择框：选择品牌
   */
     const onChangeBrand = (value) => {
      form.setFieldsValue({ userId: undefined })
      setOption(value)
    }

  /**
   * api
   * 编辑操作人
   */
  const onOkRemarks = async (value) => {
    const { status } = await reqUpdateBloodGlucoseOperator({
      userId: option,
      bloodGlucoseId:row.id
    })
    if (status) {
      message.success('备注信息提交成功！')
    }
    form.resetFields()
    setModal(false)
    getSugarPerspectiveById({
      patientId: patientRow.id,
      pageNo: commonPage.pageNo,
      pageSize: commonPage.pageSize
    })
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])

  useEffect(() => {
    getSugarPerspectiveById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize,flag])

  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        scroll={{ x: 800, y: 500 }}
        loading={isLoading}
        columns={initColumns}
        dataSource={sugarPerspective}
        pagination={{
          defaultPageSize: PAGE_SIZE,
          showQuickJumper: true,
          showSizeChanger: false,
          total,
          showTotal: (total) => `共${total} 条记录`,
          onChange: onChangePage
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRow(record)
            }
          }
        }}
      />
      <IModal visible={modal.isShow} titleText='编辑操作人' width={400}>
        <Form form={form} onFinish={onOkRemarks}>
        <span className='g_mb_10'>选择操作人：</span>
          <Item name='userId' lable='请选择操作人'>
            {memoryUtils.user.role.id >= 3 ? (
              <Select style={{ width: '100%' }} onChange={onChangeBrand}>
                {nurseListByLoginUser.length &&
                  nurseListByLoginUser.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            ) : '无操作'}
          </Item>
          <div className='g_txt_c'>
            <Button className='g_mr_20' key='back' onClick={() => setModal({ ...modal, isShow: false })}>
              取消
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              确定
            </Button>
          </div>
        </Form>
      </IModal>
    </>
  )
}

export default BSPerspectivePane

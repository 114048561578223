/*糖化血脂 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqSaccharifyByPatientId } from '../../api'
import { mergeRid } from '../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
BSFat.prototype = {
  patientRow: PropTypes.object,
  commonRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func.isRequired
}


/* 唯一的模块导出
---------------------------------------------------------------- */
function BSFat(props) {
  const {  patientRow, commonRow, commonPage, setCommonPage, setCommonRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总条数
  const [total, setTotal] = useState(0)

  //糖化血脂
  const [chronicDiseaseById, setChronicDiseaseById] = useState([])

  /**
   * 初始化生成 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '时间',
      dataIndex: 'detectionTime',
      width: 150,
      align: 'center',
      render: (_, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'HbA1c',
      dataIndex: 'hbA1c',
      width: '',
      align: 'center'
    },
    {
      title: 'TC',
      dataIndex: 'tc',
      width: '',
      align: 'center'
    },
    {
      title: 'TG',
      dataIndex: 'tg',
      width: '',
      align: 'center'
    },
    {
      title: 'HDL-C',
      dataIndex: 'hdl',
      width: '',
      align: 'center'
    },
    {
      title: 'LDL-C',
      dataIndex: 'ldl',
      width: '',
      align: 'center'
    }
  ]

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  /**
   * api
   * 通过患者id获取页面慢病信息
   * @param {*} id
   */
  const getSaccharifyByPatientId = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqSaccharifyByPatientId(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setChronicDiseaseById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getSaccharifyByPatientId({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        loading={isLoading}
        scroll={{ x: 800, y: 500 }}
        columns={initColumns}
        dataSource={chronicDiseaseById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setCommonRow(record)
            }
          }
        }}
      />
    </>
  )
}
export default BSFat

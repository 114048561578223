/* 基本信息 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Row, Col, Divider, Spin } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqQueryQcById } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { MONITOR } from '../../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
BasicPane.prototype = {
  newestQc: PropTypes.object,
  operation: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BasicPane(props) {
  const { newestQc, operation } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 质控信息
  const [qc, setQc] = useState({})

  const getQueryQcById = async (param) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqQueryQcById(param)
      if (status) {
        setQc(data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  // 解构质控信息
  const {
    level1Value,
    level2Value,
    level3Value,
    qualityControlInfo: {
      company: { name: company } = {},
      office: { name: office } = {},
      type,
      controlFloatMeanValueSetting = {},
      controlStaticMeanValueSetting = {},
      instrument: { brand: { name: instrumentBrand } = {}, modelVO: { name: instrumentModel } = {}, snnum } = {},
      controlProject: { name: controlProject } = {},
      metering: { name: metering } = {},
      reagent: {
        brand: { name: reagentBrand } = {},
        modelVO: { name: reagentModel } = {},
        batchNumber,
        methodology: { name: methodology } = {},
        expireDate
      } = {},
      controlThings: {
        levelNum,
        brand: { name: controlThingsBrand } = {},
        model: { name: controlThingsModel } = {},
        thingsBatchNumber,
        name: { name: controlThings } = {},
        stroma: { name: stroma } = {}
      } = {},
      level1,
      level2,
      level3
    } = {},
    level1ZValue,
    level1StaticMV,
    level1StaticSDCV,
    level1Status,
    level2ZValue,
    level2StaticMV,
    level2StaticSDCV,
    level2Status,
    level3ZValue,
    level3StaticMV,
    level3StaticSDCV,
    level3Status,
    createUser
    // level1StaticMV,
    // level1StaticSDCV,
    // level2StaticMV,
    // level2StaticSDCV,
    // level3StaticMV,
    // level3StaticSDCV,
    // level1FloatMV,
    // level1FloatSDCV,
    // level2FloatMV,
    // level2FloatSDCV,
    // level3FloatMV,
    // level3FloatSDCV
  } = qc

  //固定均值（水平1）
  let static1CV
  if (controlStaticMeanValueSetting.level1SDCV && controlStaticMeanValueSetting.level1MV !== null) {
    static1CV =
      parseFloat((controlStaticMeanValueSetting.level1SDCV / controlStaticMeanValueSetting.level1MV) * 100).toFixed(2) +
      '%'
  }
  // 浮动均值（水平1）
  let float1CV
  if (controlFloatMeanValueSetting.level1SDCV && controlFloatMeanValueSetting.level1MV !== null) {
    float1CV =
      parseFloat((controlFloatMeanValueSetting.level1SDCV / controlFloatMeanValueSetting.level1MV) * 100).toFixed(2) +
      '%'
  }

  //固定均值（水平2）
  let static2CV
  if (controlStaticMeanValueSetting.level2SDCV && controlStaticMeanValueSetting.level2MV !== null) {
    static2CV =
      parseFloat((controlStaticMeanValueSetting.level2SDCV / controlStaticMeanValueSetting.level2MV) * 100).toFixed(2) +
      '%'
  }
  // 浮动均值（水平2）
  let float2CV
  if (controlFloatMeanValueSetting.level2SDCV && controlFloatMeanValueSetting.level2MV !== null) {
    float2CV =
      parseFloat((controlFloatMeanValueSetting.level2SDCV / controlFloatMeanValueSetting.level2MV) * 100).toFixed(2) +
      '%'
  }

  //固定均值（水平3）
  let static3CV
  if (controlStaticMeanValueSetting.level3SDCV && controlStaticMeanValueSetting.level3MV !== null) {
    static3CV =
      parseFloat((controlStaticMeanValueSetting.level3SDCV / controlStaticMeanValueSetting.level3MV) * 100).toFixed(2) +
      '%'
  }
  // 浮动均值（水平3）
  let float3CV
  if (controlFloatMeanValueSetting.level3SDCV && controlFloatMeanValueSetting.level3MV !== null) {
    float3CV =
      parseFloat((controlFloatMeanValueSetting.level3SDCV / controlFloatMeanValueSetting.level3MV) * 100).toFixed(2) +
      '%'
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (operation && operation.action === 'viewQc') {
      getQueryQcById({ qualityControlItemId: operation.data.id })
    }
  }, [operation])

  useEffect(() => {
    if (newestQc && Object.keys(newestQc).length > 0) {
      getQueryQcById({ qualityControlItemId: newestQc.id })
    }
  }, [newestQc])

  /**
   * 渲染
   */
  return (
    <Spin spinning={isLoading}>
      <Row className='g_mb_10'>
        <Col className='g_pr_10' span={8}>
          医院：{company}
        </Col>
        <Col className='g_pr_10' span={8}>
          科室：{office}
        </Col>
        <Col span={8}>监测类型：{type && MONITOR[type - 1].name}</Col>
      </Row>
      {operation !== {} && operation.action === 'viewQc' &&
        <Divider plain></Divider>}
      <Row className='g_mb_10'>
        {operation !== {} && operation.action === 'viewQc' && <Col span={8}>
          操作人：{createUser.name}
        </Col>}
        {operation !== {} && operation.action === 'viewQc' &&
          <Col span={8}>
            工号：{createUser.no}
          </Col>}
      </Row>

      <Divider plain></Divider>
      <Row className='g_mb_10'>
        <Col>
          仪器：{instrumentBrand}&emsp;{instrumentModel}&emsp;{snnum}
        </Col>
      </Row>

      <Divider plain></Divider>
      <Row className='g_mb_10'>
        <Col className='g_pr_10' span={8}>
          项目：{controlProject}
        </Col>
        <Col span={8}>单位：{metering}</Col>
      </Row>

      <Divider plain></Divider>
      <Row className='g_mb_10'>
        <Col className='g_pr_10' span={8}>
          试剂：{reagentBrand}&emsp;{reagentModel}&emsp;{batchNumber}
        </Col>
        <Col className='g_pr_10' span={8}>
          方法学：{methodology}
        </Col>
        <Col span={8}>效期：{expireDate}</Col>
      </Row>

      <Divider plain></Divider>
      <Row className='g_mb_10'>
        <Col className='g_pr_10' span={8}>
          质控液：{controlThingsBrand}&emsp;{controlThingsModel}&emsp;{thingsBatchNumber}
        </Col>
        <Col className='g_pr_10' span={8}>
          质控液名称：{controlThings}
        </Col>
        <Col span={8}>基质：{stroma}</Col>
      </Row>
      {levelNum && levelNum >= 1 && (
        <Col className='g_mb_10'>
          <Row>
            <Col className='g_pr_10'>质控水平1</Col>
            {operation !== {} && operation.action === 'viewQc' && <Col className='g_pr_10'>质控值：{level1Value}</Col>}
            <Col className='g_pr_10'>Z值：{level1ZValue}</Col>
            <Col className='g_pr_10'>生效均值：{level1StaticMV}</Col>
            <Col className='g_pr_10'>生效SD值：{level1StaticSDCV}</Col>
            <Col className='g_pr_10'>是否在控：{level1Status === true ? '在控' : '失控'}</Col>
          </Row>
          <Row>
            <Col className='g_pr_10'>质控水平1</Col>
            <Col className='g_pr_10'>
              固定：[均值：{controlStaticMeanValueSetting.level1MV}，SD：{controlStaticMeanValueSetting.level1SDCV}
              ，CV：
              {static1CV}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{controlFloatMeanValueSetting.level1MV}，SD：{controlFloatMeanValueSetting.level1SDCV}，CV：
              {float1CV}]
            </Col>
            <Col className='g_pr_10'>次批号：{level1 && level1.batchNum}</Col>
            <Col>效期：{level1 && level1.expireDate}</Col>
          </Row>
        </Col>
      )}

      {levelNum && levelNum >= 2 && (
        <Col className='g_mb_10'>
          <Row>
            <Col className='g_pr_10'>质控水平2</Col>
            {operation !== {} && operation.action === 'viewQc' && <Col className='g_pr_10'>质控值：{level2Value}</Col>}
            <Col className='g_pr_10'>Z值：{level2ZValue}</Col>
            <Col className='g_pr_10'>生效均值：{level2StaticMV}</Col>
            <Col className='g_pr_10'>生效SD值：{level2StaticSDCV}</Col>
            <Col className='g_pr_10'>是否在控：{level2Status === true ? '在控' : '失控'}</Col>
          </Row>
          <Row>
            <Col className='g_pr_10'>质控水平2</Col>
            <Col className='g_pr_10'>
              固定：[均值：{controlStaticMeanValueSetting.level2MV}，SD：{controlStaticMeanValueSetting.level2SDCV}
              ，CV：
              {static2CV}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{controlFloatMeanValueSetting.level2MV}，SD：{controlFloatMeanValueSetting.level2SDCV}，CV：
              {float2CV}]
            </Col>
            <Col className='g_pr_10'>次批号：{level2 && level2.batchNum}</Col>
            <Col>效期：{level2 && level2.expireDate}</Col>
          </Row>
        </Col>
      )}

      {levelNum && levelNum >= 3 && (
        <Col className='g_mb_10'>
          <Row>
            <Col className='g_pr_10'>质控水平3</Col>
            {operation !== {} && operation.action === 'viewQc' && <Col className='g_pr_10'>质控值：{level3Value}</Col>}
            <Col className='g_pr_10'>Z值：{level3ZValue}</Col>
            <Col className='g_pr_10'>生效均值：{level3StaticMV}</Col>
            <Col className='g_pr_10'>生效SD值：{level3StaticSDCV}</Col>
            <Col className='g_pr_10'>是否在控：{level3Status === true ? '在控' : '失控'}</Col>
          </Row>
          <Row>
            <Col className='g_pr_10'>质控水平3</Col>
            <Col className='g_pr_10'>
              固定：[均值：{controlStaticMeanValueSetting.level3MV}，SD：{controlStaticMeanValueSetting.level3SDCV}
              ，CV：
              {static3CV}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{controlFloatMeanValueSetting.level3MV}，SD：{controlFloatMeanValueSetting.level3SDCV}，CV：
              {float3CV}]
            </Col>
            <Col className='g_pr_10'>次批号：{level3 && level3.batchNum}</Col>
            <Col>效期：{level3 && level3.expireDate}</Col>
          </Row>
        </Col>
      )}
    </Spin>
  )
}

export default BasicPane

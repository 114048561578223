/* 胰岛素录入 */

/* 
需求：
1. 提交表单的时候：‘录入失败：您未填写用药剂量’
*/

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Button, Row, Col, DatePicker, message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqMedicationItemList, reqFindInsulinList, reqAddInsulin } from '../../../api/index'

/* 静态资源
---------------------------------------------------------------- */
import { DRUG } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 静态类型检查
---------------------------------------------------------------- */
AddInsulinForm.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddInsulinForm(props) {
  const { flag, patientRow, onCancel, setModal, setFlag } = props

  // 提交表单
  const [form] = Form.useForm()

  // 查询胰岛素具体用药时间 （早餐前、餐后。。。）枚举列表
  const [medicationItemList, setMedicationItemList] = useState([])

  // 查询胰岛素类型枚举列表
  const [findInsulinList, setFindInsulinList] = useState([])

  /**
   * api
   * 查询胰岛素具体用药时间 （早餐前、餐后。。。）枚举列表
   */
  const getMedicationItemList = async () => {
    const { data, status } = await reqMedicationItemList()
    if (status) {
      setMedicationItemList(data)
    }
  }

  /**
   * api
   * 查询胰岛素类型枚举列表
   */
  const getFindInsulinList = async () => {
    const { data, status } = await reqFindInsulinList()
    if (status) {
      setFindInsulinList(data)
    }
  }

  /**
   * api
   * 按钮
   * 点击保存胰岛素录入
   */
  const onOkAddInsulinList = async (values) => {
    const { drug, item, insulinType, value, medicationTime } = values
    const params = {
      drug,
      value,
      insulinType,
      item,
      druginsulinType: findInsulinList.insulin,
      patientId: patientRow.id,
      medicationTime: moment(medicationTime[0]).format('YYYY-MM-DD HH:mm:ss')
    }
    const { status } = await reqAddInsulin(params)
    if (status) {
      message.success('录入成功！')
      form.resetFields()
      setModal(0)
      setFlag(!flag)
    }
  }

  useEffect(() => {
    getMedicationItemList()
    getFindInsulinList()
  }, [])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkAddInsulinList}>
      <Row>
        <Col className='g_mr_10'>
          <Item
            name='drug'
            label={<span className='text-right g_w_60'>药品</span>}
            rules={[{ required: true, message: '请输入仪器' }]}
          >
            <Select className='g_w_174'>
              {DRUG.length &&
                DRUG.map((v) => (
                  <Option key={v.id} value={v.name}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <Item
            name='insulinType'
            label={<span className='text-right g_w_71'>类型</span>}
            rules={[{ required: true, message: '请选择型号' }]}
          >
            <Select className='g_w_174'>
              {findInsulinList.length &&
                findInsulinList.map((v) => (
                  <Option key={v.insulin} value={v.insulin}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col className='g_mr_10'>
          <Item
            name='item'
            label={<span className='text-right g_w_60'>时间段</span>}
            rules={[{ required: true, message: '请选择试纸批号' }]}
          >
            <Select className='g_w_174'>
              {medicationItemList.length &&
                medicationItemList.map((v) => (
                  <Option key={v.insulinMedicationItem} value={v.insulinMedicationItem}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <Item
            name='value'
            label={<span className='text-right g_w_71'>数值</span>}
            rules={[
              {
                required: true,
                pattern: /^([0-9]{0,20})(\.\d{1,2})?$/,
                message: '请输入(0-20位，后可跟两位小数)数值'
              }
            ]}
          >
            <Input className='g_w_174 g_mr_10' />
          </Item>
        </Col>
        <Col>U</Col>
      </Row>
      <Row>
        <Col className='g_mr_10'>
          <Item
            name='medicationTime'
            label={<span className='text-right g_w_60'>用药时间</span>}
            rules={[{ required: true, message: '请选择试纸批号' }]}
          >
            <DatePicker className='g_w_174' />
          </Item>
        </Col>
      </Row>

      <div className='g_txt_c'>
        <Button className='g_mr_10' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default AddInsulinForm

/* 维护历史 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Tooltip, Button } from 'antd'
import { DropboxOutlined, PlusOutlined, RollbackOutlined, EditOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import MaintainForm from './MaintainForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqMaintains } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { MAINTAIN_CONTENT, PAGE_SIZE } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
Maintain.propTypes = {
  qc: PropTypes.object,
  goBack: PropTypes.func.isRequired
}

/* 唯一的模块调用
---------------------------------------------------------------- */
function Maintain(props) {
  const { qc, goBack } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 失控历史记录
  const [maintains, setMaintains] = useState([])

  // 弹框相关参数
  const [modal, setModal] = useState({
    action: undefined, // add：添加维护 | up：编辑维护
    isShow: false,
    data: {}
  })

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '维护时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center'
    },
    {
      title: '质控时间',
      dataIndex: 'qcTime',
      width: 150,
      align: 'center'
    },
    {
      title: '操作人',
      dataIndex: 'createBy',
      width: 100,
      align: 'center',
      render: (_, { createBy }) => createBy.name
    },
    {
      title: '维护内容',
      dataIndex: 'maintainType',
      ellipsis: true,
      align: 'center',
      render: (_, { maintainType }) => {
        const { name } = MAINTAIN_CONTENT.find((v) => v.enumId === maintainType) || ''
        return name
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 60,
      align: 'center',
      render: (_, record) => (
        <div className='g_action'>
          <Tooltip title='编辑维护' mouseEnterDelay={0.5}>
            <Button type='link' icon={<EditOutlined />} onClick={() => onShowModal('up', record)} />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取维护历史列表
   * @param {*} params
   */
  const getMaintains = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows },
        status
      } = await reqMaintains(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setMaintains(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 操作
   * 新增 / 编辑失控 / 查看报告
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMaintains({ qualityControlItemId: qc.data.id, pageNo: page.pageNo, pageSize: page.pageSize })
  }, [qc.data.id, page.pageNo, page.pageSize])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <DropboxOutlined className='g_mr_10' />
            <span>维护历史</span>
          </>
        }
        extra={
          <>
            <Button
              className='g_ml_10'
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => onShowModal('add', {})}
            >
              新增
            </Button>
            <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={() => goBack()}>
              返回
            </Button>
          </>
        }
      >
        <Table
          bordered
          size='small'
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={maintains}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
        />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '新增维护' : '编辑维护'}>
        <MaintainForm
          maintainContent={MAINTAIN_CONTENT}
          qc={qc}
          modal={modal}
          setModal={() => setModal({ ...modal, isShow: false })}
          refreshMaintains={() =>
            getMaintains({ qualityControlItemId: qc.data.id, pageNo: page.pageNo, pageSize: page.pageSize })
          }
        />
      </IModal>
    </>
  )
}

export default Maintain

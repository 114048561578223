/* 申请原因 */


/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Descriptions,Input } from 'antd'
import PropTypes from 'prop-types'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Descriptions
const { TextArea } = Input

/* 使用类型检查
---------------------------------------------------------------- */
Reason.propTypes = {
  consultation: PropTypes.object.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function Reason(props) {

const {consultation} = props

  return (
    <Descriptions className='g_mb_10' bordered size='small'>
      <Item className='width-98' contentStyle={{textAlign: 'left'}} label='申请原因' name='pursueReason'>
      <TextArea rows={4} showCount maxLength={100} value={consultation.pursueReason} />
      </Item>
    </Descriptions>
  )
}

export default Reason

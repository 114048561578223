/* 字典数据 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, message, Table, Tooltip, Modal } from 'antd'
import {
  DropboxOutlined,
  RollbackOutlined,
  StopOutlined,
  PauseCircleOutlined,
  EditOutlined,
  PlusOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import TagForm from './DicForm'

/* 功能模块
---------------------------------------------------------------- */
import {
  reqBrands,
  reqMeters,
  reqStromas,
  reqMethodologys,
  reqProjects,
  reqQualityControls,
  reqAddOrEditMeter,
  reqAddOrEditStroma,
  reqAddOrEditMethodology,
  reqAddOrEditBrand,
  reqAddOrEditModel,
  reqAddOrEditProject,
  reqAddOrEditQC
} from '../../../api'
import { mergeTreeByKey, filterArr } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { DICTIONARY_STATUS, MONITOR } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 使用类型检查
---------------------------------------------------------------- */
DataTag.propTypes = {
  setDictionary: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function DataTag(props) {
  const { dictionary, setDictionary } = props

  const formRef = useRef()

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 添加 / 编辑表单的相关参数
  const [modal, setModal] = useState({ action: undefined, isShow: false, data: {} })

  // 列表
  const [list, setList] = useState([])

  /**
   * 初始化 Table 列表所有字段
   */
  const initColumns = [
    {
      title: '字典标签',
      dataIndex: 'name',
      width: '',
      align: 'left'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 80,
      align: 'center',
      render: (_, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{DICTIONARY_STATUS[1].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{DICTIONARY_STATUS[0].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (_, record) => {
        switch (dictionary.id) {
          case 4:
            return (
              <div className='g_action'>
                <Tooltip title='编辑字典数据' mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={<EditOutlined />}
                    onClick={() => onShowModal('up', record)}
                    disabled={record.status ? false : true}
                  />
                </Tooltip>
                <Tooltip title={record.status ? '停用字典数据' : '启用字典数据'} mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
                    onClick={() => onShowOffOrOn(dictionary, record)}
                    disabled={'children' in record ? true : false}
                  />
                </Tooltip>
              </div>
            )
          case 5:
            return (
              <div className='g_action'>
                <Tooltip title='编辑字典数据' mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={<EditOutlined />}
                    onClick={() => onShowModal('up', record)}
                    disabled={record.level === 1 ? true : record.status ? false : true}
                  />
                </Tooltip>
                <Tooltip title={record.status ? '停用字典数据' : '启用字典数据'} mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
                    onClick={() => onShowOffOrOn(dictionary, record)}
                    disabled={'children' in record ? true : false}
                  />
                </Tooltip>
              </div>
            )
          default:
            return (
              <div className='g_action'>
                <Tooltip title='编辑字典数据' mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={<EditOutlined />}
                    onClick={() => onShowModal('up', record)}
                    disabled={record.status ? false : true}
                  />
                </Tooltip>
                <Tooltip title={record.status ? '停用字典数据' : '启用字典数据'} mouseEnterDelay={0.5}>
                  <Button
                    type='link'
                    icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
                    onClick={() => onShowOffOrOn(dictionary, record)}
                  />
                </Tooltip>
              </div>
            )
        }
      }
    }
  ]

  /**
   * api
   * 根据 typeId 获取对应的列表
   * @param {*} typeId
   */
  const getList = async (typeId) => {
    setIsLoading(true)
    try {
      let res
      switch (typeId) {
        case 1:
          res = await reqMeters()
          break
        case 2:
          res = await reqStromas()
          break
        case 3:
          res = await reqMethodologys()
          break
        case 4:
          res = await reqBrands()
          break
        case 5:
          res = await reqProjects()
          if (res.status) {
            res = filterArr(MONITOR, res.data)
            setList(mergeTreeByKey(res))
          }
          return
        default:
          res = await reqQualityControls()
          break
      }
      if (res.status) {
        res = res.data
        setList(mergeTreeByKey(res))
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 弹框
   * 点击显示新增 / 编辑字典
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
    // console.log(data)
    if (action === 'add') {
      if (formRef.current) formRef.current.resetFields()
    }
  }

  /**
   * 弹框
   * 确定新增 / 编辑字典
   * @param {*} value
   */
  const onOkModal = async (value) => {
    // console.log(value)
    const { action, data } = modal
    let params = value
    let msg = '新增'
    if (action === 'up') {
      params = { ...value, id: data.id }
      msg = '编辑'
    }
    let res
    switch (dictionary.id) {
      case 1:
        res = await reqAddOrEditMeter(params)
        // console.log(res)
        break
      case 2:
        res = await reqAddOrEditStroma(params)
        break
      case 3:
        res = await reqAddOrEditMethodology(params)
        break
      case 4:
        if (!value.dicType) {
          res = await reqAddOrEditBrand(params)
          // console.log(123)
        } else {
          res = await reqAddOrEditModel(params)
        }
        break
      case 5:
        // console.log('5', params)
        if ('name' in value) {
          res = await reqAddOrEditProject(params)
        } else {
          res = { status: 1 }
        }
        break
      default:
        res = await reqAddOrEditQC(params)
        break
    }
    if (res.status) {
      setModal({ ...modal, isShow: false })
      if ('name' in value) {
        message.success(`${msg}${dictionary.name}成功！`)
        formRef.current.resetFields()
        getList(dictionary.id)
      }
    }
  }

  /**
   * 列表行操作
   * 按钮：点击停用 / 启用状态
   * @param {*} record
   */
  const onShowOffOrOn = (dictionary, record) => {
    const { id, status } = record
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该字典数据吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该字典数据吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        let res
        const params = { id, status: status ? 0 : 1 }
        switch (dictionary.id) {
          case 1:
            res = await reqAddOrEditMeter(params)
            break
          case 2:
            res = await reqAddOrEditStroma(params)
            break
          case 3:
            res = await reqAddOrEditMethodology(params)
            break
          case 4:
            res = await reqAddOrEditModel(params)
            break
          case 5:
            res = await reqAddOrEditProject(params)
            break
          default:
            res = await reqAddOrEditQC(params)
            break
        }
        if (res.status) {
          if (status) {
            message.success(`停用${dictionary.name}成功！`)
          } else {
            message.success(`启用${dictionary.name}成功！`)
          }
          getList(dictionary.id)
        }
      }
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getList(dictionary.id)
  }, [dictionary.id])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <DropboxOutlined className='g_mr_10' />
            <span className='g_mr_10'>字典数据</span>
            <span>( {dictionary.name} )</span>
          </>
        }
        extra={
          <>
            <Button
              className='g_ml_10'
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => onShowModal('add', {})}
              disabled={dictionary.id === 7 ? true : false}
            >
              新增
            </Button>
            <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={() => setDictionary({ isShow: false })}>
              返回
            </Button>
          </>
        }
      >
        <Table
          rowKey='key'
          bordered
          size='small'
          loading={isLoading}
          columns={initColumns}
          dataSource={list}
          pagination={false}
          scroll={{ y: 450 }}
        />
      </Card>

      <IModal
        visible={modal.isShow}
        titleText={modal.action === 'add' ? `新增字典 ( ${dictionary.name} )` : `编辑字典 ( ${dictionary.name} )`}
      >
        <TagForm
          formRef={formRef}
          dictionary={dictionary}
          modal={modal}
          list={list}
          onOk={onOkModal}
          onCancel={() => {
            setModal({ ...modal, isShow: false })
          }}
        />
      </IModal>
    </>
  )
}

export default DataTag

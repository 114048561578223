/* 全院患者变化趋势 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 使用类型检查
---------------------------------------------------------------- */
PatientChangeBar.prototype = {
  data: PropTypes.array
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientChangeBar(props) {
  const { data } = props

  const [myChart, setMyChart] = useState('')

  const getChart = (myChart, data) => {
    if (myChart !== '') {
      const option = {
        dataset: {
          source: data
        },
        grid: {
          top: 10,
          right: '5%',
          bottom: 30,
          left: '5%'
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let result = moment(params[0].name).format('YYYY-MM-DD') + '<br/>'
            result += params[0].marker
            result +=
              '<span style="float: right; font-weight: bold; margin-left: 20px">' + params[0].value.num + '</span></br>'
              return result
          }
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#0E8AA4',
              width: 2
            }
          },
          axisLabel: {
            fontSize: 14,
            formatter: (value, _) => {
              // console.log(value);
              return moment(value).format('YYYY-MM-DD')
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#0E8AA4',
              width: 2
            }
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#06B5D7' }, //柱图渐变色
                { offset: 0.5, color: '#44C0C1' }, //柱图渐变色
                { offset: 1, color: '#71C8B1' } //柱图渐变色
              ])
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#71C8B1' }, //柱图高亮渐变色
                { offset: 0.7, color: '#44C0C1' }, //柱图高亮渐变色
                { offset: 1, color: '#06B5D7' } //柱图高亮渐变色
              ])
            },
            encode: { x: 'today', y: 'num' }
          }
        ]
      }
      // setInterval(() => {
      //   option && myChart.setOption(option)
      // }, 5000)
      option && myChart.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const node = document.getElementById('patientChange')
    const myChart = echarts.init(node)
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, data)
  }, [myChart, data])

  /**
   * 渲染
   */
  return <div id='patientChange' className='b1_chart5'></div>
}

export default PatientChangeBar

/* 录入 / 编辑试剂 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Button, DatePicker, message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditReagent, reqMethodologys } from '../../../api'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
ReagentForm.propTypes = {
  monitor: PropTypes.array,
  brands: PropTypes.array,
  modal: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  refreshReagents: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function ReagentForm(props) {
  const [form] = Form.useForm()

  const { monitor, brands, modal, setModal, refreshReagents, setForm } = props

  // 品牌 id
  const [brandId, setBrandId] = useState(undefined)

  // 方法学列表
  const [methodologys, setMethodologys] = useState([])

  /**
   * api
   * 获取方法学列表
   */
  const getMethodologys = async () => {
    const { data, status } = await reqMethodologys()
    if (status) {
      setMethodologys(data)
    }
  }

  /**
   * 新增 / 编辑试剂
   * 选择框：选择品牌
   * @param {*} value
   */
  const onChangeBrand = (value) => {
    form.setFieldsValue({ modelID: undefined })
    setBrandId(value)
  }

  /**
   * 通过品牌 id 显示对应的型号列表
   * @param {*} brandId
   */
  const showModels = (brandId) => {
    const result = brands.filter((v) => v.id === brandId)
    if ('children' in result[0]) {
      return result[0].children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 新增 / 编辑试剂
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkReagent = async (value) => {
    const { expireDate } = value
    // console.log(value)
    let msg // 提示信息
    let params // 表单参数
    if (modal.action === 'up') {
      msg = '编辑试剂成功！'
      params = {
        ...value,
        expireDate: moment(expireDate).format('YYYY-MM-DD HH:mm:ss'),
        id: modal.data.id
      }
    } else {
      msg = '新增试剂成功！'
      params = {
        ...value,
        expireDate: moment(expireDate).format('YYYY-MM-DD HH:mm:ss')
      }
    }
    const { status } = await reqAddOrEditReagent(params)
    if (status) {
      setModal()
      message.success(msg)
      refreshReagents()
      form.resetFields()
      setBrandId(undefined)
    }
  }

  /**
   * 新增 / 编辑试剂
   * 按钮：点击取消
   */
  const onCancel = () => {
    setModal()
    setBrandId(undefined)
  }

  /**
   * 不能选今天和今天之前的日期
   * @param {*} current 
   * @returns 
   */
  const disabledDate = (current) => {
    return current && current < moment();
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMethodologys()
  }, [])

  useEffect(() => {
    setForm(form)
  }, [setForm, form])

  useEffect(() => {
    if (modal.action === 'up') {
      form.setFieldsValue({ ...modal.data, expireDate: moment(modal.data.expireDate) })
    }
  }, [form, modal.action, modal.data])

  /**
   * 渲染
   */

  return (
    <Form labelCol={{ span: 4 }} labelAlign='right' form={form} onFinish={onOkReagent}>
      <Item
        name='type'
        label={<span className='g_txt_r g_w_56'>监测类型</span>}
        rules={[{ required: true, message: '请选择监测类型' }]}
      >
        <Select>
          {monitor.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='brandID'
        label={<span className='g_txt_r g_w_56'>品牌</span>}
        rules={[{ required: true, message: '请选择品牌' }]}
      >
        <Select onChange={onChangeBrand}>
          {brands.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='modelID'
        label={<span className='g_txt_r g_w_56'>型号</span>}
        rules={[{ required: true, message: '请选择型号' }]}
      >
        <Select>{brandId ? showModels(brandId) : modal.action === 'up' && showModels(modal.data.brandID)}</Select>
      </Item>
      <Item
        name='batchNumber'
        label={<span className='g_txt_r g_w_56'>批号</span>}
        rules={[{ required: true,pattern:/^[a-zA-Z\d]{1,20}$/, message: '请输入(包含英文大小写，数字 1-20位)批号' }]}
      >
        <Input />
      </Item>
      <Item
        name='code'
        label={<span className='g_txt_r g_w_56'>代号</span>}
        rules={[{ required: true,pattern:/^[a-zA-Z\d]{1,20}$/, message: '请输入(包含英文大小写，数字 1-20位)代号' }]}
      >
        <Input />
      </Item>
      <Item
        name='methodologyID'
        label={<span className='g_txt_r g_w_56'>方法学</span>}
        rules={[{ required: true, message: '请输入方法学' }]}
      >
        <Select>
          {methodologys.length &&
            methodologys.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
        </Select>
      </Item>
      <Item
        name='expireDate'
        label={<span className='g_txt_r g_w_56'>效期</span>}
        rules={[{ required: true, message: '请选择效期' }]}
      >
        <DatePicker style={{ width: '100%' }} disabledDate={disabledDate}/>
      </Item>
      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default ReagentForm

/* 新建患者 */

import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button, Input, Select, DatePicker, Divider, message } from 'antd'
import { UndoOutlined, CheckOutlined, UserAddOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import {
  reqGebders,
  reqNations,
  reqEducations,
  reqUserByRoles,
  reqAddPatient,
  reqMaritalStatus,
  reqInstitutionTrees,
  reqDoctorList,
  reqNurseList
} from '../../../api'
import memoryUtils from '../../../utils/memoryUtils'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Option } = Select
const { Item } = Form

/* 唯一的模块导出
---------------------------------------------------------------- */
function Create() {
  const { company: { id: companyId } = {}, office: { id: officeId } = {} } = memoryUtils.user
  // 民族列表
  const [nations, setNations] = useState([])

  // 性别列表
  const [genders, setGenders] = useState([])

  // 文化程度列表
  const [educations, setEducations] = useState([])

  // 婚姻状况列表
  const [maritallists, setMaritallists] = useState([])

  // 角色列表
  const [roles, setRoles] = useState([])

  // 机构列表(医院-科室-病区)
  const [institutions, setInstitutions] = useState([])

  // 水平弹框
  const [level, setLevel] = useState(2)

  // 体重
  const [weight, setWeight] = useState(0)

  // 身高
  const [stature, setStature] = useState(0)

  //医生列表
  const [doctorList, setDoctorList] = useState([])

  //护士列表
  const [nurseList, setNurseList] = useState([])

  // 提交表单
  const [form] = Form.useForm()


  /**
   * api
   * 获取性别列表
   */
  const getGebders = async () => {
    const { data = {}, status } = await reqGebders()
    if (status) {
      setGenders(data)
    }
  }

  /**
   * api
   * 获取民族列表
   */
  const getNations = async () => {
    const { data, status } = await reqNations()
    if (status) {
      setNations(data)
    }
  }

  /**
   * api
   * 获取文化程度列表
   */
  const getEducations = async () => {
    const { data, status } = await reqEducations()
    if (status) {
      setEducations(data)
    }
  }

  /**
   * api
   * 获取角色列表
   */
  const getUserByRoles = async () => {
    const { data, status } = await reqUserByRoles()
    if (status) {
      setRoles(data)
    }
  }

  /**
   * api
   * 获取婚姻状态列表
   */
  const getMaritalStatus = async () => {
    const { data, status } = await reqMaritalStatus()
    if (status) {
      setMaritallists(data)
    }
  }

  /**
   * api
   * 获取医院树结构列表（医院-科室-病区）
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutionTrees()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * api
   * 根据医院和科室查询医生列表
   */
  const getDoctorList = async (params) => {
    const { data, status } = await reqDoctorList(params)
    if (status) {
      setDoctorList(data)
    }
  }

  /**
   * api
   * 根据医院和科室查询护士列表
   */
  const getNurseList = async (params) => {
    const { data, status } = await reqNurseList(params)
    if (status) {
      setNurseList(data)
    }
  }

  /**
   * api
   * 新建患者
   * @param {*} value
   */
  const onOkAdd = async (value) => {
    const {
      bedCards,
      birth,
      bmi,
      doctorId,
      education,
      email,
      gender,
      homeAddress,
      hospitalizationCards,
      identityCard,
      inpatientWardId,
      maritalStatus,
      medicalCards,
      name,
      nation,
      nationPlace,
      patientNo,
      phone,
      primaryNurseId,
      stature,
      weight
    } = value
    const params = {
      bedCards,
      birth: moment(birth).format('YYYY-MM-DD HH:mm:ss'),
      bmi: bmi ? bmi : null,
      companyId,
      doctorId,
      education: education ? education : null,
      email: email ? email : null,
      gender,
      homeAddress: homeAddress ? homeAddress : null,
      hospitalizationCards,
      identityCard: identityCard ? identityCard : null,
      inpatientWardId,
      maritalStatus: maritalStatus ? maritalStatus : null,
      medicalCards,
      name,
      nation: nation ? nation : null,
      nationPlace: nationPlace ? nationPlace : null,
      officeId,
      patientNo,
      phone: phone ? phone : null,
      primaryNurseId,
      stature: stature ? stature : null,
      weight: weight ? weight : null
    }
    const { status } = await reqAddPatient(params)
    if (status) {
      message.success('新建患者成功！')
      form.resetFields()
    }
  }

  /**
   *获取身高输入框的值
   * @param {*} e
   */
  const onChangeStature = (e) => {
    setStature(e.target.value)
  }

  /**
   *获取体重输入框的值
   * @param {*} e
   */
  const onChangeWeight = (e) => {
    setWeight(e.target.value)
  }
  /**
   * 失去焦点时，进行BMI计算
   */
  const onBlurWeight = () => {
    // const { stature, weight } = this.state
    if (stature && weight) {
      let result = weight / [(stature / 100) * (stature / 100)]
      let results = result.toFixed(2)
      form.setFieldsValue({ bmi: results })
    }
  }

  /**
   * 选择框
   * 水平变化
   * @param {*} value
   */
  const onChangeLevel = (value) => {
    setLevel(value)
  }

  /**
   * 按钮
   * 重置条件查询
   */
  const onReset = () => {
    form.resetFields()
    // initUser()
  }

  /**
   * 当前日期之后的时间不可选 && 1900年之前日期不可选
   * @param {*} current
   * @returns
   */
  const disabledDate = (current) => {
    if (current) {
      return current < moment('1900-01-01') || current > moment().endOf('day')
    }
  }

  /**
   * 通过选择器选择出现相对应的输入框
   * @param {*} level
   */
  const showLevel = (level) => {
    if (level === 1) {
      return (
        <Col span={8}>
          <Item
            name='patientNo'
            label={<span className='g_txt_r g_w_56'>门诊号</span>}
            rules={[{ required: true, pattern: /^[0-9]{1,20}$/, message: '请输入(1-20位数字)的门诊号' }]}
          >
            <Input />
          </Item>
        </Col>
      )
    }
    if (level === 2) {
      return (
        <>
          <Col span={8}>
            <Item
              name='hospitalizationCards'
              label={<span className='g_txt_r g_w_56'>住院号</span>}
              rules={[{ required: true,pattern: /^[0-9]{1,20}$/, message: '请输入(1-20位数字)的住院号' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name='bedCards'
              label={<span className='g_txt_r g_w_67'>床位号</span>}
              rules={[{ required: true,pattern: /^[0-9]{1,20}$/, message: '请输入(1-20位数字)的床位号' }]}
            >
              <Input />
            </Item>
          </Col>
        </>
      )
    }
  }

  /**
   * 显示病区
   * @param {*} institutions
   * @returns
   */
  const showWards = (institutions) => {
    const { children: wards } = institutions[0]['children'][0]
    if (wards && wards.length > 0) {
      return wards.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 生命周期
   */

  useEffect(() => {
    getInstitutions()
    getGebders()
    getNations()
    getEducations()
    getUserByRoles()
    getMaritalStatus()
    return () => {
      setInstitutions((_) => {
        return
      })
      setGenders((_) => {
        return
      })
      setNations((_) => {
        return
      })
      setEducations((_) => {
        return
      })
      setRoles((_) => {
        return
      })
      setMaritallists((_) => {
        return
      })
    }
  }, [])

  useEffect(() => {
    const initUser = () => {
      const { role, id } = memoryUtils.user
      if (role.id === 4) {
        // 医师
        form.setFieldsValue({ doctorId: id })
      }
      // 护士
      if (role.id === 5) {
        form.setFieldsValue({ primaryNurseId: id })
      }
    }
    initUser()
    return () => initUser
  }, [form])

  useEffect(() => {
    getDoctorList({ companyId: companyId, officeId: officeId })
    getNurseList({ companyId: companyId, officeId: officeId })
  }, [companyId, officeId])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserAddOutlined className='g_mr_10' />
          新建患者
        </h3>
      }
    >
      <Form form={form} onFinish={onOkAdd}>
        <Divider orientation='left' plain>
          基本信息
        </Divider>
        <Row gutter={10}>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Item
                  name='name'
                  label={<span className='g_txt_r g_w_56'>姓名</span>}
                  rules={[
                    { required: true, pattern: /^[A-Za-z0-9\u4e00-\u9fa5.·]{1,20}$/, message: '请输入格式正确的姓名' }
                  ]}
                >
                  <Input className='width-120' />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Item
                  name='birth'
                  label={<span className='g_txt_r g_w_56'>出生年月</span>}
                  rules={[{ required: true, message: '请选择姓名' }]}
                >
                  <DatePicker className='width-120' disabledDate={disabledDate} />
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Item
                  name='gender'
                  label={<span className='g_txt_r g_w_56'>性别</span>}
                  rules={[{ required: true, message: '请选择性别' }]}
                >
                  <Select style={{ width: 120 }}>
                    {Object.keys(genders) &&
                      genders.map((v) => (
                        <Option key={v.gender} value={v.gender}>
                          {v.name}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Item name='education' label={<span className='g_txt_r g_w_67'>文化程度</span>}>
                  <Select style={{ width: 120 }}>
                    {Object.keys(educations).length &&
                      educations.map((v) => (
                        <Option key={v.education} value={v.education}>
                          {v.name}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Item name='age' label={<span className='g_txt_r g_w_67'>年龄</span>}>
                  <Input className='width-120' disabled />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Item
                  name='stature'
                  label={<span className='g_txt_r g_w_67'>身高</span>}
                  rules={[
                    {
                      pattern: /^([0-9]{1,6})(\.\d{1,2})?$/,
                      message: '请输入1-6位(可包含小数点后两位)的数字'
                    }
                  ]}
                >
                  <Input className='width-120' placeholder='cm' onChange={onChangeStature} onBlur={onBlurWeight} />
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Item name='nation' label={<span className='g_txt_r g_w_67'>民族</span>}>
                  <Select style={{ width: 120 }}>
                    {Object.keys(nations).length &&
                      nations.map((v) => (
                        <Option key={v.nation} value={v.nation}>
                          {v.name}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Item
                  name='weight'
                  label={<span className='g_txt_r g_w_67'>体重</span>}
                  rules={[
                    {
                      pattern: /^([0-9]{1,6})(\.\d{1,2})?$/,
                      message: '请输入1-6位(可包含小数点后两位)的数字'
                    }
                  ]}
                >
                  <Input className='width-120' placeholder='kg' onChange={onChangeWeight} onBlur={onBlurWeight} />
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row>
              <Col flex={1}>
                <Item
                  name='nationPlace'
                  label={<span className='g_txt_r g_w_67'>籍贯</span>}
                  rules={[{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5.·]{1,20}$/, message: '请输入格式正确的籍贯' }]}
                >
                  <Input className='width-120' />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <Item name='bmi' label={<span className='g_txt_r g_w_67'>BMI</span>}>
                  <Input className='width-120' disabled={stature && weight ? true : false} />
                </Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={50}>
          <Col flex={1}>
            <Item name='maritalStatus' label={<span className='g_txt_r g_w_67'>婚姻状况</span>}>
              <Select style={{ width: '100%' }}>
                {Object.keys(maritallists).length &&
                  maritallists.map((v) => (
                    <Option key={v.maritalStatus} value={v.maritalStatus}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col flex={1}>
            <Item
              name='phone'
              label={<span className='g_txt_r g_w_67'>联系电话</span>}
              rules={[{ pattern: /^1\d{10}$/, message: '请输入以1开头的11位数字的手机号码' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col flex={1}>
            <Item name='identityCard' label={<span className='g_txt_r g_w_67'>身份证号</span>}>
              <Input />
            </Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col flex={1}>
            <Item
              name='email'
              rules={[{ pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: '请输入正确格式的电子邮箱' }]}
              label={
                <span
                  className='g_txt_r g_w_67'
                >
                  电子邮箱
                </span>
              }
            >
              <Input />
            </Item>
          </Col>
          <Col flex={1}>
            <Item name='homeAddress' label={<span className='g_txt_r g_w_67'>家庭住址</span>}>
              <Input />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          详细信息
        </Divider>

        <Col>
          <Row gutter={60}>
            <Col flex={1} span={8}>
              <Item
                label={<span className='g_txt_r g_w_67'>所在医院</span>}
                rules={[{ required: true, message: '请输入所在医院' }]}
              >
                <Input readOnly value={memoryUtils.user.company.name} />
              </Item>
            </Col>
            <Col flex={1} span={8}>
              <Item
                label={<span className='g_txt_r g_w_67'>所在科室</span>}
                rules={[{ required: true, message: '请输入所在科室' }]}
              >
                <Input readOnly value={memoryUtils.user.office.name} />
              </Item>
            </Col>
            <Col flex={1} span={8}>
              <Item
                name='inpatientWardId'
                label={<span className='g_txt_r g_w_67'>所在病区</span>}
              >
                <Select style={{ width: '100%' }}>{institutions.length > 0 ? showWards(institutions) : null}</Select>
              </Item>
            </Col>
          </Row>
          <Col flex={1}>
            <Row gutter={60}>
              <Col flex={1} span={8}>
                <Item
                  name='doctorId'
                  label={<span className='g_txt_r g_w_67'>主治医师</span>}
                  rules={[{ required: true, message: '请输入主治医师' }]}
                >
                  <Select style={{ width: '100%' }}>
                    {memoryUtils.user.role.id === 4 ? (
                      <Option value={memoryUtils.user.id}>{memoryUtils.user.name}</Option>
                    ) : memoryUtils.user.role.id !== 4 ? (
                      doctorList.length &&
                      doctorList.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))
                    ) : (
                      roles.length &&
                      roles.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))
                    )}
                  </Select>
                </Item>
              </Col>
              <Col flex={1} span={8}>
                <Item
                  name='primaryNurseId'
                  label={<span className='g_txt_r g_w_67'>责任护士</span>}
                  rules={[{ required: true, message: '请输入责任护士' }]}
                >
                  <Select style={{ width: '100%' }}>
                    {memoryUtils.user.role.id === 5 ? (
                      <Option value={memoryUtils.user.id}>{memoryUtils.user.name}</Option>
                    ) : memoryUtils.user.role.id !== 5 ? (
                      nurseList.length &&
                      nurseList.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))
                    ) : (
                      roles.length &&
                      roles.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))
                    )}
                  </Select>
                </Item>
              </Col>
              <Col flex={1} span={8}>
                <Item
                  name='medicalCards'
                  label={
                    <span
                      className='g_txt_r g_w_80'
                      rules={[{ pattern: /^[0-9]{1,20}$/, message: '请输入(1-20位数字)的医保卡号' }]}
                    >
                      医保卡号
                    </span>
                  }
                >
                  <Input />
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row gutter={60}>
              <Col flex={1} span={8}>
                <Item
                  label={<span className='g_txt_r g_w_56 sign'>患者类别</span>}
                  rules={[{ required: true, message: '请输入' }]}
                >
                  <Select style={{ width: '100%' }} onChange={onChangeLevel} defaultValue='住院'>
                    <Option value={1}>门诊</Option>
                    <Option value={2}>住院</Option>
                  </Select>
                </Item>
              </Col>
              {showLevel(level)}
            </Row>
          </Col>
        </Col>
        <div className='g_txt_c g_mb_10'>
          <Button className='g_mr_20' key='back' size='middle' icon={<UndoOutlined />} onClick={onReset}>
            重置
          </Button>
          <Button key='submit' type='primary' htmlType='submit' size='middle' icon={<CheckOutlined />}>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default Create

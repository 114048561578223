/* 血糖趋势图 */

// ==================
// 调用模块
// ==================
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import moment from 'moment'

// ==================
// 功能模块
// ==================
import { reqSugarTrendById } from '../../../api/index'

// ==================
// 唯一的模块调用
// ==================
function BSTrend(props) {
  const { patientRow, condition } = props

  const [chartDom, setChartDom] = useState('')

  //血糖趋势图
  const [sugarTrend, setSugarTrend] = useState([])

  /**
   * 血糖趋势图
   * @param {*} params
   */
  const getSugarTrendById = async (params) => {
    const { data, status } = await reqSugarTrendById(params)
    if (status) {
      setSugarTrend(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, sugarTrend) => {
    if (chartDom !== '' && sugarTrend.length > 0) {
      //遍历获取数值(数组形式)
      var time = []
      var weeHours = []
      var beforeBreakfast = []
      var afterBreakfast = []
      var beforeLunch = []
      var afterLunch = []
      var beforeDinner = []
      var afterDinner = []
      var beforeSleep = []
      var random = []
      for (const i in sugarTrend) {
        time.push(moment(sugarTrend[i].detectionTime).format('YYYY-MM-DD'))
        weeHours.push(sugarTrend[i].weeHours)
        beforeBreakfast.push(sugarTrend[i].beforeBreakfast)
        afterBreakfast.push(sugarTrend[i].afterBreakfast)
        beforeLunch.push(sugarTrend[i].beforeLunch)
        afterLunch.push(sugarTrend[i].afterLunch)
        beforeDinner.push(sugarTrend[i].beforeDinner)
        afterDinner.push(sugarTrend[i].afterDinner)
        beforeSleep.push(sugarTrend[i].beforeSleep)
        random.push(sugarTrend[i].random)
      }
      const weeHours1 = weeHours.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const beforeBreakfast1 = beforeBreakfast.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const afterBreakfast1 = afterBreakfast.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const beforeLunch1 = beforeLunch.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const afterLunch1 = afterLunch.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const beforeDinner1 = beforeDinner.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const afterDinner1 = afterDinner.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const beforeSleep1 = beforeSleep.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })
      const random1 = random.map((element) => {
        if (element) {
          return element.value || null
        } else {
          return null
        }
      })

      const option = {
        title: {
          text: '血糖趋势图',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 30,
          data: ['凌晨', '早餐前', '早餐后', '午餐前', '午餐后', '晚餐前', '晚餐后', '睡前', '随机']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: time
        },
        yAxis: [
          {
            name: 'mmol/L',
            type: 'value',
            position: 'left'
          }
        ],
        series: [
          {
            name: '凌晨',
            type: 'line',
            data: weeHours1,
            connectNulls: true
          },
          {
            name: '早餐前',
            type: 'line',
            data: beforeBreakfast1,
            connectNulls: true
          },
          {
            name: '早餐后',
            type: 'line',
            data: afterBreakfast1,
            connectNulls: true
          },
          {
            name: '午餐前',
            type: 'line',
            data: beforeLunch1,
            connectNulls: true
          },
          {
            name: '午餐后',
            type: 'line',
            data: afterLunch1,
            connectNulls: true
          },
          {
            name: '晚餐前',
            type: 'line',
            data: beforeDinner1,
            connectNulls: true
          },
          {
            name: '晚餐后',
            type: 'line',
            data: afterDinner1,
            connectNulls: true
          },
          {
            name: '睡前',
            type: 'line',
            data: beforeSleep1,
            connectNulls: true
          },
          {
            name: '随机',
            type: 'line',
            data: random1,
            connectNulls: true
          }
        ]
      }
      option && chartDom.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getSugarTrendById({ patientId: patientRow.id, ...condition })
  }, [patientRow.id, condition])

  useEffect(() => {
    const node = document.getElementById('trend')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, sugarTrend)

    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, sugarTrend])

  /**
   * 渲染
   */
  return <div id='trend' style={{ height: 400, padding: '0 10px' }}></div>
}

export default BSTrend

/* 患者-会诊历史页签 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table, Empty } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { STATUS } from '../../utils/constants'

/* 功能模块
---------------------------------------------------------------- */
import { reqConsultationLists } from '../../api'
import { mergeRid } from '../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
HistoryPane.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  commonRow: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function HistoryPane(props) {
  const { patientRow, commonPage, commonRow, setCommonRow, setCommonPage, flag } = props
  // 总条数
  const [total, setTotal] = useState(0)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 获取患者会诊历史接口
  const [consultations, setConsultations] = useState([])

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '会诊医院',
      dataIndex: 'company',
      width: 150,
      align: 'center',
      render: (text, { company }) => <>{company.name}</>
    },
    {
      title: '会诊科室',
      dataIndex: 'office',
      width: 150,
      align: 'center',
      render: (text, { office }) => <>{office.name}</>
    },
    {
      title: '医师',
      dataIndex: 'user',
      width: 100,
      align: 'center'
    },
    {
      title: '会诊状态',
      dataIndex: 'status',
      width: 100,
      align: 'center',
      render: (text, { status }) => <>{status && STATUS[status - 1].name}</>
    },
    {
      title: '会诊时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '诊疗意见',
      dataIndex: 'prescription',
      width: 150,
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      align: 'center'
    }
  ]

  /**
   * api
   * 获取患者会诊历史接口
   * @param {*} value
   */
  const getConsultations = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqConsultationLists(params)
      const result = resultList && mergeRid(resultList, params.pageNos, params.pageSizes)
      if (status) {
        setConsultations(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  useEffect(() => {
    getConsultations({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize, flag])

  /**
   * 渲染
   */
  if (!Object.keys(patientRow).length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        scroll={{ y: 400 }}
        loading={isLoading}
        columns={initColumns}
        dataSource={consultations}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          showQuickJumper: true,
          showSizeChanger: false,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setCommonRow(record)
            }
          }
        }}
      />
    </>
  )
}

export default HistoryPane

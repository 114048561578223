/* 慢病录入 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Button, Row, Col, message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqComplicationList, reqChronicDisease } from '../../../api/index'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
AddNCDForm.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddNCDForm(props) {
  const { flag, patientRow, onCancel, setModal, setFlag } = props

  // 提交表单
  const [form] = Form.useForm()

  // 体重
  const [weight, setWeight] = useState(0)

  // 身高
  const [stature, setStature] = useState(0)

  //并发症列表
  const [complicationList, setComplicationList] = useState([])

  /**
   * api
   * 获取并发症列表
   */
  const getComplicationList = async () => {
    const { data, status } = await reqComplicationList()
    if (status) {
      setComplicationList(data)
    }
  }

  /**
   *获取身高输入框的值
   * @param {*} e
   */
  const onChangeStature = (e) => {
    setStature(e.target.value)
  }

  /**
   *获取体重输入框的值
   * @param {*} e
   */
  const onChangeWeight = (e) => {
    setWeight(e.target.value)
  }
  /**
   * 失去焦点时，进行BMI计算
   */
  const onBlurWeight = () => {
    if (stature && weight) {
      let result = weight / [(stature / 100) * (stature / 100)]
      let results = result.toFixed(2)
      form.setFieldsValue({ bmi: results })
    }
  }

  /**
   * api
   * 按钮
   * 点击保存新建慢病
   */
  const onOkAddNcdForm = async (value) => {
    const { status } = await reqChronicDisease({
      ...value,
      patientId: patientRow.id
    })
    if (status) {
      message.success('新建慢病成功！')
    }
    form.resetFields()
    setModal(0)
    setFlag(!flag)
  }

  useEffect(() => {
    getComplicationList()
  }, [])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkAddNcdForm}>
      <Row>
        <Col span={8} offset={2}>
          <Item
            name='stature'
            label={<span className='g_w_42'>身高</span>}
            rules={[
              {
                required: true,
                pattern: /^([0-9]{1,6})(\.\d{1,2})?$/,
                message: '请输入(1-6位，后可跟两位小数)的身高'
              }
            ]}
          >
            <Input placeholder='cm' onChange={onChangeStature} />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item
            name='weight'
            label={<span className='g_w_42'>体重</span>}
            rules={[
              {
                required: true,
                pattern: /^([0-9]{1,6})(\.\d{1,2})?$/,
                message: '请输入(1-6位，后可跟两位小数)的体重'
              }
            ]}
          >
            <Input placeholder='kg' onChange={onChangeWeight} onBlur={onBlurWeight} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='bmi' label={<span className='g_w_42'>BMI</span>}>
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item
            name='waistline'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的腰围'
              }
            ]}
            label={<span className='g_w_42'>腰围</span>}
          >
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item
            name='hipLine'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的臀围'
              }
            ]}
            label={<span className='g_w_42'>臀围</span>}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item
            name='systolicPressure'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的收缩压'
              }
            ]}
            label={<span className='g_w_42'>收缩压</span>}
          >
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item
            name='dbp'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的舒张压'
              }
            ]}
            label={<span className='g_w_42'>舒张压</span>}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item
            name='bodyFat'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的体脂'
              }
            ]}
            label={<span className='g_w_42'>体脂</span>}
          >
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item
            name='heartRate'
            rules={[
              {
                pattern: /^([0-9]{1,20})(\.\d{1,2})?$/,
                message: '请输入(1-20位，后可跟两位小数)的心率'
              }
            ]}
            label={<span className='g_w_42'>心率</span>}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='complicationIds' label={<span className='g_w_42'>并发症</span>}>
            <Select style={{ width: '100%' }} mode='multiple'>
              {complicationList &&
                complicationList.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <div className='g_txt_c'>
        <Button className='g_mr_10' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default AddNCDForm

/* 字典维护 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Card, Table, Button, Tooltip } from 'antd'
import { ReadOutlined, MenuOutlined } from '@ant-design/icons'


/* 子模块
---------------------------------------------------------------- */
import DicData from './DicData'

/* 静态资源
---------------------------------------------------------------- */
import { DICTIONARY, DICTIONARY_STATUS } from '../../../utils/constants'


/* 唯一的模块导出
---------------------------------------------------------------- */
function Dictionary() {
  // 列表相关参数
  const [dictionary, setDictionary] = useState({ isShow: false, data: [] })

  /**
   * 初始化 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'id',
      width: 60,
      align: 'center'
    },
    {
      title: '字典名称',
      dataIndex: 'name',
      width: '',
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 80,
      align: 'center',
      render: (text, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{DICTIONARY_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{DICTIONARY_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Tooltip title='字典数据' mouseEnterDelay={0.5}>
          <Button type='link' icon={<MenuOutlined />} onClick={() => onShowDicData(record)} />
        </Tooltip>
      )
    }
  ]

  /**
   * 列表行
   * 按钮：点击切换到对应的列表
   * @param {*} data
   */
  const onShowDicData = (data) => {
    setDictionary({ isShow: true, data })
  }

  /**
   * 渲染
   */
  if (!dictionary.isShow) {
    return (
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <ReadOutlined className='g_mr_10' />
            <span>字典维护</span>
          </>
        }
      >
        <Table rowKey='id' bordered size='small' columns={initColumns} dataSource={DICTIONARY} scroll={{y: 450}}/>
      </Card>
    )
  }

  return (
    <DicData dictionary={dictionary.data} setDictionary={(params) => setDictionary({ ...dictionary, ...params })} />
  )
}

export default Dictionary

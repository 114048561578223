/* 数据可视化 BI 看板 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Spin } from 'antd'

/* 子模块
---------------------------------------------------------------- */
import DeptPatientBar from './DeptPatientBar'
import AgePie from './AgePie'
import GenderRatio from './GenderRatio'
import TodayMonitorGauge from './TodayMonitorGauge'
import TodayWarningGauge from './TodayWarningGauge'
import TodayQcGauge from './TodayQcGauge'
import TodayRunawayGauge from './TodayRunawayGauge'
import BSMonitorPie from './BSMonitorPie'
import BSStandardPie from './BSStandardPie'
import PatientChangeBar from './PatientChangeBar'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstitutions, reqBI } from '../../api'

/* 静态资源
---------------------------------------------------------------- */
import './index.less'
import heartImg from './images/heart.png'
import hospitalImg from './images/hospital.png'
import { MONITOR } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
BigScreen.prototype = {
  setIsBigScreen: PropTypes.func.isRequired
}

// let refreshBI

/* 唯一的模块导出
---------------------------------------------------------------- */
function BigScreen(props) {
  const { setIsBigScreen } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 医院
  const [companys, setCompanys] = useState([])

  // 大屏信息
  const [bi, setBi] = useState({})

  const [companyId, setCompanyId] = useState(undefined)

  const [flag, setFlag] = useState(false)

  /**
   * 大屏切换
   */
  const onBigScreen = () => {
    setIsBigScreen(false)
  }

  /**
   * 获取医院
   */
  const getCompanys = async () => {
    const { data, status } = await reqInstitutions()
    if (status) {
      setCompanyId(data[0]['id'])
      setCompanys(data)
    }
  }

  /**
   * 通过 companyId 获取大屏信息
   * @param {*} params
   */
  const getBI = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqBI(params)
      if (status) {
        setBi(data)
      }
    } finally {
      setIsLoading(false)
      // setFlag(true)
    }
  }

  const getRefreshBI = async (params) => {
    try {
      const { data, status } = await reqBI(params)
      if (status) {
        setBi(data)
      }
    } finally {
      setFlag(true)
    }
  }

  const onChangeCompany = (e) => {
    const { value } = e.target
    if (value !== companyId) {
      setFlag(false)
    }
    setCompanyId(value)
  }

  const onChangeMonitor = (e) => {}

  const {
    ageDistribution,
    bloodSugarDistribution,
    bloodSugarWarningDistribution,
    count: { num: countMonitors } = {},
    gender,
    officePatientDistribution,
    patientDistribution,
    todayOutControl,
    todayQualityControl,
    todaySupervise,
    todayWarning
  } = bi

  /**
   * 生命周期
   */
  useEffect(() => {
    let biTimer = null
    if (companyId) {
      if (flag) {
        biTimer = setInterval(() => {
          getRefreshBI({ companyId })
        }, 4000)
      } else {
        getBI({ companyId })
        setFlag(true)
      }
    }

    return () => {
      biTimer && clearInterval(biTimer)
    }
  }, [companyId, flag])

  useEffect(() => {
    getCompanys()
  }, [])

  /**
   * 渲染
   */
  return (
    <div className='bi'>
      <Spin spinning={isLoading}>
        {Object.keys(bi).length > 0 ? (
          <>
            <Row className='bi_header'>
              <Col className='g_fx_b bi_header_section1' span={8}>
                <div className='g_fx_a g_fx_m'>
                  <img className='g_mr_20' src={heartImg} alt='' />
                  <span className='g_mr_20 bhs1_label'>累计监测人</span>
                  <span className='g_mr_20 bhs1_txt'>{countMonitors}</span>
                </div>
              </Col>
              <Col span={8}>
                <h1 className='bi_header_section2' onClick={onBigScreen}>
                  上海市第六人民医院POCT监测系统
                </h1>
              </Col>
              <Col span={8}>
                <div className='g_fx_a g_fx_mr bi_header_section3'>
                  <select className='bi_select' onChange={onChangeCompany}>
                    {companys.map((v) => (
                      <option key={v.id} className='bi_select_item' value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                  <select className='bi_select g_ml_20' onChange={onChangeMonitor}>
                    {MONITOR.map((v) => (
                      <option className='bi_select_item' key={v.id} value={v.enumId}>
                        {v.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>

            <div className='bi_main'>
              <div className='g_fx_lr'>
                <div style={{ width: 450 }}>
                  <div className='bi_chart'>
                    <h2 className='bi_chart_label'>科室患者分布</h2>
                    <div id='bi-patient' className='bi_chart_bg'>
                      <DeptPatientBar data={officePatientDistribution || []} companyId={companyId} flag={flag}/>
                    </div>
                  </div>
                  <div className='bi_chart'>
                    <h2 className='bi_chart_label'>性别比例</h2>
                    <GenderRatio data={gender} />
                  </div>
                  <div className='bi_chart'>
                    <h2 className='bi_chart_label'>年龄分布</h2>
                    <div className='bi_chart_bg'>
                      <AgePie data={ageDistribution || {}} />
                    </div>
                  </div>
                </div>
                <div style={{ padding: '0 30px', width: 600 }} className='g_fx_b'>
                  <img className='bi_main_img' src={hospitalImg} alt='hospitalImg' />
                </div>
                <div style={{ width: 810 }}>
                  <Row>
                    <Col span={24}>
                      <div className='bi_chart'>
                        <h2 className='bi_chart_label'>今日数据</h2>
                        <Row className='bi_chart_bg bi_chart3'>
                          <Col span={6}>
                            <TodayMonitorGauge data={todaySupervise} />
                          </Col>
                          <Col span={6}>
                            <TodayWarningGauge data={todayWarning} />
                          </Col>
                          <Col span={6}>
                            <TodayQcGauge data={todayQualityControl} />
                          </Col>
                          <Col span={6}>
                            <TodayRunawayGauge data={todayOutControl} />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className='g_fx'>
                        <div className='bi_chart'>
                          <h2 className='bi_chart_label'>血糖监测点分布</h2>
                          <div className='bi_chart_bg'>
                            <BSMonitorPie data={bloodSugarDistribution || []} />
                          </div>
                        </div>
                        <div style={{ width: 12 }}></div>
                        <div className='bi_chart '>
                          <h2 className='bi_chart_label'>血糖达标率</h2>
                          <div className='bi_chart_bg'>
                            <BSStandardPie data={bloodSugarWarningDistribution || {}} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col span={24}>
                  <div className='bi_chart'>
                    <h2 className='bi_chart_label'>全院患者变化趋势</h2>
                    <PatientChangeBar data={patientDistribution || []} />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : null}
      </Spin>
    </div>
  )
}

export default BigScreen

/* 版本信息 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Row, Col } from 'antd'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Version() {
  /**
   * 渲染
   */
  return (
    <div
      className='g_h_percent g_color_gray'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#fff'
      }}
    >
      <Row>
        <Col>上线版本：v1.0</Col>
      </Row>
      <Row>
        <Col>版权所有：上海联莱信息科技有限公司</Col>
      </Row>
      <Row>
        <Col>开发者：上海联莱信息科技有限公司</Col>
      </Row>
      {/* <Row>
        <Col>官网：www.qowho.com</Col>
      </Row> */}
      {/* <Row>
        <Col>联系电话：86-021-674832</Col>
      </Row> */}
    </div>
  )
}

export default Version

/* 添加维护 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect } from 'react'
import { Form, Button, Input, Select, message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditMaintain } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select
const { TextArea } = Input

/* 使用类型检查
---------------------------------------------------------------- */
MaintainForm.prototype = {
  maintainContent: PropTypes.array.isRequired,
  qc: PropTypes.object.isRequired,
  modal: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  refreshMaintains: PropTypes.func.isRequired
}

/* 唯一的模块调用
---------------------------------------------------------------- */
function MaintainForm(props) {
  const { maintainContent, qc, modal, setModal, refreshMaintains } = props

  const [form] = Form.useForm()

  /**
   * 确定
   * @param {*} value
   */
  const onOkRunaway = async (value) => {
    // console.log(value)
    let msg = '' // 提示信息
    let params = {} // 表单信息
    if (modal.action === 'up') {
      msg = '编辑维护成功！'
      params = {
        ...value,
        qualityControlItemId: qc.data.id,
        id: modal.data.id
      }
    } else {
      msg = '新增维护成功！'
      params = {
        ...value,
        qualityControlItemId: qc.data.id
      }
    }
    const { status } = await reqAddOrEditMaintain(params)
    if (status) {
      message.success(msg)
      setModal()
      refreshMaintains()
    }
  }

  /**
   * 取消
   */
  const onCancel = () => {
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (modal.action === 'up') {
      form.setFieldsValue(modal.data)
    } else {
      form.resetFields()
    }
  }, [modal.action, form, modal.data])
  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkRunaway}>
      <Item
        name='maintainType'
        label={<span className='g_w_56'>维护内容</span>}
        rules={[{ required: true, message: '请选择维护内容' }]}
      >
        <Select>
          {maintainContent.map((v) => (
            <Option key={v.id} value={v.enumId}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item name='remark' label={<span className='g_w_67'>备注信息</span>}>
        <TextArea rows={4} maxLength={300}/>
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default MaintainForm

/*血气列表详情 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Divider, Button, Spin,Col,Row } from 'antd'
import { TagsOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqFleshReportByPatientId } from '../../../api/index'

/* 使用类型检查
---------------------------------------------------------------- */
AddDetailsForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddDetailsForm(props) {
  const { onCancel, patientRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  const [fleshReportById, setFleshReportById] = useState([])

  /**
   * 根据患者id统计血糖报表详情
   * @param {*} params
   */
  const getFleshReportByPatientId = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqFleshReportByPatientId(params)
      if (status) {
        const result = data
        setFleshReportById(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getFleshReportByPatientId({ id: patientRow.id })
  }, [patientRow.id])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <TagsOutlined className='g_mr_10' />
          详情
        </h3>
      }
    >
      <Spin spinning={isLoading}>
        <div className='g_ml_10'>
          <Row className='table'>
            <Col className='g_txt_c' span={1}>
              &nbsp;
            </Col>
            <Col className='g_txt_c' span={1}>
              总值
            </Col>
            <Col className='g_txt_c' span={1}>
              二氧化碳分压
            </Col>
            <Col className='g_txt_c' span={1}>
              标准重碳酸
            </Col>
            <Col className='g_txt_c' span={1}>
              酸碱度
            </Col>
            <Col className='g_txt_c' span={1}>
              碳酸氢根浓度
            </Col>
            <Col className='g_txt_c' span={1}>
              氧分压
            </Col>
            <Col className='g_txt_c' span={1}>
              二氧化碳总量
            </Col>
            <Col className='g_txt_c' span={1}>
              细胞比积
            </Col>
            <Col className='g_txt_c' span={1}>
              氧饱和度
            </Col>
            <Col className='g_txt_c' span={1}>
              钠
            </Col>
            <Col className='g_txt_c' span={1}>
              血红蛋白
            </Col>
            <Col className='g_txt_c' span={1}>
              钾
            </Col>
            <Col className='g_txt_c' span={1}>
              细胞外液剩余碱
            </Col>
            <Col className='g_txt_c' span={1}>
              氯
            </Col>
            <Col className='g_txt_c' span={1}>
              肺泡内氧分压
            </Col>
            <Col className='g_txt_c' span={1}>
              离子钙
            </Col>
            <Col className='g_txt_c' span={1}>
              全血碱剩余
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
              测量次数
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].num : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].num : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            血气平均值
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].average : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].average : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            血气最大值
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].max : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].max : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            血气最小值
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].min : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].min : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            理想频率
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].theIdealFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            过高频率
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].highFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={1}>
            过低频率
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[16].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[0].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[1].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[2].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[3].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[4].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[5].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[6].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[7].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[8].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[9].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[10].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[11].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[12].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[13].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[14].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={1}>
              {fleshReportById.length > 0 ? fleshReportById[15].underFrequency : ''}
            </Col>
          </Row>
        </div>

        <Divider plain className='g_mb_30' />
        <div className='g_txt_c'>
          <Button className='g_mr_20 ' onClick={onCancel}>
            关闭
          </Button>
        </div>
      </Spin>
    </Card>
  )
}

export default AddDetailsForm

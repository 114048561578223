/* 心标修改 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Select, Button, Divider, DatePicker, Input, message, Spin } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstruments, reqReagents, reqUpdateHeart, reqfindNurseListByLoginUser } from '../../../api/index'

/* 静态资源模块
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 唯一的模块导出
---------------------------------------------------------------- */
function UpdateHeartForm(props) {
  const { patientRow, operation, setOperation } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 仪器列表
  const [instrumentList, setInstrumentList] = useState([])

  //护士列表
  const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 提交表单
  const [form] = Form.useForm()

  /**
 * api
 * 仪器接口
 */
  const getInstrumentList = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqInstruments(params)
      if (status) {
        setInstrumentList(data.resultList)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
* api
* 获取护士列表
* @param {*} value 
*/
  const getNurseListByLoginUser = async () => {
    const { data, status } = await reqfindNurseListByLoginUser()
    if (status) {
      setNurseListByLoginUser(data)
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqReagents(params)
      if (status) {
        setReagents(data.resultList)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 按钮
   * 点击保存心标录入
   * @param {*} value
   */
  const onOkUpdateHeart = async (value) => {
    const { detectionTime, item1, item2, item3, item4, item5, userId } = value
    const { status } = await reqUpdateHeart({
      ...value,
      userId,
      id: operations.id,
      patientId: patientRow.id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss'),
      theResult: {
        1: parseFloat(item1),
        2: parseFloat(item2),
        3: parseFloat(item3),
        4: parseFloat(item4),
        5: parseFloat(item5)
      }
    })
    if (status) {
      message.success('心标修改成功')
      form.resetFields()
      setOperation()
    }
  }

  let operations = operation.data

  const { theHeartItems } = operations
  let item1
  let item2
  let item3
  let item4
  let item5
  for (let i = 0; i < theHeartItems.length; i++) {
    if (theHeartItems[i].item === 1) {
      item1 = theHeartItems[i].value
    } if (theHeartItems[i].item === 2) {
      item2 = theHeartItems[i].value
    } if (theHeartItems[i].item === 3) {
      item3 = theHeartItems[i].value
    } if (theHeartItems[i].item === 4) {
      item4 = theHeartItems[i].value
    } if (theHeartItems[i].item === 5) {
      item5 = theHeartItems[i].value
    }
  }

  /**
   * 生命周期
   */

  useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])
  useEffect(() => {
    getInstrumentList({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
  }, [])

  useEffect(() => {
    const { detectionTime, snnum, batchNumber, user } = operations
    form.setFieldsValue({
      ...operations,
      userId: user.id,
      snnum,
      batchNumber,
      detectionTime: moment(detectionTime),
      item1,
      item2,
      item3,
      item4,
      item5
    })
  }, [form, operations, item1, item2, item3, item4, item5])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          心标修改
        </h3>
      }
    >
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onOkUpdateHeart}>
          <Divider orientation='left' plain>
            基本信息
          </Divider>
          <Row>
            <Col span={8} offset={2}>
              <Item
                name='snnum'
                rules={[{ required: true, message: '请选择试纸批号' }]}
                label={
                  <span className='g_w_98' rules={[{ required: true, message: '请输入仪器' }]}>
                    仪器
                  </span>
                }
              >
                <Select style={{ width: '100%' }}>
                  {instrumentList.length &&
                    instrumentList.map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.brand.name + '     ' + v.modelVO.name + '     ' + v.snnum}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={8} offset={2}>
              <Item
                name='userId'
                label={<span className='g_w_56'>操作人</span>}
                rules={[{ required: true, message: '请选择操作人' }]}
              >
                <Select >
                  {nurseListByLoginUser.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item
                name='batchNumber'
                rules={[{ required: true, message: '请选择试纸批号' }]}
                label={<span className='g_w_98'>试纸批号</span>}
              >
                <Select style={{ width: '100%' }}>
                  {reagents.length &&
                    reagents.map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.brand.name + '      ' + v.methodology.name + '      ' + v.batchNumber}
                      </Option>
                    ))}
                </Select>
              </Item>
            </Col>
            <Col span={8} offset={2}>
              <Item name='detectionTime' label={<span className='g_w_67'>监测时间</span>}>
                <DatePicker className='width-percent' />
              </Item>
            </Col>
          </Row>
          <Divider orientation='left' plain>
            详细信息
          </Divider>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item1' label={<span className='g_w_67'>CKMB</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item2' label={<span className='g_w_67'>肌红蛋白</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item3' label={<span className='g_w_67'>肌钙蛋白I</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
            <Col span={8} offset={2}>
              <Item name='item4' label={<span className='g_w_67'>B型钠尿肽</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              pg/mL
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={2}>
              <Item name='item5' label={<span className='g_w_67'>DDIM</span>}>
                <Input />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Divider plain />
          <div className='text-center'>
            <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={setOperation}>
              关闭
            </Button>
            <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
              保存
            </Button>
          </div>
        </Form>
      </Spin>
    </Card>
  )
}

export default UpdateHeartForm

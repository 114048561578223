/* 会诊管理 - 应诊列表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Table, Card, Tooltip, Descriptions, message } from 'antd'
import {
  ScheduleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  AuditOutlined,
  CheckCircleOutlined,
  UserSwitchOutlined,
  ContainerOutlined
} from '@ant-design/icons'
import moment from 'moment'

/* 公共模块
---------------------------------------------------------------- */
import SelfTable from './SelfTable'
import PatientDetailForm from './PatientDetailForm'
import { mergeRid } from '../../../utils/func'

/* 功能模块
---------------------------------------------------------------- */
import { PAGE_SIZE, COURTYARD_STATUS, CONSULTATION } from '../../../utils/constants'
import { IModal } from '../../../components/ui'
import { reqOfficeConsultations, reqConsultationStatus, reqAddConsultationPrescription } from '../../../api/index'

/* 调用模块: 对象结构
---------------------------------------------------------------- */
const { TextArea } = Input
const { Item } = Form

/* 唯一的模块导出
---------------------------------------------------------------- */
function Consultation() {
  // 0 默认 | 1 患者详情 | 2 会诊列表
  const [show, setShow] = useState(0)

  // 提交表单
  const [form] = Form.useForm()

  // 一行记录
  const [row, setRow] = useState(undefined)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 0 隐藏 | 1 完成会诊 | 2 取消会诊 | 3 会诊详情
  const [modal, setModal] = useState(0)

  // 应诊列表
  const [consultations, setConsultations] = useState([])

  // 分页相关参数x
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总条数
  const [total, setTotal] = useState(0)

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center',
      fixed: 'left'
    },
    {
      title: '患者',
      dataIndex: 'name',
      align: 'center',
      fixed: 'left',
      width: 100,
      render: (text, { patient }) => <>{patient && patient.name}</>
    },
    {
      title: '住院号',
      dataIndex: 'hospitalizationCards',
      align: 'center',
      render: (text, { patient }) => <>{patient && patient.hospitalizationCards}</>
    },
    {
      title: '会诊类别',
      dataIndex: 'consultationType',
      align: 'center',
      render: (text, { consultationType }) => <>{consultationType && COURTYARD_STATUS[consultationType - 1].name}</>
    },
    {
      title: '申请医院',
      dataIndex: 'company',
      align: 'center',
      render: (text, { company }) => <>{company && company.name}</>
    },
    {
      title: '申请科室',
      dataIndex: 'office',
      align: 'center',
      render: (text, { office }) => <>{office && office.name}</>
    },
    {
      title: '申请时间',
      dataIndex: 'createDate',
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: 180,
      render: (text, { id }) => (
        <div className='action'>
          <Tooltip title='完成会诊' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showComplete(id)} icon={<CheckCircleOutlined />} />
          </Tooltip>
          <Tooltip title='取消会诊' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showCancel(id)} icon={<CloseCircleOutlined />} />
          </Tooltip>
          <Tooltip title='会诊详情' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showConsultationDetail(id)} icon={<AuditOutlined />} />
          </Tooltip>
          <Tooltip title='患者详情' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showPatientDetail(id)} icon={<ContainerOutlined />} />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取应诊列表接口
   */
  const getOfficeConsultations = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqOfficeConsultations(params)
      if (status) {
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        setConsultations(result)
        setTotal(totalRows)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 新建诊疗意见
   */
  const onOkPrescription = async (value) => {
    const { prescription } = value
    const { status } = await reqAddConsultationPrescription({
      officeConsultationId: row.id,
      prescription
    })
    if (status) {
      message.success('新建意见成功！')
    }
    form.resetFields()
    setModal(0)
    getOfficeConsultations({
      pageNo: page.pageNo,
      pageSize: page.pageSize
    })
  }

  /**
   * api
   * 取消诊疗时的备注信息
   */
  const onOkRemarks = async (value) => {
    const { remarks } = value
    const { status } = await reqConsultationStatus({
      id: row.id,
      remarks,
      code: CONSULTATION[2].id
    })
    if (status) {
      message.success('备注信息提交成功！')
    }
    form.resetFields()
    setModal(0)
    getOfficeConsultations({
      pageNo: page.pageNo,
      pageSize: page.pageSize
    })
  }

  /**
   * 获取会诊患者列表
   */
  const data = mergeRid(consultations, 1, PAGE_SIZE)

  /**
   * 按钮
   * 点击切换到会诊列表
   */
  const showSelf = () => {
    setShow(2)
  }

  /**
   * 按钮
   * 点击弹出完成会诊
   * @param {*} id
   */
  const showComplete = (id) => {
    setModal(1)
  }

  /**
   * 按钮
   * 点击弹出取消会诊
   * @param {*} id
   */
  const showCancel = (id) => {
    setModal(2)
  }

  /**
   * 按钮
   * 点击弹出会诊详情
   * @param {*} id
   */
  const showConsultationDetail = (id) => {
    setModal(3)
  }

  /**
   * 按钮
   * 点击切换到会诊列表
   */
  const showPatientDetail = () => {
    setShow(3)
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 挂载
   */
  useEffect(() => {
    getOfficeConsultations({
      pageNo: page.pageNo,
      pageSize: page.pageSize
    })
  }, [page.pageNo, page.pageSize])

  /**
   * 渲染
   */
  if (!show) {
    return (
      <>
        <Card
          className='height-percent'
          size='small'
          title={
            <h3 className='margin-clear'>
              <ScheduleOutlined className='g_mr_10' />
              应诊列表
            </h3>
          }
          extra={
            <Button type='primary' icon={<UserSwitchOutlined />} onClick={showSelf}>
              会诊列表
            </Button>
          }
        >
          <Table
            bordered
            size='small'
            rowKey='rid'
            scroll={{
              x: 1400
            }}
            loading={isLoading}
            columns={initColumns}
            dataSource={data}
            pagination={{
              current: page.pageNo,
              pageSize: page.pageSize,
              total: total,
              showTotal: (total) => `共 ${total} 条记录`,
              showQuickJumper: true,
              showSizeChanger: false,
              onChange: onChangePage
            }}
            rowClassName={showRowClassName}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRow(record)
                }
              }
            }}
          />
        </Card>
        <IModal visible={modal === 1} titleText='完成会诊'>
          <h3 style={{ display: 'flex', alignItems: 'center' }}>
            <ExclamationCircleOutlined className='g_mr_10 font-22 g_color_alert' />
            <span className='font-16'>
              确认要<i className='color-green'>完成</i>
              该患者的会诊吗？
            </span>
          </h3>
          <Form form={form} onFinish={onOkPrescription}>
            <Item label='诊疗意见' name='prescription' rules={[{ required: true, message: '请填写完成会诊意见' }]}>
              <TextArea rows={4} maxLength={200}/>
            </Item>
            <div className='g_txt_c'>
              <Button className='g_mr_20' key='back' onClick={() => setModal(0)}>
                取消
              </Button>
              <Button key='submit' type='primary' htmlType='submit'>
                确定
              </Button>
            </div>
          </Form>
        </IModal>

        <IModal visible={modal === 2} titleText='取消会诊'>
          <h3 style={{ display: 'flex', alignItems: 'center' }}>
            <ExclamationCircleOutlined className='g_mr_10 font-22 g_color_alert' />
            <span className='font-16'>
              确认要<i className='g_color_red'>取消</i>
              该患者的会诊吗？
            </span>
          </h3>
          <Form form={form} onFinish={onOkRemarks}>
            <Item label='备注信息' name='remarks' rules={[{ required: true, message: '请填写取消会诊的备注信息' }]}>
              <TextArea rows={4} maxLength={200}/>
            </Item>
            <div className='g_txt_c'>
              <Button className='g_mr_20' key='back' onClick={() => setModal(0)}>
                取消
              </Button>
              <Button key='submit' type='primary' htmlType='submit'>
                确定
              </Button>
            </div>
          </Form>
        </IModal>

        <IModal visible={modal === 3} titleText='会诊详情'>
          <Descriptions className='g_mb_10' bordered size='small'>
            <Item className='g_w_98' contentStyle={{ textAlign: 'left' }} label='申请原因' name='pursueReason'>
              <TextArea rows={4} showCount value={row && row.pursueReason} />
            </Item>
          </Descriptions>
          <div className='g_txt_c'>
            <Button key='back' onClick={() => setModal(0)}>
              关闭
            </Button>
          </div>
        </IModal>
      </>
    )
  }

  if (show === 3) {
    return <PatientDetailForm onClose={() => setShow(0)} row={row} />
  }

  return <SelfTable onClose={() => setShow(0)} />
}

export default Consultation

/* 机构管理 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Tooltip, Modal, message } from 'antd'
import {
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  StopOutlined,
  AppstoreOutlined,
  ExclamationCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import InstitutionForm from './InstitutionForm'

/* 功能函数
---------------------------------------------------------------- */
import { reqInstitutionTree,reqInstitutionList, reqPermissions, reqEditInstitutionStatus } from '../../../api'
import { addKeyToThree } from '../../../utils/func'
import { INSTITUTION_STATUS } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 唯一的模块导出
---------------------------------------------------------------- */
function Institution() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询 / 隐藏切换
  const [query, setQuery] = useState()

  // 弹框相关参数
  const [modal, setModal] = useState({
    action: undefined,
    isShow: false,
    data: {}
  })

  // 查询条件
  const [condition, setCondition] = useState({
    searchData: undefined, // 医院
    status: undefined // 状态
  })

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  //新建上级机构调的列表
  const [institutionList, setInstitutionList] = useState([])

  // 用户可创建的医院等级列表（数据范围）
  const [permissions, setPermissions] = useState([])

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '机构名称',
      dataIndex: 'name',
      align: 'left'
    },
    {
      title: '创建时间',
      dataIndex: 'createDate',
      width: 160,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    // {
    //   title: '创建人',
    //   dataIndex: 'createBy',
    //   width: 100,
    //   align: 'center'
    // },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      align: 'center',
      render: (text, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{INSTITUTION_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{INSTITUTION_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='编辑机构' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowModal('up', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          <Tooltip title={record.status ? '停用机构' : '启用机构'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip>
          {/* <Tooltip title='新增下级机构' mouseEnterDelay={0.5}>
            <Button type='link' icon={<PlusCircleOutlined />} onClick={() => this.showAddLower(record)} />
          </Tooltip> */}
        </div>
      )
    }
  ]

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqInstitutionTree(params)
      if (status) {
        const result = addKeyToThree(data[0])
        setInstitutions(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
  * api
  * 获取机构列表（新建）
  */
 const getInstitutionList = async () => {
   setIsLoading(true)
   try {
     const { data, status } = await reqInstitutionList()
     if (status) {
       const result = data
       setInstitutionList(result)
     }
   } finally {
     setIsLoading(false)
   }
 }

  /**
   * api
   * 获取用户可创建的医院等级列表（数据范围）
   */
  const getPermissions = async () => {
    const { data, status } = await reqPermissions()
    if (status) {
      setPermissions(data)
    }
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 新增 / 编辑机构
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
  }

  /**
   * 列表
   * 按钮：改变停用 / 启用用户状态
   * @param {*} record
   */
  const onShowStatus = (record) => {
    const { id, status } = record
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该机构吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该机构吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const res = await reqEditInstitutionStatus({ code: status ? 0 : 1, ids: [id] })
        let msg
        if (res.status) {
          if (status) {
            msg = '禁用机构成功！'
          } else {
            msg = '启用机构成功！'
          }
          message.success(msg)
          getInstitutions(condition)
          getInstitutionList()
        }
      }
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions(condition)
  }, [condition])

  useEffect(() => {
    getPermissions()
    getInstitutionList()
  }, [])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        bordered={false}
        title={
          <>
            <AppstoreOutlined className='g_mr_10' />
            <span>机构管理</span>
          </>
        }
        extra={
          <>
            <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
              {query ? '隐藏' : '查询'}
            </Button>
            <Button type='primary' onClick={() => onShowModal('add', {})} icon={<PlusOutlined />}>
              新增
            </Button>
          </>
        }
      >
        {query && (
          <QueryForm
            statusType={INSTITUTION_STATUS}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          scroll={{ y: 450 }}
          rowKey='key'
          loading={isLoading}
          columns={initColumns}
          dataSource={institutions}
        />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '新增机构' : '编辑机构'}>
        <InstitutionForm
          institutionList={institutionList}
          // institutions={institutions}
          permissions={permissions}
          modal={modal}
          setModal={() => setModal({ ...modal, isShow: false })}
          refreshInstitutions={() => getInstitutions(condition)}
          refreshInstitutionList={() => getInstitutionList()}
        />
      </IModal>
    </>
  )
}

export default Institution

/* 应用页面入口 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

/* 登录模块
---------------------------------------------------------------- */
import Login from './pages/Login'

/* 主页面布局模块
---------------------------------------------------------------- */
import AdminLayout from './layout'

/* 唯一的模块导出
---------------------------------------------------------------- */
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/' component={AdminLayout} />
      </Switch>
    </BrowserRouter>
  )
}

export default App

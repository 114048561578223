/* 患者列表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { GENDER, PATIENT_OUT_STATUS } from '../../utils/constants'
import { reqPatients } from '../../api'
import { mergeRid } from '../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
PatientList.propTypes = {
  patientPage: PropTypes.object,
  patientRow: PropTypes.object,
  condition: PropTypes.object.isRequired,
  setPatientPage: PropTypes.func.isRequired,
  setPatientRow: PropTypes.func.isRequired,
  setCommonPage: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientList(props) {
  const { patientPage, patientRow, condition, setPatientPage, setPatientRow, setCommonPage, setCommonRow,flag} = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  // 患者列表
  const [patients, setPatients] = useState([])

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 80,
      align: 'center'
    },
    {
      title: '床位',
      dataIndex: 'bedCards',
      width: 60,
      align: 'center'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: 100,
      align: 'center'
    },
    {
      title: '性别',
      dataIndex: 'gender',
      width: 60,
      align: 'center',
      render: (text, { gender }) => <>{GENDER[gender].name}</>
    },
    {
      title: '年龄',
      dataIndex: 'age',
      width: 60,
      align: 'center'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: (text, { status }) => <>{status && PATIENT_OUT_STATUS[status - 1].name}</>
    }
  ]

  /**
   * api
   * 获取患者列表
   * @param {*} params
   */
  const getPatients = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqPatients(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setPatients(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 分页器
   * 切换当前页
   */
  const onChangePage = (pageNo, pageSize) => {
    setPatientPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (patientRow) return record.id === patientRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getPatients({ pageNo: patientPage.pageNo, pageSize: patientPage.pageSize, ...condition })
  }, [patientPage.pageNo, patientPage.pageSize, condition,flag])

  /**
   * 渲染
   */
  return (
    <Table
      size='small'
      bordered
      rowKey='rid'
      loading={isLoading}
      columns={initColumns}
      dataSource={patients}
      pagination={{
        current: patientPage.pageNo,
        pageSize: patientPage.pageSize,
        showSizeChanger: false,
        total,
        showTotal: (total) => `共 ${total} 条记录`,
        onChange: onChangePage,
        position: ['bottomCenter']
      }}
      rowClassName={showRowClassName}
      onRow={(record) => {
        return {
          onClick: () => {
            setPatientRow(record)
            setCommonRow()
            setCommonPage()
          }
        }
      }}
    />
  )
}

export default PatientList

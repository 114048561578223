/* 血糖均值图 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

/* 功能模块
---------------------------------------------------------------- */
import { reqMeanValueById } from '../../../api/index'

/* 调用模块
---------------------------------------------------------------- */
function BSAverage(props) {
  const { patientRow, condition } = props

  const [chartDom, setChartDom] = useState('')

  //血糖均值图/血糖漂移度图(共用)
  const [meanValueById, setMeanValueById] = useState({})

  /**
   * 血糖均值图
   * @param {*} params
   */
  const getMeanValueById = async (params) => {
    const { data, status } = await reqMeanValueById(params)
    if (status) {
      setMeanValueById(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, meanValueById) => {
    if (chartDom !== '' && Object.keys(meanValueById).length > 0) {
      /**
       * 获取均值对象
       */
      let arr = []
      for (const i in meanValueById) {
        arr.push(meanValueById[i].num)
      }
      let newArr = []
      arr.forEach(item => {
          if (item !== 0) {
              newArr.push(item)
          } else {
              newArr.push(null)
          }
      })
      const option = {
        title: {
          text: '血糖均值图',
          left: 'center'
        },
        animation: true,
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: ['凌晨', '早餐前', '早餐后', '午餐前', '午餐后', '晚餐前', '晚餐后', '睡前']
        },
        yAxis: [
          {
            name: 'mmol/L',
            type: 'value',
            position: 'left'
          }
        ],
        series: [
          {
            data: newArr,
            type: 'line',
            connectNulls:true
          }
        ]
      }
      option && chartDom.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMeanValueById({ patientId: patientRow.id, ...condition })
  }, [patientRow.id, condition])

  useEffect(() => {
    const node = document.getElementById('average')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, meanValueById)

    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, meanValueById])
  return <div id='average' style={{ height: 400 }}></div>
}

export default BSAverage

/* 新增 / 编辑 机构 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Select, Input, TreeSelect, message } from 'antd'

/* 功能函数
---------------------------------------------------------------- */
import { reqAddInstitution, reqEditInstitution } from '../../../api'
import { addValueToThrees } from '../../../utils/func'
// import { addValueToThree } from '../../../utils/func'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
InstitutionForm.prototype = {
  institutionList: PropTypes.array,
  permissions: PropTypes.array,
  modal: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  refreshInstitutions: PropTypes.func.isRequired,
  refreshInstitutionList: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function InstitutionForm(props) {
  const [form] = Form.useForm()

  const { institutionList, permissions, modal, setModal, refreshInstitutions,refreshInstitutionList } = props
  /**
   * 升级后的机构 tree
   * @param {*} institutions
   */
  // const upgradeInstitutions = (institutions) => {
  //   return addValueToThree(institutions)
  // }
  const upgradeInstitutions = (institutionList) => {
    return  addValueToThrees(institutionList)
  }


  /**
   * 新增 / 编辑机构
   * 按钮：点击确定
   */
  const onOkInstitution = async (value) => {
    let res // 响应信息
    let msg // 提示信息
    // 判断是否为编辑用户操作
    if (modal.action === 'up') {
      res = await reqEditInstitution({ ...value, id: modal.data.id })
      msg = '编辑机构成功！'
    } else {
      res = await reqAddInstitution(value)
      msg = '新增机构成功！'
    }
    if (res.status) {
      message.success(msg)
      form.resetFields()
      setModal()
      refreshInstitutions()
      refreshInstitutionList()
    }
  }

  /**
   * 新增 / 编辑机构
   * 按钮：点击取消
   */
  const onCancel = () => {
    form.resetFields()
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (modal.action === 'up') {
      form.setFieldsValue(modal.data)
    }
  })

  /**
   * 渲染
   */
  if (modal.action === 'add') {
    return (
      <Form form={form} onFinish={onOkInstitution}>
        <Item
          name='pid'
          label={<span className='g_w_67'>上级机构</span>}
          // rules={[{ required: true, message: '请输入上级机构名称' }]}
        >
          <TreeSelect
            showSearch
            allowClear
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={upgradeInstitutions(institutionList)}
            treeDefaultExpandAll
            treeNodeFilterProp='title'
          />
        </Item>

        <Item name='name' label={<span>机构名称</span>} rules={[{ required: true,message: '请输入机构名称' }]}>
          <Input />
        </Item>

        <Item
          name='hosLevel'
          label={<span className='g_w_56'>数据权限</span>}
          rules={[{ required: true, message: '请选择数据权限' }]}
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {permissions.map((v) => (
              <Option key={v.hosLevel} value={v.hosLevel}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Item>

        <div className='g_txt_c'>
          <Button className='g_mr_20' onClick={onCancel}>
            取消
          </Button>
          <Button type='primary' htmlType='submit'>
            确定
          </Button>
        </div>
      </Form>
    )
  }

  if (modal.action === 'up') {
    return (
      <Form form={form} onFinish={onOkInstitution}>
        <Item name='name' label={<span>机构名称</span>} rules={[{ required: true, message: '请输入机构名称' }]}>
          <Input />
        </Item>

        <div className='g_txt_c'>
          <Button className='g_mr_20' onClick={onCancel}>
            取消
          </Button>
          <Button type='primary' htmlType='submit'>
            确定
          </Button>
        </div>
      </Form>
    )
  }
}

export default InstitutionForm

/* 血糖测量点测量次数分布图 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucoseItemCounts } from '../../../api/index'

// ==================
// 唯一的模块调用
// ==================
function BSMonitor(props) {
  const { condition } = props

  const [chartDom, setChartDom] = useState('')

  //获取每日检测次数图
  const [bloodGlucoseItemCounts, setBloodGlucoseItemCounts] = useState([])

  /**
   * api
   * 获取每日检测次数图
   * @param {*} params
   */
  const getBloodGlucoseItemCounts = async (params) => {
    const { data, status } = await reqBloodGlucoseItemCounts(params)
    if (status) {
      setBloodGlucoseItemCounts(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, bloodGlucoseItemCounts) => {
    if (chartDom !== '') {
      //遍历获取次数值
      var num = []
      for (const i in bloodGlucoseItemCounts) {
        num.push(bloodGlucoseItemCounts[i].num)
      }

      const option = {
        title: {
          text: '血糖测量点测量次数分布图',
          left: 'center',
          top: 20
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: ['凌晨', '早餐前', '早餐后', '午餐前', '午餐后', '晚餐前', '晚餐后', '睡前', '随机']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: num,
            name: '次数',
            type: 'bar'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
      }
      option && chartDom.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodGlucoseItemCounts({ ...condition })
  }, [condition])

  useEffect(() => {
    const node = document.getElementById('trend')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, bloodGlucoseItemCounts)
    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, bloodGlucoseItemCounts])

  /**
   * 渲染
   */
  return <div id='trend' style={{ height: 400 }}></div>
}

export default BSMonitor

/* 录入 / 编辑次批号 */

/* 调用模块
---------------------------------------------------------------- */
import React, {useEffect} from 'react'
import { Form, Button, Input, Select, DatePicker, message } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditLiquidSubBN } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
SubBNForm.propTypes = {
  level: PropTypes.array.isRequired,
  levelNum: PropTypes.number,
  liquidId: PropTypes.number,
  modal: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  refreshLiquidSubBNs: PropTypes.func.isRequired,
  setForm: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function SubBNForm(props) {
  const [form] = Form.useForm()

  const { level, levelNum, liquidId, modal, setModal, refreshLiquidSubBNs, setForm } = props

  /**
   * 录入 / 编辑次批号
   * 按钮：点击确定
   * @param {*} value 
   */
  const onOkSubBN = async (value) => {
    const { expireDate } = value
    let msg // 提示信息
    let params // 表单参数
    if (modal.action === 'up') {
      msg = '编辑次批号成功！'
      params = {
        ...value,
        expireDate: moment(expireDate).format('YYYY-MM-DD HH:mm:ss'),
        controlThingsId: liquidId,
        id: modal.data.id
      }
    } else {
      msg = '录入次批号成功！'
      params = {
        ...value,
        expireDate: moment(expireDate).format('YYYY-MM-DD HH:mm:ss'),
        controlThingsId: liquidId
      }
    }
    const { status } = await reqAddOrEditLiquidSubBN(params)
    if (status) {
      setModal()
      message.success(msg)
      refreshLiquidSubBNs()
      form.resetFields()
    }
  }

  /**
   * 录入 / 编辑次批号
   * 按钮：点击取消
   */
  const onCancel = () => {
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    setForm(form)
    if(modal.action === 'up') {
      form.setFieldsValue({ ...modal.data, expireDate: moment(modal.data.expireDate) })
    }
  }, [setForm, form, modal.action, modal.data])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkSubBN}>
      <Item name='batchLevel' label={<span className='g_w_85'>质控水平</span>}>
        <Select disabled={modal.action === 'up' ? true : false}>
          {level.map((v) => {
            if (v.id <= levelNum) {
              return (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              )
            }
            return null
          })}
        </Select>
      </Item>
      <Item name='batchNum' label={<span className='g_w_85'>质控液次批号</span>}>
        <Input />
      </Item>
      <Item name='expireDate' label={<span className='g_w_85'>效期</span>}>
        <DatePicker className='g_w_percent' disabled={modal.action === 'up' ? true : false} />
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default SubBNForm

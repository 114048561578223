/* 试剂维护 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, Modal, Table, Tooltip, message } from 'antd'
import {
  BgColorsOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  StopOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import ReagentForm from './ReagentForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqReagents, reqBrands, reqAddOrEditReagent } from '../../../api'
import { PAGE_SIZE, REAGENT_STATUS, MONITOR } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 唯一的模块导出
---------------------------------------------------------------- */
function Reagent() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 弹框相关参数
  const [modal, setModal] = useState({
    action: undefined, // 操作行为
    isShow: false, // 显示状态
    data: {} // 数据
  })

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE, // 每页数量
    total: 0 // 总记录数
  })

  // 查询条件
  const [condition, setCondition] = useState({
    type: undefined, // 监测类型
    brandID: undefined, // 品牌
    modelID: undefined, // 型号
    batchNumber: undefined, // 批号
    code: undefined, // 代号
    status: undefined, // 状态
    startTime: undefined, // 开始时间
    endTime: undefined // 结束时间
  })

  // 试剂列表
  const [reagents, setReagents] = useState([])

  // 一行记录
  const [row, setRow] = useState(undefined)

  // 品牌列表
  const [brands, setBrands] = useState([])

  // 新增 / 编辑试剂表单信息 ref
  const [form, setForm] = useState(undefined)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '监测类型',
      dataIndex: 'type',
      width: '',
      align: 'center',
      render: (text, { type }) => <>{MONITOR[type - 1].name}</>
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      align: 'center',
      render: (text, { brand }) => <>{brand.name}</>
    },
    {
      title: '型号',
      dataIndex: 'modelVO',
      align: 'center',
      render: (text, { modelVO }) => <>{modelVO.name}</>
    },
    {
      title: '试剂批号',
      dataIndex: 'batchNumber',
      align: 'center'
    },
    {
      title: '试剂代号',
      dataIndex: 'code',
      width: '',
      align: 'center'
    },
    {
      title: '方法学',
      dataIndex: 'methodology',
      align: 'center',
      render: (text, { methodology }) => <>{methodology.name}</>
    },
    {
      title: '效期',
      dataIndex: 'expireDate',
      width: 150,
      align: 'center'
    },
    {
      title: '录入者',
      dataIndex: 'createUser',
      align: 'center',
      render: (text, { createUser }) => <>{createUser.name}</>
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => <>{moment(createDate).format('YYYY-MM-DD HH:mm:ss')}</>
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      align: 'center',
      render: (text, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{REAGENT_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{REAGENT_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='编辑试剂' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowModal('up', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          <Tooltip title={record.status ? '停用试剂' : '启用试剂'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取试剂列表
   */
  const getRegents = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqReagents(params)
      if (status) {
        const result = mergeRid(data.resultList, params.pageNo, params.pageSize)
        setReagents(result)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          total: data.totalRows
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 获取品牌列表
   * @param {*} value
   */
  const getBrands = async () => {
    const { data, status } = await reqBrands()
    if (status) {
      setBrands(data)
    }
  }

  /**
   * 分页器
   * 切换用户列表页
   * @param {*} page
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ ...page, pageNo, pageSize })
  }

  /**
   * 按钮
   * 点击显示查询 / 隐藏条件栏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 按钮
   * 点击弹出录入 / 编辑仪器
   * @param {*} type
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
    if (action === 'add') {
      if (form) form.resetFields()
    }
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 列表操作
   * 按钮：点击启用 / 停用试剂
   * @param {*} value
   */
  const onShowStatus = (value) => {
    const { id, status } = value
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该试剂吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该试剂吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const res = await reqAddOrEditReagent({ id, status: status ? 0 : 1 })
        let msg // 提示信息
        if (res.status) {
          if (status) {
            msg = '停用试剂成功！'
          } else {
            msg = '启用试剂成功！'
          }
          message.success(msg)
          getRegents({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
        }
      }
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getRegents({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
  }, [page.pageNo, page.pageSize, condition])

  useEffect(() => {
    getBrands()

    return () => {
      setBrands((_) => {
        return
      })
    }
  }, [])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='g_h_percent'
        size='small'
        title={
          <>
            <BgColorsOutlined className='g_mr_10' />
            <span>试剂维护</span>
          </>
        }
        extra={
          <>
            <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
              {query ? '隐藏' : '查询'}
            </Button>
            <Button type='primary' onClick={() => onShowModal('add', {})} icon={<PlusOutlined />}>
              录入试剂
            </Button>
          </>
        }
      >
        {query && (
          <QueryForm
            monitor={MONITOR}
            brands={brands}
            reagentStatus={REAGENT_STATUS}
            setPage={() => setPage({ ...page, pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          rowKey='rid'
          scroll={{ y: 440 }}
          loading={isLoading}
          columns={initColumns}
          dataSource={reagents}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: page.total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
          rowClassName={showRowClassName}
          onRow={(record) => {
            return { onClick: () => setRow(record) }
          }}
        />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '录入试剂' : '编辑试剂'}>
        <ReagentForm
          monitor={MONITOR}
          brands={brands}
          modal={modal}
          setModal={() => setModal({ ...modal, isShow: false })}
          refreshReagents={() => getRegents({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })}
          setForm={(form) => setForm(form)}
        />
      </IModal>
    </>
  )
}

export default Reagent

/* 慢病更新 */

// ==================
// 调用模块
// ==================
import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Row, Col,message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqUpdateChronicDisease } from '../../../api'

// ==================
// 调用模块: 对象解构
// ==================
const { Item } = Form

// ==================
// 使用类型检查
// ==================
UpdateNCDForm.propTypes = {
  ncd: PropTypes.object.isRequired,
  patientRow: PropTypes.object,
  commonRow: PropTypes.object.isRequired,
  onShowModal: PropTypes.func.isRequired,
  refresh:PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function UpdateNCDForm(props) {
 
  const { ncd, patientRow, commonRow, onShowModal,refresh } = props


  // 提交表单
  const [form] = Form.useForm()
  
  // 体重
  const [weight, setWeight] = useState(0)

  // 身高
  const [stature, setStature] = useState(0)

  /**
   *获取身高输入框的值
   * @param {*} e
   */
  const onChangeStature = (e) => {
    setStature(e.target.value)
  }

  /**
   *获取体重输入框的值
   * @param {*} e
   */
  const onChangeWeight = (e) => {
    setWeight(e.target.value)
  }
  /**
   * 失去焦点时，进行BMI计算
   */
  const onBlurWeight = () => {
    if (stature && weight) {
      let result = weight / [(stature / 100) * (stature / 100)]
      let results = result.toFixed(2)
      form.setFieldsValue({ bmi: results })
    }
  }

   /**
   * api
   * 按钮
   * 点击保存修改慢病
   */
    const onOkChronicDisease = async (value) => {
      const { status } = await reqUpdateChronicDisease({
        ...value,
        patientId: patientRow.id,
        id: commonRow.id
      })
      if (status) {
        message.success('修改成功！')
        form.resetFields()
        refresh()
        onShowModal()
      }
    }

  /**
   * 生命周期
   */
  useEffect(() => {
    form.setFieldsValue(ncd)
  }, [form, ncd])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkChronicDisease}>
      <Row>
        <Col span={8} offset={2}>
          <Item
            name='stature'
            label={<span className='g_w_42'>身高</span>}
            rules={[
              {
                required: true,
                message: '请输入身高'
              }
            ]}
          >
            <Input placeholder='cm' onChange={onChangeStature} />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item
            name='weight'
            label={<span className='g_w_42'>体重</span>}
            rules={[
              {
                required: true,
                message: '请输入体重'
              }
            ]}
          >
            <Input placeholder='kg' onChange={onChangeWeight} onBlur={onBlurWeight} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='bmi' label={<span className='g_w_42'>BMI</span>}>
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='waistline' label={<span className='g_w_42'>腰围</span>}>
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item name='hipLine' label={<span className='g_w_42'>臀围</span>}>
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='systolicPressure' label={<span className='g_w_42'>收缩压</span>}>
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item name='dbp' label={<span className='g_w_42'>舒张压</span>}>
            <Input />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8} offset={2}>
          <Item name='bodyFat' label={<span className='g_w_42'>体脂</span>}>
            <Input />
          </Item>
        </Col>
        <Col span={8} offset={4}>
          <Item name='heartRate' label={<span className='g_w_42'>心率</span>}>
            <Input />
          </Item>
        </Col>
      </Row>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onShowModal}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default UpdateNCDForm

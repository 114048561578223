/* 科室图谱 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, DatePicker, Form, Select, Col, Button, Input } from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstitutions } from '../../../api/index'

/* 子模块
---------------------------------------------------------------- */
import BSMonitor from './BSMonitor'
import BSControl from './BSControl'
import BSTIME from './BSTime'
import BSPie from './BSPie'

/* 静态资源
---------------------------------------------------------------- */
import { ATLAS_1 } from '../../../utils/constants'

// ==================
// 调用模块: 对象解构
// ==================
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker

// ==================
// 唯一的模块导出
// ==================
function Atlas() {
  // 机构列表
  const [institutions, setInstitutions] = useState([])

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  const [form] = Form.useForm()
  // 图谱切换显示状态
  const [show, setShow] = useState(0)


  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院id
    officeId: undefined, // 科室id
    startTime: undefined,
    endTime: undefined,
    under:undefined,
    high:undefined
  })

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutions()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    setCompanyId(value)
    form.setFieldsValue({ officeId: undefined })
  }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId)
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    const { date } = value
    let params
    if (date) {
      params = {
        ...value,
        // high: form.getFieldValue('high'),
        // under: form.getFieldValue('under'),
        startTime: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      params = {
        ...value,
        high: form.getFieldValue('high') ? form.getFieldValue('high') : '16',
        under: form.getFieldValue('under')? form.getFieldValue('under') : '3.9',
        startTime: moment().startOf('day').subtract(14, 'days').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      }
    }
    setCondition(params)
  }

  /**
   * 点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setCompanyId(undefined)
    setCondition({
      companyId: undefined,
      officeId: undefined,
      startTime: undefined,
      endTime: undefined,
      under:undefined,
      high:undefined,
    })
  }

  /**
   * 选择器
   * 选择血糖图谱获取id
   * @param {*} value
   */
  const onChangeAtlas = (value) => {
    setShow(value)
  }


  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()
    return () => {
      setInstitutions((_) => {
        return
      })
    }
  }, [])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <PictureOutlined className='margin-r-10' />
          科室图谱
        </h3>
      }
    >
      <Form className='g_mb_10' layout='inline' form={form} onFinish={onOkQuery}>
        <Item label='图谱'>
          <Select style={{ width: 174 }} onChange={onChangeAtlas} defaultValue='测量血糖次数图'>
            {ATLAS_1.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name='companyId' label='医院'>
          <Select style={{ width: 174 }} onChange={onChangeCompany}>
            {institutions.length && institutions.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name='officeId' label='科室'>
          <Select style={{ width: 174 }}>{companyId && showOffices(companyId)}</Select>
        </Item>
        {
          show === 4 ?
            <>
              <Item name='high' label='高值'>
                <Input style={{ width: 174 }} placeholder='16'/>
              </Item>
              <Item name='under' label='低值'>
                <Input style={{ width: 174 }} placeholder='3.9'/>
              </Item>
            </>
            : null
        }
        <Item name='date' label='时间'>
          <RangePicker defaultValue={[moment(),moment().startOf('day').subtract(14, 'days')]}/>
        </Item>
        <Col className='g_mb_10'>
          <Button className='margin-r-10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Form>

      <Atlass id={show} condition={condition} />
    </Card>
  )
}

const Atlass = (props) => {
  const { id, condition } = props
  if (id === 1) return <BSControl condition={condition} />
  if (id === 2) return <BSMonitor condition={condition} />
  if (id === 3) return <BSPie condition={condition} />
  if (id === 4) return <BSTIME condition={condition} />
  else {
    return <BSControl condition={condition} />
  }
}

export default Atlas

/* 设置质控规则 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Button, Select, message, Spin } from 'antd'

/* 功能模块
---------------------------------------------------------------- */
import { reqEditQcRule, reqQueryQcRule } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { QC_RULES } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QCRulesForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  modal: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCRulesForm(props) {
  const [form] = Form.useForm()

  const { monitor, modal, setModal } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 质控规则
  const [qcRule, setQcRule] = useState({})

  const {
    controlId,
    type,
    instrument: { brand, modelVO, snnum },
    controlProject
  } = modal.data

  /**
   * api
   * 通过质控 id 获取对应的质控规则
   * @param {*} params 
   */
  const getQcRule = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqQueryQcRule(params)
      if (status) {
        setQcRule(data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表操作：设置质控规则
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkQcRules = async (value) => {
    let params = {
      ...value,
      qualityControlId: controlId
    }
    const { status } = await reqEditQcRule(params)
    if (status) {
      message.success('设置质控规则成功！')
      setModal()
      getQcRule({ qualityControlId: controlId })
    }
  }

  const onCancel = () => {
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getQcRule({ qualityControlId: controlId })
  }, [controlId])

  useEffect(() => {
    if (Object.keys(qcRule).length > 0) {
      form.setFieldsValue(qcRule)
    }
  }, [form, qcRule])

  /**
   * 渲染
   */
  return (
    <>
      <Row className='g_mb_10'>
        <Col span={8}>类型：{monitor[type - 1].name}</Col>
        <Col span={8}>品牌：{brand.name}</Col>
        <Col span={8}>型号：{modelVO.name}</Col>
        <Col span={8}>SN号：{snnum}</Col>
        <Col span={8}>项目：{controlProject.name}</Col>
      </Row>

      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onOkQcRules}>
          <Row>
            <Col span={6}>
              <Item name='s1_3' label={<span className='g_w_67'>1-3S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='s1_2' label={<span className='g_w_67'>1-2S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='s2_2' label={<span className='g_w_67'>2-2S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='s3_1' label={<span className='g_w_67'>3-1S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='s2OF3_2' label={<span className='g_w_67'>2of3-2S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='sr_4' label={<span className='g_w_67'>R-4S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='s4_1' label={<span className='g_w_67'>4-1S</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='x_6' label={<span className='g_w_67'>6-X</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='t_7' label={<span className='g_w_67'>7-T</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='x_8' label={<span className='g_w_67'>8-X</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='x_9' label={<span className='g_w_67'>9-X</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='x_10' label={<span className='g_w_67'>10-X</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name='x_12' label={<span className='g_w_67'>12-X</span>}>
                <Select className='g_w_70'>
                  {QC_RULES.map((v) => (
                    <Option key={v.id} value={v.id}>
                      <span className={v.color}>{v.name}</span>
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>

          <div className='g_txt_c'>
            <Button className='g_mr_20' key='back' onClick={onCancel}>
              取消
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              确定
            </Button>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default QCRulesForm

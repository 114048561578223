/* 项目绑定 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Tooltip, message, Modal } from 'antd'
import {
  PartitionOutlined,
  FilterOutlined,
  PlusOutlined,
  EditOutlined,
  StopOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import BindingForm from './BindingForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditProjectBinding, reqProjectBindings } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE, MONITOR, BINDING_TYPE, BINDING_STATUS } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 唯一的模块导出
---------------------------------------------------------------- */
function Binding() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    type: undefined, // 监测类型
    instrumentId: undefined, // 仪器
    controlProjectId: undefined, // 项目
    controlProjectStatus: undefined // 状态
  })

  // 项目相关参数
  const [modal, setModal] = useState({
    action: undefined, // 操作行为
    isShow: false, // 显示状态
    data: {} // 数据
  })

  // 项目绑定列表
  const [projectBindings, setProjectBindings] = useState([])

  // 绑定 / 编辑项目表单信息 ref
  const [form, setForm] = useState(undefined)

  // 一行记录
  const [row, setRow] = useState(undefined)

  /**
   * 初始化生成 Table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '绑定类型',
      dataIndex: 'bindType',
      align: 'center',
      render: (text, { bindType }) => BINDING_TYPE[bindType - 1].name 
    },
    {
      title: '监测类型',
      dataIndex: '',
      width: '',
      align: 'center',
      render: (text, { type }) => MONITOR[type - 1].name
    },
    {
      title: '仪器',
      dataIndex: 'instrument',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { instrument }) => instrument.brand.name + '  ' + instrument.modelVO.name + '  ' + instrument.snnum
    },
    {
      title: '试剂',
      dataIndex: 'reagent',
      align: 'center',
      ellipsis: true,
      render: (text, { reagent }) => reagent.brand.name + '  ' + reagent.modelVO.name + '  ' + reagent.batchNumber
    },
    {
      title: '质控液',
      dataIndex: 'controlThings',
      align: 'center',
      ellipsis: true,
      render: (text, { controlThings }) =>
        controlThings.brand.name + '  ' + controlThings.model.name + '  ' + controlThings.thingsBatchNumber
    },
    {
      title: '质控液名称',
      dataIndex: 'controlThingsName',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { controlThings }) => controlThings.name.name
    },
    {
      title: '方法学',
      dataIndex: 'methodology',
      align: 'center',
      ellipsis: true,
      render: (text, { reagent }) => reagent.methodology.name
    },
    {
      title: '基质',
      dataIndex: 'stroma',
      align: 'center',
      render: (text, { controlThings }) => controlThings.stroma.name
    },
    {
      title: '项目',
      dataIndex: 'controlProject',
      width: '',
      align: 'center',
      ellipsis: true,
      render: (text, { controlProject }) => controlProject.name
    },
    {
      title: '单位',
      dataIndex: 'metering',
      width: 100,
      align: 'center',
      render: (text, { metering }) => metering.name
    },
    {
      title: '状态',
      dataIndex: 'controlProjectStatus',
      width: 60,
      align: 'center',
      render: (text, { controlProjectStatus }) =>
        controlProjectStatus ? (
          <span style={{ color: 'green' }}>{BINDING_STATUS[controlProjectStatus].name}</span>
        ) : (
          <span style={{ color: 'red' }}>
            {controlProjectStatus === 0 && BINDING_STATUS[controlProjectStatus].name}
          </span>
        )
    },
    {
      title: '操作',
      dataIndex: '',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='编辑项目' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowModal('up', record)}
              disabled={record.controlProjectStatus ? false : true}
            />
          </Tooltip>
          <Tooltip title={record.controlProjectStatus ? '停用项目' : '启用项目'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.controlProjectStatus ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取已项目绑定的列表
   */
  const getProjectBindings = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqProjectBindings(params)
      if (status) {
        const result = mergeRid(resultList, params.pageNo, params.pageSize)
        setProjectBindings(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 查询
   * 按钮：条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 列表操作
   * 按钮：绑定 / 编辑项目
   * @param {*} action
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
    if (action === 'add') {
      if (form) form.resetFields()
    }
  }

  /**
   * 列表操作
   * 按钮：点击启用 / 停用项目
   * @param {*} record
   */
  const onShowStatus = (record) => {
    const { controlId, controlProjectStatus } = record
    confirm({
      title: controlProjectStatus ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该项目吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该项目吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const { data } = await reqAddOrEditProjectBinding({
          controlId,
          controlProjectStatus: controlProjectStatus ? 0 : 1
        })
        if (data) {
          if (controlProjectStatus) {
            message.success('停用项目成功！')
          } else {
            message.success('启用项目成功！')
          }
          getProjectBindings({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
        }
      }
    })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getProjectBindings({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })

    return () => {
      setProjectBindings((_) => {
        return
      })
    }
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='height-percent'
        size='small'
        title={
          <>
            <PartitionOutlined className='g_mr_10' />
            <span>项目绑定</span>
          </>
        }
        extra={
          <>
            <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
              {query ? '隐藏' : '查询'}
            </Button>
            <Button type='primary' onClick={() => onShowModal('add', {})} icon={<PlusOutlined />}>
              绑定项目
            </Button>
          </>
        }
      >
        {query && (
          <QueryForm
            monitor={MONITOR}
            bindingStatus={BINDING_STATUS}
            setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          scroll={{ x: 1600, y: 450 }}
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={projectBindings}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
          rowClassName={showRowClassName}
          onRow={(record) => {
            return { onClick: () => setRow(record) }
          }}
        />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '绑定项目' : '编辑项目'} width={660}>
        <BindingForm
          binding={BINDING_TYPE}
          monitor={MONITOR}
          modal={modal}
          setModal={() => setModal({ ...modal, isShow: false })}
          setForm={(form) => setForm(form)}
          refreshProjectBindings={() =>
            getProjectBindings({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
          }
        />
      </IModal>
    </>
  )
}

export default Binding

/* 血糖监测人数图 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucosePatientCount } from '../../../api/index'

// ==================
// 唯一的模块导出
// ==================
function BSPie(props) {
  const { condition } = props

  const [chartDom, setChartDom] = useState('')

  //获取每日检测次数图
  const [patientCount, setPatientCount] = useState([])

  /**
   * api
   * 获取每日检测次数图
   * @param {*} params
   */
  const getBloodGlucosePatientCount = async (params) => {
    const { data, status } = await reqBloodGlucosePatientCount(params)
    if (status) {
      setPatientCount(data)
    }
  }

  const getChart = (chartDom, patientCount) => {
    if (chartDom !== '') {
      let option = {
        title: {
          text: '血糖监测人数图',
          left: 'center',
          top: 20
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: patientCount,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      option && chartDom.setOption(option, true)
    }
  }
  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodGlucosePatientCount({ ...condition })
  }, [condition])

  useEffect(() => {
    const node = document.getElementById('pie')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, patientCount)
    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, patientCount])

  return <div id='pie' style={{ height: 500 }}></div>
}
export default BSPie

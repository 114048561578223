/* 录入 / 编辑仪器 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import PropTypes from 'prop-types'
import { reqAddOrEditInstrument } from '../../../api'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
InstrumentForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  brands: PropTypes.array,
  modal: PropTypes.object,
  setForm: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  refreshInstrument: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function InstrumentForm(props) {
  const [form] = Form.useForm()

  const { monitor, brands, modal, setForm, setModal, refreshInstrument } = props

  // 品牌 id
  const [brandId, setBrandId] = useState(undefined)

  /**
   * 新增 / 编辑仪器
   * 选择框：选择品牌
   */
  const onChangeBrand = (value) => {
    form.setFieldsValue({ modelID: undefined })
    setBrandId(value)
  }

  /**
   * 通过品牌 id 显示对应的型号列表
   * @param {*} brandId
   */
  const showModels = (brandId) => {
    const result = brands.filter((v) => v.id === brandId)
    if ('children' in result[0]) {
      return result[0].children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  const onOkInstrument = async (value) => {
    let msg // 提示信息
    let params // 表单参数
    if (modal.action === 'up') {
      msg = '编辑仪器成功！'
      params = {
        ...value,
        id: modal.data.id
      }
    } else {
      msg = '新增仪器成功！'
      params = value
    }
    const { status } = await reqAddOrEditInstrument(params)
    if (status) {
      setModal()
      message.success(msg)
      refreshInstrument()
      form.resetFields()
      setBrandId(undefined)
    }
  }

  /**
   * 新增 / 编辑试剂
   * 按钮：点击取消
   */
  const onCancel = () => {
    setModal()
    setBrandId(undefined)
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    setForm(form)
  }, [setForm, form])

  useEffect(() => {
    if (modal.action === 'up') {
      form.setFieldsValue(modal.data)
    }
  }, [form, modal.action, modal.data])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkInstrument}>
      <Item
        name='type'
        label={<span className='g_txt_r g_w_56'>监测类型</span>}
        rules={[{ required: true, message: '请选择监测类型' }]}
      >
        <Select>
          {monitor.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='brandID'
        label={<span className='g_txt_r g_w_56'>品牌</span>}
        rules={[{ required: true, message: '请输入品牌' }]}
      >
        <Select onChange={onChangeBrand}>
          {brands.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='modelID'
        label={<span className='g_txt_r g_w_56'>型号</span>}
        rules={[{ required: true, message: '请输入型号' }]}
      >
        <Select>{brandId ? showModels(brandId) : modal.action === 'up' && showModels(modal.data.brandID)}</Select>
      </Item>
      <Item
        name='snnum'
        label={<span className='g_txt_r g_w_56'>SN号</span>}
        rules={[{ required: true,pattern:/^[a-zA-Z\d]+$/, message: '请输入(英文大小写，数字)的SN号' }]}
      >
        <Input />
      </Item>
      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default InstrumentForm

/* 登录 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

/* 功能模块
---------------------------------------------------------------- */
import { reqLogin } from '../../api'
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'

/* 静态资源
---------------------------------------------------------------- */
import logo from '../Login/images/left-logo.png'
import './index.less'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Login(props) {
  const login = async (value) => {
    const { data, status } = await reqLogin(value)
    if (status) {
      // 提示登录成功，保存用户登录信息，跳转到主页面
      message.success('登录成功')
      // 保存用户登录信息
      // console.log('登录者', data)
      storageUtils.saveUser(data)
      memoryUtils.user = data
      // 跳转到主页面
      props.history.replace('/')
    }
  }

  /**
   * 如果用户已登录，自动跳转到admin
   */
  if (Object.keys(memoryUtils.user).length > 0) {
    return <Redirect to='/' />
  }
  return (
    <div className='login'>
      <header className='login_header'>
        <img src={logo} alt='' />
      </header>
      <section className='login_content g_shape'>
        <h2>POC智能监测管理系统</h2>
        <Form className='login_form' initialValues={{ remember: true }} onFinish={login}>
          <Form.Item
            name='username'
            rules={[
              { required: true, whitespace: true, message: '用户名必须输入' },
              { min: 5, message: '用户名至少5位' },
              { max: 16, message: '用户名最多16位' },
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: '用户名必须是英文、数字'
              }
            ]}
          >
            <Input
              className='g_shape_login'
              suffix={<UserOutlined style={{ color: 'rgba(217, 217, 217, 1)' }} />}
              placeholder='用户名'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              { required: true, whitespace: true, message: '密码必须输入' },
              { min: 6, message: '密码至少6位' },
              { max: 16, message: '密码最多16位' },
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: '密码必须是英文、数字'
              }
            ]}
          >
            <Input
              className='g_shape_login'
              suffix={<LockOutlined style={{ color: 'rgba(217, 217, 217, 1)' }} />}
              type='password'
              placeholder='密码'
            />
          </Form.Item>
          {/* <Form.Item>
            <Checkbox>记住用户名和密码</Checkbox>
          </Form.Item> */}
          <Form.Item>
            <Button type='primary' shape='round' htmlType='submit' className='g_w_percent'>
              登录
            </Button>
          </Form.Item>
        </Form>
      </section>

      <footer className='login_footer'>Lab-link Intelligent Point of Care Monitor System</footer>
    </div>
  )
}

export default Login

/* 修改密码 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Input, message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqUpdatePwd } from '../../api'
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form

UpdatePwdForm.prototype = {
  setModal: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function UpdatePwdForm(props) {
  const [form] = Form.useForm()

  const { setModal } = props

  /**
   * 取消
   */
  const onCancel = () => {
    setModal()
  }

  const onOk = async (value) => {
    // console.log(value)
    const { status } = await reqUpdatePwd(value)
    if (status) {
      // 移除保存的user
      storageUtils.removeUser()
      memoryUtils.user = {}
      // 跳转到login
      props.history.push('/login')
      setModal()
      message.success('修改密码成功！')
    }
  }

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOk}>
      <Item name='oldPassword' label='旧密码' rules={[{ required: true, message: '请输入旧密码' }]}>
        <Input />
      </Item>
      <Item name='newPassword' label='新密码' rules={[{ required: true, message: '请输入新密码' }]}>
        <Input />
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default withRouter(UpdatePwdForm)

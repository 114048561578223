//应诊列表 | 患者查询 里面的血糖医嘱

/* 慢病管理 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { mergeRid } from '../../utils/func'
import { reqChronicDiseaseById } from '../../api'

/* 使用类型检查
---------------------------------------------------------------- */
PatientNCD.propTypes = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
}

/* 唯一的模块调用
---------------------------------------------------------------- */
function PatientNCD(props) {
  const { patientRow, commonPage, setCommonPage, } = props

  //根据患者id查询患者慢病页面列表
  const [chronicDiseaseById, setChronicDiseaseById] = useState([])

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总条数
  const [total, setTotal] = useState(0)

  /**
   * 初始化生成 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '身高',
      dataIndex: 'stature',
      width: '',
      align: 'center'
    },
    {
      title: '体重',
      dataIndex: 'weight',
      width: '',
      align: 'center'
    },
    {
      title: 'BMI',
      dataIndex: 'bmi',
      width: '',
      align: 'center'
    },
    {
      title: '腰围',
      dataIndex: 'waistline',
      width: '',
      align: 'center'
    },
    {
      title: '臂围',
      dataIndex: 'hipLine',
      width: '',
      align: 'center'
    },
    {
      title: '收缩压',
      dataIndex: 'systolicPressure',
      width: '',
      align: 'center'
    },
    {
      title: '舒张压',
      dataIndex: 'dbp',
      width: '',
      align: 'center'
    },
    {
      title: '心率',
      dataIndex: 'heartRate',
      width: '',
      align: 'center'
    },
    {
      title: '体脂',
      dataIndex: 'bodyFat',
      width: '',
      align: 'center'
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { updateDate }) => moment(updateDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '糖尿病并发症',
      dataIndex: 'complications',
      width: 300,
      align: 'center',
      render: (text, { complications }) => complications.map((v) => <span key={v.id}>{`${v.name}; `}</span>),
    }
  ]

  /**
   * api
   * 通过患者id获取页面慢病信息
   * @param {*} id
   */
  const getChronicDisease = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } ={},
        status
      } = await reqChronicDiseaseById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setChronicDiseaseById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }


  /**
   * 生命周期
   */
  useEffect(() => {
    getChronicDisease({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  /**
   * 渲染
   */
  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        loading={isLoading}
        scroll={{ x: 1400, y: 400 }}
        columns={initColumns}
        dataSource={chronicDiseaseById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
      />
    </>
  )
}

export default PatientNCD

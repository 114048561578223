/* 诊疗医嘱 */

// ==================
// 调用模块
// ==================
import React, { useState, useEffect } from 'react'
import { Table, Button, Empty } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

// ==================
// 调用模块
// ==================
import { IModal } from '../ui'
import { mergeRid } from '../../utils/func'

// ==================
// 功能模块
// ==================
import { reqPrescriptionById } from '../../api/index'

// ==================
// 子模块
// ==================
import UpdateAdviceForm from '../../pages/Patient/List/UpdateAdviceForm'

// ==================
// 使用类型检查
// ==================
PatientAdvicePane.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  commonPage: PropTypes.object.isRequired,
  commonRow: PropTypes.object.isRequired,
  setCommonRow: PropTypes.func.isRequired,
  setCommonPage: PropTypes.func.isRequired,
}

// ==================
// 唯一的模块导出
// ==================
function PatientAdvicePane(props) {
  const { patientRow, commonPage, commonRow, setCommonRow, setCommonPage,flag } = props
  // 加载
  const [isLoading, setIsLoading] = useState(false)

  // 诊疗医嘱列表
  const [prescriptions, setPrescriptions] = useState([])
  // 总记录数
  const [total, setTotal] = useState(0)


  // 模态框相关参数
  const [modal, setModal] = useState({
    type: undefined,
    data: {},
    isShow: false
  })

  /**
   * 初始化 table 列表所有字段
   */
  const initColumns = [
    {
      title: '序号',
      dataIndex: 'rid',
      align: 'center'
    },
    {
      title: '医嘱人',
      dataIndex: 'user',
      align: 'center',
      render:(text,{user})=><>{user.name}</>
    },
    {
      title: '治疗医嘱',
      dataIndex: 'prescription',
      align: 'center'
    },
    {
      title: '备注信息',
      dataIndex: 'remarks',
      align: 'center'
    },
    {
      title: '医嘱时间',
      dataIndex: 'createDate',
      width:150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作',
      dataIndex: '',
      align: 'center',
      render: (text, record) => (
        <Button type='link' onClick={() => showAdviceDetail('up', record)}>
          详情
        </Button>
      )
    }
  ]

  /**
   * api
   * 根据患者id分页查询诊疗医嘱
   */
  const getPrescriptionById = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqPrescriptionById(params)
      const result = resultList && mergeRid(resultList, params.pageNos, params.pageSizes)
      if (status) {
        setPrescriptions(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  //点击详情获取医嘱数据
  const showAdviceDetail = (type, data) => {
    setModal({ type, data, isShow: true })
  }
  
  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
   const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getPrescriptionById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize,flag])


  /**
   * 渲染
   */
  if (!Object.keys(patientRow).length) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  return (
    <>
      <Table
        bordered
        rowKey='rid'
        size='small'
        loading={isLoading}
        columns={initColumns}
        dataSource={prescriptions}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setCommonRow(record)
            }
          }
        }}
      />

      <IModal visible={modal.isShow} titleText='医嘱详情' width={800}>
        <UpdateAdviceForm
          commonRow={commonRow}
          ncd={modal.data}
          onShowModal={() => setModal({ ...modal, isShow: false })}
          refresh={() =>
            getPrescriptionById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
          }
        />
      </IModal>
    </>
  )
}

export default PatientAdvicePane

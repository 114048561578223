/* 血糖饼图 */

// ==================
// 调用模块
// ==================
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

// ==================
// 功能模块
// ==================
import { reqSummaryById } from '../../../api/index'

// ==================
// 唯一的模块导出
// ==================
function BSPie(props) {
  const { patientRow, condition } = props

  const [chartDom, setChartDom] = useState('')

  //血糖均值图/血糖漂移度图(共用)
  const [summaryById, setSummaryById] = useState([])

  /**
   * 血糖均值图
   * @param {*} params
   */
  const getSummaryById = async (params) => {
    const { data, status } = await reqSummaryById(params)
    if (status) {
      setSummaryById(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, summaryById) => {
    if (chartDom !== '' && summaryById.length > 0) {
      const option = {
        title: {
          text: '血糖控制图',
          left: 'center'
        },
        //下载标识
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data:summaryById,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      option && chartDom.setOption(option, true)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getSummaryById({ patientId: patientRow.id, ...condition })
  }, [patientRow.id, condition])

  useEffect(() => {
    const node = document.getElementById('pie')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, summaryById)

    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, summaryById])

  return <div id='pie' style={{ height: 400 }}></div>
}

export default BSPie

/*血凝列表详情 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Divider, Button, Col, Row, Spin } from 'antd'
import { TagsOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodClotByPatientId } from '../../../api/index'

/* 使用类型检查
---------------------------------------------------------------- */
AddDetailsForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddDetailsForm(props) {
  
  const { patientRow, onCancel } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  const [bloodClotByPatientId, setBloodClotByPatientId] = useState([])

  /**
   * 根据患者id统计血糖报表详情
   * @param {*} params
   */
  const getBloodClotByPatientId = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqBloodClotByPatientId(params)
      if (status) {
        const result = data
        setBloodClotByPatientId(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodClotByPatientId({ id: patientRow.id })
  }, [patientRow.id])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <TagsOutlined className='g_mr_10' />
          详情
        </h3>
      }
    >
      <Spin spinning={isLoading}>
        <div className='g_ml_40'>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
              &nbsp;
            </Col>
            <Col className='g_txt_c' span={3}>
              总值
            </Col>
            <Col className='g_txt_c' span={3}>
              血浆凝血酶原时间
            </Col>
            <Col className='g_txt_c' span={3}>
              活化部分凝血活酶时间
            </Col>
            <Col className='g_txt_c' span={3}>
              凝血酶时间
            </Col>
            <Col className='g_txt_c' span={3}>
              纤维蛋白原
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
              测量次数
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].num : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].num : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].num : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].num : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].num : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
            血凝平均值
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].average : ''}
            </Col>

            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].average : ''}
            </Col>

            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].average : ''}
            </Col>

            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].average : ''}
            </Col>

            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].average : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
            血凝最大值
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].max : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].max : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].max : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].max : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].max : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
            血凝最小值
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].min : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].min : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].min : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].min : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].min : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
              理想频率
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].theIdealFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
              过高频率
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].highFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={3}>
              过低频率
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[4].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[0].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[1].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[2].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={3}>
              {bloodClotByPatientId.length > 0 ? bloodClotByPatientId[3].underFrequency : ''}
            </Col>
          </Row>
        </div>

        <Divider plain className='g_mb_30' />
        <div className='g_txt_c'>
          <Button className='g_mr_20' onClick={onCancel}>
            关闭
          </Button>
        </div>
      </Spin>
    </Card>
  )
}

export default AddDetailsForm

/* 预警设置 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import { Card, Form, Row, Col, Input, Button, Divider, message } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined, BulbOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqFleshReportWarning,reqFleshReportWarningById } from '../../../api/index'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form

/* 使用类型检查
---------------------------------------------------------------- */
AddWarningForm.prototype = {
  onShow: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddWarningForm(props) {
  const { onCancel,onShow } = props

  const [fleshReportWarningById, setFleshReportWarningById] = useState({})

  // 提交表单
  const [form] = Form.useForm()

  /**
   * 对象解构
   */
   const { bloodGlucoseHighMap, bloodGlucoseUnderMap,id } = fleshReportWarningById

    /**
   * 将属性名替换成不一样的，然后进行一一对应回显
   * @param {*} bloodGlucoseHighMap
   * @param {*} bloodGlucoseUnderMap
   * @returns
   */
  const filterTimePart = (bloodGlucoseHighMap, bloodGlucoseUnderMap) => {
    let bhName = [
      { id: 1, name: 'warningHigh1' },
      { id: 2, name: 'warningHigh2' },
      { id: 3, name: 'warningHigh3' },
      { id: 4, name: 'warningHigh4' },
      { id: 5, name: 'warningHigh5' },
      { id: 6, name: 'warningHigh6' },
      { id: 7, name: 'warningHigh7' },
      { id: 8, name: 'warningHigh8' },
      { id: 9, name: 'warningHigh9' },
      { id: 10, name: 'warningHigh10' },
      { id: 11, name: 'warningHigh11' },
      { id: 12, name: 'warningHigh12' },
      { id: 13, name: 'warningHigh13' },
      { id: 14, name: 'warningHigh14' },
      { id: 15, name: 'warningHigh15' },
      { id: 16, name: 'warningHigh16' },
    ]

    let blName = [
      { id: 1, name: 'warningLow1' },
      { id: 2, name: 'warningLow2' },
      { id: 3, name: 'warningLow3' },
      { id: 4, name: 'warningLow4' },
      { id: 5, name: 'warningLow5' },
      { id: 6, name: 'warningLow6' },
      { id: 7, name: 'warningLow7' },
      { id: 8, name: 'warningLow8' },
      { id: 9, name: 'warningLow9' },
      { id: 10, name: 'warningLow10' },
      { id: 11, name: 'warningLow11' },
      { id: 12, name: 'warningLow12' },
      { id: 13, name: 'warningLow13' },
      { id: 14, name: 'warningLow14' },
      { id: 15, name: 'warningLow15' },
      { id: 16, name: 'warningLow16' },
    ]
    let obj = {}
    for (const key in bloodGlucoseHighMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseHighMap, key)) {
        let resH = bhName.find((v) => v.id === parseInt(key))
        obj = { [resH.name]: bloodGlucoseHighMap[key], ...obj }
      }
    }
    for (const key in bloodGlucoseUnderMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseUnderMap, key)) {
        let resL = blName.find((v) => v.id === parseInt(key))
        obj = { [resL.name]: bloodGlucoseUnderMap[key], ...obj }
      }
    }

    return obj
  }

  /**
   * 预警设置数据回显
   * @param {*} params 
   */
   const getFleshReportWarningById = async (params) => {
     const { data, status } = await reqFleshReportWarningById(params)
     if (status) {
       setFleshReportWarningById(data)
     }
   }

  /**
   * 血糖预警录入
   */
  const onOkWarning = async (value) => {
    const {
      detectionTime,
      warningLow1,
      warningLow2,
      warningLow3,
      warningLow4,
      warningLow5,
      warningLow6,
      warningLow7,
      warningLow8,
      warningLow9,
      warningLow10,
      warningLow11,
      warningLow12,
      warningLow13,
      warningLow14,
      warningLow15,
      warningLow16,
      warningHigh1,
      warningHigh2,
      warningHigh3,
      warningHigh4,
      warningHigh5,
      warningHigh6,
      warningHigh7,
      warningHigh8,
      warningHigh9,
      warningHigh10,
      warningHigh11,
      warningHigh12,
      warningHigh13,
      warningHigh14,
      warningHigh15,
      warningHigh16
    } = value
    const { status } = await reqFleshReportWarning({
      ...value,
      type:1,
      id:id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss'),
      fleshUnderResult: {
        1: parseFloat(warningLow1),
        2: parseFloat(warningLow2),
        3: parseFloat(warningLow3),
        4: parseFloat(warningLow4),
        5: parseFloat(warningLow5),
        6: parseFloat(warningLow6),
        7: parseFloat(warningLow7),
        8: parseFloat(warningLow8),
        9: parseFloat(warningLow9),
        10: parseFloat(warningLow10),
        11: parseFloat(warningLow11),
        12: parseFloat(warningLow12),
        13: parseFloat(warningLow13),
        14: parseFloat(warningLow14),
        15: parseFloat(warningLow15),
        16: parseFloat(warningLow16)
      },
      fleshHighResult: {
        1: parseFloat(warningHigh1),
        2: parseFloat(warningHigh2),
        3: parseFloat(warningHigh3),
        4: parseFloat(warningHigh4),
        5: parseFloat(warningHigh5),
        6: parseFloat(warningHigh6),
        7: parseFloat(warningHigh7),
        8: parseFloat(warningHigh8),
        9: parseFloat(warningHigh9),
        10: parseFloat(warningHigh10),
        11: parseFloat(warningHigh11),
        12: parseFloat(warningHigh12),
        13: parseFloat(warningHigh13),
        14: parseFloat(warningHigh14),
        15: parseFloat(warningHigh15),
        16: parseFloat(warningHigh16)
      }
    })
    if (status) {
      message.success('血气预警设置成功！')
      form.resetFields()
      onShow()
    }
  }

  /**
   * 生命周期
   */
  useEffect(()=>{
    getFleshReportWarningById({type:1})
  },[])
  useEffect(() => {
    form.setFieldsValue(filterTimePart(bloodGlucoseHighMap, bloodGlucoseUnderMap))
  }, [form, bloodGlucoseHighMap, bloodGlucoseUnderMap])

    /**
   * 渲染
   */
     return (
      <Card
        size='small'
        title={
          <h3 className='margin-clear'>
            <UserOutlined className='g_mr_10' />
            预警设置
          </h3>
        }
      >
        <div className='g_mb_10 g_color_gray'>
          <BulbOutlined className='g_mr_10' />
          说明：左边输入值为低值，右边输入值为高值
        </div>
        <Form form={form} onFinish={onOkWarning}>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow1'
                label={<span className='g_w_98'>二氧化碳分压</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh1' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow2'
                label={<span className='g_w_98'>标准重碳酸</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh2' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow3'
                label={<span className='g_w_98'>酸碱度</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh3' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow4'
                label={<span className='g_w_98'>碳酸氢根浓度</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh4' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow5'
                label={<span className='g_w_98'>氧分压</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh5' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow6'
                label={<span className='g_w_98'>二氧化碳总量</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh6' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow7'
                label={<span className='g_w_98'>细胞比积</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh7' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow8'
                label={<span className='g_w_98'>氧饱和度</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh8' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow9'
                label={<span className='g_w_98'>钠</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh9' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow10'
                label={<span className='g_w_98'>血红蛋白</span>}

                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh10' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow11'
                label={<span className='g_w_98'>钾</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh11' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow12'
                label={<span className='g_w_98'>细胞外液剩余碱</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh12' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow13'
                label={<span className='g_w_98'>氯</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh13' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow14'
                label={<span className='g_w_98'>肺泡内氧分压</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh14' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow15'
                label={<span className='g_w_98'>离子钙</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh15' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Row>
            <Col offset={2}>
              <Item
                name='warningLow16'
                label={<span className='g_w_98'>全血碱剩余</span>}
                rules={[
                  {
                    required: true,
                    pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                    message: '请输入(1-10位，后可跟两位小数)低值'
                  }
                ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='g_txt_c g_pt_4' span={1}>
              ~
            </Col>
            <Col>
              <Item name='warningHigh16' 
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
              >
                <Input className='g_w_174' />
              </Item>
            </Col>
            <Col className='unit-1' span={2}>
              ng/L
            </Col>
          </Row>
          <Divider plain />
          <div className='g_txt_c'>
            <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
              关闭
            </Button>
            <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
              保存
            </Button>
          </div>
        </Form>
      </Card>
    )
}

export default AddWarningForm

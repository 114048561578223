/* 质控报表导出 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Form, DatePicker, Button, message } from 'antd'
import moment from 'moment'
// import axios from 'axios'

/* 功能模块
---------------------------------------------------------------- */
// import { reqExportQc } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCReportExportForm(props) {
  const {
    modal: { controlId },
    setModal
  } = props

  const [form] = Form.useForm()

  /**
   * 确定
   * @param {*} value
   */
  const onOk = async (value) => {
    let params = {
      date: moment(value.date).format('YYYY-MM-DD HH:mm:ss'),
      qualityControlId: controlId
    }
    // const res = await reqExportQc(params)
    // const { headers, data } = res
    // console.log(res)
    // const fileName = headers['content-disposition'].split('=')[1]
    // let blob = new Blob(
    //   [data],
    //   { type: headers['content-type'] }
    //   // word文档为application/msword,pdf文档为application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8
    // )
    let aElement = document.createElement('a')
    document.body.appendChild(aElement)
    aElement.style.display = 'none'
    aElement.href = `/v1/westgard/downloadExcel?qualityControlId=${params.qualityControlId}&date=${params.date}`
    // aElement.download = fileName
    aElement.click()
    // document.body.removeChild(aElement)
    window.URL.revokeObjectURL(aElement.href)
    message.success('质控报表导出成功！')
    setModal()
  }
  // const onOk = (value) => {
  //   let params = {
  //     date: moment(value.date).format('YYYY-MM-DD HH:mm:ss'),
  //     qualityControlId: controlId
  //   }
  //   axios({ method: 'get', url: '/v1/westgard/downloadExcel', params, responseType: 'arraybuffer' }).then((res) => {
  //     const { headers, data } = res
  //     console.log(res)
  //     const fileName = headers['content-disposition'].split('=')[1]
  //     let blob = new Blob(
  //       [data],
  //       { type: headers['content-type'] }
  //       // word文档为application/msword,pdf文档为application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8
  //     )
  //     let aElement = document.createElement('a')
  //     document.body.appendChild(aElement)
  //     aElement.style.display = 'none'
  //     aElement.href = window.URL.createObjectURL(blob)
  //     aElement.download = fileName
  //     aElement.click()
  //     // document.body.removeChild(aElement)
  //     window.URL.revokeObjectURL(aElement.href)
  //     message.success('质控报表导出成功！')
  //     setModal()
  //   })
  // }

  /**
   * 取消
   */
  const onCancel = () => {
    setModal()
  }

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOk}>
      <Item name='date' label='时间'>
        <DatePicker picker='month' className='g_w_percent' />
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default QCReportExportForm

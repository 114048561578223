/* 质控报表导出 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, DatePicker, Button, message, Select } from 'antd'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstitutionTrees } from '../../../api/index'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 唯一的模块导出
---------------------------------------------------------------- */
function WorkDownLoad(props) {
  const {
    setModal
  } = props

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  const [form] = Form.useForm()

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutionTrees()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    setCompanyId(value)
    form.setFieldsValue({ officeId: undefined })
  }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId)
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 确定
   * @param {*} value
   */
  const onOk = async (value) => {
    let params = {
      ...value,
      date: moment(value.date).format('YYYY-MM-DD HH:mm:ss'),
      companyId
    }
    let aElement = document.createElement('a')
    document.body.appendChild(aElement)
    aElement.style.display = 'none'
    aElement.href=`/v1/workload/downloadWorkloadList?companyId=${params.companyId}&officeId=${params.officeId}&date=${params.date}`
    aElement.click()
    window.URL.revokeObjectURL(aElement.href)
    message.success('工作量报表导出成功')
    form.resetFields()
    setModal()
  }

  /**
   * 取消
   */
  const onCancel = () => {
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()
  }, [])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOk}>
      <Item name='companyId' label='医院' rules={[{ required: true, message: '请选择医院' }]}>
        <Select style={{ width: 174 }} onChange={onChangeCompany} className='width-percent'>
          {institutions.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item name='officeId' label='科室' rules={[{ required: true, message: '请选择科室' }]}>
        <Select style={{ width: 174 }} className='width-percent'>
          {companyId && showOffices(companyId)}
        </Select>
      </Item>
      <Item name='date' label='时间' rules={[{ required: true, message: '请选择时间' }]}>
        <DatePicker picker='month' className='width-percent' />
      </Item>

      <div className='text-center'>
        <Button className='g_mb_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default WorkDownLoad

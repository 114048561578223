/* 测量血糖次数图 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucoseCounts } from '../../../api/index'

/* 唯一的模块导出
---------------------------------------------------------------- */
function BSControl(props) {
  const { condition } = props

  const [chartDom, setChartDom] = useState('')

  //获取每日检测次数图
  const [bloodGlucoseCounts, setBloodGlucoseCounts] = useState([])

  /**
   * api
   * 获取每日检测次数图
   * @param {*} params
   */
  const getBloodGlucoseCounts = async (params) => {
    const { data, status } = await reqBloodGlucoseCounts(params)
    if (status) {
      setBloodGlucoseCounts(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, bloodGlucoseCounts) => {
    if (chartDom !== '') {
      //遍历获取次数值
      var num = []
      var data = []
      for (const i in bloodGlucoseCounts) {
        num.push(bloodGlucoseCounts[i].num)
        data.push(moment(bloodGlucoseCounts[i].count).format('YYYY-MM-DD'))
      }
      let option
      option = {
        title: {
          text: '测量血糖次数图',
          left: 'center',
          top: 20
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: data
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: num,
            name: '次数',
            type: 'bar'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
      }

      option && chartDom.setOption(option)
    }
  }

  /**
   * 生命周期
   */

  useEffect(() => {
    getBloodGlucoseCounts({ ...condition })
  }, [condition])

  useEffect(() => {
    const node = document.getElementById('average')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, bloodGlucoseCounts)
    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, bloodGlucoseCounts])

  return <div id='average' style={{ height: 400 }}></div>
}

export default BSControl

/* 会诊进度 */


/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Card, Divider, Row, Col, Button, Timeline } from 'antd'
import { ProjectOutlined, RollbackOutlined } from '@ant-design/icons'
import Reason from './Reason'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { CONSULTATION } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Timeline

/* 使用类型检查
---------------------------------------------------------------- */
Progress.propTypes = {
  onClose: PropTypes.func.isRequired,
  consultation: PropTypes.object.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function Progress(props) {
  const { onClose, consultation } = props

  /**
   * 遍历显示会诊进度时间轴
   * @param {*} params
   */
  const showTimeLine = (params) => {
    if (params && params.length > 0) {
      return params.map((v) => (
        <Item key={v.id} label={v.office.name} 
        color={ 
          v.consultationStatus === 1 ? 'blue'
          :v.consultationStatus ===2 ? 'red' : 'green'
         }>
          <p className='margin-clear'>{CONSULTATION[v.consultationStatus-1].name}</p>
          <p className='margin-clear'>{v.updateDate ?  moment(v.updateDate).format('YYYY-MM-DD HH:mm:ss'): '你时间没给我'}</p>
        </Item>
      ))
    }
  }

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      // className={ consultation.office && consultation.office.length === 1 ? 'height-percent' : 'height-percents' }
      size='small'
      title={
        <h3 className='margin-clear'>
          <ProjectOutlined className='g_mr_10' />
          会诊进度
        </h3>
      }
      extra={
        <Button className='g_ml_10' onClick={onClose} icon={<RollbackOutlined />}>
          关闭
        </Button>
      }
    >
      <Divider orientation='left' plain>
        基本信息
      </Divider>
      <Row>
        <Col span={20} offset={2}>
          <Reason consultation={consultation} />
        </Col>
      </Row>
      <Divider orientation='left' plain>
        详细信息
      </Divider>
      <Timeline mode='left'>{showTimeLine(consultation.office)}</Timeline>
    </Card>
  )
}

export default Progress

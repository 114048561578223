//应诊列表 | 患者查询 里面的血糖医嘱

/* 胰岛素治疗 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import {  Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { mergeRid } from '../../utils/func'
import {reqInsulinById } from '../../api/index'

/* 静态资源
---------------------------------------------------------------- */
import { INSULINMEDICATIONITEM, INSULIN } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
PatientInsulin.propTypes = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientInsulin(props) {
  const {patientRow, commonPage, setCommonPage, } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总条数
  const [total, setTotal] = useState(0)

  // 根据患者id分页查询胰岛素数据表
  const [insulinById, setInsulinById] = useState([])

  /**
   * 初始化生成 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '药品',
      dataIndex: 'drug',
      width: '',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'insulinType',
      width: '',
      align: 'center',
      render: (text, { insulinType }) => <>{insulinType && INSULINMEDICATIONITEM[insulinType - 1].name}</>
    },
    {
      title: '时间段',
      dataIndex: 'item',
      width: '',
      align: 'center',
      render: (text, { item }) => <>{item && INSULIN[item - 1].name}</>
    },
    {
      title: '剂量',
      dataIndex: 'value',
      width: '',
      align: 'center'
    },
    {
      title: '用药日期',
      dataIndex: 'medicationTime',
      width: 160,
      align: 'center',
      render: (text, { medicationTime }) => moment(medicationTime).format('YYY-MM-DD HH:mm:ss')
    },
    {
      title: '录入日期',
      dataIndex: 'createDate',
      width: 160,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYY-MM-DD HH:mm:ss')
    },
    {
      title: '录入者',
      dataIndex: 'user',
      width: '',
      align: 'center',
      render: (text, { user }) => <div>{user.name}</div>
    }
  ]

  /**
   * 根据患者id分页查询胰岛素数据表
   * @param {*} patientId
   */
  const getInsulinById = async (params) => {
    setIsLoading(true)
    try {
      const {
        data: { resultList, totalRows } = {},
        status
      } = await reqInsulinById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setInsulinById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 生命周期
   */

  useEffect(() => {
    getInsulinById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  /**
   * 渲染
   */
  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        scroll={{ x: 1400, y: 400 }}
        loading={isLoading}
        columns={initColumns}
        dataSource={insulinById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
      />
    </>
  )
}

export default PatientInsulin

//应诊列表 | 患者查询 里面的血气记录表

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqReagents, reqReportByPatientId } from '../../api/index'
import { mergeRid } from '../../utils/func'

/* 调用模块
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
PatientBG.prototype = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientBG(props) {
  const { patientRow, commonPage, setCommonPage } = props

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 血气记录表
  const [gasRecords, setGasRecords] = useState([])

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  /**
   * 初始化生成 table 所有列
   */
   const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '检测时间',
      dataIndex: 'detectionTime',
      width: 180,
      align: 'center',
      render: (text, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '二氧化碳分压',
      dataIndex: 'item1',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 1)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[0] ? fleshReportItems[0].value : ''}</>
    },
    {
      title: '标准重碳酸',
      dataIndex: 'item2',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 2)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[1] ? fleshReportItems[1].value : ''}</>
    },
    {
      title: '酸碱度',
      dataIndex: 'item3',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 3)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[2] ? fleshReportItems[2].value : ''}</>
    },
    {
      title: '碳酸氢根浓度',
      dataIndex: 'item4',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 4)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[3] ? fleshReportItems[3].value : ''}</>
    },

    {
      title: '氧分压',
      dataIndex: 'item5',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 5)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[4] ? fleshReportItems[4].value : ''}</>
    },
    {
      title: '二氧化碳总量',
      dataIndex: 'item6',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 6)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[5] ? fleshReportItems[5].value : ''}</>
    },
    {
      title: '细胞比积',
      dataIndex: 'item7',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 7)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[6] ? fleshReportItems[6].value : ''}</>
    },

    {
      title: '氧饱和度',
      dataIndex: 'item8',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 8)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[7] ? fleshReportItems[7].value : ''}</>
    },
    {
      title: '钠',
      dataIndex: 'item9',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 9)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[8] ? fleshReportItems[8].value : ''}</>
    },

    {
      title: '血红蛋白',
      dataIndex: 'item10',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 10)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[9] ? fleshReportItems[9].value : ''}</>
    },
    {
      title: '钾',
      dataIndex: 'item11',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 11)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[10] ? fleshReportItems[10].value : ''}</>
    },
    {
      title: '细胞外液剩余碱',
      dataIndex: 'item12',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 12)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[11] ? fleshReportItems[11].value : ''}</>
    },
    {
      title: '氯',
      dataIndex: 'item13',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 13)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[12] ? fleshReportItems[12].value : ''}</>
    },
    {
      title: '肺泡内氧分压',
      dataIndex: 'item14',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 14)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[13] ? fleshReportItems[13].value : ''}</>
    },
    {
      title: '离子钙',
      dataIndex: 'item15',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 15)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[14] ? fleshReportItems[14].value : ''}</>
    },
    {
      title: '全血碱剩余',
      dataIndex: 'item16',
      width: '',
      align: 'center',
      render: (_, { fleshReportItems }) => {
        const result = fleshReportItems.filter((v) => v.item === 16)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.value}</span>
          })
        }
      }
      // render: (text, { fleshReportItems }) => <>{fleshReportItems[15] ? fleshReportItems[15].value : ''}</>
    },
    {
      title: '试纸批号',
      dataIndex: 'batchNumber',
      width: '',
      align: 'center',
      // render: (text, { batchNumber }) => <>{reagents.length && reagents[batchNumber - 1].batchNumber}</>
      render: (_, { batchNumber }) => {
        const result = reagents.filter((v) => v.id === batchNumber)
        if (result.length) {
          return result.map((v) => {
            return <span key={v.id}>{v.batchNumber}</span>
          })
        }
      }
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (_, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作者',
      dataIndex: 'user',
      align: 'center',
      render: (_, { user }) => <>{user.name}</>
    }
  ]

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    if (status) {
      setReagents(data.resultList)
    }
  }

  /**
   * api
   * 根据患者 id 查询血气记录表
   */
  const getGasRecords = async (params) => {
    setIsLoading(true)
    if (params) {
      try {
        const {
          data: { resultList, totalRows } = {},
          status
        } = await reqReportByPatientId(params)
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        if (status) {
          setGasRecords(result)
          setTotal(totalRows)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX })
  }, [])

  useEffect(() => {
    getGasRecords({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  /**
   * 渲染
   */
  return (
    <Table
      bordered
      size='small'
      rowKey='rid'
      loading={isLoading}
      columns={initColumns}
      dataSource={gasRecords}
      scroll={{ x: 1600, y: 320 }}
      pagination={{
        current: commonPage.pageNo,
        pageSize: commonPage.pageSize,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        showQuickJumper: true,
        showSizeChanger: false,
        onChange: onChangePage
      }}
    />
  )
}

export default PatientBG

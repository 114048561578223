/* 预警设置 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button, Row, Col, Divider, message } from 'antd'
import { UserOutlined, BulbOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddWarningById, reqAddWarning } from '../../../api/index'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form

/* 使用类型检查
---------------------------------------------------------------- */
AddWarningForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddWarningForm(props) {
  const { patientRow, onCancel, setShow } = props

  const [warningById, setAddWarningById] = useState({})

  /**
   * 对象解构
   */
  const { bloodGlucoseHighMap, bloodGlucoseUnderMap, id } = warningById

  /**
   * 将属性名替换成不一样的，然后进行一一对应回显
   * @param {*} bloodGlucoseHighMap
   * @param {*} bloodGlucoseUnderMap
   * @returns
   */
  const filterTimePart = (bloodGlucoseHighMap, bloodGlucoseUnderMap) => {
    let bhName = [
      { id: 1, name: 'earlyMorningH' },
      { id: 8, name: 'beforeSheepH' },
      { id: 9, name: 'randomH' },
      { id: 11, name: 'beforeMealsH' },
      { id: 12, name: 'afterMealsH' }
    ]

    let blName = [
      { id: 1, name: 'earlyMorningL' },
      { id: 8, name: 'beforeSheepL' },
      { id: 9, name: 'randomL' },
      { id: 11, name: 'beforeMealsL' },
      { id: 12, name: 'afterMealsL' }
    ]
    let obj = {}
    for (const key in bloodGlucoseHighMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseHighMap, key)) {
        let resH = bhName.find((v) => v.id === parseInt(key))
        obj = { [resH.name]: bloodGlucoseHighMap[key], ...obj }
      }
    }
    for (const key in bloodGlucoseUnderMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseUnderMap, key)) {
        let resL = blName.find((v) => v.id === parseInt(key))
        obj = { [resL.name]: bloodGlucoseUnderMap[key], ...obj }
      }
    }

    return obj
  }

  // 提交表单
  const [form] = Form.useForm()

  /**
   * api
   * 通过id获取患者信息
   * @param {*} id
   */
  const getAddWarningById = async (params) => {
    const { data, status } = await reqAddWarningById(params)
    if (status) {
      setAddWarningById(data)
    }
  }

  /**
   * 血糖预警录入
   */
  const onOkWarning = async (value) => {
    const {
      detectionTime,
      afterMealsH,
      afterMealsL,
      beforeMealsH,
      beforeMealsL,
      beforeSheepH,
      beforeSheepL,
      earlyMorningH,
      earlyMorningL,
      randomH,
      randomL
    } = value
    const { status } = await reqAddWarning({
      ...value,
      patientId: patientRow.id,
      id: id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss'),
      bloodUnderResult: {
        1: parseFloat(afterMealsL),
        11: parseFloat(beforeMealsL),
        12: parseFloat(beforeSheepL),
        8: parseFloat(earlyMorningL),
        9: parseFloat(randomL)
      },
      bloodHighResult: {
        1: parseFloat(afterMealsH),
        11: parseFloat(beforeMealsH),
        12: parseFloat(beforeSheepH),
        8: parseFloat(earlyMorningH),
        9: parseFloat(randomH)
      }
    })
    if (status) {
      message.success('血糖预警录入成功！')
      form.resetFields()
      setShow()
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getAddWarningById({ patientId: patientRow.id })
  }, [patientRow.id])

  useEffect(() => {
    form.setFieldsValue(filterTimePart(bloodGlucoseHighMap, bloodGlucoseUnderMap))
  }, [form, bloodGlucoseHighMap, bloodGlucoseUnderMap])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          预警设置
        </h3>
      }
    >
      <div className='g_mb_10 g_color_gray'>
        <BulbOutlined className='g_mr_10' />
        说明：左边输入值为低值，右边输入值为高值
      </div>
      <Form form={form} onFinish={onOkWarning}>
        <Row>
          <Col offset={2}>
            <Item
              name='earlyMorningL'
              label={<span className='g_w_85'>凌晨</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='earlyMorningH'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='beforeMealsL'
              label={<span className='g_w_85'>餐前</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='beforeMealsH'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='afterMealsL'
              label={<span className='g_w_85'>餐后</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='afterMealsH'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='beforeSheepL'
              label={<span className='g_w_85'>睡前</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='beforeSheepH'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='randomL'
              label={<span className='g_w_85'>随机</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='randomH'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            mmol/L
          </Col>
        </Row>

        <Divider plain />

        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default AddWarningForm

/* 性别比例 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import './GenderRatio.less'

/* 使用类型检查
---------------------------------------------------------------- */
GenderRatio.prototype = {
  data: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function GenderRatio(props) {
  const {
    data: { man, woman }
  } = props

  /**
   * 渲染
   */
  return (
    <div className='g_fx bi_genderratio'>
      <div style={{ width: man ? man : '100%', backgroundColor: '#37ADD3' }} className='bi_genderratio_label'>
        男&nbsp;{man ? parseInt(man) : 0}%
      </div>
      <div style={{ width: woman ? woman : '100%', backgroundColor: '#DDAD61' }} className='bi_genderratio_label'>
        女&nbsp;{woman ? parseInt(woman) : 0}%
      </div>
    </div>
  )
}

export default GenderRatio

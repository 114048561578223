/* 血糖记录表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { mergeRid } from '../../utils/func'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqQatientBloodById } from '../../api'

/* 静态资源
---------------------------------------------------------------- */
import { BS_ADVICE, WARNING } from '../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
BSRecordPane.propTypes = {
  patientRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BSRecordPane(props) {
  const { patientRow, commonPage, setCommonPage } = props

  // 总记录数
  const [total, setTotal] = useState(0)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 血糖记录表
  const [blood, setBlood] = useState([])

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '检测时间',
      dataIndex: 'detectionTime',
      width: 150,
      align: 'center',
      render: (text, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '血糖值',
      dataIndex: 'value',
      align: 'center'
    },
    {
      title: '预警',
      dataIndex: 'warning',
      align: 'center',
      render: (text, { warning }) =>
        // warning === 1? <></> : warning === 2 ? <></> :<></>
        warning === 1 ? (
          <span>{warning && WARNING[warning - 1].name}</span>
        ) : warning === 2 ? (
          <span className='g_warning_red'>{warning && WARNING[warning - 1].name}</span>
        ) : (
          <span className='g_warning_blue'>{warning && WARNING[warning - 1].name}</span>
        )
    },
    {
      title: '时间段',
      dataIndex: 'item',
      align: 'center',
      render: (text, { item }) => <>{item && BS_ADVICE[item - 1].label}</>
    },
    {
      title: '试纸批号',
      dataIndex: 'batchNumber',
      align: 'center'
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作人',
      dataIndex: 'user',
      align: 'center'
    }
  ]

  /**
   * 根据患者id分页查询血糖记录表数据
   * @param {*} patientId
   */
  const getBloodById = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqQatientBloodById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setBlood(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize])

  return (
    <Table
      bordered
      size='small'
      rowKey='rid'
      scroll={{ x: 800, y: 500 }}
      loading={isLoading}
      columns={initColumns}
      dataSource={blood}
      pagination={{
        current: commonPage.pageNo,
        pageSize: commonPage.pageSize,
        showQuickJumper: true,
        showSizeChanger: false,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        onChange: onChangePage
      }}
    />
  )
}

export default BSRecordPane

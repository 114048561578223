/* 编辑质控 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect,useState } from 'react'
import { Form, Button, Input, DatePicker, Row, Col, Select, message, Modal } from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditQc,reqfindNurseListByLoginUser } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { IS_ACCEPT } from '../../../utils/constants'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QCForm.propTypes = {
  projectRow: PropTypes.object,
  flag: PropTypes.bool.isRequired,
  operation: PropTypes.object,
  setOperation: PropTypes.func.isRequired,
  refreshQcs: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCForm(props) {
  const { projectRow, flag, operation, setOperation, refreshQcs } = props

 //护士列表
 const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])


  const [form] = Form.useForm()

  const {
    controlId: qualityControlId,
    controlThings: { levelNum }
  } = projectRow

  /**
   * 录入 / 编辑质控
   * 确定
   * @param {*} value
   */
  const onOkQc = async (value) => {
    const { level1Value, level2Value, level3Value, level1Status, level2Status, level3Status, createDate,userId } = value
    if (level1Value || level2Value || level3Value) {
      let msg // 提示信息
      // 表单信息
      let params = {
        createDate: moment(createDate).format('YYYY-MM-DD HH:mm:ss'),
        qualityControlId,
        userId
      }
      if (levelNum >= 1) {
        params.level1Value = parseFloat(level1Value)
        params.level1Status = level1Status
      }
      if (levelNum >= 2) {
        params.level2Value = parseFloat(level2Value)
        params.level2Status = level2Status
      }
      if (levelNum >= 3) {
        params.level3Value = parseFloat(level3Value)
        params.level3Status = level3Status
      }

      if (operation.action === 'upQc') {
        msg = '编辑质控成功！'
        params = {
          ...params,
          id: operation.data.id,
          userId
        }
      } else {
        msg = '录入质控成功！'
      }
      const { status } = await reqAddOrEditQc(params)
      if (status) {
        message.success(msg)
        setOperation()
        refreshQcs(!flag)
      }
    } else {
      Modal.confirm({
        centered: true,
        title: '提示',
        icon: <ExclamationCircleOutlined />,
        content: '请至少选择 1 个水平！',
        okText: '确认',
        cancelText: '取消',
      });
    }
  }

  /**
   * 录入 / 编辑质控
   * 取消
   */
  const onCancel = () => {
    setOperation()
  }

  /**
 * api
 * 获取护士列表
 * @param {*} value 
 */
   const getNurseListByLoginUser = async () => {
    const { data, status } = await reqfindNurseListByLoginUser()
    if (status) {
      setNurseListByLoginUser(data)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (operation.action === 'upQc') {
      form.setFieldsValue({ ...operation.data, createDate: moment(operation.data.createDate) })
    } else {
      form.resetFields()
      let params = {}
      if (levelNum >= 1) {
        params.level1Status = true
      }
      if (levelNum >= 2) {
        params.level2Status = true
      }
      if (levelNum >= 3) {
        params.level3Status = true
      }
      form.setFieldsValue(params)
    }
  }, [operation.action, form, operation.data, levelNum])


  useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])
  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkQc}>
      {levelNum >= 1 && (
        <Row gutter={10}>
          <Col span={12}>
            <Item
              name='level1Value'
              label={<span className='g_w_56'>水平1</span>}
              rules={[{ pattern: /^([-]|[+])?\d+(\.\d{2})?$/, message: '请输入数字(包括小数点后两位)' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name='level1Status'
              label={<span className='g_w_56'>是否接受</span>}
              rules={[{ required: true, message: '请选择是否接受' }]}
            >
              <Select disabled={operation.action === 'addQc' ? true : false}>
                {IS_ACCEPT.map((v) => (
                  <Option key={v.id} value={v.type}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          
        </Row>
      )}

      {levelNum >= 2 && (
        <Row gutter={10}>
          <Col span={12}>
            <Item
              name='level2Value'
              label={<span className='g_w_56'>水平2</span>}
              rules={[{ pattern: /^([-]|[+])?\d+(\.\d{2})?$/, message: '请输入数字(包括小数点后两位)' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name='level2Status'
              label={<span className='g_w_56'>是否接受</span>}
              rules={[{ required: true, message: '请选择是否接受' }]}
            >
              <Select disabled={operation.action === 'addQc' ? true : false}>
                {IS_ACCEPT.map((v) => (
                  <Option key={v.id} value={v.type}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      )}

      {levelNum >= 3 && (
        <Row gutter={10}>
          <Col span={12}>
            <Item
              name='level3Value'
              label={<span className='g_w_56'>水平3</span>}
              rules={[{ pattern: /^([-]|[+])?\d+(\.\d{2})?$/, message: '请输入数字(包括小数点后两位)' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name='level3Status'
              label={<span className='g_w_56'>是否接受</span>}
              rules={[{ required: true, message: '请选择是否接受' }]}
            >
              <Select disabled={operation.action === 'addQc' ? true : false}>
                {IS_ACCEPT.map((v) => (
                  <Option key={v.id} value={v.type}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      )}

      <Row gutter={10}>
        <Col span={12}>
          <Item name='createDate' label={<span className='g_w_67'> 质控时间</span>}>
            <DatePicker className='g_w_percent' showTime />
          </Item>
        </Col>
        <Col span={12}>
            <Item
              name='userId'
              label={<span className='g_w_56'>操作人</span>}
              rules={[{ required: true, message: '请选择操作人' }]}
            >
              <Select >
                {nurseListByLoginUser.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
      </Row>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default QCForm

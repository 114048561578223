/* 胰岛素治疗 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { mergeRid } from '../../utils/func'
import { reqMedicationItemList, reqFindInsulinList, reqInsulinById } from '../../api/index'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../ui'

/* 静态资源
---------------------------------------------------------------- */
import { INSULINMEDICATIONITEM, INSULIN } from '../../utils/constants'

/* 调用子组件
---------------------------------------------------------------- */
import UpdateInsulinForm from '../../pages/Monitor/BloodSugar/UpdateInsulinForm'

/* 使用类型检查
---------------------------------------------------------------- */
BSInsulinPane.propTypes = {
  flag: PropTypes.bool,
  patientRow: PropTypes.object,
  commonRow: PropTypes.object,
  commonPage: PropTypes.object,
  setCommonPage: PropTypes.func.isRequired,
  setCommonRow: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BSInsulinPane(props) {
  const { flag, patientRow, commonRow, commonPage, setCommonPage, setCommonRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总条数
  const [total, setTotal] = useState(0)

  // 查询胰岛素具体用药时间 （早餐前、餐后。。。）枚举列表
  const [medicationItemList, setMedicationItemList] = useState([])

  // 查询胰岛素类型枚举列表
  const [findInsulinList, setFindInsulinList] = useState([])

  // 根据患者id分页查询胰岛素数据表
  const [insulinById, setInsulinById] = useState([])

  // 模态框相关参数
  const [modal, setModal] = useState({
    type: undefined,
    data: {},
    isShow: false
  })

  /**
   * api
   * 查询胰岛素具体用药时间 （早餐前、餐后。。。）枚举列表
   */
  const getMedicationItemList = async () => {
    const { data, status } = await reqMedicationItemList()
    if (status) {
      setMedicationItemList(data)
    }
  }

  /**
   * api
   * 查询胰岛素类型枚举列表
   */
  const getFindInsulinList = async () => {
    const { data, status } = await reqFindInsulinList()
    if (status) {
      setFindInsulinList(data)
    }
  }

  /**
   * 初始化生成 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '药品',
      dataIndex: 'drug',
      width: '',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'insulinType',
      width: '',
      align: 'center',
      render: (text, { insulinType }) => <>{insulinType && INSULINMEDICATIONITEM[insulinType - 1].name}</>
    },
    {
      title: '时间段',
      dataIndex: 'item',
      width: '',
      align: 'center',
      render: (text, { item }) => <>{item && INSULIN[item - 1].name}</>
    },
    {
      title: '剂量',
      dataIndex: 'value',
      width: '',
      align: 'center'
    },
    {
      title: '用药日期',
      dataIndex: 'medicationTime',
      width: 160,
      align: 'center',
      render: (text, { medicationTime }) => moment(medicationTime).format('YYY-MM-DD HH:mm:ss')
    },
    {
      title: '录入日期',
      dataIndex: 'createDate',
      width: 160,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYY-MM-DD HH:mm:ss')
    },
    {
      title: '录入者',
      dataIndex: 'user',
      width: '',
      align: 'center',
      render: (text, { user }) => <div>{user.name}</div>
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '',
      align: 'center',
      render: (text, record) => (
        <Tooltip title='编辑' mouseEnterDelay={0.5}>
          <Button type='link' onClick={() => onShowInsulin('up', record)} icon={<EditOutlined />}/>
        </Tooltip>
      )
    }
  ]

  /**
   * 根据患者id分页查询胰岛素数据表
   * @param {*} patientId
   */
  const getInsulinById = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqInsulinById(params)
      const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
      if (status) {
        setInsulinById(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 按钮
   * 点击显示胰岛素编辑弹框
   */
  const onShowInsulin = (type, data) => {
    setModal({ type, data, isShow: true })
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} commonPage
   */
  const onChangePage = (pageNo, pageSize) => {
    setCommonPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (commonRow) return record.id === commonRow.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMedicationItemList()
    getFindInsulinList()
  }, [])

  useEffect(() => {
    getInsulinById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
  }, [patientRow.id, commonPage.pageNo, commonPage.pageSize, flag])

  /**
   * 渲染
   */
  return (
    <>
      <Table
        bordered
        size='small'
        rowKey='rid'
        scroll={{ x: 1400, y: 400 }}
        loading={isLoading}
        columns={initColumns}
        dataSource={insulinById}
        pagination={{
          current: commonPage.pageNo,
          pageSize: commonPage.pageSize,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          showSizeChanger: false,
          onChange: onChangePage
        }}
        rowClassName={showRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              setCommonRow(record)
            }
          }
        }}
      />

      <IModal visible={modal.isShow} titleText='胰岛素编辑' width={800}>
        <UpdateInsulinForm
          patientRow={patientRow}
          commonRow={commonRow}
          ncd={modal.data}
          findInsulinList={findInsulinList}
          medicationItemList={medicationItemList}
          onShowModal={() => setModal({ ...modal, isShow: false })}
          refresh={() =>
            getInsulinById({ patientId: patientRow.id, pageNo: commonPage.pageNo, pageSize: commonPage.pageSize })
          }
        />
      </IModal>
    </>
  )
}

export default BSInsulinPane

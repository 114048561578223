/* 出院 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useRef, useState, useEffect } from 'react'
import { Card, Row, Col, Divider, Input, Button, Popconfirm, message } from 'antd'
import { RollbackOutlined, PrinterOutlined, FileDoneOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqPerspectiveByPatientId, reqSugarPerspective, reqPatientsStatus } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { BS_ADVICE } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { TextArea } = Input

/* 使用类型检查
---------------------------------------------------------------- */
DischargeForm.propTypes = {
  patient: PropTypes.object.isRequired,
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function DischargeForm(props) {
  const { patientRow, patient, onCancel, refresh } = props

  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const [visible, setVisible] = React.useState(false)

  // 出院血糖
  const [perspectiveByPatient, setPerspectiveByPatient] = useState({})

  // 入院血糖
  const [sugarPerspective, setSugarPerspective] = useState({})

  const printRef = useRef()

  const onPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: '上海交通大学附属第六人民医院POC血糖监测报告',
    bodyClass: 'print'
  })

  /**
   * api
   * 通过患者id获取出院血糖
   * @param {*} id
   */
  const getPerspectiveByPatient = async (params) => {
    const { data, status } = await reqPerspectiveByPatientId(params)
    if (status) {
      setPerspectiveByPatient(data)
    }
  }

  /**
   * api
   * 通过患者id获取入院血糖
   * @param {*} id
   */
  const getSugarPerspective = async (params) => {
    const { data, status } = await reqSugarPerspective(params)
    if (status) {
      setSugarPerspective(data)
    }
  }

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    const { status } = await reqPatientsStatus({
      code: 3,
      id: patientRow.id
    })
    if (status) {
      setConfirmLoading(true)
      message.success('出院成功！')
      setVisible(false)
      refresh()
    }
  }

  /**
   * 取消
   */
  const onCancels = () => {
    setVisible(false)
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getSugarPerspective({ patientId: patientRow.id })
    return () => {
      getSugarPerspective({ patientId: patientRow.id })
    }
  }, [ patientRow.id])

  useEffect(() => {
    getPerspectiveByPatient({ patientId: patientRow.id })
    return () => {
      getPerspectiveByPatient({ patientId: patientRow.id })
    }
  }, [ patientRow.id])
  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <>
          <FileDoneOutlined className='g_mr_10' />
          <span>出院</span>
        </>
      }
      extra={
        <>
          <Popconfirm
            title='是否确定出院？'
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={handleOk}
            visible={visible}
            onCancel={onCancels}
          >
            <Button name='code' className='g_ml_10' type='primary' onClick={showPopconfirm}>
              出院
            </Button>
          </Popconfirm>
          <Button
            className='g_ml_10'
            type='primary'
            icon={<PrinterOutlined />}
            onClick={onPrint}
            disabled={confirmLoading ? false : true}
          >
            打印
          </Button>
          <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={onCancel}>
            返回
          </Button>
        </>
      }
    >
      <div ref={printRef}>
        <h3 className='g_txt_c theme-color' style={{ fontSize: 16, fontWeight: 700, marginBottom: 30 }}>
        上海交通大学附属第六人民医院POC血糖监测报告
        </h3>
        <Row gutter={10}>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>姓名：</span>
                <span>{patient.name}</span>
              </Col>
            </Row>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>科室：</span>
                <span>{patient.office && patient.office.name}</span>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>性别：</span>
                <span>{patient.gender === 0 ? '女' : '男'}</span>
              </Col>
            </Row>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>病区：</span>
                <span>{patient.inpatientWard && patient.inpatientWard.name}</span>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>年龄：</span>
                <span>{patient.age}</span>
              </Col>
            </Row>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>床位号：</span>
                <span>{patient.bedCards}</span>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>入院时间：</span>
                <span>{patient.createDate}</span>
              </Col>
            </Row>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>主治医师：</span>
                <span>{patient.doctor && patient.doctor.name}</span>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>出院时间：</span>
                <span>{patient.dischargeTime ? patient.dischargeTime : ''}</span>
              </Col>
            </Row>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>责任护士：</span>
                <span>{patient.primaryNurse && patient.primaryNurse.name}</span>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row className='padding-5-0'>
              <Col flex={1}>
                <span>住院号：</span>
                <span>{patient.hospitalizationCards}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={10}>
          <Col style={{ width: 80 }}>
            <Row>
              <Col flex={1}>&nbsp;</Col>
            </Row>
            <Row>
              <Col flex={1}>入院血糖：</Col>
            </Row>
            <Row>
              <Col flex={1}>出院血糖：</Col>
            </Row>
          </Col>
          <Col flex={1}>
            <Row>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[0].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[1].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[2].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[3].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[4].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[5].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[6].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[7].label}
              </Col>
              <Col className='g_txt_c' span={2}>
                {BS_ADVICE[8].label}
              </Col>
            </Row>
            <Row>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.weeHours && sugarPerspective.weeHours.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.beforeBreakfast && sugarPerspective.beforeBreakfast.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.afterBreakfast && sugarPerspective.afterBreakfast.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.beforeLunch && sugarPerspective.beforeLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.afterLunch && sugarPerspective.afterLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.beforeDinner && sugarPerspective.beforeLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.afterDinner && sugarPerspective.afterDinner.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.beforeSleep && sugarPerspective.beforeSleep.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {sugarPerspective.random && sugarPerspective.random.value}
              </Col>
            </Row>
            <Row>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.weeHours && perspectiveByPatient.weeHours.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.beforeBreakfast && perspectiveByPatient.beforeBreakfast.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.afterBreakfast && perspectiveByPatient.afterBreakfast.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.beforeLunch && perspectiveByPatient.beforeLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.afterLunch && perspectiveByPatient.afterLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.beforeDinner && perspectiveByPatient.beforeLunch.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.afterDinner && perspectiveByPatient.afterDinner.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.beforeSleep && perspectiveByPatient.beforeSleep.value}
              </Col>
              <Col className='g_txt_c' span={2}>
                {perspectiveByPatient.random && perspectiveByPatient.random.value}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row className='g_mb_20'>
          <Col flex={1}>
            <div>
              <label>出院医嘱</label>
              <TextArea rows={4} maxLength={200}/>
            </div>
          </Col>
        </Row>
        <Row className='g_mb_20' gutter={30}>
          <Col span={8}>
            <div>
              <label>随访医院</label>
              <Input maxLength={20}/>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <label>随访科室</label>
              <Input maxLength={20}/>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <label>随访时间</label>
              <Input placeholder='如：2020-12-12' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <div>
              <label>备注</label>
              <TextArea rows={4} />
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default DischargeForm

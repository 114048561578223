/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Form, Row, Col, Input, Button, Select } from 'antd'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { PATIENT_OUT_STATUS } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  query: PropTypes.bool.isRequired,
  officePatientList: PropTypes.array.isRequired,
  hospital: PropTypes.object.isRequired,
  offices: PropTypes.object.isRequired,
  onChangeHospital: PropTypes.func.isRequired,
  onChangeOffice: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const { query, officePatientList, hospital, offices, onChangeHospital, onChangeOffice,setPage,setCondition } = props

  // 提交表单
  const [form] = Form.useForm()

  /**
   * 查询
   * 选择框：选择医院
   * @param {*} value
   */
   const onChangeCompany = (value) => {
    form.setFieldsValue({ officeId: undefined })
  }

  /**
   * 查询
   * 选择框：选择科室
   * @param {*} value
   */
  const onChangeOffices = (value) => {
    form.setFieldsValue({ inpatientWardId: undefined })
  }

    /**
   * 查询
   * 按钮：选择状态
   * @param {*} value
   */
     const onChangeStatus = (value) => {
      setPage()
      setCondition({ status: value })
    }

    /**
   * 查询
   * 按钮：点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPage()
    setCondition(value)
  }

  /**
   * 查询
   * 按钮：点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setPage()
    setCondition({
      companyId: undefined,
      officeId: undefined,
      inpatientWardId: undefined,
      patientNo: undefined,
      hospitalizationCards: undefined,
      searchData: undefined,
      status: undefined
    })
  }

  /**
   * 渲染
   */
  if (!query) return null
  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item label='医院' name='companyId'>
          <Select style={{ width: 174 }} onClick={onChangeCompany} onChange={onChangeHospital}>
              {officePatientList.length &&
                officePatientList.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
          <Select style={{ width: 174 }} onClick={onChangeOffices} onChange={onChangeOffice}>
              {Object.keys(hospital).length &&
                hospital.children.length &&
                hospital.children.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='inpatientWardId' label='病区'>
          <Select style={{ width: 174 }}>
              {Object.keys(offices).length &&
                offices.children &&
                offices.children.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='hospitalizationCards' label='住院号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='patientNo' label='门诊号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='患者'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
          <Select style={{ width: 174 }} onChange={onChangeStatus}>
              {PATIENT_OUT_STATUS.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col>
          <div>
            <Button className='g_mr_10' type='primary' htmlType='submit'>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 底部组件 */

// ==================
// 调用模块
// ==================
import React from 'react'

// ==================
// 静态资源
// ==================
import './index.less'

// ==================
// 唯一的模块导出
// ==================
function Footer() {
  return <div className='footer'>POCT ©2020 Created by lianlai</div>
}

export default Footer

/* 血糖漂移度图 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'

/*  功能模块
---------------------------------------------------------------- */
import { reqDeviationById, reqMeanValueById } from '../../../api/index'

/* 唯一的模块导出
---------------------------------------------------------------- */
function BSDrift(props) {
  const { patientRow, condition } = props

  const [chartDom, setChartDom] = useState('')

  //血糖漂移度图
  const [deviation, setDeviation] = useState({})

  //血糖均值图
  const [meanValueById, setMeanValueById] = useState({})

  /**
   * 血糖均值图
   * @param {*} params
   */
  const getDeviation = async (params) => {
    const { data, status } = await reqDeviationById(params)
    if (status) {
      setDeviation(data)
    }
  }

  /**
   * 血糖均值图
   * @param {*} params
   */
  const getMeanValueById = async (params) => {
    const { data, status } = await reqMeanValueById(params)
    if (status) {
      setMeanValueById(data)
    }
  }

  /**
   * 初始化生成 option 所有列
   */
  const getChart = (chartDom, deviation, meanValueById) => {
    if (chartDom !== '' && Object.keys(deviation).length && Object.keys(meanValueById).length) {
      /**
       * 获取均值对象
       */
      let arr = []
      for (const i in meanValueById) {
        arr.push(meanValueById[i].num)
      }

      let arr1 = []
      for (const i in deviation) {
        arr1.push(deviation[i].num)
      }

      const option = {
        title: {
          text: '血糖波动图',
          left: 'center'
        },
        animation: true,
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['凌晨', '早餐前', '早餐后', '午餐前', '午餐后', '晚餐前', '晚餐后', '睡前']
        },
        yAxis: [
          {
            name: '均值(M)',
            type: 'value',
            position: 'left'
          },
          {
            name: '标准差(SD)',
            type: 'value',
            position: 'right'
          }
        ],
        series: [
          {
            name: '均值(M)',
            data: arr,
            type: 'line',
            yAxisIndex: 0
          },
          {
            name: '标准差(SD)',
            data: arr1,
            type: 'line',
            yAxisIndex: 1
          }
        ]
      }
      option && chartDom.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getDeviation({ patientId: patientRow.id, ...condition })
    getMeanValueById({ patientId: patientRow.id, ...condition })
  }, [patientRow.id, condition])

  useEffect(() => {
    const node = document.getElementById('average')
    const myChart = echarts.init(node)
    setChartDom(myChart)
  }, [])

  useEffect(() => {
    getChart(chartDom, deviation, meanValueById)

    if (chartDom !== '') {
      window.addEventListener('resize', () => {
        chartDom.resize()
      })

      return () => {
        window.removeEventListener('resize', () => {
          chartDom.resize()
        })
      }
    }
  }, [chartDom, deviation, meanValueById])

  return <div id='average' style={{ height: 400 }}></div>
}

export default BSDrift

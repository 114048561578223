/* 会诊列表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Tooltip } from 'antd'
import { TeamOutlined, RollbackOutlined, FilterOutlined, ProjectOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 静态资源
---------------------------------------------------------------- */
import { CONSULTATION_STATUS, CONSULTATION_TYPE, PAGE_SIZE } from '../../../utils/constants'

/* 功能模块
---------------------------------------------------------------- */
import { reqConsultationList, reqConsultationById } from '../../../api/index'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import Progress from './Progress'
import { mergeRid } from '../../../utils/func'

/* 使用类型检查
---------------------------------------------------------------- */
SelfTable.propTypes = {
  onClose: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function SelfTable(props) {
  const { onClose } = props

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 切换: fasle 默认 | true 会诊进度
  const [show, setShow] = useState(false)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 会诊列表
  const [consultationList, setConsultationList] = useState([])

  // 根据会诊id查询会诊进度
  const [consultation, setConsultation] = useState({})

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总条数
  const [total, setTotal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    hospitalizationCards: undefined, // 住院号
    searchData: undefined, // 姓名
    status: undefined // 状态
  })

  /**
   * 初始化 table 所有列
   */
  const initColumns = [
    {
      title: '序号',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '医院',
      dataIndex: 'company',
      width: '',
      align: 'center',
      render: (text, { company }) => <>{company && company.name}</>
    },
    {
      title: '住院号',
      dataIndex: 'patientHc',
      width: '',
      align: 'center'
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      width: '',
      align: 'center'
    },
    {
      title: '会诊类型',
      dataIndex: 'consultationType',
      width: '',
      align: 'center',
      render: (_, { consultationType }) =>
        consultationType === 1 ? (
          <span>{consultationType && CONSULTATION_TYPE[consultationType - 1].name}</span>
        ) : (
          <span className='color-red'>{consultationType && CONSULTATION_TYPE[consultationType - 1].name}</span>
        )
    },
    {
      title: '申请时间',
      dataIndex: 'createDate',
      width: '',
      align: 'center',
      render: (text, { createDate }) => <>{createDate ? moment(createDate).format('YYYY-MM-DD HH:mm:ss') : ''}</>
    },
    {
      title: '会诊状态',
      dataIndex: 'status',
      width: '',
      align: 'center',
      render: (text, { status }) => <span>{status && CONSULTATION_STATUS[status - 1].name}</span>
    },
    {
      title: '操作',
      dataIndex: '',
      width: 60,
      align: 'center',
      render: (text, { id }) => (
        <>
          <Tooltip title='会诊进度' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showProgess(id)} icon={<ProjectOutlined />} />
          </Tooltip>
        </>
      )
    }
  ]

  /**
   * api
   * 获取会诊列表接口
   */
  const getSelfs = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqConsultationList(params)
      if (status) {
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        setConsultationList(result)
        setTotal(totalRows)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 根据会诊id查询会诊进度
   */
  const getConsultationById = async (id) => {
    const { data, status } = await reqConsultationById({ id })
    if (status) {
      setConsultation(data)
    }
  }

  const data = mergeRid(consultationList, 1, PAGE_SIZE)

  /**
   * 按钮
   * 点击显示 / 隐藏查询
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 点击显示会诊进度
   * @param {*} id
   */
  const showProgess = (id) => {
    getConsultationById(id)
    setShow(true)
  }

  /**
   * 分页器
   * 切换用户列表页
   * @param {*} page
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  /**
   * 挂载
   */

  useEffect(() => {
    getSelfs({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      ...condition
    })
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  if (!show) {
    return (
      <Card
        className='height-percent'
        size='small'
        title={
          <h3 className='margin-clear'>
            <TeamOutlined className='g_mr_10' />
            会诊列表
          </h3>
        }
        extra={
          <>
            <Button icon={<FilterOutlined />} onClick={showQuery}>
              {query ? '隐藏' : '查询'}
            </Button>
            <Button className='g_ml_10' onClick={onClose} icon={<RollbackOutlined />}>
              关闭
            </Button>
          </>
        }
      >
        {query && (
          <QueryForm
            query={query}
            setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />
        )}

        <Table
          bordered
          size='small'
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={data}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            showSizeChanger: false,
            total,
            showTotal: (total) => ` 共 ${total} 条记录`,
            onChange: onChangePage
          }}
        />
      </Card>
    )
  }
  return <Progress onClose={() => setShow(false)} consultation={consultation} />
}

export default SelfTable

/* 今日质控 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'

/* 使用类型检查
---------------------------------------------------------------- */
TodayQcGauge.prototype = {
  data: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function TodayQcGauge(props) {
  const { data } = props

  const [myChart, setMyChart] = useState('')

  const getChart = (myChart, data) => {
    if (myChart !== '') {
      let option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '50%'],
            startAngle: 220,
            endAngle: -40,
            radius: '65%',
            progress: {
              show: true,
              width: 15,
              clip: true,
              itemStyle: {
                color: '#FF56FF'
              }
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 15
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            anchor: {
              show: false
            },
            title: {
              show: true,
              offsetCenter: [0, '70%'],
              fontSize: 18,
              fontFamily: 'Source Han Sans CN',
              fontWeight: 'bold',
              color: '#fff'
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              height: '15%',
              offsetCenter: [0, 0],
              fontSize: 30,
              fontWeight: 'bold',
              color: '#FF56FF'
            },
            data: [
              {
                value: data,
                name: '今日质控'
              }
            ]
          }
        ]
      }
      // setInterval(() => {
      //   option && myChart.setOption(option)
      // }, 3000)
      option && myChart.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const node = document.getElementById('todayQc')
    const myChart = echarts.init(node)
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, data)
  }, [myChart, data])

  /**
   * 渲染
   */
  return <div id='todayQc' className='bi_chart3_item'></div>
}

export default TodayQcGauge

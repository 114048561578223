/* 患者列表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Tabs, Card, Empty } from 'antd'
import { TeamOutlined, UserOutlined, FilterOutlined } from '@ant-design/icons'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'
import {
  QueryForm,
  PatientForm,
  InfoPane,
  HistoryPane,
  PatientAdvicePane,
  PatientList
} from '../../../components/Patient'

/* 子模块
---------------------------------------------------------------- */
import DischargeForm from './DischargeForm'
import AddConsultationForm from './AddConsultationForm'
import AddAdviceForm from './AddAdviceForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqPatientById } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'
import memoryUtils from '../../../utils/memoryUtils'

/* 调用模块对象解构
---------------------------------------------------------------- */
const { TabPane } = Tabs

/* 唯一的模块导出
---------------------------------------------------------------- */
function List() {

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 按钮事件的点击行为
  const [operation, setOperation] = useState({
    action: undefined, // 操作行为：upPatient(信息更新)  | outHospital（出院）
    data: {} // 患者信息
  })

  // 弹框相关参数
  const [modal, setModal] = useState(0)

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院id
    officeId: undefined, // 科室id
    inpatientWardId: undefined, // 病区id
    bedCards: undefined, // 床位号
    patientNo: undefined, // 门诊号
    hospitalizationCards: undefined, // 住院号
    searchData: undefined, // 条件查询: 姓名字段
    status: undefined // 患者状态
  })

  // 分页相关参数
  const [patientPage, setPatientPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 分页相关参数
  const [commonPage, setCommonPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 患者的一行记录
  const [patientRow, setPatientRow] = useState({})

  //公共的一行记录
  const [commonRow, setCommonRow] = useState({})

  // 患者列表(id)
  const [patient, setPatient] = useState({})

  // 标记
  const [flag, setFlag] = useState(false)

  // 获取登录用户id
  const [values, setValues] = useState(undefined)


  /**
   * api
   * 通过id获取患者信息
   * @param {*} id
   */
  const getPatientById = async (params) => {
    const { data, status } = await reqPatientById(params)
    if (status) {
      setPatient(data)
    }
  }

  /**
   * 按钮
   * 点击显示 / 隐藏 查询
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   *按钮
   点击获取类型和值
   * @param {*} action
   * @param {*} data
   */
  const onShowOperation = (action, data) => {
    setOperation({ action, data })
  }

/**
   * 按钮
   * 点击弹出新建会诊
   */
  const showAddConsultion = ()=>{
    setModal(1)
  }

  /**
   * 按钮
   * 点击弹出新建医嘱
   */
  const showAddAdvice = () => {
    setModal(2)
  }

  //通过登陆用户判断角色,禁选新建会诊弹框
  const initUserId = () => {
    const { role } = memoryUtils.user
    setValues(role.dataLevel)
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (patientRow.id) {
      getPatientById({ id: patientRow.id })
    }
  }, [patientRow.id])

  useEffect(() => {
    initUserId()
  }, [])

  /**
   * 渲染
   */
  if (operation.action === undefined) {
    // 患者列表
    return (
      <>
        <div className='g_fx_lr g_h_percent'>
          <Card
            className='g_w_385 g_mr_10'
            size='small'
            title={
              <>
                <TeamOutlined className='g_mr_10' />
                <span>患者列表</span>
              </>
            }
          >
            <PatientList
              patientPage={patientPage}
              patientRow={patientRow}
              condition={condition}
              setPatientPage={(params) => setPatientPage(params)}
              setPatientRow={(params) => setPatientRow(params)}
              setCommonPage={() => setCommonPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              setCommonRow={() => setCommonRow({})}
              flag={flag}
            />
          </Card>
          <Card
            className='g_fx_a '
            size='small'
            title={
              <h3 className='margin-clear'>
                <UserOutlined className='g_mr_10' />
                患者信息
              </h3>
            }
            extra={
              <>
                <Button className='g_ml_10' icon={<FilterOutlined />} onClick={showQuery}>
                  {query ? '隐藏' : '查询'}
                </Button>
                {values > 3 ? (
                  <Button
                    className='g_ml_10'
                    type='primary'
                    onClick={() => onShowOperation('upPatient', {})}
                    disabled={patientRow.id ? false : true}
                  >
                    信息更新
                  </Button>
                ) : null}
                {values > 3 ? (
                  <Button
                    className='g_ml_10'
                    type='primary'
                    onClick={() => showAddConsultion()}
                    disabled={patientRow.id ? false : true}
                  >
                    新建会诊
                  </Button>
                ) : null}

                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => showAddAdvice()}
                  disabled={patientRow.id ? false : true}
                >
                  新建医嘱
                </Button>
                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => onShowOperation('outCompany', {})}
                  disabled={patientRow.id ? false : true}
                >
                  出院
                </Button>
              </>
            }
          >
            {query && (
              <QueryForm
                setCondition={(params) => setCondition({ ...condition, ...params })}
                setPatientPage={() => setPatientPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              />
            )}
            <Tabs defaultActiveKey='1' type='card' tabBarGutter={-1}>
              <TabPane tab='基本信息' key='1'>
                {patientRow.id ? (
                  <InfoPane patientRow={patientRow} flag={flag} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
              <TabPane tab='会诊历史' key='2'>
                {patientRow.id ? (
                  <HistoryPane
                    flag={flag}
                    patientRow={patientRow}
                    commonRow={commonRow}
                    commonPage={commonPage}
                    setCommonPage={(params) => setCommonPage(params)}
                    setCommonRow={(params) => setCommonRow(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
              <TabPane tab='诊疗医嘱' key='3'>
                {patientRow.id ? (
                  <PatientAdvicePane
                    flag={flag}
                    patientRow={patientRow}
                    commonRow={commonRow}
                    commonPage={commonPage}
                    setCommonPage={(params) => setCommonPage(params)}
                    setCommonRow={(params) => setCommonRow(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </div>
        <IModal visible={modal === 1} titleText={ '新建会诊'}>
          <AddConsultationForm
            flag={flag}
            patientRow={patientRow}
            setModal={() => setModal({ ...modal, isShow: false})}
            setFlag={(params) => setFlag(params)}
          />
        </IModal>
        <IModal visible={modal === 2} titleText='新建医嘱'>
          <AddAdviceForm
            flag={flag}
            patientRow={patientRow}
            onCancel={() => setModal(0)}
            setModal={() => setModal(0)}
            setFlag={(params) => setFlag(params)}
          />
        </IModal>
      </>
    )
  }

  if (operation.action === 'upPatient') {
    return (
      <PatientForm
        patient={patient}
        onCancel={() => setOperation({ ...operation, action: undefined })}
        setShow={() => setOperation({ ...operation, action: undefined })}
      />
    )
  }
  if (operation.action === 'outCompany') {
    return (
      <DischargeForm
        patient={patient}
        patientRow={patientRow}
        onCancel={() => setOperation({ ...operation, action: undefined })}
        refresh={() => getPatientById({ id: patientRow.id })}
      />
    )
  }
}

export default List

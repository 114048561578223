/* 操作日志 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, Table } from 'antd'
import { SendOutlined, FilterOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqLogs } from '../../../api'
import { mergeRid } from '../../../utils/func'
import { PAGE_SIZE } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Log() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 日志列表
  const [logs, setLogs] = useState([])

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE, // 每页数量
    total: 0 // 总记录数
  })

  // 查询条件
  const [condition, setCondition] = useState({ userId: undefined })

  /**
   * 初始化显示 table 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      width: 100,
      align: 'center'
    },
    {
      title: '系统ID',
      dataIndex: 'userId',
      width: 100,
      align: 'center'
    },
    {
      title: '事件',
      dataIndex: 'handler',
      align: 'center'
    },
    {
      title: '操作时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center',
      render: (text, { createDate }) => moment(createDate).format('YYYY-MM-DD HH:mm:ss')
    }
  ]

  /**
   * api
   * 获取操作日志列表
   */
  const getLogs = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqLogs(params)
      if (status) {
        const result = mergeRid(data.resultList, params.pageNo, params.pageSize)
        setLogs(result)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          total: data.totalRows
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 分页器：改变当前页
   * @param {*} pageNo
   * @param {*} pageSize
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ ...page, pageNo, pageSize })
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getLogs({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      ...condition
    })
  }, [page.pageNo, page.pageSize, condition])

  /**
   * 渲染
   */
  return (
    <Card
      className='g_h_percent'
      size='small'
      title={
        <>
          <SendOutlined className='g_mr_10' />
          <span>操作日志</span>
        </>
      }
      extra={
        <Button className='g_ml_10' icon={<FilterOutlined />} onClick={showQuery}>
          {query ? '隐藏' : '查询'}
        </Button>
      }
    >
      {query && (
        <QueryForm
          setPage={() => setPage({ ...page, pageNo:1, pageSize: PAGE_SIZE })}
          setCondition={(params) => setCondition(params)}
        />
      )}
      <Table
        bordered
        size='small'
        scroll={{ y: 450 }}
        rowKey='rid'
        loading={isLoading}
        columns={initColumns}
        dataSource={logs}
        pagination={{
          current: page.pageNo,
          pageSize: page.pageSize,
          total: page.total,
          showTotal: (total) => `共 ${total} 条记录`,
          showQuickJumper: true,
          onChange: onChangePage
        }}
      />
    </Card>
  )
}

export default Log

/* 患者查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Tooltip } from 'antd'
import { TeamOutlined, FilterOutlined, ContainerOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import PatientDetailForm from './PatientDetailForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqQueryPatient, reqOfficePatientList } from '../../../api/index'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'

/* 唯一的模块导出
---------------------------------------------------------------- */
function Query() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 加载中
  const [row, setRow] = useState({})

  // 0 默认 | 1 患者详情 | 2 会诊列表
  const [show, setShow] = useState(0)

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 医院列表
  const [hospital, setHospital] = useState({})

  // 科室列表
  const [offices, setOffices] = useState({})

  // 科室列表tree结构（查询科室列表（用作多条件患者查询页面的条件查询使用）
  const [officePatientList, setOfficePatientList] = useState([])

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 总记录数
  const [total, setTotal] = useState(0)

  // 患者页面
  const [patients, setPatients] = useState([])

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院id
    officeId: undefined, // 科室id
    inpatientWardId: undefined, // 病区id
    patientNo: undefined, // 门诊号
    hospitalizationCards: undefined, // 住院号
    searchData: undefined, // 条件查询: 姓名字段
    status: undefined // 患者状态
  })

  /**
   * 初始化 table 所有列
   */
  const initColumns = [
    {
      title: '序号',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '医院',
      dataIndex: 'company',
      width: '',
      align: 'center',
      render: (text, { company }) => <>{company && company.name}</>
    },
    {
      title: '科室',
      dataIndex: 'office',
      width: '',
      align: 'center',
      render: (text, { office }) => <>{office && office.name}</>
    },
    {
      title: '病区',
      dataIndex: 'inpatientWard',
      width: '',
      align: 'center',
      render: (text, { inpatientWard }) => <>{inpatientWard && inpatientWard.name}</>
    },
    {
      title: '住院号',
      dataIndex: 'hospitalizationCards',
      width: '',
      align: 'center'
    },
    {
      title: '门诊号',
      dataIndex: 'patientNo',
      width: '',
      align: 'center'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: '',
      align: 'center'
    },
    {
      title: '主治医师',
      dataIndex: 'doctor',
      width: '',
      align: 'center',
      render: (text, { doctor }) => <>{doctor && doctor.name}</>
    },
    {
      title: '责任护士',
      dataIndex: 'primaryNurse',
      width: '',
      align: 'center',
      render: (text, { primaryNurse }) => <>{primaryNurse && primaryNurse.name}</>
    },
    {
      title: '入院时间',
      dataIndex: 'createDate',
      width: '',
      align: 'center',
      render: (text, { createDate }) => <>{createDate ? moment(createDate).format('YYYY-MM-DD HH:mm:ss') : ''}</>
    },
    {
      title: '出院时间',
      dataIndex: 'dischargeTime',
      width: '',
      align: 'center',
      render: (text, { dischargeTime }) => (
        <>{dischargeTime ? moment(dischargeTime).format('YYYY-MM-DD HH:mm:ss') : ''}</>
      )
    },
    {
      title: '操作',
      dataIndex: '',
      width: 60,
      align: 'center',
      render: (text, { id }) => (
        <>
          <Tooltip title='患者详情' mouseEnterDelay={0.5}>
            <Button type='link' onClick={() => showPatientDetail(id)} icon={<ContainerOutlined />} />
          </Tooltip>
        </>
      )
    }
  ]

  /**
   * 按钮
   * 点击切换到会诊列表
   */
  const showPatientDetail = () => {
    setShow(1)
  }

  /**
   * api
   * 获取患者页面列表
   */
  const getQueryPatient = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqQueryPatient(params)
      if (status) {
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        setPatients(result)
        setTotal(totalRows)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getOfficePatientList = async () => {
    const { data, status } = await reqOfficePatientList()
    if (status) {
      setOfficePatientList(data)
    }
  }

  /**
   * 选择框
   * 点击选择医院
   * @param {*} value
   */
  const onChangeHospital = (value) => {
    const result = officePatientList.find((v) => v.id === value)
    setHospital(result)
  }

  /**
   * 选择器
   * 点击选择科室
   *  @param {*} value
   */
  const onChangeOffice = (value) => {
    const result = hospital.children.find((v) => v.id === value)
    setOffices(result)
  }

  /**
   * 按钮
   * 点击显示 / 隐藏查询
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} page
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ pageNo, pageSize })
  }

  let data = mergeRid(patients, 1, PAGE_SIZE)

  /**
   * 挂载
   */
  useEffect(() => {
    getQueryPatient({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      ...condition
    })
  }, [page.pageNo, page.pageSize, condition])

  useEffect(() => {
    getOfficePatientList()
    return ()=>getOfficePatientList
  },[])

  /**
   * 渲染
   */
  if (!show) {
    return (
      <Card
        className='height-percent'
        size='small'
        title={
          <h3 className='margin-clear'>
            <TeamOutlined className='g_mr_10' />
            患者查询
          </h3>
        }
        extra={
          <Button icon={<FilterOutlined />} onClick={showQuery}>
            {query ? '隐藏' : '查询'}
          </Button>
        }
      >
        <QueryForm
          query={query}
          officePatientList={officePatientList}
          hospital={hospital}
          offices={offices}
          onChangeHospital={onChangeHospital}
          onChangeOffice={onChangeOffice}
          setPage={() => setPage({ pageNo: 1, pageSize: PAGE_SIZE })}
          setCondition={(params) => setCondition({ ...condition, ...params })}
        />

        <Table
          bordered
          size='small'
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={data}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            showSizeChanger: false,
            onChange: onChangePage
          }}
          rowClassName={showRowClassName}
          onRow={(record) => {
            return {
              onClick: () => {
                setRow(record)
              }
            }
          }}
        />
      </Card>
    )
  }
  if (show === 1) {
    return <PatientDetailForm onClose={() => setShow(0)} row={row} />
  }
}

export default Query

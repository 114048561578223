// 会诊管理 | 患者管理 里面的患者详情

/* 调用模块
---------------------------------------------------------------- */
import React, { useState,useEffect } from 'react'
import { Tabs, Card, Button, Empty } from 'antd'
import { TeamOutlined } from '@ant-design/icons'

/* 子模块
---------------------------------------------------------------- */
import {
  InfoPane,
  HistoryPane,
  BSRecordPane,
  PatientBG,
  PatientHeart,
  PatientClotting,
  BSPerspectivePane,
  BSAtlasPane,
  PatientBGAdvice,
  PatientInsulin,
  PatientNCD,
  BloodFat
} from '../../../components/Patient'

/* 功能模块
---------------------------------------------------------------- */
import { reqPatientById } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'

/* 调用模块对象解构
---------------------------------------------------------------- */
const { TabPane } = Tabs

/* 唯一的模块导出
---------------------------------------------------------------- */
function PatientDetailForm(props) {
  const { onClose, row } = props


  // 患者列表
  const [patient, setPatient] = useState({})

  // 分页相关参数
  const [commonPage, setCommonPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  /**
   * api
   * 通过id获取患者信息
   * @param {*} id
   */
  const getPatientById = async (params) => {
    const { data, status } = await reqPatientById(params)
    if (status) {
      setPatient(data)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    if (row.patient.id) {
      getPatientById({ id: row.patient.id })
    }
  }, [row.patient.id])

  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <TeamOutlined className='g_mr_10' />
          患者详情
        </h3>
      }
      extra={
        <>
          <Button className='g_ml_10' onClick={onClose}>
            关闭
          </Button>
        </>
      }
    >
      <Tabs type='card'>
        <TabPane tab='基本信息' key='1'>
          {row.patient.id ? <InfoPane patientRow={patient} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </TabPane>
        <TabPane tab='会诊历史' key='2'>
          {row.patient.id ? (
            <HistoryPane
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='血糖记录表' key='3'>
          {row.patient.id ? (
            <BSRecordPane
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='血气记录表' key='4'>
          {row.patient.id ? (
            <PatientBG
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='心标记录表' key='5'>
          {row.patient.id ? (
            <PatientHeart
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='血凝记录表' key='6'>
          {row.patient.id ? (
            <PatientClotting
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='血糖透视表' key='7'>
          {row.patient.id ? (
            <BSPerspectivePane
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='血糖图谱' key='8'>
          <BSAtlasPane patientRow={row.patient} />
        </TabPane>
        <TabPane tab='血糖医嘱' key='9'>
          {row.patient.id ? (
            <PatientBGAdvice
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='胰岛素治疗' key='10'>
          {row.patient.id ? (
            <PatientInsulin
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='慢病管理' key='11'>
          {row.patient.id ? (
            <PatientNCD
              patientRow={row.patient}
              commonPage={commonPage}
              setCommonPage={(params) => setCommonPage(params)}
            />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
        <TabPane tab='糖化血脂' key='12'>
          {row.patient.id ? (
            <BloodFat
            patientRow={row.patient}
            commonPage={commonPage}
            setCommonPage={(params) => setCommonPage(params)}
          />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default PatientDetailForm

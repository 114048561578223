/* 质控图形-图表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 使用类型检查
---------------------------------------------------------------- */
QCGraphChart.prototype = {
  isZValue: PropTypes.bool,
  chartData: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCGraphChart(props) {
  const { isZValue, chartData } = props

  // 初始化图表
  const [myChart, setMyChart] = useState(undefined)

  const getChart = (myChart, chartData, isZValue) => {
    if (myChart && Object.keys(chartData).length > 0) {
      const {
        qualityControlItemVOList: qcs,
        qualityControlInfoVO: { controlThings: { levelNum } = {}, controlSectionMeanValueVO: sectionMean } = {}
      } = chartData

      let newQcs = JSON.parse(JSON.stringify(qcs)) // 辅助数据
      let ms = [] // 均值和标准差的对象数组，数组长度即为水平个数
      let option = {} // 图表参数
      let resetLine = [] // 重置线

      if (qcs.length > 0) {
        for (let i = 0; i < newQcs.length; i++) {
          if (newQcs[i]['hasRest']) {
            resetLine.push({ xAxis: newQcs[i]['createDate'] })
          }
        }

        for (let i = 0; i < levelNum; i++) {
          ms.push({
            id: i,
            mean: newQcs[newQcs.length - 1][`level${i + 1}StaticMV`]
              ? newQcs[newQcs.length - 1][`level${i + 1}StaticMV`]
              : sectionMean[`level${i + 1}MV`],
            sd: newQcs[newQcs.length - 1][`level${i + 1}StaticSDCV`]
              ? newQcs[newQcs.length - 1][`level${i + 1}StaticSDCV`]
              : sectionMean[`level${i + 1}SDCV`]
          })
        }

        // 判断是否有 Z 值
        if (!isZValue) {
          for (let i = 0; i < newQcs.length; i++) {
            if (levelNum >= 1) {
              newQcs[i]['level1Value'] = newQcs[i]['level1Value']
                ? ((newQcs[i]['level1Value'] - ms[0]['mean']) / ms[0]['sd']).toFixed(2)
                : null
              newQcs[i]['level1Value'] = newQcs[i]['level1Value'] > 3 ? 3 : newQcs[i]['level1Value']
              newQcs[i]['level1Value'] = newQcs[i]['level1Value'] < -3 ? -3 : newQcs[i]['level1Value']
            }
            if (levelNum >= 2) {
              newQcs[i]['level2Value'] = newQcs[i]['level2Value']
                ? ((newQcs[i]['level2Value'] - ms[1]['mean']) / ms[1]['sd']).toFixed(2)
                : null
              newQcs[i]['level2Value'] = newQcs[i]['level2Value'] > 3 ? 3 : newQcs[i]['level2Value']
              newQcs[i]['level2Value'] = newQcs[i]['level2Value'] < -3 ? -3 : newQcs[i]['level2Value']
            }
            if (levelNum >= 3) {
              newQcs[i]['level3Value'] = newQcs[i]['level3Value']
                ? ((newQcs[i]['level3Value'] - ms[2]['mean']) / ms[2]['sd']).toFixed(2)
                : null
              newQcs[i]['level3Value'] = newQcs[i]['level3Value'] > 3 ? 3 : newQcs[i]['level3Value']
              newQcs[i]['level3Value'] = newQcs[i]['level3Value'] < -3 ? -3 : newQcs[i]['level3Value']
            }
          }
        } else {
          for (let i = 0; i < newQcs.length; i++) {
            if (levelNum >= 1) {
              if (newQcs[i]['level1Value']) {
                if (newQcs[i]['level1StaticMV']) {
                  newQcs[i]['level1Value'] = (
                    (newQcs[i]['level1Value'] - newQcs[i]['level1StaticMV']) /
                    newQcs[i]['level1StaticSDCV']
                  ).toFixed(2)
                } else {
                  newQcs[i]['level1Value'] = ((newQcs[i]['level1Value'] - ms[0]['mean']) / ms[0]['sd']).toFixed(2)
                }
                newQcs[i]['level1Value'] = newQcs[i]['level1Value'] > 3 ? 3 : newQcs[i]['level1Value']
                newQcs[i]['level1Value'] = newQcs[i]['level1Value'] < -3 ? -3 : newQcs[i]['level1Value']
              } else {
                newQcs[i]['level1Value'] = null
              }
            }
            if (levelNum >= 2) {
              if (newQcs[i]['level2Value']) {
                if (newQcs[i]['level2StaticMV']) {
                  newQcs[i]['level2Value'] = (
                    (newQcs[i]['level2Value'] - newQcs[i]['level2StaticMV']) /
                    newQcs[i]['level2StaticSDCV']
                  ).toFixed(2)
                } else {
                  newQcs[i]['level2Value'] = ((newQcs[i]['level2Value'] - ms[1]['mean']) / ms[1]['sd']).toFixed(2)
                }
                newQcs[i]['level2Value'] = newQcs[i]['level2Value'] > 3 ? 3 : newQcs[i]['level2Value']
                newQcs[i]['level2Value'] = newQcs[i]['level2Value'] < -3 ? -3 : newQcs[i]['level2Value']
              } else {
                newQcs[i]['level2Value'] = null
              }
            }
            if (levelNum >= 3) {
              if (newQcs[i]['level3Value']) {
                if (newQcs[i]['level3StaticMV']) {
                  newQcs[i]['level3Value'] = (
                    (newQcs[i]['level3Value'] - newQcs[i]['level3StaticMV']) /
                    newQcs[i]['level3StaticSDCV']
                  ).toFixed(2)
                } else {
                  newQcs[i]['level3Value'] = ((newQcs[i]['level3Value'] - ms[2]['mean']) / ms[2]['sd']).toFixed(2)
                }
                newQcs[i]['level3Value'] = newQcs[i]['level3Value'] > 3 ? 3 : newQcs[i]['level3Value']
                newQcs[i]['level3Value'] = newQcs[i]['level3Value'] < -3 ? -3 : newQcs[i]['level3Value']
              } else {
                newQcs[i]['level3Value'] = null
              }
            }
          }
        }
      }

      switch (levelNum) {
        case 1:
          option = {
            dataset: {
              source: newQcs
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params) => {
                if (qcs) {
                  let result = ''
                  result = params[0].name + '<br/>'
                  result += params[0].marker + params[0].seriesName
                  result +=
                    '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                    (qcs[params[0].dataIndex].level1Value ? qcs[params[0].dataIndex].level1Value : '-') +
                    '</span></br>'

                  return result
                }
                return
              }
            },
            legend: {
              data: ['水平1']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: true
              ? {}
              : {
                  feature: {
                    saveAsImage: {}
                  }
                },
            xAxis: {
              axisLine: {
                show: true,
                onZero: true
              },
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                formatter: (value, _) => moment(value).format('YYYY-MM-DD')
              }
            },
            yAxis: [
              {
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: [
                      'rgba(255,102,102, 0.5)',
                      'rgba(255,204,153, 0.5)',
                      'rgb(255,255,255)',
                      'rgb(255,255,255)',
                      'rgba(255,204,153, 0.5)',
                      'rgba(255,102,102, 0.5)'
                    ]
                  }
                },
                max: 3,
                min: -3,
                interval: 1,
                position: 'left',
                axisLine: {
                  show: true
                },
                axisLabel: {
                  formatter: (_, index) => {
                    if (index === 3) return '均值'
                    return `${index - 3}SD`
                  }
                }
              },
              {
                position: 'right',
                axisLine: {
                  show: true
                }
              }
            ],
            series: [
              {
                name: '水平1',
                type: 'line',
                symbol: 'circle',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level1Value' },
                connectNulls: true
              }
            ]
          }
          break
        case 2:
          option = {
            dataset: {
              source: newQcs
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params) => {
                if (qcs) {
                  let result = params[0].name + '<br/>'
                  for (let i = 0; i < params.length; i++) {
                    result += params[i].marker + params[i].seriesName
                    switch (params[i].seriesIndex) {
                      case 0:
                        result +=
                          '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                          (qcs[params[i].dataIndex].level1Value ? qcs[params[i].dataIndex].level1Value : '-') +
                          '</span></br>'
                        break

                      default:
                        result +=
                          '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                          (qcs[params[i].dataIndex].level2Value ? qcs[params[i].dataIndex].level2Value : '-') +
                          '</span></br>'
                        break
                    }
                  }
                  return result
                }
                return
              }
            },
            legend: {
              data: ['水平1', '水平2']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: true
              ? {}
              : {
                  feature: {
                    saveAsImage: {}
                  }
                },
            xAxis: {
              axisLine: {
                show: true,
                onZero: true
              },
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                formatter: (value, _) => moment(value).format('YYYY-MM-DD')
              }
            },
            yAxis: [
              {
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: [
                      // 'rgba(255,241,240, 0.5)',
                      // 'rgba(255,247,230, 0.5)',
                      // 'rgb(255,255,255)',
                      // 'rgb(255,255,255)',
                      // 'rgba(255,247,230, 0.5)',
                      // 'rgba(255,241,240, 0.5)'
                      'rgba(255,102,102, 0.5)',
                      'rgba(255,204,153, 0.5)',
                      'rgb(255,255,255)',
                      'rgb(255,255,255)',
                      'rgba(255,204,153, 0.5)',
                      'rgba(255,102,102, 0.5)'
                    ]
                  }
                },
                max: 3,
                min: -3,
                interval: 1,
                position: 'left',
                axisLine: {
                  show: true
                },
                axisLabel: {
                  formatter: (_, index) => {
                    if (index === 3) return '均值'
                    return `${index - 3}SD`
                  }
                }
              },
              {
                position: 'right',
                axisLine: {
                  show: true
                }
              }
            ],
            series: [
              {
                name: '水平1',
                type: 'line',
                symbol: 'circle',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level1Value' },
                connectNulls: true
              },
              {
                name: '水平2',
                type: 'line',
                symbol: 'rect',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level2Value' },
                connectNulls: true
              }
            ]
          }
          break

        default:
          option = {
            dataset: {
              source: newQcs
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params) => {
                if (qcs) {
                  let result = params[0].name + '<br/>'
                  for (let i = 0; i < params.length; i++) {
                    result += params[i].marker + params[i].seriesName
                    switch (params[i].seriesIndex) {
                      case 0:
                        result +=
                          '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                          (qcs[params[i].dataIndex].level1Value ? qcs[params[i].dataIndex].level1Value : '-') +
                          '</span></br>'
                        break
                      case 1:
                        result +=
                          '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                          (qcs[params[i].dataIndex].level2Value ? qcs[params[i].dataIndex].level2Value : '-') +
                          '</span></br>'
                        break

                      default:
                        result +=
                          '<span style="float: right; font-weight: bold; margin-left: 20px">' +
                          (qcs[params[i].dataIndex].level3Value ? qcs[params[i].dataIndex].level3Value : '-') +
                          '</span></br>'
                        break
                    }
                  }
                  return result
                }
                return
              }
            },
            legend: {
              data: ['水平1', '水平2', '水平3']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: true
              ? {}
              : {
                  feature: {
                    saveAsImage: {}
                  }
                },
            xAxis: {
              axisLine: {
                show: true,
                onZero: true
              },
              axisLabel: {
                formatter: (value, _) => moment(value).format('YYYY-MM-DD')
              },
              type: 'category',
              boundaryGap: false
            },
            yAxis: [
              {
                splitArea: {
                  show: true,
                  areaStyle: {
                    color: [
                      'rgba(255,102,102, 0.5)',
                      'rgba(255,204,153, 0.5)',
                      'rgb(255,255,255)',
                      'rgb(255,255,255)',
                      'rgba(255,204,153, 0.5)',
                      'rgba(255,102,102, 0.5)'
                    ]
                  }
                },
                max: 3,
                min: -3,
                interval: 1,
                position: 'left',
                axisLine: {
                  show: true
                },
                axisLabel: {
                  formatter: (_, index) => {
                    if (index === 3) return '均值'
                    return `${index - 3}SD`
                  }
                }
              },
              {
                position: 'right',
                axisLine: {
                  show: true
                }
              }
            ],
            series: [
              {
                name: '水平1',
                type: 'line',
                symbol: 'circle',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level1Value' },
                connectNulls: true
              },
              {
                name: '水平2',
                type: 'line',
                symbol: 'rect',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level2Value' },
                connectNulls: true
              },
              {
                name: '水平3',
                type: 'line',
                symbol: 'triangle',
                symbolSize: 8,
                markLine: {
                  lineStyle: {
                    width: 3,
                    color: 'red',
                    type: 'solid'
                  },
                  symbol: ['none', 'none'],
                  data: resetLine,
                  label: {
                    formatter: '' // 隐藏基准线数值
                  }
                },
                encode: { x: 'createDate', y: 'level3Value' },
                connectNulls: true
              }
            ]
          }
          break
      }
      myChart.setOption(option)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const myChart = echarts.init(document.getElementById('qc_graph_chart'))
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, chartData, isZValue)

    if (myChart) {
      window.addEventListener('resize', function () {
        myChart.resize()
      })

      return () => {
        window.removeEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }, [myChart, chartData, isZValue])

  /**
   * 渲染
   */
  return <div id='qc_graph_chart' style={{ height: 300 }}></div>
}

export default QCGraphChart

/* 新建医嘱 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Button, Select, message } from 'antd'
import PropTypes from 'prop-types'

/*  功能模块
---------------------------------------------------------------- */
import {
  reqAddTemplate,
  reqPrescriptionTemplates,
  reqTemplateById,
  reqUpdateTemplate,
  reqAddPrescription
} from '../../../api/index'
import memoryUtils from '../../../utils/memoryUtils'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { TextArea } = Input
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
AddAdviceForm.propTypes = {
  flag: PropTypes.bool,
  // onCancel: PropTypes.func.isRequired,
  patientRow: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function AddAdviceForm(props) {
  const { flag, patientRow, setModal, setFlag } = props

  // 提交表单
  const [form] = Form.useForm()

  // 获取医嘱模板内容
  const [templates, setTemplates] = useState()

  // 保存新建医嘱模板内容
  const [prescription, setPrescription] = useState({})

  // 根据id获取医嘱内容信息
  const [templatesById, setTemplatesById] = useState({})

  /**
   * 获取文本域输入的内容
   * @param {*} e
   */
  const onChangeTemplate = (e) => {
    setTemplates(e.target.value)
  }

  /**
   * api
   * 新建医嘱模板内容
   * @param {*} value
   */
  const onClickSave = async () => {
    if (templatesById.id) {
      const { status } = await reqUpdateTemplate({
        id: templatesById.id,
        template: templates
      })
      if (status) {
        message.success('医嘱模板设置成功！')
        getPrescriptionTemplates()
        // form.resetFields()
      }
    } else {
      const params = { template: templates }
      const { status } = await reqAddTemplate(params)
      if (status) {
        message.success('医嘱模板设置成功！')
        getPrescriptionTemplates()
        // form.resetFields()
      }
    }
  }

  /**
   * api
   * 获取医嘱模板内容列表
   */
  const getPrescriptionTemplates = async () => {
    const params = {
      users: memoryUtils.user.id
    }
    const { data, status } = await reqPrescriptionTemplates(params)
    if (status) {
      setPrescription(data)
    }
  }

  /**
   * api
   * 通过id查询医嘱模板信息
   */
  const getTemplateById = async (pamars) => {
    const { data, status } = await reqTemplateById(pamars)
    if (status) {
      setTemplatesById(data)
    }
  }

  /**
   * api
   * 新建医嘱
   */
  const OnOkAddPrescription = async (value) => {
    const { template, remarks } = value
    const params = {
      patientId: patientRow.id,
      prescription: template,
      remarks
    }
    const { status } = await reqAddPrescription(params)
    if (status) {
      message.success('新建医嘱成功！')
      form.resetFields()
      setModal()
      setFlag(!flag)
    }
  }



  /**
   * 挂载
   */
  useEffect(() => {
    form.setFieldsValue({
      ...templatesById,
      template: templatesById.template
    })
    getPrescriptionTemplates()
  }, [form, templatesById])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={OnOkAddPrescription}>
      <Row>
        <Col span={16}>
          <Item name='id' label='医嘱模板' className='g_ml_10 '>
            <Select className='g_w_174' onChange={(id) => getTemplateById({ id })}>
              {prescription.length &&
                prescription.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.template}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col span={4} offset={4}>
          <Button type='link' onClick={onClickSave}>
            设置为模板
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Item name='template' label='医嘱内容' rules={[{ required: true, message: '请输入医嘱内容' }]}>
            <TextArea rows={4} showCount maxLength={500} onChange={onChangeTemplate} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Item name='remarks' label='备注' className='g_ml_40'>
            <TextArea rows={4} showCount maxLength={200} />
          </Item>
        </Col>
      </Row>
      <div className='g_txt_c'>
        <Button className='g_mr_20' onClick={setModal}>
          取消
        </Button>
        <Button type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}
export default AddAdviceForm

/* 质控图形 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Divider, Checkbox } from 'antd'
import { PictureOutlined, FilterOutlined, RollbackOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 子模块
---------------------------------------------------------------- */
import QCGraphQueryForm from './QCGraphQueryForm'
import QCGraphChart from './QCGraphChart'

/* 功能模块
---------------------------------------------------------------- */
import { reqQCGraph } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { MONITOR } from '../../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
QCGraph.prototype = {
  operation: PropTypes.object,
  goBack: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QCGraph(props) {
  const { operation, goBack } = props

  // 查询 / 隐藏显示控制
  const [query, setQuery] = useState(false)

  // 查询条件
  const [condition, setCondition] = useState({
    startDate: undefined,
    endDate: undefined
  })

  // 质控图形相关信息
  const [QCGraph, setQCGraph] = useState({})

  // 是否 Z 值
  const [isZValue, setIsZValue] = useState(false)

  /**
   * api
   * 获取质控图形相关信息
   * @param {*} params
   */
  const getQCGraph = async (params) => {
    const { data, status } = await reqQCGraph(params)
    if (status) {
      setQCGraph(data)
    }
  }

  /**
   * 查询
   * 按钮：条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 切换 Z 值状态
   * @param {*} e
   */
  const onChangeZValue = (e) => {
    setIsZValue(e.target.checked)
  }

  // 解构质控图形相关信息
  const { qualityControlInfoVO } = QCGraph

  const {
    company: { name: company } = {},
    office: { name: office } = {},
    type,
    instrument: { brand: { name: instrumentBrand } = {}, modelVO: { name: instrumentModel } = {}, snnum } = {},
    controlProject: { name: controlProject } = {},
    metering: { name: metering } = {},
    reagent: {
      brand: { name: reagentBrand } = {},
      modelVO: { name: reagentModel } = {},
      batchNumber,
      methodology: { name: methodology } = {},
      expireDate
    } = {},
    controlThings: {
      levelNum,
      brand: { name: controlThingsBrand } = {},
      model: { name: controlThingsModel } = {},
      thingsBatchNumber,
      name: { name: controlThings } = {},
      stroma: { name: stroma } = {}
    } = {},
    controlStaticMeanValueSetting: {
      level1MV: fixedLevel1MV,
      level1SDCV: fixedLevel1SDCV,
      level2MV: fixedLevel2MV,
      level2SDCV: fixedLevel2SDCV,
      level3MV: fixedLevel3MV,
      level3SDCV: fixedLevel3SDCV
    } = {},
    controlFloatMeanValueSetting: {
      level1MV: floatLevel1MV,
      level1SDCV: floatLevel1SDCV,
      level2MV: floatLevel2MV,
      level2SDCV: floatLevel2SDCV,
      level3MV: floatLevel3MV,
      level3SDCV: floatLevel3SDCV
    } = {},
    controlSectionMeanValueVO,
    level1,
    level2,
    level3
  } = qualityControlInfoVO || {}

  const {
    level1MV: sectionLevel1MV,
    level1SDCV: sectionLevel1SDCV,
    level2MV: sectionLevel2MV,
    level2SDCV: sectionLevel2SDCV,
    level3MV: sectionLevel3MV,
    level3SDCV: sectionLevel3SDCV
  } = controlSectionMeanValueVO || {}

  /**
   * 生命周期
   */
  useEffect(() => {
    getQCGraph({ qualityControlId: operation.data.controlId, ...condition })
  }, [operation.data.controlId, condition])

  /**
   * 渲染
   */
  return (
    <Card
      className='g_h_percent'
      size='small'
      title={
        <>
          <PictureOutlined className='g_mr_10' />
          <span>质控图形</span>
        </>
      }
      extra={
        <>
          <Button className='g_ml_10' icon={<FilterOutlined />} onClick={onShowQuery}>
            {query ? '隐藏' : '查询'}
          </Button>
          <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={goBack}>
            返回
          </Button>
        </>
      }
    >
      {query && <QCGraphQueryForm setCondition={(params) => setCondition(params)} />}

      <Card size='small' bordered>
        <Row style={{ fontSize: 8 }}>
          <Col className='g_pr_10' span={8}>
            医院：{company}
          </Col>
          <Col className='g_pr_10' span={8}>
            科室：{office}
          </Col>
          <Col span={8}>监测类型：{type && MONITOR[type - 1].name}</Col>
        </Row>

        <Row style={{ fontSize: 8 }}>
          <Col>
            仪器：{instrumentBrand}&emsp;{instrumentModel}&emsp;{snnum}
          </Col>
        </Row>

        <Row style={{ fontSize: 8 }}>
          <Col className='g_pr_10' span={8}>
            项目：{controlProject}
          </Col>
          <Col span={8}>单位：{metering}</Col>
        </Row>
        <Row style={{ fontSize: 8 }}>
          <Col className='g_pr_10' span={8}>
            试剂：{reagentBrand}&emsp;{reagentModel}&emsp;{batchNumber}
          </Col>
          <Col className='g_pr_10' span={8}>
            方法学：{methodology}
          </Col>
          <Col span={8}>效期：{expireDate}</Col>
        </Row>
        <Row style={{ fontSize: 8 }}>
          <Col className='g_pr_10' span={8}>
            质控液：{controlThingsBrand}&emsp;{controlThingsModel}&emsp;{thingsBatchNumber}
          </Col>
          <Col className='g_pr_10' span={8}>
            质控液名称：{controlThings}
          </Col>
          <Col span={8}>基质：{stroma}</Col>
        </Row>

        {levelNum && levelNum >= 1 && (
          <Row style={{ fontSize: 8 }}>
            <Col className='g_pr_10'>质控水平1</Col>
            <Col className='g_pr_10'>
              固定：[均值：{fixedLevel1MV}，SD：{fixedLevel1SDCV}，CV：
              {fixedLevel1MV && fixedLevel1SDCV && parseFloat(fixedLevel1SDCV / fixedLevel1SDCV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{floatLevel1MV}，SD：{floatLevel1SDCV}，CV：
              {floatLevel1MV && floatLevel1SDCV && parseFloat(floatLevel1SDCV / floatLevel1MV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              区间统计：[均值：{sectionLevel1MV}，SD：{sectionLevel1SDCV}，CV：
              {sectionLevel1MV && sectionLevel1SDCV && parseFloat(sectionLevel1SDCV / sectionLevel1MV).toFixed(2)}]]
            </Col>
            <Col className='g_pr_10'>次批号：{level1 && level1.batchNum}</Col>
            <Col>效期：{level1 && level1.expireDate}</Col>
          </Row>
        )}

        {levelNum && levelNum >= 2 && (
          <Row style={{ fontSize: 8 }}>
            <Col className='g_pr_10'>质控水平2</Col>
            <Col className='g_pr_10'>
              固定：[均值：{fixedLevel2MV}，SD：{fixedLevel2SDCV}，CV：
              {fixedLevel2MV && fixedLevel2SDCV && parseFloat(fixedLevel2SDCV / fixedLevel2SDCV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{floatLevel2MV}，SD：{floatLevel2SDCV}，CV：
              {floatLevel2MV && floatLevel2SDCV && parseFloat(floatLevel2SDCV / floatLevel2MV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              区间统计：[均值：{sectionLevel2MV}，SD：{sectionLevel2SDCV}，CV：{' '}
              {sectionLevel2MV && sectionLevel2SDCV && parseFloat(sectionLevel2SDCV / sectionLevel2MV).toFixed(2)}]]
            </Col>
            <Col className='g_pr_10'>次批号：{level2 && level2.batchNum}</Col>
            <Col>效期：{level2 && level2.expireDate}</Col>
          </Row>
        )}

        {levelNum && levelNum >= 3 && (
          <Row style={{ fontSize: 8 }}>
            <Col className='g_pr_10'>质控水平3</Col>
            <Col className='g_pr_10'>
              固定：[均值：{fixedLevel3MV}，SD：{fixedLevel3SDCV}，CV：
              {fixedLevel3MV && fixedLevel3SDCV && parseFloat(fixedLevel3SDCV / fixedLevel3SDCV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              浮动：[均值：{floatLevel3MV}，SD：{floatLevel3SDCV}，CV：
              {floatLevel3MV && floatLevel3SDCV && parseFloat(floatLevel3SDCV / floatLevel3MV).toFixed(2)}]
            </Col>
            <Col className='g_pr_10'>
              区间统计：[均值：{sectionLevel3MV}，SD：{sectionLevel3SDCV}，CV：
              {sectionLevel3MV && sectionLevel3SDCV && parseFloat(sectionLevel3SDCV / sectionLevel3MV).toFixed(2)}]]
            </Col>
            <Col className='g_pr_10'>次批号：{level3 && level3.batchNum}</Col>
            <Col>效期：{level3 && level3.expireDate}</Col>
          </Row>
        )}
      </Card>

      <Checkbox style={{ float: 'right' }} onChange={onChangeZValue}>
        Z值
      </Checkbox>

      <Divider />
      {Object.keys(QCGraph).length > 0 ? <QCGraphChart isZValue={isZValue} chartData={QCGraph} /> : null}
    </Card>
  )
}

export default QCGraph

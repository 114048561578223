/* 个人中心 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import { Button, Descriptions } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqUserInfo } from '../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Descriptions

/* 使用静态类型检查
---------------------------------------------------------------- */
UserCenter.propTypes = {
  setModal:PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function UserCenter(props) {
  const { setModal } = props

  //个人中心
  const [userInfo, setUserInfo] = useState({})

  /**
   * 个人中心
   */
  const getUserInfo = async () => {
    const { data, status } = await reqUserInfo()
    if (status) {
      setUserInfo(data)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getUserInfo()
  }, [])

  /**
   * 渲染
   */
  return (
    <>
      <Descriptions className='g_mb_20' bordered size='small' column={1}>
        <Item label={<label>医院</label>}>{userInfo.company && userInfo.company.name}</Item>
        <Item label={<label>科室</label>}>{userInfo.office && userInfo.office.name}</Item>
        <Item label={<label>姓名</label>}>{userInfo && userInfo.name}</Item>
        <Item label={<span>工号</span>}>{ userInfo && userInfo.no}</Item>
        <Item label={<label>角色</label>}>{userInfo.role && userInfo.role.name}</Item>
      </Descriptions>

      <div className='text-center'>
        <Button type='primary' onClick={() => setModal()}>
          关闭
        </Button>
      </div>
    </>
  )
}

export default UserCenter

/* 修改密码 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Input, message } from 'antd'

/* 功能模块
---------------------------------------------------------------- */
import { reqUpdateUserPwd } from '../../../api'

/* 使用类型检查
---------------------------------------------------------------- */
UpdatePwdForm.prototype = {
  action: PropTypes.object.isRequired,
  setAction: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function UpdatePwdForm(props) {
  const {
    action: {
      data: { id }
    },
    setAction
  } = props

  const [form] = Form.useForm()

  /**
   * 取消
   */
  const onCancel = () => {
    setAction()
    form.resetFields()
  }

  /**
   * 确定
   */
  const onOk = async (value) => {
    const { status } = await reqUpdateUserPwd({ id, ...value })
    if (status) {
      message.success('修改密码成功！')
      setAction()
      form.resetFields()
    }
  }

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOk}>
      <Form.Item name='password' label={<span>新密码</span>} rules={[{ required: true, message: '请输入新密码' }]}>
        <Input />
      </Form.Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' onClick={onCancel}>
          取消
        </Button>
        <Button type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default UpdatePwdForm

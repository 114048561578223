/*心标列表详情 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Divider, Button, Col, Row, Spin } from 'antd'
import { TagsOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqTheHeartById } from '../../../api/index'

/* 静态资源类型检查
---------------------------------------------------------------- */
AddDetailsForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddDetailsForm(props) {
  const { onCancel, patientRow } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  //心标列表
  const [theHeartById, setTheHeartById] = useState([])

  /**
   * 根据患者id统计心标报表详情
   * @param {*} params
   */
  const getTheHeartById = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqTheHeartById(params)
      if (status) {
        const result = data
        setTheHeartById(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getTheHeartById({ id: patientRow.id })
  }, [patientRow.id])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <TagsOutlined className='g_mr_10' />
          详情
        </h3>
      }
    >
      <Spin spinning={isLoading}>
        <div className='g_ml_40'>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              &nbsp;
            </Col>
            <Col className='g_txt_c' span={2}>
              总值
            </Col>
            <Col className='g_txt_c' span={2}>
              CKMB
            </Col>
            <Col className='g_txt_c' span={2}>
              肌红蛋白
            </Col>
            <Col className='g_txt_c' span={2}>
              肌钙蛋白I
            </Col>
            <Col className='g_txt_c' span={2}>
              B型钠尿肽
            </Col>
            <Col className='g_txt_c' span={2}>
              DDIM
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              测量次数
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].num : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
             心标平均值
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].average : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
            心标最大值
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].max : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
            心标最小值
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].min : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              理想频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].theIdealFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              过高频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].highFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              过低频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[5].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[0].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[1].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[2].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[3].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {theHeartById.length > 0 ? theHeartById[4].underFrequency : ''}
            </Col>
          </Row>
        </div>

        <Divider plain className='g_mb_30' />
        <div className='g_txt_c'>
          <Button className='g_mr_20' onClick={onCancel}>
            关闭
          </Button>
        </div>
      </Spin>
    </Card>
  )
}

export default AddDetailsForm

/* 年龄分布 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'

/* 使用类型检查
---------------------------------------------------------------- */
AgePie.prototype = {
  data: PropTypes.object
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AgePie(props) {
  const { data } = props
  // console.log(data)

  const [myChart, setMyChart] = useState('')

  /**
   * 生命周期
   */
  useEffect(() => {}, [])

  const getChart = (myChart, data) => {
    if (myChart !== '') {
      let result = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          result.push({ value: data[key].num, name: data[key].age })
        }
      }
      // console.log(result)
      const option = {
        // grid: {
        //   top: 30,
        //   right: '5%',
        //   bottom: 20,
        //   left: '5%'
        // },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            data: result,
            radius: [0, '50%'],
            label: {
              // formatter: '{b} {c}',
              color: '#fff',
              fontSize: 14,
              fontFamily: 'PingFang SC',
              fontWight: 400
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // setInterval(() => {
      //   option && myChart.setOption(option)
      // }, 3000)
        option && myChart.setOption(option)

    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    const node = document.getElementById('age')
    const myChart = echarts.init(node)
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, data)
  }, [myChart, data])

  return <div id='age' className='bi_chart2'></div>
}

export default AgePie

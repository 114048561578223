/*
能发送 ajax 请求的函数模块
包装 axios 函数的返回值是 promise 对象
axios.get()/post()返回的就是 promise 对象
返回自己创建的 promise 
  对象: 统一处理请求异常 
  异步返回结果数据, 而不是包含结果数据的 response
*/

/* 调用模块
---------------------------------------------------------------- */
import axios from 'axios'
import { message } from 'antd'

/* 功能模块
---------------------------------------------------------------- */
import storageUtils from '../utils/storageUtils'
import memoryUtils from '../utils/memoryUtils'

/* 唯一的模块导出
---------------------------------------------------------------- */
export default function ajax(url, data = {}, method = 'GET') {
  if (method === 'GET') {
    return axios.get(url, { params: data }) // params 配置指定的是 query 参数
  } else {
    return axios.post(url, data)
  }
}

/**
 * request 请求异常拦截
 */
axios.interceptors.request.use(
  (config) => {
    // const { url } = config
    // if (url === '/v1/westgard/downloadExcel') {
    //   return { ...config, responseType: 'arraybuffer' }
    // }
    return config
  },
  (err) => {
    message.error({ message: '请求超时!' })
    return Promise.resolve(err)
  }
)

/**
 * response 响应异常拦截
 */
axios.interceptors.response.use(
  (res) => {
    // console.log(res)
    const { url } = res.config
    if (url === '/v1/westgard/downloadExcel') {
      return res
    } else {
      const result = res.data
      if (!result.status) {
        message.error(result.data)
      }
      return result
    }
  },

  (err) => {
    //==============  错误处理  ====================
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          message.error('请求错误(400)')
          break
        case 401:
          // 401 跳转到登录页
          message.error('未授权，请重新登录(401)')
          // 403 无权限操作 清空缓存
          // 移除保存的user
          storageUtils.removeUser()
          memoryUtils.user = {}
          // 跳转到login
          window.location.href = '/login'
          break
        case 403:
          message.error('无操作权限。')
          // console.log('拒绝访问(403)')
          break
        case 404:
          message.error('请求出错(404)')
          break
        case 408:
          message.error('请求超时(408)')
          break
        case 500:
          message.error('服务器错误(500)')
          break
        case 501:
          message.error('服务未实现(501)')
          break
        case 502:
          message.error('网络错误(502)')
          break
        case 503:
          message.error('服务不可用(503)')
          break
        case 504:
          message.error('网络超时(504)')
          break
        case 505:
          message.error('HTTP版本不受支持(505)')
          break
        default:
          message.error(`连接出错(${err.response.status})!`)
          break
      }
    } else {
      message.error('连接服务器失败!')
    }
    return Promise.resolve(err)
  }
)

/*修改医嘱弹框 */

// ==================
// 调用模块
// ==================
import React, { useEffect } from 'react'
import moment from 'moment'
import { Form, Button, Row, Col, Input, Divider,message } from 'antd'
import PropTypes from 'prop-types'

// ==================
// 功能模块
// ==================
import {reqUpdatePrescription} from '../../../api'
import { GENDER, PATIENT_IN_STATUS } from '../../../utils/constants'

// ==================
// 调用模块: 对象解构
// ==================
const { Item } = Form
const { TextArea } = Input

// ==================
// 使用类型检查
// ==================
UpdateAdviceForm.propTypes = {
  ncd:PropTypes.object.isRequired,
  commonRow:PropTypes.object.isRequired,
  onShowModal: PropTypes.func.isRequired,
  refresh:PropTypes.func.isRequired,
}

function UpdateAdviceForm(props) {
  const { commonRow,ncd,onShowModal,refresh } = props
  // 提交表单
  const [form] = Form.useForm()

// console.log(commonRow);


   /**
   * 修改诊疗医嘱信息
   */
    const onOk = async (values) => {
      const { prescription, remarks } = values
      const params = {
        id: commonRow.id,
        prescription,
        remarks
      }
      const { status } = await reqUpdatePrescription(params)
      if (status) {
        message.success('修改成功！')
        onShowModal()
        form.resetFields()
        refresh()
      }
    }

  /**
   * 生命周期
   */
  useEffect(() => {
    form.setFieldsValue(ncd)
  }, [ form, ncd])

  return (
    <Form form={form} onFinish={onOk}>
      <Row gutter={10}>
        <Col span={6}>
          <span>姓名：</span>
          <span>{commonRow.patient.name}</span>
        </Col>
        <Col span={6}>
          <span>性别：</span>
          <span>{commonRow.patient.gender > -1 && GENDER[commonRow.patient.gender].name}</span>
        </Col>
        <Col span={6}>
          <span>床位号：</span>
          <span>{commonRow.bedCards}</span>
        </Col>
        <Col span={6}>
          <span>就诊号：</span>
          <span>{commonRow.patientNo}</span>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <span>医嘱科室：</span>
          <span>{commonRow.office.name}</span>
        </Col>
        <Col span={6}>
          <span>医嘱人：</span>
          <span>{commonRow.user.name}</span>
        </Col>
        <Col span={6}>
          <span>会诊类型：</span>
          <span>{commonRow.status > -1 && PATIENT_IN_STATUS[commonRow.status].name}</span>
        </Col>
        <Col span={6}>
          <span>医嘱日期：</span>
          <span>{moment(commonRow.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>
        </Col>
      </Row>
      <Divider />
      {/* <Descriptions className='margin-b-10' bordered size='small' column={1}> */}
      <Item label='治疗医嘱' className={{ width: 100, textAlign: 'center' }} name='prescription'>
        <TextArea rows={4} showCount maxLength={100} />
      </Item>
      <Item label='备注说明' className={{ width: 100, textAlign: 'center' }} name='remarks'>
        <TextArea rows={4} showCount maxLength={100} />
      </Item>
      {/* </Descriptions> */}
      <div className='text-center '>
        <Button type='primary' className='g_mr_20' key='back' onClick={onShowModal}>
          关闭
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default UpdateAdviceForm

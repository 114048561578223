/* 凝血监测 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Card, Button, Tabs,Empty } from 'antd'
import { TeamOutlined, UserOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons'

/* 公共模块
---------------------------------------------------------------- */
import { PatientList, QueryForm } from '../../../components/Patient'
import BCRecordPane from '../../../components/Patient/BCRecordPane'

/* 子模块
---------------------------------------------------------------- */
import AddRecordForm from './AddRecordForm'
import UpdateBcForm from './UpdateBCForm'


/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { TabPane } = Tabs

/* 唯一的模块导出
---------------------------------------------------------------- */
function BloodClotting() {
  // 查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [patientPage, setPatientPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 分页相关参数(公共)
  const [commonPage, setCommonPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 按钮事件的点击行为
  const [operation, setOperation] = useState({
    action: undefined, // 操作行为：addBg | addWarning | upBg
    data: {} // 患者信息
  })

  // 条件查询参数
  const [condition, setCondition] = useState({
    companyId: undefined,
    officeId: undefined,
    inpatientWardId: undefined,
    bedCards: undefined,
    patientNo: undefined,
    hospitalizationCards: undefined,
    searchData: undefined,
    status: undefined
  })


  // 患者的一行记录
  const [patientRow, setPatientRow] = useState({})

  //公共的一行记录
  const [commonRow, setCommonRow] = useState({})


  /**
   * 按钮
   * 点击条件查询显示 / 隐藏
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   *按钮
   点击获取类型和值
   * @param {*} action
   * @param {*} data
   */
  const onShowOperation = (action, data) => {
    setOperation({ action, data })
  }

  /**
   * 渲染
   */
  if (operation.action === undefined) {
    return (
      <>
        <div className='g_fx_lr g_h_percent'>
          <Card
            className='g_w_385 g_mr_10'
            size='small'
            title={
              <h3 className='margin-clear'>
                <TeamOutlined className='g_mr_10' />
                患者列表
              </h3>
            }
          >
            <PatientList
            patientPage={patientPage}
            patientRow={patientRow}
            condition={condition}
            setPatientPage={(params) => setPatientPage(params)}
            setPatientRow={(params) => setPatientRow(params)}
            setCommonPage={() => setCommonPage({pageNo: 1, pageSize: PAGE_SIZE})}
            setCommonRow={() => setCommonRow({})}
          />
          </Card>
          <Card
            className='g_fx_a g_minw_705'
            size='small'
            title={
              <h3 className='margin-clear'>
                <UserOutlined className='g_mr_10' />
                凝血监测
              </h3>
            }
            extra={
              <>
                <Button className='g_ml_10' icon={<FilterOutlined />} onClick={showQuery}>
                  {query ? '隐藏' : '查询'}
                </Button>
                <Button
                  className='g_ml_10'
                  type='primary'
                  onClick={() => onShowOperation('addClotting', {})}
                  icon={<PlusOutlined />}
                  disabled={patientRow.id ? false :true}
                >
                  凝血录入
                </Button>
              </>
            }
          >
            {query && (
              <QueryForm
                setCondition={(params) => setCondition({ ...condition, ...params })}
                setPatientPage={() => setPatientPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              />
            )}
            <Tabs
              defaultActiveKey='1'
              type='card'
              tabBarGutter={-1}
            >
              <TabPane tab='凝血记录表' key='1'>
                {patientRow.id ? (
                  <BCRecordPane
                    patientRow={patientRow}
                    commonRow={commonRow}
                    commonPage={commonPage}
                    onShowOperation={onShowOperation}
                    setCommonPage={(params) => setCommonPage(params)}
                    setCommonRow={(params) => setCommonRow(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </>
    )
  }
  if (operation.action === 'addClotting') {
    return (
      <AddRecordForm
      onCancel={() => setOperation({ ...operation, action: undefined })}
      patientRow={patientRow}
      setShow={() => setOperation({ ...operation, action: undefined })}
      />
    )
  }

  if (operation.action === 'upClotting') {
    return (
      <UpdateBcForm
      operation={operation}
      setOperation={() => setOperation({ ...operation, action: undefined })}
      patientRow={patientRow}
      />
    )
  }
}

export default BloodClotting

/* 血凝报表 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, Table, Tooltip } from 'antd'
import { FileExcelOutlined, FilterOutlined, ToolOutlined, ProfileOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 子模块
---------------------------------------------------------------- */
import OfficeQueryForm from '../../../components/Patient/OfficeQueryForm'
import AddWarningForm from './AddWarningForm'
import AddDetailsForm from './AddDetailsForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodClotPatientList, reqInstitutionTrees } from '../../../api'
import { mergeRid } from '../../../utils/func'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function BloodClotting() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 总记录数
  const [total, setTotal] = useState(0)

  // 患者一行的记录
  const [patientRow, setPatientRow] = useState({})

  const [bloodClotPatients, setBloodClotPatients] = useState([])

  // 查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  //切换  1：预警设置 / 2.详情的隐藏/显示
  const [show, setShow] = useState(0)

  // 分页相关参数
  const [patientPage, setPatientPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院id
    officeId: undefined, // 科室id
    inpatientWardId: undefined, // 病区id
    bedCards: undefined, // 床位号
    hospitalizationCards: undefined, // 住院号
    searchData: undefined, // 条件查询: 姓名字段
    startTime: undefined,
    endTime: undefined,
    status: undefined
  })

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  /**
   * 初始化显示 table 所有列
   */
  const initColumns = [
    {
      title: '序号',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '检测时间',
      dataIndex: 'detectionTime',
      width: '',
      align: 'center',
      render: (_, { detectionTime }) => moment(detectionTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '患者姓名',
      dataIndex: 'name',
      width: '',
      align: 'center'
    },
    {
      title: '门诊号',
      dataIndex: 'patientNo',
      width: '',
      align: 'center'
    },
    {
      title: '住院号',
      dataIndex: 'hospitalizationCards',
      width: '',
      align: 'center'
    },
    {
      title: '床位号',
      dataIndex: 'bedCards',
      width: '',
      align: 'center'
    },
    {
      title: '异常项目数',
      dataIndex: 'num',
      width: '',
      align: 'center'
    },
    {
      title: '医院',
      dataIndex: 'company',
      width: '',
      align: 'center',
      render: (_, { company }) => <>{company && company.name}</>
    },
    {
      title: '科室',
      dataIndex: 'office',
      width: '',
      align: 'center',
      render: (_, { office }) => <>{office && office.name}</>
    },
    {
      title: '病区',
      dataIndex: 'inpatientWard',
      width: '',
      align: 'center',
      render: (_, { inpatientWard }) => <>{inpatientWard && inpatientWard.name}</>
    },
    {
      title: '操作人',
      dataIndex: 'user',
      width: '',
      align: 'center',
      render: (_, { user }) => <>{user.name}</>
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '',
      align: 'center',
      render: (text) => (
        <Tooltip title='详情' mouseEnterDelay={0.5}>
          <Button type='link' onClick={() => showAddviceDetail()} icon={<ProfileOutlined />} />
        </Tooltip>
      )
    }
  ]

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutionTrees()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 血凝列表患者列表数据
   * @param {*} params
   */
  const getBloodClotPatients = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqBloodClotPatientList(params)
      if (status) {
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        setBloodClotPatients(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 按钮
   * 点击展开预警设置
   * @param {*} id
   */
  const showAddWarning = (id) => {
    setShow(1)
  }

  /**
   * 分页器
   * 切换当前页
   */
  const onChangePage = (pageNo, pageSize) => {
    setPatientPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (patientRow) return record.id === patientRow.id ? 'g_row_bg' : ''
  }

  /**
   * 按钮
   * 点击展开详情
   * @param {*} id
   */
  const showAddviceDetail = (id) => {
    setShow(2)
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const showQuery = (query) => {
    if (query) {
      return (
        <OfficeQueryForm
          institutions={institutions}
          setCondition={(params) => setCondition({ ...condition, ...params })}
          setPatientPage={() => setPatientPage({ pageNo: 1, pageSize: PAGE_SIZE })}
        />
      )
    }
    return null
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()

    return () => {
      setInstitutions((_) => {
        return
      })
    }
  }, [])

  useEffect(() => {
    getBloodClotPatients({ pageNo: patientPage.pageNo, pageSize: patientPage.pageSize, ...condition })
  }, [patientPage.pageNo, patientPage.pageSize, condition])

  /**
   * 渲染
   */
  if (show === 0) {
    return (
      <>
        <Card
          className='height-percent'
          size='small'
          title={
            <h3 className='margin-clear'>
              <FileExcelOutlined className='g_mr_10' />
              凝血报表
            </h3>
          }
          extra={
            <>
              <Button className='g_mr_10' icon={<FilterOutlined />} onClick={() => setQuery(!query)}>
                {query ? '隐藏' : '查询'}
              </Button>
              <Button className='g_ml_10' type='primary' onClick={() => showAddWarning()} icon={<ToolOutlined />}>
                预警设置
              </Button>
            </>
          }
        >
          {showQuery(query)}

          <Table
            bordered
            size='small'
            rowKey='rid'
            loading={isLoading}
            columns={initColumns}
            dataSource={bloodClotPatients}
            rowClassName={showRowClassName}
            pagination={{
              current: patientPage.pageNo,
              pageSize: patientPage.pageSize,
              showQuickJumper: true,
              showSizeChanger: false,
              total: total,
              showTotal: (total) => `共 ${total} 条记录`,
              onChange: onChangePage
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setPatientRow(record)
                }
              }
            }}
          />
        </Card>
      </>
    )
  }
  if (show === 1) {
    return (
      <AddWarningForm
        onCancel={() => {
          setShow(0)
        }}
        onShow={() => {
          setShow(0)
        }}
      />
    )
  }
  if (show === 2) {
    return (
      <AddDetailsForm
        patientRow={patientRow}
        onCancel={() => {
          setShow(0)
        }}
      />
    )
  }
}

export default BloodClotting

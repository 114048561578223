/* 工作量 */

/*  调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, Table } from 'antd'
import { DiffOutlined, FilterOutlined, ArrowDownOutlined } from '@ant-design/icons'
import moment from 'moment'

/*子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import WorkDownLoad from './WorkDownLoad'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'
import { mergeRid } from '../../../utils/func'

/* 功能模块
---------------------------------------------------------------- */
import { reqWorkload, reqInstitutionTrees } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'

/* 唯一的模块导出
---------------------------------------------------------------- */
function WorkLoad() {
  // 查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 患者一行的记录
  const [patientRow, setPatientRow] = useState({})

  // 总记录数
  const [total, setTotal] = useState(0)

  // 弹框相关参数
  const [modal, setModal] = useState({
    isShow: false,
    data: {}
  })

  // 分页相关参数
  const [patientPage, setPatientPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院id
    officeId: undefined, // 科室id
    inpatientWardId: undefined, // 病区id
    no: undefined, //职工号
    searchData: undefined, // 条件查询: 姓名字段
    startTime: undefined
  })

  // 工作量
  const [work, setWork] = useState([])

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  /**
   * 初始化显示 table 所有列
   */
  const initColumns = [
    {
      title: '序号',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '所属医院',
      dataIndex: 'company',
      width: '',
      align: 'center',
      render: (_, { company }) => <>{company.name}</>
    },
    {
      title: '所属科室',
      dataIndex: 'office',
      width: '',
      align: 'center',
      render: (_, { office }) => <>{office.name}</>
    },
    {
      title: '职工号',
      dataIndex: 'no',
      width: '',
      align: 'center'
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: '',
      align: 'center'
    },
    {
      title: '月份',
      dataIndex: 'detectionTime',
      width: '',
      align: 'center',
      render: (_, { detectionTime }) => moment(detectionTime).format('YYYY-MM')
    },
    {
      title: '测量数量',
      dataIndex: 'num',
      width: '',
      align: 'center'
    }
  ]

  /**
   * api
   * 获取机构列表
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutionTrees()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 工作量
   * @param {*} params
   */
  const getWork = async (params) => {
    setIsLoading(true)
    try {
      const { data: { resultList, totalRows } = {}, status } = await reqWorkload(params)
      if (status) {
        const result = resultList && mergeRid(resultList, params.pageNo, params.pageSize)
        setWork(result)
        setTotal(totalRows)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 分页器
   * 切换当前页
   */
  const onChangePage = (pageNo, pageSize) => {
    setPatientPage({ pageNo, pageSize })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} _
   */
  const showRowClassName = (record, _) => {
    if (patientRow) return record.id === patientRow.id ? 'g_row_bg' : ''
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   *
   * @param {*} record
   */
  const onShowModal = (data) => {
    setModal({ isShow: true, data })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()

    return () => {
      setInstitutions((_) => {
        return
      })
    }
  }, [])

  useEffect(() => {
    getWork({ pageNo: patientPage.pageNo, pageSize: patientPage.pageSize, ...condition })
  }, [patientPage.pageNo, patientPage.pageSize, condition])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <DiffOutlined className='g_mr_10' />
          工作量(仅统计护士血糖监测试纸用量)
        </h3>
      }
      extra={
        <>
          <Button className='g_mr_10' icon={<FilterOutlined />} onClick={showQuery}>
            {query ? '隐藏' : '查询'}
          </Button>
          <Button
            className='g_ml_10'
            type='primary'
            icon={<ArrowDownOutlined />}
            onClick={(record) => onShowModal(record)}
          >
            下载
          </Button>
        </>
      }
    >
      {query && (
        <QueryForm
          institutions={institutions}
          setCondition={(params) => setCondition({ ...condition, ...params })}
          setPatientPage={() => setPatientPage({ pageNo: 1, pageSize: PAGE_SIZE })}
        />
      )}
      <Table
        bordered
        size='small'
        rowKey='rid'
        loading={isLoading}
        columns={initColumns}
        dataSource={work}
        rowClassName={showRowClassName}
        pagination={{
          current: patientPage.pageNo,
          pageSize: patientPage.pageSize,
          showQuickJumper: true,
          showSizeChanger: false,
          total: total,
          showTotal: (total) => `共 ${total} 条记录`,
          onChange: onChangePage
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setPatientRow(record)
            }
          }
        }}
      />
      <IModal visible={modal.isShow} titleText='工作量报表导出'>
        <WorkDownLoad modal={modal.data} setModal={() => setModal({ ...modal, isShow: false })} />
      </IModal>
    </Card>
  )
}

export default WorkLoad

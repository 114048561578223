/* 设置固定均值 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Input, message, Spin } from 'antd'

/* 功能模块
---------------------------------------------------------------- */
import { reqQueryQcFixed, reqEditQcFixed } from '../../../api'

/* 静态资源
---------------------------------------------------------------- */
import { errorMsg } from '../../../utils/constants'

/* 使用类型检查
---------------------------------------------------------------- */
FixedMeanForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  modal: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function FixedMeanForm(props) {
  const { monitor, modal, setModal } = props

  // 加载中
  const [loading, setLoading] = useState(false)

  // 表单信息
  const [form, setForm] = useState({})

  const {
    controlId,
    type,
    instrument: { brand, modelVO, snnum },
    controlProject,
    controlThings: { levelNum }
  } = modal.data

  /**
   * api
   * 通过质控 id 获取对应的质控均值
   * @param {*} params
   */
  const getQcFixed = async (params) => {
    setLoading(true)
    try {
      const { data, status } = await reqQueryQcFixed(params)
      if (status) {
        setForm(data)
      }
    } finally {
      setLoading(false)
    }
  }

  /**
   * 收集 form 信息
   * @param {*} e
   */
  const onChangeValue = (e) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  /**
   * 列表操作：设置固定均值
   * 按钮：点击确定
   * @param {*} e
   */
  const onOkQcFixedMean = async (e) => {
    e.preventDefault()
    const { level1MV, level1SDCV, level2MV, level2SDCV, level3MV, level3SDCV, qualityControlId } = form
    let params = {
      level1MV: level1MV ? parseFloat(level1MV).toFixed(2) : null,
      level1SDCV: level1SDCV ? parseFloat(level1SDCV).toFixed(2) : null,
      level2MV: level2MV ? parseFloat(level2MV).toFixed(2) : null,
      level2SDCV: level2SDCV ? parseFloat(level2SDCV).toFixed(2) : null,
      level3MV: level3MV ? parseFloat(level3MV).toFixed(2) : null,
      level3SDCV: level3SDCV ? parseFloat(level3SDCV).toFixed(2) : null,
      qualityControlId
    }
    const { status } = await reqEditQcFixed(params)
    if (status) {
      message.success('设置固定均值成功！')
      setModal()
    }
  }

  /**
   * 列表操作：设置固定均值
   * 按钮：点击取消
   */
  const onCancel = () => {
    setModal()
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getQcFixed({ controlId })
  }, [controlId])

  /**
   * 渲染
   */
  return (
    <>
      <Row className='g_mb_10' gutter={10}>
        <Col span={8}>类型：{monitor[type - 1].name}</Col>
        <Col span={8}>品牌：{brand.name}</Col>
        <Col span={8}>型号：{modelVO.name}</Col>
        <Col span={8}>SN号：{snnum}</Col>
        <Col span={8}>项目：{controlProject.name}</Col>
      </Row>
      <Spin spinning={loading}>
        <form onSubmit={onOkQcFixedMean}>
          {levelNum >= 1 && (
            <Row className='g_mb_10' gutter={10}>
              <Col span={8}>
                <span className='g_sign_no'>水平1</span>
                <Input
                  className='g_w_174'
                  name='level1MV'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={'level1MV' in form ? form.level1MV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>SD</span>
                <Input
                  className='g_w_174'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  name='level1SDCV'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={form.level1SDCV ? form.level1SDCV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>CV</span>
                <Input
                  className='g_w_174'
                  value={form.level1MV && form.level1SDCV ? ((form.level1SDCV / form.level1MV).toFixed(2)*100) + '%' : undefined}
                  readOnly
                />
              </Col>
            </Row>
          )}
          {levelNum >= 2 && (
            <Row className='g_mb_10' gutter={10}>
              <Col span={8}>
                <span className='g_sign_no'>水平2</span>
                <Input
                  className='g_w_174'
                  name='level2MV'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={'level2MV' in form ? form.level2MV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>SD</span>
                <Input
                  className='g_w_174'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  name='level2SDCV'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={form.level2SDCV ? form.level2SDCV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>CV</span>
                <Input
                  className='g_w_174'
                  value={form.level2MV && form.level2SDCV ? ((form.level2SDCV / form.level2MV).toFixed(2)*100) + '%' : undefined}
                  readOnly
                />
              </Col>
            </Row>
          )}
          {levelNum >= 3 && (
            <Row className='g_mb_10' gutter={10}>
              <Col span={8}>
                <span className='g_sign_no'>水平3</span>
                <Input
                  className='g_w_174'
                  name='level3MV'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={'level3MV' in form ? form.level3MV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>SD</span>
                <Input
                  className='g_w_174'
                  pattern='^[\-,\+]?\d+(\.\d+)?$'
                  name='level3SDCV'
                  title={errorMsg[0].name}
                  onChange={onChangeValue}
                  value={form.level3SDCV ? form.level3SDCV : undefined}
                />
              </Col>
              <Col span={8}>
                <span className='g_sign_no'>CV</span>
                <Input
                  className='g_w_174'
                  value={form.level3MV && form.level3SDCV ? ((form.level3SDCV / form.level3MV).toFixed(2)*100) + '%' : undefined}
                  readOnly
                />
              </Col>
            </Row>
          )}

          <div className='g_txt_c'>
            <Button className='g_mr_20' key='back' onClick={onCancel}>
              取消
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              确定
            </Button>
          </div>
        </form>
      </Spin>
    </>
  )
}

export default FixedMeanForm

/*
  1. 登录后, 刷新后依然是已登录状态 (维持登录) 
  2. 登录后, 关闭浏览器后打开浏览器访问依然是已登录状态 (自动登录)
  3. 登录后, 访问登录路径自动跳转到管理界面
*/

/* 调用模块
---------------------------------------------------------------- */
import store from 'store'

/* 常量
---------------------------------------------------------------- */
const USER_KEY = 'user_key'

const storageUtils = {
  saveUser(user) {
    store.set(USER_KEY, user)
  },
  getUser() {
    return store.get(USER_KEY) || {}
  },
  removeUser() {
    store.remove(USER_KEY)
  }
}
export default storageUtils
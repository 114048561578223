/* 血糖监测 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Card, Button, Tabs, Empty } from 'antd'
import { TeamOutlined, FilterOutlined, PlusOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import AddBSForm from './AddBSForm'
import AddInsulinForm from './AddInsulinForm'
import AddNCDForm from './AddNCDForm'
import AddWarningForm from './AddWarningForm'
import AddAdviceForm from './AddAdviceForm'

/* 功能模块
---------------------------------------------------------------- */
import {
  PatientList,
  QueryForm,
  BSRecordPane,
  BSPerspectivePane,
  BSAtlasPane,
  BSAdvicePane,
  BSInsulinPane,
  BSNCDPane,
  BSFat
} from '../../../components/Patient'

/* 静态资源
---------------------------------------------------------------- */
import { PAGE_SIZE } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { TabPane } = Tabs

/* 唯一的模块导出
---------------------------------------------------------------- */
function BloodSugar() {
  // 按钮事件的点击行为
  const [operation, setOperation] = useState({
    action: undefined, // 操作行为：addBs(新建血糖) | addWarning（新建预警） | upBs（修改血糖）
    data: {} // 患者信息
  })

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 弹框切换显示: 0 隐藏 | 1 新建医嘱 | 2 胰岛素录入 | 3 慢病录入
  const [modal, setModal] = useState(0)

  // 分页相关参数
  const [patientPage, setPatientPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE
  })

  // 分页相关参数
  const [commonPage, setCommonPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE // 每页数量
  })

  // 条件查询参数
  const [condition, setCondition] = useState({
    companyId: undefined,
    officeId: undefined,
    inpatientWardId: undefined,
    bedCards: undefined,
    patientNo: undefined,
    hospitalizationCards: undefined,
    searchData: undefined,
    status: undefined
  })

  // 患者的一行记录
  const [patientRow, setPatientRow] = useState({})

  //公共的一行记录
  const [commonRow, setCommonRow] = useState({})

  // 标记
  const [flag, setFlag] = useState(false)

  /**
   * 按钮
   * 点击条件查询显示 / 隐藏
   */
  const showQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   *按钮
   点击获取类型和值
   * @param {*} action
   * @param {*} data
   */
  const onShowOperation = (action, data) => {
    setOperation({ action, data })
  }

  /**
   * 按钮
   * 点击切换到新建医嘱
   */
  const showAddAdvice = (value) => {
    setModal(1)
  }

  /**
   * 按钮
   * 点击显示胰岛素录入弹框
   */
  const showAddInsulin = () => {
    setModal(2)
  }

  /**
   * 按钮
   * 点击显示慢病录入弹框
   */
  const showAddNCD = () => {
    setModal(3)
  }

  /**
   * 渲染
   */
  if (operation.action === undefined) {
    return (
      <>
        <div className='g_fx_lr g_h_percent'>
          <Card
            className='g_w_385 g_mr_10'
            size='small'
            title={
              <h3 className='margin-clear'>
                <TeamOutlined className='g_mr_10' />
                患者列表
              </h3>
            }
          >
            <PatientList
              patientPage={patientPage}
              patientRow={patientRow}
              condition={condition}
              setPatientPage={(params) => setPatientPage(params)}
              setPatientRow={(params) => setPatientRow(params)}
              setCommonPage={() => setCommonPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              setCommonRow={() => setCommonRow({})}
            />
          </Card>
          <Card
            className='g_fx_a g_minw_705'
            size='small'
            title={
              <h3 className='margin-clear'>
                <UserOutlined className='g_mr_10' />
                血糖监测
              </h3>
            }
            extra={
              <>
                <Button className='g_mr_10' icon={<FilterOutlined />} onClick={showQuery}>
                  {query ? '隐藏' : '查询'}
                </Button>
                <Button
                  className='g_mr_10'
                  type='primary'
                  onClick={() => onShowOperation('addBs', {})}
                  icon={<PlusOutlined />}
                  disabled={patientRow.id ? false : true}
                >
                  血糖录入
                </Button>
                <Button
                  className='g_mr_10'
                  type='primary'
                  onClick={() => showAddAdvice()}
                  icon={<PlusOutlined />}
                  disabled={patientRow.id ? false : true}
                >
                  新建血糖医嘱
                </Button>
                <Button
                  className='g_mr_10'
                  type='primary'
                  onClick={() => showAddInsulin()}
                  icon={<PlusOutlined />}
                  disabled={patientRow.id ? false : true}
                >
                  胰岛素录入
                </Button>
                <Button
                  className='g_mr_10'
                  type='primary'
                  onClick={() => showAddNCD()}
                  icon={<PlusOutlined />}
                  disabled={patientRow.id ? false : true}
                >
                  慢病录入
                </Button>
                <Button
                  className='g_mr_10'
                  type='primary'
                  onClick={() => onShowOperation('addWarning', {})}
                  icon={<ToolOutlined />}
                  disabled={patientRow.id ? false : true}
                >
                  预警设置
                </Button>
              </>
            }
          >
            {query && (
              <QueryForm
                setCondition={(params) => setCondition({ ...condition, ...params })}
                setPatientPage={() => setPatientPage({ pageNo: 1, pageSize: PAGE_SIZE })}
              />
            )}

            <Tabs defaultActiveKey='1' type='card' tabBarGutter={-1}>
              <TabPane tab='血糖记录表' key='1' disabled={patientRow.id ? false : true}>
                {patientRow.id ? (
                  <BSRecordPane
                    patientRow={patientRow}
                    commonPage={commonPage}
                    setCommonPage={(params) => setCommonPage(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
              <TabPane tab='血糖透视表' key='2' disabled={patientRow.id ? false : true}>
                {patientRow.id ? (
                  <BSPerspectivePane
                  flag={flag}
                    patientRow={patientRow}
                    commonPage={commonPage}
                    setCommonPage={(params) => setCommonPage(params)}
                  />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </TabPane>
              <TabPane tab='血糖图谱' key='3' disabled={patientRow.id ? false : true}>
                <BSAtlasPane patientRow={patientRow} />
              </TabPane>
              <TabPane tab='血糖医嘱' key='4' disabled={patientRow.id ? false : true}>
                <BSAdvicePane
                  flag={flag}
                  patientRow={patientRow}
                  commonRow={commonRow}
                  commonPage={commonPage}
                  setCommonPage={(params) => setCommonPage(params)}
                  setCommonRow={(params) => setCommonRow(params)}
                />
              </TabPane>
              <TabPane tab='胰岛素治疗' key='5' disabled={patientRow.id ? false : true}>
                <BSInsulinPane
                  flag={flag}
                  patientRow={patientRow}
                  commonRow={commonRow}
                  commonPage={commonPage}
                  setCommonPage={(params) => setCommonPage(params)}
                  setCommonRow={(params) => setCommonRow(params)}
                />
              </TabPane>
              <TabPane tab='慢病管理' key='6' disabled={patientRow.id ? false : true}>
                <BSNCDPane
                  flag={flag}
                  patientRow={patientRow}
                  commonRow={commonRow}
                  commonPage={commonPage}
                  setCommonPage={(params) => setCommonPage(params)}
                  setCommonRow={(params) => setCommonRow(params)}
                />
              </TabPane>
              <TabPane tab='糖化血脂' key='7' disabled={patientRow.id ? false : true}>
                <BSFat
                  patientRow={patientRow}
                  commonRow={commonRow}
                  commonPage={commonPage}
                  setCommonPage={(params) => setCommonPage(params)}
                  setCommonRow={(params) => setCommonRow(params)}
                />
              </TabPane>
            </Tabs>
          </Card>
        </div>

        <IModal visible={modal === 1} titleText='新建医嘱' width={800}>
          <AddAdviceForm
            flag={flag}
            patientRow={patientRow}
            onCancel={() => setModal(0)}
            setModal={() => setModal(0)}
            setFlag={(params) => setFlag(params)}
          />
        </IModal>

        <IModal visible={modal === 2} titleText='胰岛素录入' width={680}>
          <AddInsulinForm
            flag={flag}
            patientRow={patientRow}
            onCancel={() => setModal(0)}
            setModal={() => setModal(0)}
            setFlag={(params) => setFlag(params)}
          />
        </IModal>

        <IModal visible={modal === 3} titleText='慢病录入' width={800}>
          <AddNCDForm flag={flag} patientRow={patientRow} onCancel={() => setModal(0)} setModal={() => setModal(0)} setFlag={(params) => setFlag(params)}/>
        </IModal>
      </>
    )
  }
  if (operation.action === 'addBs') {
    return (
      <AddBSForm
        patientRow={patientRow}
        onCancel={() => setOperation({ ...operation, action: undefined })}
        setShow={() => setOperation({ ...operation, action: undefined })}
      />
    )
  }
  if (operation.action === 'addWarning') {
    return (
      <AddWarningForm
        patientRow={patientRow}
        onCancel={() => setOperation({ ...operation, action: undefined })}
        setShow={() => setOperation({ ...operation, action: undefined })}
      />
    )
  }
}

export default BloodSugar

/* 包含 n 个接口请求函数的模块 每个函数返回 promise */

/* 调用模块
---------------------------------------------------------------- */
import ajax from './ajax'

/* 常量：确定应该将匹配baseURL开头的请求代理到目标主机
---------------------------------------------------------------- */
const baseURL = '/v1'

/* 登录
---------------------------------------------------------------- */

// 请求登录
export const reqLogin = (params) => ajax(baseURL + '/user/login', params, 'POST')

// 请求菜单列表
// export const reqMenus = () => ajax('/menuList')
export const reqMenus = () => ajax(baseURL + '/menu/menuList')

/* 请求大屏信息
---------------------------------------------------------------- */
export const reqBI = (params) => ajax(baseURL + '/bigScreen/bigScreenBloodSugarPage', params)

/* 消息通知
---------------------------------------------------------------- */
// 请求 token
export const reqChatToken = () => ajax(baseURL + '/getChatToken')

// 请求 ws 协议的消息
// export const req

/* 患者管理
---------------------------------------------------------------- */

// 请求患者列表
export const reqPatients = (params) => ajax(baseURL + '/patient/patientList', params, 'POST')

// 请求查询患者信息: 根据患者id
export const reqPatientById = (params) => ajax(baseURL + '/patient/patientById', params)

// 请求民族列表
export const reqNations = () => ajax(baseURL + '/patient/findPatientNationList')

// 请求患者性别
export const reqGebders = () => ajax(baseURL + '/patient/findGenderList')

// 获取文化程度列表
export const reqEducations = () => ajax(baseURL + '/patient/findEducationList')

// 获取婚姻状态列表
export const reqMaritalStatus = () => ajax(baseURL + '/patient/findMaritalStatusList')

// 通过角色查询用户列表
export const reqUserByRoles = () => ajax(baseURL + '/patient/findUserByRoles')

// 请求新建患者
export const reqAddPatient = (params) => ajax(baseURL + '/patient/addPatientBaseInfo', params, 'POST')

// 修改患者
export const reqUpdatePatient = (params) => ajax(baseURL + '/patient/updatePatient', params, 'POST')

// 查询科室列表（用户等页面边上的树结构）
export const reqFindOfficeList = (params) => ajax(baseURL + '/office/findOfficeList', params)

//新建会诊 科室列表
export const findConsultationOfficeList = (params) => ajax(baseURL + '/office/findConsultationOfficeList', params)

// 新建会诊
export const reqAddConsultation = (params) =>
  ajax(baseURL + '/patientConsultation/addPatientConsultation', params, 'POST')

// 患者会诊历史接口
export const reqConsultationLists = (params) =>
  ajax(baseURL + '/patientConsultation/patientOfficeConsultationList', params, 'POST')

// 新建医嘱内容模板
export const reqAddTemplate = (params) =>
  ajax(baseURL + '/prescriptionTemplate/addPrescriptionTemplate', params, 'POST')

// 医嘱模板列表接口
export const reqPrescriptionTemplates = (params) =>
  ajax(baseURL + '/prescriptionTemplate/prescriptionTemplateList', params, 'POST')

// 通过id查询医嘱模板信息
export const reqTemplateById = (params) => ajax(baseURL + '/prescriptionTemplate/prescriptionTemplateById', params)

// 修改医嘱模板信息
export const reqUpdateTemplate = (params) =>
  ajax(baseURL + '/prescriptionTemplate/updatePrescriptionTemplate', params, 'POST')

// 新增医嘱
export const reqAddPrescription = (params) => ajax(baseURL + '/patient/addPatientPrescription', params, 'POST')

// 根据患者id 分页查询诊疗医嘱
export const reqPrescriptionById = (params) => ajax(baseURL + '/patient/patientPrescriptionByPatientId', params, 'POST')

// 修改诊疗医嘱
export const reqUpdatePrescription = (params) => ajax(baseURL + '/patient/updatePatientPrescription', params, 'POST')

// 会诊列表接口
export const reqConsultationList = (params) => ajax(baseURL + '/patientConsultation/consultationList', params, 'POST')

// 根据会诊id查询会诊进度
export const reqConsultationById = (params) => ajax(baseURL + '/patientConsultation/PatientConsultationById', params)

// 应诊列表接口
export const reqOfficeConsultations = (params) =>
  ajax(baseURL + '/patientConsultation/officeConsultationList', params, 'POST')

// 取消会诊
export const reqConsultationStatus = (params) =>
  ajax(baseURL + '/patientConsultation/updateConsultationStatus', params, 'POST')

// 新建诊疗意见
export const reqAddConsultationPrescription = (params) =>
  ajax(baseURL + '/patientConsultation/addPatientConsultationPrescription', params, 'POST')

// 患者查询页面接口
export const reqQueryPatient = (params) => ajax(baseURL + '/patient/queryPatient', params, 'POST')

// 查询科室列表（查询科室列表（用作多条件患者查询页面的条件查询使用）
export const reqOfficePatientList = (params) => ajax(baseURL + '/office/findOfficePatientList', params)

// 根据患者id查询患者出院血糖
export const reqPerspectiveByPatientId = (params) =>
  ajax(baseURL + '/patient/dischargeSugarPerspectiveByPatientId', params)

// 根据患者id查询患者入院血糖
export const reqSugarPerspective = (params) => ajax(baseURL + '/patient/beSugarPerspectiveByPatientId', params)

// 出院状态
export const reqPatientsStatus = (params) => ajax(baseURL + '/patient/updatePatientsStatus', params, 'POST')

//根据医院和科室查询护士列表
export const reqNurseList = (params) => ajax(baseURL + '/user/findNurseList', params)

//根据医院和科室查询医生列表
export const reqDoctorList = (params) => ajax(baseURL + '/user/findDoctorList', params)

/* 床旁监测
---------------------------------------------------------------- */

// 慢病录入
export const reqChronicDisease = (params) => ajax(baseURL + '/patientBlood/addChronicDisease', params, 'POST')

// 修改慢病
export const reqUpdateChronicDisease = (params) => ajax(baseURL + '/patientBlood/updateChronicDisease', params, 'POST')

// 查询并发症列表
export const reqComplicationList = () => ajax(baseURL + '/patientBlood/findDicBloodGlucoseComplicationList')

// 根据 id 查询患者慢病页面数据
export const reqChronicDiseaseById = (params) => ajax(baseURL + '/patientBlood/ChronicDiseaseById', params, 'POST')

// 根据 id 查询慢病数据
export const reqDiseaseById = (params) => ajax(baseURL + '/patientBlood/chronicDisease', params)

// 血糖录入
export const reqAddPatientBlood = (params) => ajax(baseURL + '/patientBlood/addPatientBlood', params, 'POST')

// 新增血糖医嘱
export const reqAddBloodPrescription = (params) => ajax(baseURL + '/patientBlood/addBloodPrescription', params, 'POST')

//根据患者id查询血糖医嘱页面数据
export const reqBloodGlucoseById = (params) =>
  ajax(baseURL + '/patientBlood/bloodGlucosePrescriptionById', params, 'POST')

// 根据血糖医嘱id查询血糖医嘱信息
export const reqBloodPrescriptionById = (params) => ajax(baseURL + '/patientBlood/bloodPrescriptionById', params)

// 修改血糖医嘱
export const reqBloodPrescription = (params) => ajax(baseURL + '/patientBlood/updateBloodPrescription', params, 'POST')

// 根据患者id查询患者血糖医嘱数据(正在执行)
export const reqQrescriptionInProgress = (params) =>
  ajax(baseURL + '/patientBlood/prescriptionInProgressById', params, 'POST')

// 查询胰岛素类型枚举列表
export const reqFindInsulinList = () => ajax(baseURL + '/patientBlood/findInsulinList')

// 录入/修改胰岛素
export const reqAddInsulin = (params) => ajax(baseURL + '/patientBlood/addAndUpdatePatientInsulin', params, 'POST')

// 查询胰岛素具体用药时间 （早餐前、餐后。。。）枚举列表
export const reqMedicationItemList = () => ajax(baseURL + '/patientBlood/findInsulinMedicationItemList')

// 根据患者id分页查询胰岛素数据表
export const reqInsulinById = (params) => ajax(baseURL + '/patientBlood/insulinByPatientId', params, 'POST')

// 根据胰岛素id查询胰岛素数据
export const reqinsulin = (params) => ajax(baseURL + '/patientBlood/insulinById', params)

// 血糖预警录入(修改)
export const reqAddWarning = (params) => ajax(baseURL + '/warning/addWarning', params, 'POST')

// 血糖预警录入（回显）
export const reqAddWarningById = (params) => ajax(baseURL + '/warning/warningById', params, 'POST')

// 根据患者id查询患者血糖记录表
export const reqQatientBloodById = (params) => ajax(baseURL + '/patientBlood/patientBloodById', params, 'POST')

// 根据患者id查询血糖透视表
export const reqSugarPerspectiveById = (params) => ajax(baseURL + '/patientBlood/sugarPerspectiveById', params, 'POST')

// 血糖趋势图
export const reqSugarTrendById = (params) => ajax(baseURL + '/patientBlood/sugarTrendById', params, 'POST')

// 血糖均值图 
export const reqMeanValueById = (params) => ajax(baseURL + '/patientBlood/meanValueById', params, 'POST')

// 血糖漂移度图
export const reqDeviationById = (params) => ajax(baseURL + '/patientBlood/StandardDeviationById', params, 'POST')

//血糖饼图
export const reqSummaryById = (params) => ajax(baseURL + '/patientBlood/summaryById', params, 'POST')

// 血气/心标/血凝预警设置（回显）
export const reqFleshReportWarningById = (params) => ajax(baseURL + '/fleshReport/fleshReportWarningById', params, 'POST')

// 血气/心标/血凝预警设置（修改）
export const reqFleshReportWarning = (params) => ajax(baseURL + '/fleshReport/addFleshReportWarning', params, 'POST')

// 血气录入
export const reqFleshReport = (params) => ajax(baseURL + '/fleshReport/addPatientFleshReport', params, 'POST')

// 根据患者id查询患者血气记录表
export const reqReportByPatientId = (params) => ajax(baseURL + '/fleshReport/fleshReportByPatientId', params, 'POST')

// 修改血气
export const reqUpdateFleshReport = (params) => ajax(baseURL + '/fleshReport/updatePatientFleshReport', params, 'POST')

// 心标录入
export const reqAddTheHeart = (params) => ajax(baseURL + '/theHeart/addPatientTheHeart', params, 'POST')

// 通过患者id查询心标记录表
export const reqHeart = (params) => ajax(baseURL + '/theHeart/theHeartByPatientId', params, 'POST')

// 修改心标
export const reqUpdateHeart = (params) => ajax(baseURL + '/theHeart/updatePatientTheHeart', params, 'POST')

// 血凝录入
export const reqBloodClot = (params) => ajax(baseURL + '/bloodClot/addPatientBloodClot', params, 'POST')

// 根据患者id查询血凝记录表
export const reqBloodClotById = (params) => ajax(baseURL + '/bloodClot/bloodClotByPatientId', params, 'POST')

// 修改血凝
export const requpdateClot = (params) => ajax(baseURL + '/bloodClot/updatePatientBloodClot', params, 'POST')

// 糖化血脂
export const reqSaccharifyByPatientId = (params) => ajax(baseURL + '/patientBlood/saccharifyByPatientId', params, 'POST')

/* 科室报表
---------------------------------------------------------------- */

// 血糖列表患者列表数据
export const reqBloodGlucose = (params) =>
  ajax(baseURL + '/bloodSugarReport/findBloodGlucosePatientList', params, 'POST')

// 根据患者id统计血糖报表详情
export const reqBloodGlucoseByPatientId = (params) =>
  ajax(baseURL + '/bloodSugarReport/findBloodGlucoseByPatientId', params)

//血气报表患者列表数据
export const reqReportPatientList = (params) =>
  ajax(baseURL + '/deptFleshReport/findFleshReportPatientList', params, 'POST')

//根据患者id统计血气报表详情
export const reqFleshReportByPatientId = (params) =>
  ajax(baseURL + '/deptFleshReport/findFleshReportByPatientId', params)

//心标报表患者列表数据
export const reqTheHeartPatientList = (params) =>
  ajax(baseURL + '/deptTheHeart/findTheHeartPatientList', params, 'POST')

//根据患者id统计心标报表详情
export const reqTheHeartById = (params) => ajax(baseURL + '/deptTheHeart/findTheHeartByPatientId', params)

//血凝报表患者列表数据
export const reqBloodClotPatientList = (params) =>
  ajax(baseURL + '/deptBloodClot/findBloodClotPatientList', params, 'POST')

//根据患者id统计血凝报表详情
export const reqBloodClotByPatientId = (params) => ajax(baseURL + '/deptBloodClot/findBloodClotByPatientId', params)

// 工作量列表
export const reqWorkload = (params) => ajax(baseURL + '/workload/findWorkloadList', params, 'POST')

//导出工作量报表
export const reqdownloadWorkloads = (params) => ajax(baseURL + '/workload/downloadWorkloadList', params, 'POST')

//血糖检测点监测血糖次数
export const reqBloodGlucoseItemCounts = (params) =>
  ajax(baseURL + '/departmentMap/findBloodGlucoseItemCountList', params, 'POST')

//每日监测血糖次数
export const reqBloodGlucoseCounts = (params) =>
  ajax(baseURL + '/departmentMap/findBloodGlucoseCountList', params, 'POST')

//血糖检测人数
export const reqBloodGlucosePatientCount = (params) =>
  ajax(baseURL + '/departmentMap/findBloodGlucosePatientCount', params, 'POST')

//血糖控制图
export const reqBloodGlucoseScopeCount = (params) =>
  ajax(baseURL + '/departmentMap/findBloodGlucoseScopeCount', params, 'POST')

/* 项目维护
---------------------------------------------------------------- */

// 请求仪器列表
export const reqInstruments = (params) => ajax(baseURL + '/qc/instrumentList', params, 'POST')

// 请求试剂列表
export const reqReagents = (params) => ajax(baseURL + '/qc/reagentList', params, 'POST')

// 请求质控液列表
export const reqLiquids = (params) => ajax(baseURL + '/qc/controlThingsList', params, 'POST')

// 请求录入 / 更新仪器
export const reqAddOrEditInstrument = (params) => ajax(baseURL + '/qc/addAndUpdateInstrument', params, 'POST')

// 请求录入 / 更新试剂
export const reqAddOrEditReagent = (params) => ajax(baseURL + '/qc/addAndUpdateReagent', params, 'POST')

// 请求录入 / 更新质控液
export const reqAddOrEditLiquid = (params) => ajax(baseURL + '/qc/addAndUpdateControlThings', params, 'POST')

// 请求质控液次批号列表
export const reqLiquidSubBNs = (params) => ajax(baseURL + '/qc/controlThingsInfo', params)

// 请求录入以及更新质控液次批号信息
export const reqAddOrEditLiquidSubBN = (params) => ajax(baseURL + '/qc/addAndUpdateControlThingsLevel', params, 'POST')

// 请求已项目绑定的列表
export const reqProjectBindings = (params) => ajax(baseURL + '/qc/controlProjectInfoList', params, 'POST')

// 请求绑定 / 编辑项目
export const reqAddOrEditProjectBinding = (params) => ajax(baseURL + '/qc/controlProjectBind', params, 'POST')

/* 质控管理
---------------------------------------------------------------- */

// 请求通过质控 id 查询对应的质控规则
export const reqQueryQcRule = (params) => ajax(baseURL + '/qc/queryControlRuleSetting', params)

// 请求设置 / 更新质控规则
export const reqEditQcRule = (params) => ajax(baseURL + '/qc/controlRuleSetting', params, 'POST')

// 请求通过质控 id 查询对应的固定均值
export const reqQueryQcFixed = (params) => ajax(baseURL + '/qc/queryControlStaticMeanValueSetting', params)

// 请求设置 / 更新固定均值
export const reqEditQcFixed = (params) => ajax(baseURL + '/qc/updateControlStaticMeanValueSetting', params, 'POST')

// 请求通过质控 id 查询对应的浮动均值
export const reqQueryQcFloat = (params) => ajax(baseURL + '/qc/queryControlFloatMeanValueSetting', params)

// 请求设置 / 更新浮动均值
export const reqEditQcFloat = (params) => ajax(baseURL + '/qc/updateControlFloatMeanValueSetting', params, 'POST')

// 请求录入质控 / 编辑质控数据
export const reqAddOrEditQc = (params) => ajax(baseURL + '/westgard/addQualityControlItem', params, 'POST')

// 请求质控列表
export const reqQcsById = (params) => ajax(baseURL + '/westgard/qualityControlItemList', params)

// 请求新增 / 更新失控
export const reqAddOrEditRunaway = (params) => ajax(baseURL + '/westgard/addOutOfControlRecord', params, 'POST')

// 请求失控历史列表
export const reqRunaways = (params) => ajax(baseURL + '/westgard/queryOutOfControlRecord', params)

// 请求维护历史列表
export const reqMaintains = (params) => ajax(baseURL + '/westgard/queryControlMaintain', params)

// 请求新增 / 编辑维护
export const reqAddOrEditMaintain = (params) => ajax(baseURL + '/westgard/addControlMaintain', params, 'POST')

// 请求质控信息
export const reqQueryQcById = (params) => ajax(baseURL + '/westgard/qualityControlItemInfo', params)

// 请求导出质控报表
export const reqExportQc = (params) => ajax(baseURL + '/westgard/downloadExcel', params)

// 请求质控图形
export const reqQCGraph = (params) => ajax(baseURL + '/westgard/queryQcGraphical', params, 'POST')

// 请求失控报告详情
export const reqRunawayReport = (params) => ajax(baseURL + '/westgard/queryOutOfControlRecordInfo', params)

/* 组织管理
---------------------------------------------------------------- */

// 请求机构列表：tree 结构（医院-科室-病区）1
export const reqInstitutionTree = (params) => ajax(baseURL + '/office/officeList', params, 'POST')

//请求机构列表：tree 结构（医院-科室-病区）
export const reqInstitutionTrees = (params) => ajax(baseURL + '/office/findOfficeLists', params)

// 请求机构列表：tree 结构（医院-科室-病区）2
export const reqInstitutions = (params) => ajax(baseURL + '/office/findOfficeList', params)

//获取血糖透视表护士列表
export const reqfindNurseListByLoginUser = () => ajax(baseURL + '/user/findNurseListByLoginUser')

//保存护士信息
export const reqUpdateBloodGlucoseOperator = (params) => ajax(baseURL + '/patientBlood/updateBloodGlucoseOperator', params, 'POST')

export const reqInstitutionList = () => ajax(baseURL + '/office/findOfficesList')

//新建用户/office/findOkCreateOfficeList
export const reqCreateOfficeList = () => ajax(baseURL + '/office/findOkCreateOfficeList')

// 请求用户可创建的医院等级列表（数据范围）
export const reqPermissions = () => ajax(baseURL + '/office/findHospLevelList')

// 请求新增机构
export const reqAddInstitution = (params) => ajax(baseURL + '/office/create', params, 'POST')

// 请求编辑机构
export const reqEditInstitution = (params) => ajax(baseURL + '/office/updateOffice', params, 'POST')

// 请求修改机构状态
export const reqEditInstitutionStatus = (params) => ajax(baseURL + '/office/updateOfficeStatus', params, 'POST')

// 请求角色列表
export const reqRoles = () => ajax(baseURL + '/role/findRoleList')

// 查询时角色列表
export const reqRoleLists = () => ajax(baseURL + '/role/findRoleLists')

// 请求用户列表
export const reqUsers = (params) => ajax(baseURL + '/user/userList', params, 'POST')

// 请求增加用户
export const reqAddUser = (params) => ajax(baseURL + '/user/addUser', params, 'POST')

// 请求修改用户状态
export const reqEditUserStatus = (params) => ajax(baseURL + '/user/updateUsersStatus', params, 'POST')

// 请求编辑用户
export const reqEditUser = (params) => ajax(baseURL + '/user/updateUser', params, 'POST')

// 请求修改个人密码
export const reqUpdatePwd = (params) => ajax(baseURL + '/user/updatePass/', params, 'POST')

// 请求修改用户密码
export const reqUpdateUserPwd = (params) => ajax(baseURL + '/user/updateUserPass', params, 'POST')

/* 系统设置
---------------------------------------------------------------- */

// 请求品牌列表
export const reqBrands = (params) => ajax(baseURL + '/brand/queryBrands', params)

// 请求型号列表
export const reqModels = (params) => ajax(baseURL + '/brand/queryModels', params)

// 请求方法学列表
export const reqMethodologys = (params) => ajax(baseURL + '/brand/queryMethodology', params)

// 请求单位列表
export const reqMeters = (params) => ajax(baseURL + '/brand/queryMetering', params)

// 请求基质列表
export const reqStromas = (params) => ajax(baseURL + '/brand/queryStroma', params)

// 请求项目列表
export const reqProjects = (params) => ajax(baseURL + '/brand/projectList', params)

// 请求质控液名称列表
export const reqQualityControls = (params) => ajax(baseURL + '/brand/controlThingsNameList', params)

// 请求新增 / 编辑单位
export const reqAddOrEditMeter = (params) => ajax(baseURL + '/brand/addMetering', params, 'POST')

// 请求新增 / 编辑基质
export const reqAddOrEditStroma = (params) => ajax(baseURL + '/brand/addStroma', params, 'POST')

// 请求新增 / 编辑方法学
export const reqAddOrEditMethodology = (params) => ajax(baseURL + '/brand/addMethodology', params, 'POST')

// 请求新增 / 编辑品牌
export const reqAddOrEditBrand = (params) => ajax(baseURL + '/brand/addBrand', params, 'POST')

// 请求新增 / 编辑型号
export const reqAddOrEditModel = (params) => ajax(baseURL + '/brand/addModel', params, 'POST')

// 请求新增 / 编辑项目
export const reqAddOrEditProject = (params) => ajax(baseURL + '/brand/addProject', params, 'POST')

// 请求新增 / 编辑质控物
export const reqAddOrEditQC = (params) => ajax(baseURL + '/brand/addControlThingsName', params, 'POST')

// 请求操作日志列表
export const reqLogs = (params) => ajax(baseURL + '/log/queryLogs', params)

// 个人中心
export const reqUserInfo = () => ajax(baseURL + '/user/userInfo')
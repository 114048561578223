/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Select } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstitutions, reqUsers } from '../../../api'
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  setPage: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const [form] = Form.useForm()

  const { setPage, setCondition } = props

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  // 医院id
  const [companyId, setCompanyId] = useState(undefined)

  // 用户列表
  const [users, setUsers] = useState([])

  /**
   * api
   * 获取机构列表：tree 结构
   */
  const getInstitutions = async () => {
    const { data, status } = await reqInstitutions()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 查询
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    form.setFieldsValue({ officeId: undefined, userId: undefined })
    setCompanyId(value)
    setUsers([])
  }

  /**
   * 通过医院查询科室，并显示
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const result = institutions.filter((v) => v.id === companyId)
    if ('children' in result[0]) {
      return result[0].children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 查询
   * 选择框：选择科室
   * @param {*} value
   */
  const onChangeOffice = async (value) => {
    const { data, status } = await reqUsers({ companyId, officeId: value, pageNo: 1, pageSize: PAGE_SIZE_MAX })
    if (status) {
      setUsers(data.resultList)
      form.setFieldsValue({ userId: undefined })
    }
  }

  /**
   * 查询
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkQuery = (value) => {
    setPage()
    setCondition({userId: value.userId})
  }

  /**
   * 查询
   * 按钮：点击重置
   */
  const onReset = () => {
    form.resetFields()
    setPage()
    setUsers([])
    setCompanyId(undefined)
    setCondition({userId: undefined})
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstitutions()
  }, [])

  /**
   * 渲染
   */
  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='companyId' label='医院'>
            <Select style={{ width: 174 }} onChange={onChangeCompany}>
              {institutions.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
            <Select style={{ width: 174 }} onChange={onChangeOffice}>
              {companyId && showOffices(companyId)}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='userId' label='姓名'>
            <Select style={{ width: 174 }}>
              {users.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 头部 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Badge, Tooltip, Modal, Drawer, Card, Button, message, Dropdown, Menu } from 'antd'
import { FullscreenOutlined, FullscreenExitOutlined, RetweetOutlined } from '@ant-design/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

/* 公共模块
---------------------------------------------------------------- */
import { IModal } from '../ui'
import UserCenter from '../User/UserCenter'
import UpdatePwdForm from '../User/UpdatePwdForm'

/* 功能模块
---------------------------------------------------------------- */
import memoryUtils from '../../utils/memoryUtils'
import storageUtils from '../../utils/storageUtils'
import { reqMenus, reqChatToken } from '../../api'

/* 静态资源
---------------------------------------------------------------- */
import Logo from '../../assets/images/poct-logo.png'
import './index.less'
import '../../assets/fonts/iconfont.css'

/* 使用类型检查
---------------------------------------------------------------- */
Header.prototype = {
  setIsBigScreen: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function Header(props) {
  const {
    setIsBigScreen,
    location: { pathname }
  } = props

  const ws = useRef()

  // 菜单列表
  const [menus, setMenus] = useState([])

  // 第一层菜单 id
  const [isOn, setIsOn] = useState(pathname === '/' ? '/patient' : pathname.substring(0, pathname.indexOf('/', 1)))

  // 抽屉: 显示 / 隐藏
  const [drawer, setDrawer] = useState(false)

  // chatToken连接id
  const [socketId, setSocketId] = useState(undefined)

  // 消息通知
  const [notice, setNotice] = useState([])

  // 全屏
  const [isFullScreen, setIsFullScreen] = useState(false)

  // 弹框相关参数
  const [modal, setModal] = useState({
    type: '',
    data: {}
  })

  /**
   * 按钮
   * 点击退出登录
   */
  const logout = () => {
    Modal.confirm({
      title: '确定退出吗?',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        // 移除保存的user
        storageUtils.removeUser()
        memoryUtils.user = {}
        // 跳转到login
        props.history.replace('/login')
      }
    })
  }

  /**
   * api
   * 获取菜单列表
   */
  const getMenus = async () => {
    // 模拟菜单的数据会影响拦截器的错误反馈吗
    const { data, status } = await reqMenus()
    if (status) {
      setMenus(data)
    }
  }

  /**
   * 返回首页
   */
  const onHome = () => {
    props.history.replace('/')
  }

  // 进入全屏
  const enterFullScreen = <span style={{ color: '#2B579A', fontSize: 12 }}>进入全屏</span>
  // 退出全屏
  const exitFullScreen = <span style={{ color: '#2B579A', fontSize: 12 }}>退出全屏</span>

  // 大屏模式切换
  const switchBigScreen = <span style={{ color: '#2B579A', fontSize: 12 }}>大屏模式</span>

  // 预警消息
  const warningMsg = <span style={{ color: '#2B579A', fontSize: 12 }}>预警消息</span>

  /**
   * 按钮
   * 点击显示消息通知
   */
  const showNotification = () => {
    setDrawer(true)
  }

  /**
   * api
   * 获取连接WebSocket内容的id
   */
  const getChatToken = async () => {
    const { data, status } = await reqChatToken()
    if (status) {
      setSocketId(data)
    }
  }

  /**
   * 预警消息
   * @param {*} notice
   */
  const showNotice = (notice) => {
    return notice.map((v) => (
      <Card
        className='g_mb_10'
        size='small'
        key={v.id}
        title={v.title}
        extra={moment(v.timestamp).format('YYYY-MM-DD HH:mm:ss')}
      >
        <p>{v.content}</p>
        <div className='width-percent text-right'>
          <Button size='small' type='primary' onClick={() => onOkNotice(v.id)}>
            确认
          </Button>
        </div>
      </Card>
    ))
  }

  /**
   * 预警消息
   * 确认
   * @param {*} id
   */
  const onOkNotice = (id) => {
    // console.log(ws.current)
    ws.current.send(id)
    let arr = []
    if (notice) {
      for (let i = 0; i < notice.length; i++) {
        if (notice[i].id !== id) {
          arr.push(notice[i])
        }
      }
      setNotice(arr)
      message.success('已确认该预警消息！')
    }
  }

  /**
   * 全屏切换
   */
  const onScreen = () => {
    if (!isFullScreen) {
      requestFullScreen()
    } else {
      exitFullscreen()
    }
  }

  /**
   * 进入全屏
   */
  const requestFullScreen = () => {
    let rootElement = document.getElementsByTagName('body')[0]
    if (rootElement.requestFullscreen) {
      rootElement.requestFullscreen()
    } else if (rootElement.mozRequestFullScreen) {
      // 兼容火狐
      rootElement.mozRequestFullScreen()
    } else if (rootElement.webkitRequestFullscreen) {
      // 兼容谷歌
      rootElement.webkitRequestFullscreen()
    } else if (rootElement.msRequestFullscreen) {
      // 兼容IE
      rootElement.msRequestFullscreen()
    }
    setIsFullScreen(true)
  }

  /**
   * 退出全屏
   */
  const exitFullscreen = () => {
    let rootElement = document
    if (rootElement.exitFullScreen) {
      rootElement.exitFullScreen()
    } else if (rootElement.mozCancelFullScreen) {
      rootElement.mozCancelFullScreen()
    } else if (rootElement.webkitExitFullscreen) {
      rootElement.webkitExitFullscreen()
    } else if (rootElement.msExitFullscreen) {
      rootElement.msExitFullscreen()
    }
    setIsFullScreen(false)
  }

  /**
   * 大屏模式切换
   * @param {*} checked
   * @param {*} event
   */
  const onSwitchBigScreen = () => {
    setIsBigScreen(true)
  }

  /**
   * 显示第二层菜单
   * @param {*} menu1Id
   */
  const showMenu2 = (isOn) => {
    const { children: res } = menus.find((v) => v.url === isOn) || {}
    if (res) {
      return res.map((v) => (
        <li className='header_menu2_item' key={v.id}>
          <NavLink className='g_fy_m' activeClassName='header_menu2_active' to={v.url}>
            {v.url === pathname ? (
              <img className='header_menu2_item_icon' src={`/icons/${v.icon}_d.png`} alt='' />
            ) : (
              <img className='header_menu2_item_icon' src={`/icons/${v.icon}.png`} alt='' />
            )}
            <span className='header_menu2_item_content'>{v.title}</span>
          </NavLink>
        </li>
      ))
    }
  }

  const showBigScreen = () => {
    // 获取登录者信息
    const { role: { id } = {} } = memoryUtils.user
    switch (id) {
      case 2:
      case 3:
        return (
          <Tooltip
            className='g_ml_10'
            placement='bottom'
            title={switchBigScreen}
            color='#fff'
            onClick={onSwitchBigScreen}
          >
            <RetweetOutlined style={{ color: '#fff', fontSize: 25, cursor: 'pointer' }} />
          </Tooltip>
        )

      default:
        return null
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getMenus()
    getChatToken()
  }, [])

  useEffect(() => {
    if (socketId) {
      // 服务端连接的url
      // let url = `ws://192.168.1.114:7070/v1/webSocket/${socketId}`
      var test = window.location.host
      let url = `wss://${test}/v1/webSocket/${socketId}`

      ws.current = new WebSocket(url)
      ws.current.onmessage = (e) => {
        const result = JSON.parse(e.data)
        setNotice((notice) => [result, ...notice])
        // message.warning({
        //   content: '您有一条预警消息需要确认!',
        //   duration: 8,
        //   style: {
        //     display: 'flex',
        //     marginLeft: '130vh'
        //   }
        // })
      }
    }

    return () => {
      // 在组件卸载的时候，关闭连接
      ws.current?.close()
      
    }
  }, [socketId, ws])

  useEffect(() => {
    document.addEventListener('webkitfullscreenchange', setIsFullScreen(document.webkitIsFullScreen), false)
    // return () => {
    //   document.removeEventListener('webkitfullscreenchange', setIsFullScreen(document.webkitIsFullScreen) ,true)
    // }
  }, [])

  useEffect(() => {
    setIsOn(pathname.substring(0, pathname.indexOf('/', 1)))
  }, [pathname])

  /**
   * 渲染
   */
  return (
    <>
      <div className='header'>
        <div className='header_up'>
          <div className='up_l'>
            <Link to='/patient/list' onClick={onHome}>
              <img src={Logo} alt='患者列表' />
            </Link>
            <h1>POC智能监测管理系统</h1>
          </div>
          <div className='up_r'>
            {isFullScreen ? (
              <Tooltip className='g_ml_10' placement='bottom' title={exitFullScreen} color='#fff' onClick={onScreen}>
                <FullscreenExitOutlined style={{ color: '#fff', fontSize: 25, cursor: 'pointer' }} />
              </Tooltip>
            ) : (
              <Tooltip className='g_ml_10' placement='bottom' title={enterFullScreen} color='#fff' onClick={onScreen}>
                <FullscreenOutlined style={{ color: '#fff', fontSize: 25, cursor: 'pointer' }} />
              </Tooltip>
            )}

            {showBigScreen()}

            <Tooltip className='g_ml_10' placement='bottom' title={warningMsg} color='#fff'>
              <Badge count={notice.length} size='small'>
                <span
                  style={{ fontSize: 30, color: '#fff', cursor: 'pointer' }}
                  className='iconfont icon-notification-filling'
                  onClick={showNotification}
                ></span>
              </Badge>
            </Tooltip>

            <Dropdown
              className='g_ml_10'
              overlay={
                <Menu>
                  <Menu.Item onClick={() => setModal({ type: 'user_center', data: memoryUtils.user })}>
                    <span style={{ color: '#2b579a' }}>个人中心</span>
                  </Menu.Item>
                  <Menu.Item onClick={() => setModal({ type: 'update_pwd', data: memoryUtils.user })}>
                    <span style={{ color: '#2b579a' }}>修改密码</span>
                  </Menu.Item>
                  <hr />
                  <Menu.Item onClick={logout}>
                    <span style={{ color: '#2b579a' }}>退出</span>
                  </Menu.Item>
                </Menu>
              }
              placement='bottomLeft'
            >
              <span
                style={{ fontSize: 30, color: '#fff', cursor: 'pointer' }}
                className='iconfont icon-user-filling'
              ></span>
            </Dropdown>
          </div>
        </div>

        <ul className='g_fx header_menu1'>
          {menus.length > 0 &&
            menus.map((v) => (
              <li
                className={v.url === isOn ? 'header_menu1_item header_menu1_active' : 'header_menu1_item'}
                key={v.id}
                onClick={() => setIsOn(v.url)}
              >
                {v.title}
              </li>
            ))}
        </ul>
        <ul className='g_fx header_menu2'>{menus.length > 0 ? showMenu2(isOn) : null}</ul>
      </div>
      <Drawer
        width={400}
        title='消息通知'
        placement='right'
        closable={false}
        onClose={() => setDrawer(false)}
        visible={drawer}
      >
        {notice.length > 0 && showNotice(notice)}
      </Drawer>

      <IModal visible={modal.type === 'user_center'} titleText='个人中心'>
        <UserCenter setModal={() => setModal({ ...modal, type: '' })} />
      </IModal>

      <IModal visible={modal.type === 'update_pwd'} titleText='修改密码'>
        <UpdatePwdForm setModal={() => setModal({ ...modal, type: '' })} />
      </IModal>
    </>
  )
}

export default withRouter(Header)

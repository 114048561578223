/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Form, Row, Col, Button, Select, Input, DatePicker } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 静态资源类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  institutions: PropTypes.array,
  setCondition: PropTypes.func.isRequired,
  setPatientPage: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const { setCondition, setPatientPage, institutions } = props

  const [form] = Form.useForm()

  // // 机构列表
  // const [institutions, setInstitutions] = useState([])

  // 医院 id
  const [companyId, setCompanyId] = useState(undefined)

  /**
   * api
   * 获取机构列表
   */
  // const getInstitutions = async () => {
  //   const { data, status } = await reqInstitutions()
  //   if (status) {
  //     setInstitutions(data)
  //   }
  // }

  /**
   * 选择框：选择医院
   * @param {*} value
   */
  const onChangeCompany = (value) => {
    setCompanyId(value)
    form.setFieldsValue({ officeId: undefined, inpatientWardId: undefined })
  }

  /**
   * 通过医院 id 显示对应的科室列表
   * @param {*} companyId
   */
  const showOffices = (companyId) => {
    const { children } = institutions.find((v) => v.id === companyId)
    if (children) {
      return children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 选择科室
   * @param {*} value
   */
  const onChangeOffice = (value) => {
    form.setFieldsValue({ inpatientWardId: undefined })
  }

  /**
   * 点击通过条件查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    const { startTime } = value
    let params
    if (startTime) {
      params = {
        ...value,
        startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      params = {
        ...value,
        startTime: undefined
      }
    }
    setPatientPage()
    setCondition(params)
  }

  /**
   * 点击重置查询条件
   */
  const onReset = () => {
    form.resetFields()
    setCompanyId(undefined)
    setPatientPage()
    setCondition({
      companyId: undefined,
      officeId: undefined,
      inpatientWardId: undefined,
      no: undefined,
      searchData: undefined,
      startTime: undefined
    })
  }

  /**
   * 生命周期
   */
  // useEffect(() => {
  //   getInstitutions()

  //   return () => {
  //     setInstitutions((_) => {
  //       return
  //     })
  //   }
  // }, [])

  /**
   * 渲染
   */

  return (
    <Form layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='companyId' label='医院'>
            <Select style={{ width: 174 }} onChange={onChangeCompany}>
              {institutions.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='officeId' label='科室'>
            <Select style={{ width: 174 }} onChange={onChangeOffice}>
              {companyId && showOffices(companyId)}
            </Select>
          </Item>
        </Col>
        <Col>
          <Item name='startTime' label='时间'>
            <DatePicker picker='month' />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='no' label='职工号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='searchData' label='姓名'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

/* 患者详情 */

// ==================
// 调用模块
// ==================
import React from 'react'
import { Tabs, Button, Card } from 'antd'
import PropTypes from 'prop-types'
import { SolutionOutlined, RollbackOutlined } from '@ant-design/icons'

// ==================
// 公共组件
// ==================
import { InfoPane, HistoryPane } from './index'

// ==================
// 调用模块: 对象解构
// ==================
const { TabPane } = Tabs

// ==================
// 使用类型检查
// ==================
PatientDetail.propTypes = {
  onClose: PropTypes.func.isRequired
}

// ==================
// 唯一的模块导出
// ==================
function PatientDetail(props) {
  const { onClose } = props

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <SolutionOutlined className='g_mr_10' />
          患者详情
        </h3>
      }
      extra={
        <Button onClick={onClose} icon={<RollbackOutlined />}>
          关闭
        </Button>
      }
    >
      <Tabs
        defaultActiveKey='1'
        type='card'
        tabBarGutter={-1}
        // size='small'
      >
        <TabPane tab='基本信息' key='1'>
          <InfoPane />
        </TabPane>
        <TabPane tab='会诊历史' key='2'>
          <HistoryPane />
        </TabPane>
        <TabPane tab='血糖记录表' key='3'>
          血糖记录表
        </TabPane>
        <TabPane tab='血糖透视表' key='4'>
          血糖透视表
        </TabPane>
        <TabPane tab='血糖图谱' key='5'>
          血糖图谱
        </TabPane>
        <TabPane tab='血糖医嘱' key='6'>
          血糖医嘱
        </TabPane>
        <TabPane tab='胰岛素治疗' key='7'>
          胰岛素治疗
        </TabPane>
        <TabPane tab='慢病管理' key='8'>
          慢病管理
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default PatientDetail

/* 仪器维护 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Card, message, Table, Tooltip, Modal } from 'antd'
import {
  ApiOutlined,
  FilterOutlined,
  PlusOutlined,
  StopOutlined,
  EditOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'

/* 公共组件
---------------------------------------------------------------- */
import { IModal } from '../../../components/ui'

/* 子模块
---------------------------------------------------------------- */
import QueryForm from './QueryForm'
import InstrumentForm from './InstrumentForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstruments, reqBrands, reqAddOrEditInstrument } from '../../../api'
import { PAGE_SIZE, MONITOR, INSTRUMENT_STATUS, INSTITUTION_STATUS } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 唯一的模块导出
---------------------------------------------------------------- */
function Instrument() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 条件查询显示 / 隐藏
  const [query, setQuery] = useState(false)

  // 弹框相关参数
  const [modal, setModal] = useState({
    action: undefined, // 操作行为
    isShow: false, // 显示状态
    data: {} // 数据
  })

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1, // 当前页
    pageSize: PAGE_SIZE, // 每页数量
    total: 0 // 总记录数
  })

  // 查询条件
  const [condition, setCondition] = useState({
    type: undefined, // 监测类型
    brandID: undefined, // 品牌号
    modelID: undefined, // 型号
    snnum: undefined, // SN号
    status: undefined, // 状态
    startTime: undefined, // 开始时间
    endTime: undefined // 结束时间
  })

  // 仪器列表
  const [instruments, setInstruments] = useState([])

  // 品牌列表
  const [brands, setBrands] = useState([])

  // 录入 / 编辑表单信息 ref
  const [form, setForm] = useState(undefined)

  // 一行记录
  const [row, setRow] = useState(undefined)

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '监测类型',
      dataIndex: 'type',
      align: 'center',
      render: (text, { type }) => <>{type && MONITOR[type - 1].name}</>
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      width: '',
      align: 'center',
      render: (text, { brand }) => <>{brand.name}</>
    },
    {
      title: '型号',
      dataIndex: 'modelVO',
      align: 'center',
      render: (text, { modelVO }) => <>{modelVO.name}</>
    },
    {
      title: 'SN号',
      dataIndex: 'snnum',
      align: 'center'
    },
    {
      title: '录入者',
      dataIndex: 'createUser',
      width: 100,
      align: 'center',
      render: (text, { createUser }) => <>{createUser.name}</>
    },
    {
      title: '录入时间',
      dataIndex: 'createDate',
      width: 150,
      align: 'center'
      // render: (text, { createDate }) => <>{createDate}</>
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 60,
      align: 'center',
      render: (text, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{INSTRUMENT_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{INSTRUMENT_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: '',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <div className='g_action'>
          <Tooltip title='编辑仪器' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowModal('up', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          <Tooltip title={record.status ? '停用仪器' : '启用仪器'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取仪器列表
   * @param {*} params
   */
  const getInstruments = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqInstruments(params)
      if (status) {
        const result = mergeRid(data.resultList, params.pageNo, params.pageSize)
        setInstruments(result)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          total: data.totalRows
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 获取品牌列表
   * @param {*} value
   */
  const getBrands = async () => {
    const { data, status } = await reqBrands()
    if (status) {
      setBrands(data)
    }
  }

  /**
   * 列表
   * 按钮：点击切换当前页
   * @param {*} page
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ ...page, pageNo, pageSize })
  }

  /**
   * 按钮
   * 条件查询显示 / 隐藏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 按钮
   * 点击弹出录入 / 编辑仪器
   * @param {*} type
   * @param {*} data
   */
  const onShowModal = (action, data) => {
    setModal({ action, isShow: true, data })
    if (action === 'add') {
      if (form) form.resetFields()
    }
  }

  /**
   * 按钮
   * 点击弹出停用仪器 / 启用仪器
   * @param {*} instrument
   */
  const onShowStatus = (record) => {
    const { id, status } = record
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该仪器吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该仪器吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const { data } = await reqAddOrEditInstrument({
          id,
          status: status ? 0 : 1
        })
        if (data) {
          if (status) {
            message.success('停用仪器成功！')
          } else {
            message.success('启用仪器成功！')
          }
          getInstruments({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
        }
      }
    })
  }

  /**
   * 选中行高亮显示
   * @param {*} record
   * @param {*} index
   */
  const showRowClassName = (record, index) => {
    // let rowBgSpace = '';
    // rowBgSpace = index % 2 === 0 ? '' : 'g_row_bg-space';
    // if (row) {
    //   return record.id === row.id ? (rowBgSpace + ' g_row_bg') : rowBgSpace
    // } else {
    //   return rowBgSpace
    // }
    if (row) return record.id === row.id ? 'g_row_bg' : ''
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getInstruments({
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      ...condition
    })
  }, [page.pageNo, page.pageSize, condition])

  useEffect(() => {
    getBrands()
    return () => {
      setBrands((_) => {
        return
      })
    }
  }, [])

  /**
   * 渲染
   */
  return (
    <>
      <Card
        className='height-percent'
        size='small'
        title={
          <>
            <ApiOutlined className='g_mr_10' />
            <span>仪器维护</span>
          </>
        }
        extra={
          <>
            <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
              {query ? '隐藏' : '查询'}
            </Button>
            <Button type='primary' onClick={() => onShowModal('add', {})} icon={<PlusOutlined />}>
              录入仪器
            </Button>
          </>
        }
      >
        <QueryForm
          query={query}
          monitor={MONITOR}
          brands={brands}
          instrumentStatus={INSTITUTION_STATUS}
          setPage={() => setPage({ ...page, pageNo: 1, pageSize: PAGE_SIZE })}
          setCondition={(params) => setCondition({ ...condition, ...params })}
        />

        <Table
          bordered
          size='small'
          scroll={{ y: 450 }}
          rowKey='rid'
          loading={isLoading}
          columns={initColumns}
          dataSource={instruments}
          pagination={{
            current: page.pageNo,
            pageSize: page.pageSize,
            total: page.total,
            showTotal: (total) => `共 ${total} 条记录`,
            showQuickJumper: true,
            onChange: onChangePage
          }}
          rowClassName={showRowClassName}
          onRow={(record) => {
            return { onClick: () => setRow(record) }
          }}
        />
      </Card>

      <IModal visible={modal.isShow} titleText={modal.action === 'add' ? '录入仪器' : '编辑仪器'}>
        <InstrumentForm
          monitor={MONITOR}
          brands={brands}
          modal={modal}
          setForm={(form) => setForm(form)}
          setModal={() => setModal({ ...modal, isShow: false })}
          refreshInstrument={() => getInstruments({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })}
        />
      </IModal>
    </>
  )
}

export default Instrument

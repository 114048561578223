/* 条件查询 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState } from 'react'
import { Form, Row, Col, Input, Button, Select, DatePicker } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select
const { RangePicker } = DatePicker

/* 使用类型检查
---------------------------------------------------------------- */
QueryForm.propTypes = {
  query: PropTypes.bool.isRequired,
  monitor: PropTypes.array.isRequired,
  brands: PropTypes.array,
  instrumentStatus: PropTypes.array.isRequired,
  setPage: PropTypes.func.isRequired,
  setCondition: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function QueryForm(props) {
  const [form] = Form.useForm()

  const { query, monitor, brands, instrumentStatus, setPage, setCondition } = props

  // 品牌 id
  const [brandId, setBrandId] = useState(undefined)

  /**
   * 查询
   * 选择框：选择品牌
   */
  const onChangeBrand = (value) => {
    form.setFieldsValue({ modelID: undefined })
    setBrandId(value)
  }

  /**
   * 通过品牌 id 显示对应的型号列表
   * @param {*} brandId
   */
  const showModels = (brandId) => {
    const result = brands.filter((v) => v.id === brandId)
    if ('children' in result[0]) {
      return result[0].children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 查询
   * 选择框：选择状态
   * @param {*} value
   */
  const onChangeStatus = (value) => {
    setPage()
    setCondition({ status: value })
  }

  /**
   * 查询
   * 按钮：点击查询
   * @param {*} value
   */
  const onOkQuery = (value) => {
    const { date } = value
    let params
    if (date) {
      params = {
        ...value,
        startTime: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    } else {
      params = {
        ...value,
        startTime: undefined,
        endTime: undefined
      }
    }
    // console.log(params)
    setPage()
    setCondition(params)
  }

  /**
   * 查询
   * 按钮：点击重置
   */
  const onReset = () => {
    form.resetFields()
    setBrandId(undefined)
    setPage()
    setCondition({
      type: undefined,
      brandID: undefined,
      modelID: undefined,
      snnum: undefined,
      status: undefined,
      startTime: undefined,
      endTime: undefined
    })
  }

  /**
   * 渲染
   */
  return (
    <Form className={query ? 'g_display': 'g_hide'} layout='inline' form={form} onFinish={onOkQuery}>
      <Row>
        <Col className='g_mb_10'>
          <Item name='type' label='监测类型'>
            <Select className='g_w_174'>
              {monitor.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='brandID' label='品牌'>
            <Select className='g_w_174' onChange={onChangeBrand}>
              {brands.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='modelID' label='型号'>
            <Select className='g_w_174'>{brandId && showModels(brandId)}</Select>
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='snnum' label='SN号'>
            <Input />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='date' label='时间'>
            <RangePicker />
          </Item>
        </Col>
        <Col className='g_mb_10'>
          <Item name='status' label='状态'>
            <Select style={{ width: 70 }} allowClear onChange={onChangeStatus}>
              {instrumentStatus.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className='g_mb_10'>
          <Button className='g_mr_10' type='primary' htmlType='submit'>
            查询
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default QueryForm

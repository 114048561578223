/* 凝血录入 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Input, Select, Button, Divider, DatePicker, message } from 'antd'
import { UserOutlined, RollbackOutlined, CheckOutlined } from '@ant-design/icons'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqInstruments, reqReagents, reqBloodClot, reqfindNurseListByLoginUser } from '../../../api/index'
import { mergeRid } from '../../../utils/func'

/* 静态资源模块
---------------------------------------------------------------- */
import { PAGE_SIZE_MAX } from '../../../utils/constants'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/*唯一的模块导出
---------------------------------------------------------------- */
function AddRecordForm(props) {
  const { onCancel, patientRow, setShow } = props

  // 仪器列表
  const [instrumentList, setInstrumentList] = useState([])

  //护士列表
  const [nurseListByLoginUser, setNurseListByLoginUser] = useState([])

  //试纸批号列表
  const [reagents, setReagents] = useState([])

  // 提交表单
  const [form] = Form.useForm()

  /**
   * api
   * 仪器接口
   */
  const getInstrumentList = async (params) => {
    const { data, status } = await reqInstruments(params)
    const result = mergeRid(data.resultList)
    if (status) {
      setInstrumentList(result)
    }
  }

  /**
* api
* 获取护士列表
* @param {*} value 
*/
  const getNurseListByLoginUser = async () => {
    const { data, status } = await reqfindNurseListByLoginUser()
    if (status) {
      setNurseListByLoginUser(data)
    }
  }

  /**
   * api
   * 试纸批号接口
   */
  const getReagents = async (params) => {
    const { data, status } = await reqReagents(params)
    const result = mergeRid(data.resultList)
    if (status) {
      setReagents(result)
    }
  }

  /**
   * 当天以后的日期不可选择
   * @param {*} current
   * @returns
   */
  const disabledDate = (current) => {
    return current && current > moment().endOf('day')
  }

  /**
   * 按钮
   * 点击保存血气录入
   * @param {*} value
   */
  const onOkAddBloodClot = async (value) => {
    const { detectionTime, item1, item2, item3, item4, userId } = value
    const { status } = await reqBloodClot({
      ...value,
      userId,
      patientId: patientRow.id,
      detectionTime: moment(detectionTime).format('YYYY-MM-DD HH:mm:ss') || moment(today).format('YYYY-MM-DD HH:mm:ss'),
      clotResult: {
        1: parseFloat(item1),
        2: parseFloat(item2),
        3: parseFloat(item3),
        4: parseFloat(item4)
      }
    })
    if (status) {
      message.success('凝血录入成功！')
      form.resetFields()
      setShow()
    }
  }

  /**
   * 挂载
   */

  useEffect(() => {
    getNurseListByLoginUser()
    return () => {
      setNurseListByLoginUser((_) => {
        return
      })
    }
  }, [])
  useEffect(() => {
    getInstrumentList({ pageNo: 1, pageSize: PAGE_SIZE_MAX, type: 4 })
    getReagents({ pageNo: 1, pageSize: PAGE_SIZE_MAX, type: 4 })
  }, [])

  /**
   * 渲染
   */
  const today = moment() // 当天日期
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          凝血录入
        </h3>
      }
    >
      <Form form={form} onFinish={onOkAddBloodClot}>
        <Divider orientation='left' plain>
          基本信息
        </Divider>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='snnum'
              rules={[{ required: true, message: '请输入仪器' }]}
              label={
                <span className='g_w_98' rules={[{ required: true, message: '请输入仪器' }]}>
                  仪器
                </span>
              }
            >
              <Select style={{ width: '100%' }}>
                {instrumentList.length &&
                  instrumentList.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '     ' + v.modelVO.name + '     ' + v.snnum}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='userId'
              label={<span className='g_w_56'>操作人</span>}
              rules={[{ required: true, message: '请选择操作人' }]}
            >
              <Select >
                {nurseListByLoginUser.map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='batchNumber'
              rules={[{ required: true, message: '请选择试纸（剂）批号' }]}
              label={<span className='g_w_98'>试纸（剂）批号</span>}
            >
              <Select style={{ width: '100%' }}>
                {reagents.length &&
                  reagents.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.brand.name + '      ' + v.methodology.name + '      ' + v.batchNumber}
                    </Option>
                  ))}
              </Select>
            </Item>
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='detectionTime'
              // rules={[{ required: true, message: '请选择监测时间' }]}
              label={
                <span className='g_w_67' name='detectionTime'>
                  监测时间
                </span>
              }
              initialValue={moment(today)}
            >
              <DatePicker className='width-percent' disabledDate={disabledDate} />
            </Item>
          </Col>
        </Row>
        <Divider orientation='left' plain>
          详细信息
        </Divider>
        <Row>
          <Col offset={1} className='g_mb_10 color-red'>
            说明：以下内容至少填写一项
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item1'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_140'>血浆凝血酶原时间</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            s
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item2'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_140'>活化部分凝血活酶时间</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            s
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={2}>
            <Item
              name='item3'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_140'>凝血酶时间</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            s
          </Col>
          <Col span={8} offset={2}>
            <Item
              name='item4'
              rules={[{ pattern: /^([0-9]{1,10})(\.\d{1,2})?$/, message: '请输入(1-10位，后可跟两位小数)的数字' }]}
              label={<span className='g_w_140'>纤维蛋白原</span>}
            >
              <Input />
            </Item>
          </Col>
          <Col className='unit-1' span={2}>
            g/L
          </Col>
        </Row>

        <Divider plain />

        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default AddRecordForm

/* 设置浮动均值 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Button, Input, Checkbox, DatePicker, Spin, message } from 'antd'
import moment from 'moment'

/* 功能模块
---------------------------------------------------------------- */
import { reqQueryQcFloat, reqEditQcFloat } from '../../../api'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { Item } = Form

/* 使用类型检查
---------------------------------------------------------------- */
FloatMeanForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  modal: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function FloatMeanForm(props) {
  const { monitor, modal, setModal } = props

  const [form] = Form.useForm()

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // form 相关参数
  const [floatMean, setFloatMean] = useState({})

  const {
    controlId,
    type,
    instrument: { brand, modelVO, snnum },
    controlProject
  } = modal.data

  /**
   * api
   * 通过质控 id 获取对应的浮动均值
   * @param {*} params
   */
  const getQcFloat = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqQueryQcFloat(params)
      if (status) {
        // console.log(data)
        setFloatMean(data)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 列表操作：设置浮动均值
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkQcFloatMean = async (value) => {
    // console.log(value)
    const { analysisNum, floatAnalysisNum, needRecalculate, recalculateDate } = value
    let params = {
      analysisNum: parseInt(analysisNum),
      floatAnalysisNum: parseInt(floatAnalysisNum),
      needRecalculate,
      recalculateDate: recalculateDate ? moment(recalculateDate).format('YYYY-MM-DD HH:mm:ss') : null,
      qualityControlId: controlId
    }
    const { status } = await reqEditQcFloat(params)
    if (status) {
      message.success('设置浮动均值成功！')
      setModal()
      form.setFieldsValue({ needRecalculate: false, recalculateDate: moment(floatMean.recalculateDate) })
      // getQcFloat({ controlId })
    }
  }

  /**
   * 列表操作：设置浮动均值
   * 按钮：点击取消
   */
  const onCancel = () => {
    setModal()
    form.setFieldsValue({ needRecalculate: false, recalculateDate: moment(floatMean.recalculateDate) })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getQcFloat({ controlId })
  }, [controlId])

  useEffect(() => {
    if (Object.keys(floatMean).length > 0) {
      form.setFieldsValue({ ...floatMean, recalculateDate: moment(floatMean.recalculateDate) })
    }
  }, [form, floatMean])

  /**
   * 渲染
   */
  return (
    <>
      <Row className='g_mb_10' gutter={10}>
        <Col span={8}>类型：{monitor[type - 1].name}</Col>
        <Col span={8}>品牌：{brand.name}</Col>
        <Col span={8}>型号：{modelVO.name}</Col>
        <Col span={8}>SN号：{snnum}</Col>
        <Col span={8}>项目：{controlProject.name}</Col>
      </Row>

      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onOkQcFloatMean}>
          <Row gutter={10}>
            <Col span={12}>
              <Item
                name='analysisNum'
                label={<span className='g_w_56'>数据点</span>}
                rules={[
                  { required: true, message: '请输入数据点' },
                  {
                    pattern: new RegExp(/^([2-9]|[1-9]\d+)$/),
                    message: '值必须为>=2的正整数'
                  }
                ]}
              >
                <Input />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name='floatAnalysisNum'
                label={<span className='g_w_56'>浮动点数</span>}
                rules={[
                  { required: true, message: '请输入数据点' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        value === '0' ||
                        (parseInt(value) >= parseInt(getFieldValue('analysisNum')) && /^([2-9]|[1-9]\d+)$/.test(value))
                      ) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('请输入>=数据点的整数或0'))
                    }
                  })
                ]}
              >
                <Input />
              </Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Item name='level1MV' label={<span className='g_w_67'>水平1</span>}>
                <Input readOnly />
              </Item>
            </Col>
            <Col span={12}>
              <Item name='level1SDCV' label={<span className='g_w_67'>SD</span>}>
                <Input readOnly />
              </Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Item name='level2MV' label={<span className='g_w_67'>水平2</span>}>
                <Input readOnly />
              </Item>
            </Col>
            <Col span={12}>
              <Item name='level2SDCV' label={<span className='g_w_67'>SD</span>}>
                <Input readOnly />
              </Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Item name='level3MV' label={<span className='g_w_67'>水平3</span>}>
                <Input readOnly />
              </Item>
            </Col>
            <Col span={12}>
              <Item name='level3SDCV' label={<span className='g_w_67'>SD</span>}>
                <Input readOnly />
              </Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Item name='needRecalculate' valuePropName='checked' label={<span className='g_w_67'>重新累计</span>}>
                <Checkbox />
              </Item>
            </Col>
            <Col span={12}>
              <Item name='recalculateDate' label={<span className='g_w_67'>起始时间</span>}>
                <DatePicker className='g_w_percent' showTime />
              </Item>
            </Col>
          </Row>

          <div className='g_txt_c'>
            <Button className='g_mr_20' key='back' onClick={onCancel}>
              取消
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              确定
            </Button>
          </div>
        </Form>
      </Spin>
    </>
  )
}

export default FloatMeanForm

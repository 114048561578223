/* 血糖监测点分布 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts'
import PropTypes from 'prop-types'

/* 使用类型检查
---------------------------------------------------------------- */
BSMonitorPie.prototype = {
  data: PropTypes.array
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function BSMonitorPie(props) {
  const { data } = props

  const [myChart, setMyChart] = useState('')

  const getChart = (myChart, data) => {
    if (myChart !== '') {
      let result = []
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          result.push({ value: parseFloat(data[key].num), name: data[key].item })
        }
      }

      let option = {
        grid: {
          top: 10,
          right: '5%',
          bottom: 30,
          left: '5%'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            data: result,
            radius: [0, '50%'],
            label: {
              formatter: '{b} {c}%',
              color: '#fff',
              fontSize: 14,
              fontFamily: 'PingFang SC',
              fontWight: 400
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      // setInterval(() => {
      //   option && myChart.setOption(option)
      // }, 3000)
      option && myChart.setOption(option)
    }
  }
  /**
   * 生命周期
   */
  useEffect(() => {
    const node = document.getElementById('bsMonitor')
    const myChart = echarts.init(node)
    setMyChart(myChart)
  }, [])

  useEffect(() => {
    getChart(myChart, data)
  }, [myChart, data])

  /**
   * 渲染
   */
  return <div id='bsMonitor' className='bi_chart4'></div>
}

export default BSMonitorPie

/* 用户管理 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Button, Table, Card, Tooltip, Modal, message } from 'antd'
import {
  UserOutlined,
  PlusOutlined,
  FilterOutlined,
  EditOutlined,
  StopOutlined,
  PauseCircleOutlined,
  ExclamationCircleOutlined,
  LockOutlined
} from '@ant-design/icons'
import moment from 'moment'

/* 子模块
---------------------------------------------------------------- */
import UserForm from './UserForm'
import QueryForm from './QueryForm'
import UpdatePwdForm from './UpdatePwdForm'

/* 功能模块
---------------------------------------------------------------- */
import { reqUsers, reqCreateOfficeList, reqRoles, reqEditUserStatus, reqRoleLists } from '../../../api'
import { PAGE_SIZE, USER_STATUS } from '../../../utils/constants'
import { mergeRid } from '../../../utils/func'
import { IModal } from '../../../components/ui'

/* 调用模块：对象解构
---------------------------------------------------------------- */
const { confirm } = Modal

/* 唯一的模块导出
---------------------------------------------------------------- */
function User() {
  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 查询 / 隐藏切换
  const [query, setQuery] = useState(false)

  // 分页相关参数
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: PAGE_SIZE,
    total: 0
  })

  // 用户列表
  const [users, setUsers] = useState([])

  // 角色列表
  const [roles, setRoles] = useState([])

  // 角色列表(查询时)
  const [roleLists, setRoleLists] = useState([])

  // 机构列表
  const [institutions, setInstitutions] = useState([])

  // 查询条件
  const [condition, setCondition] = useState({
    companyId: undefined, // 医院
    officeId: undefined, // 科室
    no: undefined, // 工号
    searchData: undefined, // 姓名
    status: undefined, // 状态
    role: undefined // 角色
  })

  // 用户
  const [action, setAction] = useState({
    type: '', // add：添加 | up：修改 | up_pwd：修改密码
    isShow: false, // 组件切换
    data: {} // 用户信息
  })

  /**
   * 初始化生成 Tabe 所有列
   */
  const initColumns = [
    {
      title: '',
      dataIndex: 'rid',
      width: 60,
      align: 'center'
    },
    {
      title: '登录账号',
      dataIndex: 'loginName',
      align: 'center'
    },
    {
      title: '员工工号',
      dataIndex: 'no',
      align: 'center'
    },
    {
      title: '员工姓名',
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: '角色',
      dataIndex: 'role',
      align: 'center',
      render: (_, { role }) => <>{role.name}</>
    },
    {
      title: '归属科室',
      dataIndex: 'office',
      align: 'center',
      render: (_, { office }) => <>{office.name}</>
    },
    {
      title: '归属医院',
      dataIndex: 'company',
      align: 'center',
      render: (_, { company }) => <>{company.name}</>
    },
    {
      title: '手机号码',
      dataIndex: 'mobile',
      align: 'center',
      width: 100
    },
    {
      title: '办公电话',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: '电子邮箱',
      dataIndex: 'email',
      width: 200,
      align: 'center'
    },
    {
      title: '更新时间',
      dataIndex: 'updateDate',
      align: 'center',
      width: 100,
      render: (_, { updateDate }) => moment(updateDate).format('YYYY-MM-DD')
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: 60,
      render: (_, { status }) =>
        status ? (
          <span style={{ color: 'green' }}>{USER_STATUS[status].name}</span>
        ) : (
          <span style={{ color: 'red' }}>{USER_STATUS[status].name}</span>
        )
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (_, record) => (
        <div className='g_action'>
          <Tooltip title='编辑用户' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<EditOutlined />}
              onClick={() => onShowAction('up', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
          <Tooltip title={record.status ? '停用用户' : '启用用户'} mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={record.status ? <StopOutlined /> : <PauseCircleOutlined />}
              onClick={() => onShowStatus(record)}
            />
          </Tooltip>
          <Tooltip title='修改密码' mouseEnterDelay={0.5}>
            <Button
              type='link'
              icon={<LockOutlined />}
              onClick={() => onShowAction('up_pwd', record)}
              disabled={record.status ? false : true}
            />
          </Tooltip>
        </div>
      )
    }
  ]

  /**
   * api
   * 获取用户列表
   */
  const getUsers = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqUsers(params)
      if (status) {
        const result = mergeRid(data.resultList, params.pageNo, params.pageSize)
        setUsers(result)
        setPage({
          pageNo: params.pageNo,
          pageSize: params.pageSize,
          total: data.totalRows
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * api
   * 获取角色列表
   */
  const getRoles = async () => {
    const { data, status } = await reqRoles()
    if (status) {
      setRoles(data)
    }
  }

  /**
   * api
   * 获取角色列表(查询时)
   */
  const getRoleLists = async () => {
    const { data, status } = await reqRoleLists()
    if (status) {
      setRoleLists(data)
    }
  }

  /**
   * api
   * 获取机构列表：tree 结构
   */
  const getInstitutions = async () => {
    const { data, status } = await reqCreateOfficeList()
    if (status) {
      setInstitutions(data)
    }
  }

  /**
   * 分页器
   * 切换用户列表页
   * @param {*} page
   */
  const onChangePage = (pageNo, pageSize) => {
    setPage({ ...page, pageNo, pageSize })
  }

  /**
   * 新增 / 编辑用户
   * 按钮：点击切换
   * @param {*} action
   * @param {*} data
   */
  const onShowAction = (type, data) => {
    let params = {}
    switch (type) {
      case 'add':
      case 'up':
        params = { type, isShow: true, data }
        break

      default:
        params = { type, data }
        break
    }
    setAction(params)
  }

  /**
   * 按钮
   * 查询/隐藏条件栏
   */
  const onShowQuery = () => {
    if (query) {
      setQuery(false)
    } else {
      setQuery(true)
    }
  }

  /**
   * 列表
   * 按钮：改变停用 / 启用用户状态
   * @param {*} record
   */
  const onShowStatus = (record) => {
    const { id, status } = record
    confirm({
      title: status ? (
        <span>
          确认要<i style={{ color: 'red' }}>停用</i>该用户吗？
        </span>
      ) : (
        <span>
          确认要<i style={{ color: 'green' }}>启用</i>该用户吗？
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const res = await reqEditUserStatus({ code: status ? 0 : 1, ids: [id] })
        let msg
        if (res.status) {
          if (status) {
            msg = '停用用户成功！'
          } else {
            msg = '启用用户成功！'
          }
          message.success(msg)
          getUsers({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
        }
      }
    })
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getUsers({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })
  }, [page.pageNo, page.pageSize, condition])

  useEffect(() => {
    getRoles()
    getRoleLists()
    getInstitutions()

    return () => {
      setRoles((_) => {
        return
      })
      setRoleLists((_) => {
        return
      })
      setInstitutions((_) => {
        return
      })
    }
  }, [])

  /**
   * 渲染
   */

  if (!action.isShow) {
    return (
      <>
        <Card
          className='g_h_percent'
          size='small'
          bordered={false}
          title={
            <>
              <UserOutlined className='g_mr_10' />
              <span>用户管理</span>
            </>
          }
          extra={
            <>
              <Button className='g_mr_10' icon={<FilterOutlined />} onClick={onShowQuery}>
                {query ? '隐藏' : '查询'}
              </Button>
              <Button type='primary' onClick={() => onShowAction('add', {})} icon={<PlusOutlined />}>
                新增
              </Button>
            </>
          }
        >
          <QueryForm
            query={query}
            roleLists={roleLists}
            statusType={USER_STATUS}
             institutions={ institutions}
            setPage={() => setPage({ ...page, pageNo: 1, pageSize: PAGE_SIZE })}
            setCondition={(params) => setCondition({ ...condition, ...params })}
          />

          <Table
            bordered
            size='small'
            rowKey='rid'
            loading={isLoading}
            columns={initColumns}
            dataSource={users}
            pagination={{
              current: page.pageNo,
              defaultPageSize: page.pageSize,
              total: page.total,
              showTotal: (total) => `共 ${total} 条记录`,
              showQuickJumper: true,
              onChange: onChangePage
            }}
            scroll={{ x: 1400, y: 450 }}
          />
        </Card>

        <IModal visible={action.type === 'up_pwd'} titleText='修改密码'>
          <UpdatePwdForm action={action} setAction={() => setAction({ ...action, type: '' })} />
        </IModal>
      </>
    )
  }
  return (
    <UserForm
      institutions={institutions}
      action={action}
      roles={roles}
      setAction={() => setAction({ ...action, isShow: false })}
      refreshUsers={() => getUsers({ pageNo: page.pageNo, pageSize: page.pageSize, ...condition })}
    />
  )
}

export default User

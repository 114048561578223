/* 预警设置 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useEffect, useState } from 'react'
import { Card, Form, Row, Col, Input, Button, Divider, message } from 'antd'
import { RollbackOutlined, CheckOutlined, BulbOutlined, UserOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqFleshReportWarning, reqFleshReportWarningById } from '../../../api/index'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form

/* 使用类型检查
---------------------------------------------------------------- */
AddWarningForm.prototype = {
  onShow: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function AddWarningForm(props) {
  const { onCancel, onShow } = props
  const [fleshReportWarningById, setFleshReportWarningById] = useState({})

  // 提交表单
  const [form] = Form.useForm()

  /**
   * 对象解构
   */
  const { bloodGlucoseHighMap, bloodGlucoseUnderMap, id } = fleshReportWarningById

  /**
   * 预警设置数据回显
   * @param {*} params
   */
  const getFleshReportWarningById = async (params) => {
    const { data, status } = await reqFleshReportWarningById(params)
    if (status) {
      setFleshReportWarningById(data)
    }
  }

  /**
   * 将属性名替换成不一样的，然后进行一一对应回显
   * @param {*} bloodGlucoseHighMap
   * @param {*} bloodGlucoseUnderMap
   * @returns
   */
   const filterTimePart = (bloodGlucoseHighMap, bloodGlucoseUnderMap) => {
    let bhName = [
      { id: 1, name: 'warningHigh1' },
      { id: 2, name: 'warningHigh2' },
      { id: 3, name: 'warningHigh3' },
      { id: 4, name: 'warningHigh4' },
      { id: 5, name: 'warningHigh5' }
    ]

    let blName = [
      { id: 1, name: 'warningLow1' },
      { id: 2, name: 'warningLow2' },
      { id: 3, name: 'warningLow3' },
      { id: 4, name: 'warningLow4' },
      { id: 5, name: 'warningLow5' }
    ]
    let obj = {}
    for (const key in bloodGlucoseHighMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseHighMap, key)) {
        let resH = bhName.find((v) => v.id === parseInt(key))
        obj = { [resH.name]: bloodGlucoseHighMap[key], ...obj }
      }
    }
    for (const key in bloodGlucoseUnderMap) {
      if (Object.hasOwnProperty.call(bloodGlucoseUnderMap, key)) {
        let resL = blName.find((v) => v.id === parseInt(key))
        obj = { [resL.name]: bloodGlucoseUnderMap[key], ...obj }
      }
    }

    return obj
  }

  /**
   * 血糖预警录入
   */
  const onOkWarning = async (value) => {
    const {
      warningLow1,
      warningLow2,
      warningLow3,
      warningLow4,
      warningLow5,
      warningHigh1,
      warningHigh2,
      warningHigh3,
      warningHigh4,
      warningHigh5
    } = value
    const { status } = await reqFleshReportWarning({
      ...value,
      id:id,
      type: 2,
      fleshUnderResult: {
        1: parseFloat(warningLow1),
        2: parseFloat(warningLow2),
        3: parseFloat(warningLow3),
        4: parseFloat(warningLow4),
        5: parseFloat(warningLow5)
      },
      fleshHighResult: {
        1: parseFloat(warningHigh1),
        2: parseFloat(warningHigh2),
        3: parseFloat(warningHigh3),
        4: parseFloat(warningHigh4),
        5: parseFloat(warningHigh5)
      }
    })
    if (status) {
      message.success('心标预警设置成功！')
      form.resetFields()
      onShow()
    }
  }

    /**
   * 生命周期
   */
     useEffect(() => {
      getFleshReportWarningById({ type: 2 })
    }, [])
    useEffect(() => {
      form.setFieldsValue(filterTimePart(bloodGlucoseHighMap, bloodGlucoseUnderMap))
    }, [form, bloodGlucoseHighMap, bloodGlucoseUnderMap])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <UserOutlined className='g_mr_10' />
          预警设置
        </h3>
      }
    >
      <div className='g_mb_10 g_color_gray'>
        <BulbOutlined className='g_mr_10' />
        说明：左边输入值为低值，右边输入值为高值
      </div>
      <Form form={form} onFinish={onOkWarning}>
        <Row>
          <Col offset={2}>
            <Item
              name='warningLow1'
              label={<span className='g_w_67'>CKMB</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='warningHigh1'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={3}>
            ng/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='warningLow2'
              label={<span className='g_w_67'>肌红蛋白</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='warningHigh2'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={3}>
            ng/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='warningLow3'
              label={<span className='g_w_67'>肌钙蛋白I</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='warningHigh3'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={3}>
            ng/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='warningLow4'
              label={<span className='g_w_67'>B型钠尿肽</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='warningHigh4'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={3}>
            ng/L
          </Col>
        </Row>
        <Row>
          <Col offset={2}>
            <Item
              name='warningLow5'
              label={<span className='g_w_67'>DDIM</span>}
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)低值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='g_txt_c g_pt_4' span={1}>
            ~
          </Col>
          <Col>
            <Item
              name='warningHigh5'
              rules={[
                {
                  required: true,
                  pattern: /^([0-9]{1,10})(\.\d{1,2})?$/,
                  message: '请输入(1-10位，后可跟两位小数)高值'
                }
              ]}
            >
              <Input className='g_w_174' />
            </Item>
          </Col>
          <Col className='unit-1' span={3}>
            pg/mL
          </Col>
        </Row>
        <Divider plain />
        <div className='g_txt_c'>
          <Button className='g_mr_20' icon={<RollbackOutlined />} onClick={onCancel}>
            关闭
          </Button>
          <Button type='primary' icon={<CheckOutlined />} htmlType='submit'>
            保存
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default AddWarningForm

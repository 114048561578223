/* 查看质控信息 */

/* 调用模块
---------------------------------------------------------------- */
import React from 'react'
import { Button, Card } from 'antd'
import { FileWordOutlined, RollbackOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 子模块
---------------------------------------------------------------- */
import BasicPane from './BasicPane'

/* 使用类型检查
---------------------------------------------------------------- */
ViewQC.prototype = {
  operation: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function ViewQC(props) {
  const { operation, goBack } = props

  /**
   * 渲染
   */
  return (
    <Card
      className='g_h_percent'
      size='small'
      title={
        <>
          <FileWordOutlined className='g_mr_10' />
          <span>查看 - 质控信息</span>
        </>
      }
      extra={
        <Button className='g_ml_10' icon={<RollbackOutlined />} onClick={goBack}>
          返回
        </Button>
      }
    >
      <BasicPane operation={operation} />
    </Card>
  )
}

export default ViewQC

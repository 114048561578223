/*血气列表详情 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Card, Divider, Button, Col, Row, Spin } from 'antd'
import { TagsOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqBloodGlucoseByPatientId } from '../../../api/index'

/* 使用类型检查
---------------------------------------------------------------- */
AddDetailsForm.propTypes = {
  patientRow: PropTypes.object,
  onCancel: PropTypes.func.isRequired
}

/* 唯一的导出模块
---------------------------------------------------------------- */
function AddDetailsForm(props) {
  const { patientRow, onCancel } = props

  // 加载中
  const [isLoading, setIsLoading] = useState(false)

  // 血气列表
  const [bloodGlucoseById, setBloodGlucoseById] = useState([])

  /**
   * 根据患者id统计血糖报表详情
   * @param {*} params
   */
  const getBloodGlucoseById = async (params) => {
    setIsLoading(true)
    try {
      const { data, status } = await reqBloodGlucoseByPatientId(params)
      if (status) {
        const result = data
        setBloodGlucoseById(result)
      }
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getBloodGlucoseById({ id: patientRow.id })
  }, [patientRow.id])

  /**
   * 渲染
   */
  return (
    <Card
      className='height-percent'
      size='small'
      title={
        <h3 className='margin-clear'>
          <TagsOutlined className='g_mr_10' />
          详情
        </h3>
      }
    >
      <Spin spinning={isLoading}>
        <div className='g_ml_40'>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              &nbsp;
            </Col>
            <Col className='g_txt_c' span={2}>
              总值
            </Col>
            <Col className='g_txt_c' span={2}>
              凌晨
            </Col>
            <Col className='g_txt_c' span={2}>
              早餐前
            </Col>
            <Col className='g_txt_c' span={2}>
              早餐后
            </Col>
            <Col className='g_txt_c' span={2}>
              午餐前
            </Col>
            <Col className='g_txt_c' span={2}>
              午餐后
            </Col>
            <Col className='g_txt_c' span={2}>
              晚餐前
            </Col>
            <Col className='g_txt_c' span={2}>
              晚餐后
            </Col>
            <Col className='g_txt_c' span={2}>
              睡前
            </Col>
            <Col className='g_txt_c' span={2}>
              随机
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              测量次数
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].num : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[8].num : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              血糖平均值
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].average : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[8].average : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              血糖标准差
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].sd : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[8].sd : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              血糖最大值
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].max : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].max : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              血糖最小值
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].min : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].min : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              理想频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].theIdealFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].theIdealFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              过高频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].highFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[8].highFrequency : ''}
            </Col>
          </Row>
          <Row className='g_mb_30'>
            <Col className='g_txt_c' span={2}>
              过低频率
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[9].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[0].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[1].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[2].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[3].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[4].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[5].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[6].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[7].underFrequency : ''}
            </Col>
            <Col className='g_txt_c' span={2}>
              {bloodGlucoseById.length > 0 ? bloodGlucoseById[8].underFrequency : ''}
            </Col>
          </Row>
        </div>

        <Divider plain className='g_mb_30'/>
        <div className='g_txt_c'>
          <Button className='g_mr_20 ' onClick={onCancel}>
            关闭
          </Button>
        </div>
      </Spin>
    </Card>
  )
}

export default AddDetailsForm

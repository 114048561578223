/* 录入 / 编辑仪器 */

/* 调用模块
---------------------------------------------------------------- */
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import PropTypes from 'prop-types'

/* 功能模块
---------------------------------------------------------------- */
import { reqAddOrEditLiquid, reqStromas } from '../../../api'

/* 调用模块: 对象解构
---------------------------------------------------------------- */
const { Item } = Form
const { Option } = Select

/* 使用类型检查
---------------------------------------------------------------- */
LiquidForm.propTypes = {
  monitor: PropTypes.array.isRequired,
  brands: PropTypes.array,
  qcLiquids: PropTypes.array,
  level: PropTypes.array.isRequired,
  operation: PropTypes.object,
  setForm: PropTypes.func.isRequired,
  setOperation: PropTypes.func.isRequired,
  refreshLiquids: PropTypes.func.isRequired
}

/* 唯一的模块导出
---------------------------------------------------------------- */
function LiquidForm(props) {
  const [form] = Form.useForm()

  const { monitor, brands, qcLiquids, level, operation, setForm, setOperation, refreshLiquids } = props

  // 品牌 id
  const [brandId, setBrandId] = useState(undefined)

  // 基质列表
  const [stromas, setStromas] = useState([])

  /**
   * api
   * 获取基质列表
   */
  const getStromas = async () => {
    const { data, status } = await reqStromas()
    if (status) {
      setStromas(data)
    }
  }

  /**
   * 查询
   * 选择框：选择品牌
   */
  const onChangeBrand = (value) => {
    form.setFieldsValue({ modelId: undefined })
    setBrandId(value)
  }

  /**
   * 通过品牌 id 显示对应的型号列表
   * @param {*} brandId
   */
  const showModels = (brandId) => {
    const result = brands.filter((v) => v.id === brandId)
    if ('children' in result[0]) {
      return result[0].children.map((v) => (
        <Option key={v.id} value={v.id}>
          {v.name}
        </Option>
      ))
    }
  }

  /**
   * 录入 / 编辑质控液
   * 按钮：点击确定
   * @param {*} value
   */
  const onOkLiquid = async (value) => {
    let msg // 提示信息
    let params // 表单参数
    if (operation.action === 'up') {
      msg = '编辑质控液成功！'
      params = {
        ...value,
        id: operation.data.id
      }
    } else {
      msg = '新增质控液成功！'
      params = value
    }
    const { status } = await reqAddOrEditLiquid(params)
    if (status) {
      setOperation()
      message.success(msg)
      refreshLiquids()
      form.resetFields()
      setBrandId(undefined)
    }
  }

  const onCancel = () => {
    setOperation()
    setBrandId(undefined)
  }

  /**
   * 生命周期
   */
  useEffect(() => {
    getStromas()
  }, [])

  useEffect(() => {
    setForm(form)
  }, [setForm, form])

  useEffect(() => {
    if (operation.action === 'up') {
      form.setFieldsValue(operation.data)
    }
  }, [form, operation.action, operation.data])

  /**
   * 渲染
   */
  return (
    <Form form={form} onFinish={onOkLiquid}>
      <Item
        name='type'
        label={<span className='g_txt_r g_w_85'>监测类型</span>}
        rules={[{ required: true, message: '请选择监测类型' }]}
      >
        <Select disabled={operation.action === 'up' ? true : false}>
          {monitor.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>

      <Item
        name='brandId'
        label={<span className='g_txt_r g_w_85'>品牌</span>}
        rules={[{ required: true, message: '请选择品牌' }]}
      >
        <Select onChange={onChangeBrand} disabled={operation.action === 'up' ? true : false}>
          {brands.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>

      <Item
        name='modelId'
        label={<span className='g_txt_r g_w_85'>型号</span>}
        rules={[{ required: true, message: '请选择型号' }]}
      >
        <Select disabled={operation.action === 'up' ? true : false}>
          {brandId ? showModels(brandId) : operation.action === 'up' && showModels(operation.data.brandId)}
        </Select>
      </Item>
      <Item
        name='controlThingsNameId'
        label={<span className='g_txt_r g_w_85'>质控液名称</span>}
        rules={[{ required: true, message: '请选择质控液名称' }]}
      >
        <Select disabled={operation.action === 'up' ? true : false}>
          {qcLiquids.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name='stromaId'
        label={<span className='g_txt_r g_w_85'>基质</span>}
        rules={[{ required: true, message: '请选择基质' }]}
      >
        <Select disabled={operation.action === 'up' ? true : false}>
          {stromas.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.name}
            </Option>
          ))}
        </Select>
      </Item>

      <Item
        name='thingsBatchNumber'
        label={<span className='g_txt_r g_w_85'>质控液主批号</span>}
        rules={[{ required: true,pattern:/^[a-zA-Z\d]{1,20}$/, message: '请输入(包含英文大小写，数字 1-20位)质控液主批号' }]}
      >
        <Input />
      </Item>

      <Item
        name='levelNum'
        label={<span className='g_txt_r g_w_85'>质控水平数</span>}
        rules={[{ required: true, message: '请选择质控水平数' }]}
      >
        <Select disabled={operation.action === 'up' ? true : false}>
          {level.map((v) => (
            <Option key={v.id} value={v.id}>
              {v.id}
            </Option>
          ))}
        </Select>
      </Item>

      <div className='g_txt_c'>
        <Button className='g_mr_20' key='back' onClick={onCancel}>
          取消
        </Button>
        <Button key='submit' type='primary' htmlType='submit'>
          确定
        </Button>
      </div>
    </Form>
  )
}

export default LiquidForm
